const superagent = require('superagent');
const localForage = require('localforage');
export interface EnvironmentVariables {
  backendServer: string,
  auth0Domain: string,
  audience: string,
  clientID: string,
  callbackUri: string,
  logoutUri: string
}

/*
export async function getEnvVariables() {
  const e:any = {
    backendServer: 'http://localhost:3010',
    callbackUri:'http://localhost:5000',
    auth0Domain:'sitem8-uat.au.auth0.com',
    audience:'https://uat.sitem8.com/api/',
    clientID:'8jy0jH9Q6br10box6od6WJBHMfTIpbkN',
  };

  let envVars = await localForage.getItem('env_vars');
  if(!envVars) {
    console.log('get env vars');
    const logoutUri = `https://${e.auth0Domain}/logout?client_id=${e.auth0ClientId}&returnTo=${encodeURIComponent(e.callbackUri)}/`;
    e.logoutUri = logoutUri;
    await localForage.setItem('env_vars', e);
    return e;
  } else {
    return e;
  }
}
*/


export async function getEnvVariables(): Promise<EnvironmentVariables> {
  
  let envVars = await localForage.getItem('env_vars');
  if(envVars) {
    return envVars;
  }

  const response = await superagent.get("/Env");
  const obj = JSON.parse(response.text);
  const envVariables = {
    backendServer: obj.backendServer,
    auth0Domain: obj.auth0Domain,
    audience: obj.auth0Audience,
    clientID: obj.auth0ClientId,
    callbackUri: obj.callbackUri,
    logoutUri: `https://${obj.auth0Domain}/logout?client_id=${obj.auth0ClientId}&returnTo=${encodeURIComponent(obj.callbackUri)}/`,
  }; 
  await localForage.setItem('env_vars', envVariables);
  return envVariables;
  
};


