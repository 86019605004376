import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  getShifts,
} from '../../API';
import {
  setShifts,
} from '../../redux/actions/AllActions';
import ScheduleWeekControl from './ScheduleWeekControl';
import ScheduleTable from './ScheduleTable';


export function WorkerShiftsPage(props) {
  useEffect(() => {
    getShifts().then(props._setShifts);
  }, []);
  return (
    <Grid container style={{padding: '20px', paddingLeft: '40px', paddingRight: '40px', overflowY: 'auto' }}>
      <Grid item xs={12}>
        <ScheduleWeekControl />
      </Grid>
      <Grid container justify="space-between">
        <Grid item xs={12}>
          <ScheduleTable />
        </Grid>
      </Grid>
    </Grid>
  )

}

const cellStyle = { 
  flexDirection: 'row',
  display: 'flex',
  flexGrow: 1,
  minHeight: '50px',
  margin: '1px',
  border: '1px black solid',
};

export const mapStateToProps = (state) => {
  const {
  } = state;

  return {
    workerGroups: state.workerGroups.all,
    workerShifts: state.workerShifts.all,
    shifts: state.shifts.all,
    sites: state.sites.all,
    workers: state.workers.all.filter(w => w.IsWorker),
  };
};

export const mapDispatchToProps = dispatch => ({
  _setShifts: (x) => setShifts(x),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkerShiftsPage));
  