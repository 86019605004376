import {
  SET_AUTH,
  SET_ME,
  SET_COMPANY,
  SET_DEVICES,
  SET_API_ROOT,
} from './actions/ActionTypes';
import { assign } from '../Utils';

const initialState = {
  auth: {},
  me: {},
  apiRoot: null,
  company: {
    Name: '',
    StartDayOfWeek: '',
    MinimumWorkingDayDuration: '',
    DailyTimesheetProcessing: '',
    DailyApprovalReminder: '',
    WorkingDayEarliestStart: '',
    WorkingDayDefaultStart: '',
    WorkingDayDefaultFinish: '',
    WorkingDayLatestFinish: '',
    MinimumLunchStart: '',
    DefaultLunchStart: '',
    DefaultLunchEnd: '',
    MaximumLunchEnd: '',
    MinimumWorkingTimeToRemoveLunchBreak: '',
    PrivateMode: '',
    PrivateModeStart: '',
    PrivateModeFinish: '',
    BufferDistanceMetres: '',
    GlitchRemovalPeriod: '',
  },
  devices: [],
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_ME: {
      return assign(state, { me: payload });
    }
    case SET_AUTH: {
      return assign(state, { auth: payload });
    }
    case SET_API_ROOT: {
      return assign(state, { apiRoot: payload});
    }
    case SET_COMPANY: {
      const company = { ...payload };
      if (company.CustomSettings) {
        try {
          company.customSettings = JSON.parse(company.CustomSettings);
        } catch (e) {
          company.customSettings = null;
        }
      } else {
        company.customSettings = null;
      }
      return assign(state, { company: assign(company, { needSave: false }) });
    }
    case SET_DEVICES: {
      return assign(state, { devices: payload });
    }
    default:
      return state;
  }
}
