import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import {
  postTimesheetNote,
  postSetTimesheetStatus,
  createWorker,
  editWorker,
  createSite,
  updateSite,
  postMyTimesheetNote,
  updateCompany,
  getWorkers,
  createZone,
  updateZone,
} from '../API';
import {
  ADD_TIMESHEET_NOTE,
  SET_TIMESHEET_STATUS, EDIT_WORKER,
  CREATE_WORKER, CREATE_SITE, UPDATE_SITE,
  SET_COMPANY, CREATE_ZONE, UPDATE_ZONE,
} from './actions/ActionTypes';
import { assign } from '../Utils';
import { WorkerActions } from './actions/AllActions';
import { PayloadAction } from './actions/types';


function siteParams(site, id) {
  const {
    WKT, Name, Active, SitePayrollId, SiteDeviceSettings, MinimumBreakTimeMinsForWorkDay,
    MinimumWorkingTimeHoursForBreak,
    DistanceBufferMeters
  } = site;
  return {
    WKT,
    SiteId: id,
    Name,
    Active: !!Active,
    SitePayrollId: SitePayrollId || '',
    SiteDeviceSettings,
    MinimumBreakTimeMinsForWorkDay,
    MinimumWorkingTimeHoursForBreak,
    DistanceBufferMeters
  };
}

function zoneParams(zone, id) {
  const {
    WKT, Name, SiteId,
  } = zone;
  return {
    WKT,
    ZoneId: id,
    SiteId,
    Name,
  };
}
// https://redux.js.org/advanced/middleware
export const apiMiddleware: Middleware = (middleware: MiddlewareAPI) => (next: Dispatch) => (action: PayloadAction<any>) => {
  // DO NOT RETURN OR CALL NEXT!!
  // The return next at the end of this function MUST run

  //export default function postChanges(middleware: MiddlewareAPI) {
  // next is the state changing function after the action is dispatched
    const { type, payload } = action;

    switch (type) {
      case ADD_TIMESHEET_NOTE:
        const { token } = action as any;
        if (token) {
          postMyTimesheetNote(token, payload);
        }
        postTimesheetNote(payload);
        break;
      case SET_TIMESHEET_STATUS:
        postSetTimesheetStatus(payload);
        break;
      case EDIT_WORKER:
        // Link to api. Edit create worker into database.
        editWorker(payload);
        break;
      case CREATE_WORKER:
        // Link to api. Create worker into database.
        // I don't think we can use promises like this in here
        createWorker(assign(payload, { WorkerId: 0 }))
          .then(() => getWorkers())
          .then(workerList => middleware.dispatch(WorkerActions.setWorkers(workerList)));
        break;
      case CREATE_SITE:
        createSite(siteParams(payload, 0));
        break;
      case UPDATE_SITE:
        updateSite(siteParams(payload, payload.SiteId));
        break;
      case CREATE_ZONE:
        createZone(zoneParams(payload, 0));
        break;
      case UPDATE_ZONE:
        updateZone(zoneParams(payload, payload.ZoneId));
        break;
      case SET_COMPANY:
        if (payload.needSave) {
          const comped = assign(payload, {});
          delete comped.needSave;
          updateCompany(comped);
        }
        break;

      default:
        break;
    }   

    return next(action);
}