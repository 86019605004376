import React from 'react';
import TimeKeeper from 'react-timekeeper';
import {
  Select, MenuItem, OutlinedInput, FormHelperText,
} from '@material-ui/core';

export function ModalTimePicker({ time, onChange, onComplete }) {
  return (
    <div className="modal display-block">
      <section className="modal-main">
        <TimeKeeper
          time={time}
          onChange={onChange}
          onDoneClick={onComplete}
          switchToMinuteOnHourSelect
        />;
      </section>
    </div>
  );
}
// pass a list of objects like [{ name: 'p', age: 1 }] valKey name, textKey age
// if the value is null the select will pass the
// text as the value so use an empty string instead

export function SelectInput({
  list, name, textKey, onChange, value,
}) {
  const options = list.map(
    (o, i) => (
      <MenuItem value={o[name] || ''} key={i}>
        {o[textKey]}
      </MenuItem>
    ),
  );
  return (
    <Select
      style={{ width: '90%', maxWidth: '300px' }}
      value={value}
      onChange={onChange}
      input={<OutlinedInput labelWidth={0} id={`select-${name}`} value={value || ''} />}
      autoWidth
    >
      { options }
    </Select>

  );
}
