import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import Paper from '@material-ui/core/Paper';
import { assign } from '../../Utils';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 900,
  },
});


class UsersTable extends Component {
  state = {
    rowsPerPage: 50,
  }

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  }

  render() {
    const {
      rows, labels, onRowClick,
    } = this.props;
    if (!rows.length) {
      return null;
    }
    const options = {
      filterType: 'multiselect',
      selectableRows: false,
      responsive: 'scroll',
      rowsPerPage: this.state.rowsPerPage,
      rowsPerPageOptions: [10, 20, 50, 100],
      onRowClick: (a) => {
        onRowClick(a[a.length - 1]);
      },
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
    };
    return (
      <div className="manage-users-table">
        <MUIDataTable
          data={rows}
          columns={labels}
          options={options}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>

    );
  }
}

export default UsersTable;
