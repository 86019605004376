import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import {
  getWorkerGroups,
  getWorkers,
  getAllSites,
  getShifts,
} from '../../API';
import {
  setWorkerGroups,
  WorkerActions,
  setSites,
  setShifts,
} from '../../redux/actions/AllActions';
import ScheduleRow from './ScheduleRow';
import { cellStyle, cellTextStyle } from './ScheduleCell';


export function ScheduleTable(props) {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    Promise.all([getWorkers(), getWorkerGroups(), getAllSites(), getShifts()]).then(data => {
      const [w, wg, s, sh] = data;
      props._setWorkerGroups(wg);
      props._setWorkers(w);
      props._setSites(s);
      props._setShifts(sh);
      setReady(true);
    });
  }, []);
  if(!ready) {
    return <div />;
  }
  const { workerGroups, workers, shifts, sites, workerShifts } = props;
  const dayRow = (
    <Grid key={'weekday-row'} container style={{overflowY: 'auto' }} justify="flex-start" alignItems="flex-start">
      <Grid item style={{minWidth: '12%'}}>
      </Grid>
      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
        (d, i) => <Grid item style={cellStyle} key={`${d}-${i}`}><p style={cellTextStyle}>{d}</p></Grid>
      )}
    </Grid>
  );
  const workerRows = workers.map(
    (w, i) => <ScheduleRow 
      key={`w${i}}`} 
      shifts={shifts} 
      sites={sites} 
      worker={w} 
      workerGroup={null} 
      workerShifts={workerShifts}
    />);
  const groupRows = workerGroups.map(
    (g, i) => <ScheduleRow 
      key={`g${i}}`}
      shifts={shifts} 
      sites={sites} 
      worker={null} 
      workerGroup={g} 
      workerShifts={workerShifts}
    />);
  return (
    <Grid container style={{ overflow: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
      { [ dayRow, ...workerRows, ...groupRows ] }
    </Grid>
  );

}

export const mapStateToProps = (state, ownProps) => {
  const {
    workerShifts,
    shifts,
    workers,
    sites,
    workerGroups,
  } = state;
  const { showGroups } = workerShifts.uiState;
  return {
    shifts: shifts.all,
    workers: !showGroups ? workers.all.filter(w => w.IsWorker) : [],
    workerGroups: showGroups ? workerGroups.all : [],
    sites: sites.all,
    workerShifts: workerShifts.all,
  };
};

export const mapDispatchToProps = dispatch => ({
  _setWorkerGroups: (x) => dispatch(setWorkerGroups(x)),
  _setWorkers: (x) => dispatch(WorkerActions.setWorkers(x)),
  _setSites: (x) => dispatch(setSites(x)),
  _setShifts: (x) => dispatch(setShifts(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleTable));
  