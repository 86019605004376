import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {

} from '../../API';
import {

} from '../../redux/actions/AllActions';
import { styles } from '../Approvals/Approvals';
import CreateWorkerGroupForm from './CreateWorkerGroupForm';
import WorkerGroupList from './WorkerGroupList';
import SetWorkersForGroupForm from './SetWorkersForGroupForm';

export function WorkerGroupsPage(props) {
  return (
    <Grid container style={{padding: '20px'}}>
      <Grid item xs={12}>
        This is a title for groups
      </Grid>
      <Grid item xs={4}>
        <WorkerGroupList />
        <CreateWorkerGroupForm />
      </Grid>
      <Grid item xs={8}>
        <SetWorkersForGroupForm />
      </Grid>

    </Grid>
  )

}

export const mapStateToProps = (state) => {
  const {
  } = state;

  return {
  };
};

export const mapDispatchToProps = dispatch => ({
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(WorkerGroupsPage)));
  