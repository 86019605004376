import { handle, Handlers } from 'redux-pack';
import { PayloadAction } from '../actions/types'
import { COVIDCASE_ActionTypes } from '../actions/CovidCaseActions';
import { FormModelBase, RestModelBase, FormFieldType, FormOption} from '../../RestClient/RestApiMicroFormat';
import { CovidCase, Worker, CovidCaseHistoryItem, CovidCaseHistory } from '../../api/index';


export type CovidCaseApiModel = CovidCase & RestModelBase

export interface CovidCaseState {  
  isLoading: boolean;
  error?: string;
  workerUri?: string; // To get us going from a legacy sytem
  debugLastCommand: string;
  supervisor?:  Worker;
  createForm?: FormModelBase;
  covidCase? : CovidCaseApiModel;
  covidCaseList : Array<CovidCaseApiModel>;
  updateForm?: FormModelBase;
  covidStatusList: Array<FormOption>;
  covidCaseHistory?: CovidCaseHistory;
}

const initialState = {
  debugLastCommand: "iniital-state",
  isLoading: false,
  status: "None",
  covidCaseList: [],
  covidCase: {uri: ''},
  covidStatusList: [],
};

export default function covidCaseReducer(state: CovidCaseState, action: PayloadAction<any>): CovidCaseState {
  const newState = makeNewState(state, action);
  return newState;
}

function makeNewState(state: CovidCaseState = initialState, action: PayloadAction<any>): CovidCaseState {
  
  const commonOnStartHandler = (s: CovidCaseState, a: PayloadAction<any>) => ({
    ...s, 
    isLoading: true, 
    debugLastCommand: a.type+"start",
  });

  const commonErrorHandler =   (s: CovidCaseState,a: PayloadAction<Error>) => ({
     ...s, 
     error: a.payload.message, 
     debugLastCommand:a.type+"+error" 
    });
  

  switch (action.type) {
    case COVIDCASE_ActionTypes.GET_SUPERVISOR:{
      const handlerActions : Handlers<CovidCaseState, Worker, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => ({
          ...s, 
          supervisor:a.payload, 
          isLoading:false, 
          debugLastCommand:a.type+"+success"
        }),
      };

      return handle(state, action, handlerActions);
    }

    case COVIDCASE_ActionTypes.GET_CASE_FORM:{
      const handlerActions : Handlers<CovidCaseState, FormModelBase, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => {
          const optionField = a.payload.attributes.find(
            x => (x.type === FormFieldType.OPTIONS && x.name === "caseStatus"));
          return {
            ...s, 
            createForm:a.payload, 
            covidStatusList: optionField!.options!,
            isLoading:false, 
            debugLastCommand:a.type+"+success"
          }
        },
      };

      return handle(state, action, handlerActions);
    }


    case COVIDCASE_ActionTypes.GET_UPDATE_FORM:{
      const handlerActions : Handlers<CovidCaseState, FormModelBase, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => {
          const optionField = a.payload.attributes.find(
            x => (x.type === FormFieldType.OPTIONS && x.name === "caseStatus"));
          return {
            ...s, 
            updateForm:a.payload, 
            covidStatusList: optionField!.options!,
            isLoading:false, 
            debugLastCommand:a.type+"+success"
          }
        },
      };

      return handle(state, action, handlerActions);
    }

    case COVIDCASE_ActionTypes.GET_CASE:{
      const handlerActions : Handlers<CovidCaseState, CovidCaseApiModel, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s, a) => ({
          ...s, 
          covidCase:a.payload,
          isLoading:false, 
          debugLastCommand:a.type+"+success"
        }),
      };

      return handle(state, action, handlerActions);
    }

    case COVIDCASE_ActionTypes.FIND_WORKERS_CASE: {
      const handlerActions : Handlers<CovidCaseState, CovidCaseApiModel, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => ({
          ...s, 
          covidCase:a.payload,
          isLoading:false, 
          debugLastCommand:a.type+"+success"
        }),
      };

      return handle(state, action, handlerActions);
    }

    case COVIDCASE_ActionTypes.CREATE_CASE:{
      const handlerActions : Handlers<CovidCaseState, CovidCaseApiModel, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => {
          const newState: CovidCaseState = {
            ...s,           
            covidCase:a.payload,
            covidCaseList: [...s.covidCaseList, a.payload],
            isLoading:false, 
            debugLastCommand:a.type+"+success"
          };

          return newState;
        }
      };
      return handle(state, action, handlerActions);
    }

    case COVIDCASE_ActionTypes.SET_COVIDCASE_LIST:{
      const handlerActions : Handlers<CovidCaseState, Array<CovidCaseApiModel>, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => {
          const newState: CovidCaseState = {
            ...s,           
            covidCaseList:a.payload, 
            isLoading:false, 
            debugLastCommand:a.type+"+success"
          };

          return newState;
        }
      };

      return handle(state, action, handlerActions);
    }

    case COVIDCASE_ActionTypes.GET_HISTORY_LIST:{
      const handlerActions : Handlers<CovidCaseState, CovidCaseHistory, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => {
          const newState: CovidCaseState = {
            ...s,           
            covidCaseHistory:a.payload, 
            isLoading:false, 
            debugLastCommand:a.type+"+success"
          };
          return newState;
        }
      };

      return handle(state, action, handlerActions);
    }

    case COVIDCASE_ActionTypes.CLEAR_SELECTED_COVID_CASE: {
      return { 
        ...state, covidCase: initialState.covidCase, 
        createForm: undefined,
        covidCaseHistory: undefined,
        updateForm: undefined,
      };
    }

    default:
      return state;
  }
}