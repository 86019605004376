import {
  Grid,
  Tab,
  Tabs
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { getWorkers } from '../../API';
import styles from '../Common/FormStyles';
import AddSuperAdminForm from './AddSystemAdminForm';
import CreateCompanyForm from './CreateCompanyForm';
import DeviceFirmwareForm from './DeviceFirmwareForm';
import Reports from './Reports';
import WorkerTimeline from './WorkerTimeline';
import WifiBeaconPage from './WifiBeacons/WifiBeaconPage';
import LabourAgencies from './LabourAgencies';
import ManageCompanys from './ManageCompanys';
import ManualEmailSender from './ManualEmailSender';
import SMSSendingPage from './SMSSendingPage';
import SMSInterface from './SMSInterface';
import SupportDashboard from './SupportDashboard';


class SuperAdminPage extends Component {
  state = {
    selectedTab: 11,
  }

  componentDidMount() {

  }

  handleTabSelect = async (evt, tab) => {
    if( tab == 5) {
      await getWorkers().then(workers => this.setState({ workers }));
    }
    this.setState({ selectedTab: tab });
  }

  render() {
    const { selectedTab } = this.state;
    return (
      <Grid
        container
        style={{
          height: '100vh', width: '100vw', paddingBottom: '70px', overflow: 'scroll',
        }}
      >
        <Grid item xs={12}>
          <Tabs value={selectedTab} 
            onChange={this.handleTabSelect} 
            variant="scrollable"
            scrollButtons="on"
          >
              <Tab label="Company" />
              <Tab label="Add Admin" />
              <Tab label="Device Config" />
              <Tab label="Reports" />
              <Tab label="Wifi Beacons" />
              <Tab label="Worker Timeline" />
              <Tab label="Labour Agencies" />
              <Tab label="Manage Companies" />
              <Tab label="Manual Emails" />
              <Tab label="Manual SMS" />
              <Tab label="Contacts" />
              <Tab label="Support" />
          </Tabs>
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 0}>
          { selectedTab == 0 && <CreateCompanyForm /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 1}>
          { selectedTab == 1 && <AddSuperAdminForm /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 2}>
          { selectedTab == 2 && <DeviceFirmwareForm /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 3}>
        { selectedTab == 3 && <Reports /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 4}>
          { selectedTab == 4 && <WifiBeaconPage /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 5}>
          { selectedTab == 5 && <WorkerTimeline workers={ this.state.workers } /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 6} style={{overflow: 'scroll'}}>
          { selectedTab == 6 && <LabourAgencies  /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 7} style={{overflow: 'scroll'}}>
          { selectedTab == 7 && <ManageCompanys  /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 8} style={{overflow: 'scroll'}}>
        { selectedTab == 8 && <ManualEmailSender  /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 9} style={{overflow: 'scroll'}}>
        { selectedTab == 9 && <SMSSendingPage /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 10} style={{overflow: 'scroll'}}>
          { selectedTab == 10 && <SMSInterface /> }
        </Grid>
        <Grid item xs={12} hidden={selectedTab !== 11} style={{overflow: 'scroll'}}>
          { selectedTab == 11 && <SupportDashboard /> }
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SuperAdminPage);
