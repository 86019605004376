import {
  EDIT_UNPAID_TIMESHEET_ENTRY,
  SET_UNPAID_TIMESHEET_ENTRIES,
} from './actions/ActionTypes';
import { assign, replaceInArray } from '../Utils';

const id = 'UnpaidTimesheetEntryId';

const initialState = {
  all: [],
};

export default function (state = initialState, action) {
  const { all } = state;
  const { payload } = action;
  switch (action.type) {
    case EDIT_UNPAID_TIMESHEET_ENTRY: {
      return assign(state, { all: replaceInArray(all, payload, id) });
    }
    case SET_UNPAID_TIMESHEET_ENTRIES: {
      return assign(state, { all: payload });
    }
    default:
      return state;
  }
}
