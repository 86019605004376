import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function Loading(props) {
  if (!props.loading) {
    return null;
  }
  return (
    <ReactLoading type="bars" color="#000" className="loading" />
  );
}

const mapStateToProps = ({ ui }) => ({ loading: ui.loading });

const mapDispatchToProps = (dispatch, ownProps) => ({
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Loading));
