/* eslint-disable */
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
export const drawerWidth = 320;
export const styles = theme => ({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    marginLeft: 'auto',
    paddingRight: '10px',
  },
  fullPageDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    marginTop: '70px',
    overflowY: 'scroll',
    height: 'calc(100vh - 68px)',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    marginTop: '70px',
    overflowY: 'scroll',
    height: 'calc(100vh - 68px)',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpenWide: {
    width: '100vw',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen * 0.7,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 6,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 8,
    },
  },
  drawerHidden: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    width: '100vw',
    height: 'calc(100vh - 68px)',
    overflowY: 'scroll',
  },
  heading: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  modalPaper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }
});
export const  mapStyle = {
  flexGrow: 1,
  padding: 0,
  width: '100%',
  height: 'calc(100vh - 60px)',
};
