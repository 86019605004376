import { Button, FormControl, FormHelperText, Grid, TextField } from "@material-ui/core";
import moment from "moment";
import MUIDataTable from 'mui-datatables';
import React, { Component } from "react";
import { getCompanies, getSitesByCompany, getBatteryConsumptionReport } from "../../API";
import { SelectInput } from "../FormComponents";

enum DeviceMode {
    GpsOn = 6,
    Sleep = 1,
    GpsSleep = 5,
    Movement = 7
}


const columns = [
    { name: 'date', label: 'Date' },
    { name: 'userName', label: 'User Name' },
    { name: 'locations', label: 'Locations' },
    
    { name: 'earlyStartTime', label: 'Early Start Time' },
    { name: 'earlyStartMode', label: 'Early Mode' },
    { name: 'earlyStartInterval', label: 'Early Interval' },

    { name: 'defaultStartTime', label: 'Default Start Time' },
    { name: 'defaultStartMode', label: 'Default Mode' },
    { name: 'defaultStartInterval', label: 'Default Interval (min)' },

    { name: 'defaultFinishTime', label: 'Default Finish Time' },
    { name: 'defaultFinishMode', label: 'Finish Mode' },
    { name: 'defaultFinishInterval', label: 'Finish Interval (min)' },
    { name: 'lateFinishTime', label: 'Late Finish Time' },

    { name: 'battPercentageFirstUpdate', label: 'Batt% First Update' },
    { name: 'battPercentageLastUpdate', label: 'Batt% Last Update' },

    { name: 'startFirstUpdate', label: 'Start First Update' },
    { name: 'finishLastUpdate', label: 'Finish Last Update' },

    { name: 'battUsed', label: 'Batt Used' },

    { name: 'hourWorked', label: 'Hour Worked' },
    { name: 'battDrainPerHour', label: 'Batt Drain/Hour' },
];


export default class Reports extends Component {
    state = {
        company: 1,
        site: 0,
        earliestStartDate: moment().format('YYYY-MM-DD'),
        latestFinishDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        rows: [],
        companies: [],
        sites: [],
        loaded: true
    };

    componentDidMount() {
        this.loadData();
    }

    getData = async () => {
        let batteryConsumptionReport = await getBatteryConsumptionReport(
            this.state.site == 0 ? this.state.sites
                .filter(s => s.SiteId != 0)
                .map(s => s.SiteId) :
                [this.state.site],
            this.state.earliestStartDate,
            this.state.latestFinishDate);

        const rowList = batteryConsumptionReport.map((w, i) => ({
            id: i,
            userName: w.WorkerName,
            date: moment(w.Date).format('YYYY-MM-DD'),
            locations: w.Location,
            earlyStartTime: w.EarlyStartTime,
            earlyStartMode: DeviceMode[w.EarlyStartMode],
            earlyStartInterval: w.EarlyStartInterval,
            defaultStartTime: w.DefaultStartTime,
            defaultStartMode: DeviceMode[w.DefaultStartMode],
            defaultStartInterval: w.DefaultStartInterval,
            defaultFinishTime: w.DefaultFinishTime,
            defaultFinishMode: DeviceMode[w.DefaultFinishMode],
            defaultFinishInterval: w.DefaultFinishInterval,
            lateFinishTime: w.LateFinishTime,
            battPercentageFirstUpdate: `${w.BatteryPercentageFirstUpdate}.00%`,
            battPercentageLastUpdate: `${w.BatteryPercentageLastUpdate}.00%`,
            startFirstUpdate: w.StartFirstUpdate,
            finishLastUpdate: w.FinishLastUpdate,
            battUsed: `${w.BatteryUsed}.00%`,
            hourWorked: w.HoursWorked,
            battDrainPerHour: `${w.BatteryDrainPerHour.toFixed(2)}%`
        }))

        this.setState({ rows: rowList });
    }

    loadData = async () => {
        // Get the required data for the report
        const comps = await getCompanies();
        const sites = await getSitesByCompany(1);
        this.setState({
            companies: comps.filter(c => !c.IsArchived),
            sites: [{ SiteId: 0, Name: 'All Sites' }].concat(sites.filter(x => x.Active)),
            site: 0
        });
    }

    handleCompanyChange = async ({ target }) => {
        const { value } = target;
        this.setState({
            company: value,
            sites: [{ SiteId: 0, Name: 'All Sites' }].concat(await getSitesByCompany(value)),
            site: 0
        });
    }

    handleSiteChange = ({ target }) => {
        const { value } = target;
        this.setState({ site: value })
    }

    handleEarliestStartDateChange = ({ target }) => {
        const { value } = target;
        this.setState({ earliestStartDate: value });
    }

    handleLatestFinishDateChange = ({ target }) => {
        const { value } = target;
        this.setState({ latestFinishDate: value })
    }

    onGenerateReportClick = async () => {
        this.setState({ loaded: false });
        await this.getData();
        this.setState({ loaded: true });
    }

    render() {
        return <Grid item xs={10} style={{ margin: 'auto' }}>
            <h1>Battery consumption (%) per hour</h1>
            <Grid item xs={6} sm={6} md={3}>
                <FormControl fullWidth>
                    <FormHelperText>
                        Select company
                    </FormHelperText>
                    <SelectInput
                        textKey="Name"
                        onChange={this.handleCompanyChange}
                        value={this.state.company}
                        list={this.state.companies.map(c => ({
                            Name: c.Name,
                            CompanyId: c.CompanyId,
                        }))}
                        name="CompanyId"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
                <FormControl fullWidth>
                    <FormHelperText>
                        Select site
                    </FormHelperText>
                    <SelectInput
                        textKey="Name"
                        onChange={this.handleSiteChange}
                        value={this.state.site}
                        list={this.state.sites.map(c => ({
                            Name: c.Name,
                            SiteId: c.SiteId,
                        }))}
                        name="SiteId"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
                <FormControl>
                    <TextField
                        id="EarliestStartDate-input"
                        label="Earliest Start"
                        type="date"
                        name="EarliestStart"
                        value={this.state.earliestStartDate}
                        onChange={this.handleEarliestStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
                <FormControl>
                    <TextField
                        id="LatestFinishDate-input"
                        label="Latest Finish"
                        type="date"
                        name="LatestFinish"
                        value={this.state.latestFinishDate}
                        onChange={this.handleLatestFinishDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <FormControl>
                    <Button
                        onClick={this.onGenerateReportClick}
                        variant="contained"
                        color="primary"
                        disabled={!this.state.loaded}
                    >
                        Generate Report
                    </Button>
                </FormControl>
            </Grid>
            {/* <Line
                data={data}
            /> */}
            < div style={{ height: '100vh', width: '100%' }}>
                <MUIDataTable
                    style={{ textOverflow: 'ellipsis' }}
                    columns={columns}
                    data={this.state.rows}
                    options={{
                        selectableRows: 'none',
                        viewColumns: true,
                        responsive: 'scroll',
                        rowsPerPage: 50,
                        rowsPerPageOptions: [50, 100, 250, 500, 1000],
                        print: false,
                        downloadOptions: {
                            filterOptions: {
                                useDisplayedColumnsOnly: true,
                                useDisplayedRowsOnly: true,
                            },
                        },
                        textLabels: {
                            body: {
                                noMatch: 'Please click generate report for a company/site and start/finish date that has data'
                            }
                        }
                    }}
                    id={"reports-table"}
                />
            </div >
        </Grid >
    }
}