import React, { useState } from 'react';
import { 
  Grid,
  List,
  ListItem,
  Modal,
  ListItemText,
  Button
} from '@material-ui/core';
import CreateShiftForm from '../Shifts/CreateShiftForm';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  setTimeFromString,
} from  '../../Utils';
import {
  postWorkerShift,
  getWorkerShifts,
} from '../../API';
import {
  setWorkerShifts,
} from '../../redux/actions/AllActions';


function getSiteName(sites, shift) {
  return sites.find(s => s.SiteId === shift.SiteId).Name;
}


function saveShift(siteId, startTime, endTime, day, workerId, shiftId=null, workerGroupId=null, multiSite=null) {
  const newShift = {
    SiteId: siteId,
    StartDateTime: setTimeFromString(day, startTime).format('YYYY-MM-DD HH:mm:ss'),
    EndDateTime: setTimeFromString(day, endTime).format('YYYY-MM-DD HH:mm:ss'),
    WorkerId: workerId,
    ShiftId: shiftId,
    WorkerGroupId: workerGroupId || null,
    MultiSite: !!multiSite,
  };    
  return postWorkerShift(newShift);

}


export function ScheduleShiftsModal(props) {
  const { allShifts, day, workerGroup, worker, open, toClose, sites, week, workersInGroup } = props;
  
  const workerId = worker && worker.WorkerId;
  
  const workerGroupId = workerGroup && workerGroup.WorkerGroupId;

  const afterSave = () => getWorkerShifts(week[0].format('YYYY-MM-DD HH:mm'), week[6].format('YYYY-MM-DD HH:mm')).then(
    props._setWorkerShifts).then(
      () => toClose(!open));

  const onSingleShiftSave = (siteId, startTime, endTime, _a, _b, _c, _d, multiSite) => {
    const saveFunc = workerGroupId ? Promise.all(
      workersInGroup.map(w => saveShift(siteId, startTime, endTime, day, w.WorkerId, null, workerGroupId, multiSite))) : saveShift(siteId, startTime, endTime, day, workerId, null, workerGroupId, multiSite);
    return saveFunc.then(afterSave);
  }

  const onShiftClick = (shift) => {

    const saveFunc = workerGroupId ? Promise.all(
      workersInGroup.map(w => saveShift(shift.SiteId, shift.StartTime, shift.EndTime, day, w.WorkerId, shift.ShiftId, workerGroupId, shift.MultiSite))) : saveShift(
        shift.SiteId, shift.StartTime, shift.EndTime, day, workerId, shift.ShiftId, null, shift.MultiSite);

    return saveFunc.then(afterSave);
  }

  return <Modal 
    open={!!open}
    disableEnforceFocus 
    disableAutoFocus
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div style={modalStyle}>
      <Grid container>
        <Grid item xs={12}>
          <div>
            { (worker || workerGroup).Name }
          </div>
          { day.format('dddd MM YY') }
        </Grid>
        <Grid item xs={6}>
          <List>
          { allShifts.map(s => <ListItem key={s.ShiftId} onClick={() => onShiftClick(s)}>
            <ListItemText
              primary={`${s.Name} ${s.StartTime.slice(0,5)} - ${s.EndTime.slice(0,5)}`}
              secondary={getSiteName(sites, s)}
            />
            </ListItem>) }
          </List>
        </Grid>
        <Grid item xs={6}>
          <div>
            Create an individual shift
          </div>
          <CreateShiftForm saveShift={onSingleShiftSave} noName />
        </Grid>
      </Grid>
      <Button onClick={() => { toClose(!open); }}>
        Cancel
      </Button>
    </div>
  </Modal>
}

const modalStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  padding: '20px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  maxHeight: '80%',
  overflow: 'scroll',
}

export const mapStateToProps = (state, ownProps) => {
  const {
    shifts,
    sites,
    workers,
    workerShifts,
  } = state;
  
  return {
    allShifts: shifts.all.filter(s => !s.Archived),
    sites: sites.all,
    workers: workers.all,
    week: workerShifts.uiState.week,
    workersInGroup: ownProps.workerGroup && workers.all.filter(
      w => w.WorkerGroupId === ownProps.workerGroup.WorkerGroupId),
  };
};

export const mapDispatchToProps = dispatch => ({
  _setWorkerShifts: (x) => dispatch(setWorkerShifts(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleShiftsModal));
  