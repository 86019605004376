import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  isMobile,
} from 'react-device-detect';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Drawer,
  IconButton,
  Grid,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import ZoneForm from './ZoneForm';
import ZonesList from './ZonesList';
import UpdateZoneMap from './UpdateZonesMap';
import { SelectInput } from '../FormComponents';
import {
  setZones,
  setSites,
} from '../../redux/actions/AllActions';
import { getAllZones, getAllSites } from '../../API';
import { styles, mapStyle } from '../Common/GlobalStyles';
import {
  STREET_MAP_URI,
  SITEM8_MAP_URI,
  RACE_MAP_URI,
  SAT_MAP_URI,
} from '../../Constants/Constants';

export function hideDrawControls() {
  const elems = document.getElementsByClassName('mapboxgl-ctrl-group');
  [...elems].forEach(el => el.classList.add('disabled'));
}

export function showDrawControls() {
  const elems = document.getElementsByClassName('mapboxgl-ctrl-group');
  [...elems].forEach(el => el.classList.remove('disabled'));
}


export class SetupZone extends Component {
  state = {
    editingZone: null,
    newZone: null,
    polygon: null,
    open: !isMobile,
    selectedSiteId: null,
    baseMapUri: SAT_MAP_URI,
  }

  toggleDrawer = (open) => {
    this.setState({ open });
  }

  handleBaseMapChange = (e) => {
    this.setState({ baseMapUri: e.target.value});
  }

  componentDidMount() {
    this.getData().then(hideDrawControls);
  }

  // Draw a new polygon
  handleDrawCreate = (wktPolygon) => {
    this.setState({ polygon: wktPolygon });
    showDrawControls();
  }

  // Delete polygon
  handleDrawDelete = () => {
    this.setState({ polygon: null });
  }


  handleDrawUpdate = (wktPolygon) => {
    this.setState({ polygon: wktPolygon });
  }


  handleZoneClick = (zone) => {
    this.setState({ newZone: null, editingZone: zone });
    showDrawControls();
  }

  handleMapClick = (zoneId) => {
    const { newZone, editingZone, zones } = this.props;
    if (!newZone && !editingZone) {
      const zone = zones.find(s => s.ZoneId === zoneId);

      if (zone) {
        this.setState({ newZone: null, editingZone: zone });
        showDrawControls();
      }
    }
  }

  handleCancelClick = () => {
    this.setState({ editingZone: null, newZone: null });
    this.setState({ polygon: null });
    hideDrawControls();
    setTimeout(this.getData, 500);
  }

  handleNewZoneClick = (newZone) => {
    const { selectedSiteId } = this.state;
    if (!selectedSiteId) {
      return;
    }
    this.setState({
      newZone: { ...newZone, SiteId: selectedSiteId },
      editingZone: null,
    });
    showDrawControls();
  }

  getData = async () => {
    const zones = await getAllZones();
    const sites = await getAllSites();
    const {
      setAllZones,
      setAllSites,
    } = this.props;
    setAllSites(sites);
    setAllZones(zones);
  }

  onSiteChange = ({ target }) => {
    this.setState({ selectedSiteId: target.value });
  }

  render() {
    const { zones, classes, sites } = this.props;
    const {
      editingZone, newZone, polygon, open, selectedSiteId,
    } = this.state;
    const showForm = !!(editingZone || newZone);
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
          classes={{
            paper: classNames(classes.drawer, open ? classes.drawerOpen : classes.drawerClose),
          }}
          open={open}
        >
          {open ? (
            <div className={classes.toolbar} onClick={() => this.toggleDrawer(false)}>
              <IconButton>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          ) : (
            <div onClick={() => this.toggleDrawer(true)}>
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </div>
          )}
          {open && showForm && (
            <Grid item style={{ marginTop: '10px', paddingBottom: '10px', marginLeft: '10px' }}>
              <Select onChange={this.handleBaseMapChange} value={this.state.baseMapUri}>
                <MenuItem value={STREET_MAP_URI}>streets</MenuItem>
                <MenuItem value={SITEM8_MAP_URI}>custom</MenuItem>
                <MenuItem value={RACE_MAP_URI}>custom2</MenuItem>
                <MenuItem value={SAT_MAP_URI}>satellite</MenuItem>
              </Select>
            </Grid>
          )}
          { open && (
            <FormControl>
              <SelectInput
                textKey="text"
                fullwidth
                onChange={this.onSiteChange}
                value={selectedSiteId}
                list={sites.map(s => ({
                  text: s.Name,
                  SiteId: s.SiteId,
                }))
                }
                name="SiteId"
              />
              <FormHelperText>
                  Select site
              </FormHelperText>
            </FormControl>
          )}
          { open && !showForm && (
            <ZonesList
              zones={zones.filter(z => z.SiteId == selectedSiteId)}
              onZoneClick={this.handleZoneClick}
              onNewZoneClick={this.handleNewZoneClick}
            />
          )}
          { open && showForm && (
            <ZoneForm
              onCancelClick={this.handleCancelClick}
              onSaveClick={this.handleSaveClick}
              zone={editingZone || newZone}
              polygon={polygon}
              editingZone={editingZone}
              newZone={newZone}
            />
          )}
        </Drawer>

        <main className={classes.content} style={{ padding: 0, flexGrow: 1 }}>
          <UpdateZoneMap
            open={open}
            onDrawCreate={this.handleDrawCreate}
            onDrawDelete={this.handleDrawDelete}
            onDrawUpdate={this.handleDrawUpdate}
            zones={zones}
            sites={sites}
            editingZone={editingZone}
            newZone={newZone}
            polygon={polygon}
            onMapClick={this.handleMapClick}
            baseMapUri={this.state.baseMapUri}
          />
        </main>

      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { zones, sites } = state;
  return { zones: zones.all, sites: sites.all };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAllZones: ws => dispatch(setZones(ws)),
  setAllSites: ws => dispatch(setSites(ws)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, { withTheme: true })(SetupZone)));


// export default withRouter(ManageUsersContainer);
// <DevicesReportMap zones={zones} />
