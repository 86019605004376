import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Drawer from '@material-ui/core/Drawer';
import Links from './Links';
import SuperAdminForm from './SuperAdminForm';
import { getEnvVariables } from '../../Constants/EnvironmentVariables';
import { capitalize } from '../../Utils';
import { LINK_NAMES } from '../../Constants/Constants';
import { isIOS } from 'react-device-detect';
import logo from '../../Images/logo_dark.png';



const styles = () => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appbarYellow: {
    position: 'fixed',
    top: 0,
    maxWidth: '100vw',
    /* Reuben hack */
    backgroundColor: '#f5c800',
    color: '#564d4b',
  },
});

class MenuAppBar extends Component {
  state = {
    anchorEl: null,
    drawerOpen: false,
  };

  componentWillReceiveProps({ forceOut }) {
    // eslint-disable-next-line no-unused-expressions
    forceOut && this.logout();
  }

  handleMenu = ({ currentTarget }) => {
    this.setState({ anchorEl: currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleDrawer = (open) => {
    this.setState({ drawerOpen: open });
  }

  getTitle() {
    const parts = window.location.pathname.split('/');
    if (parts[1] === 'ts') {
      return '';
    }
    return LINK_NAMES[parts[1].replace('/', '')];
  }

  logout = async () => {
    await this.props.auth.logout();
    const envVariables = await getEnvVariables();
    window.location.replace(envVariables.logoutUri);
    /* eslint-disable */
    /*document.getElementById('root').parentNode.removeChild(
      document.getElementById('root'));*/
    /* eslint-enable */
  }

  render() {
    const {
      classes, accessToken, admin, companyName, userImg, onClick, superAdmin, user,
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const openDrawer = () => this.toggleDrawer(true);
    const closeDrawer = () => this.toggleDrawer(false);

    return (
      <div className={classes.root}>
        <Drawer open={this.state.drawerOpen} onClose={closeDrawer}>
          <div
            tabIndex={0}
            // eslint-disable-next-line jsx-a11y/aria-role
            role="Button"
            onClick={closeDrawer}
            onKeyDown={closeDrawer}
          >
            {accessToken && (
            <Links
              admin={admin}
              companyName={companyName}
              onClick={onClick}
              superAdmin={superAdmin}
              user={user}
            />
            ) }
          </div>
        </Drawer>
        <AppBar position="static" className={classes.appbarYellow}>
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              id="open-nav"
              aria-label="Menu"
              onClick={openDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              { this.getTitle() }
            </Typography>
            { superAdmin && <SuperAdminForm companyName={companyName} /> }
            {accessToken && (
              <div>
                { /*isIOS && <img src={logo} style={{height: '28px', width: '120px', position: 'absolute', top: '15px', left: '15px' }} /> */}
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  { userImg ? <img alt="user logo" className="user-logo" src={userImg} /> : <AccountCircle /> }
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.logout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(MenuAppBar);
