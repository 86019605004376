import {HttpClient, HttpHeader, HttpResponse}  from './RestClientTypes';

export function makeFetchHttpClient(hostUrl: string) : HttpClient {

  function makeHttpResponse(requestUri: string, requestVerb: string, response: Response): Promise<HttpResponse> {

    const headers = Array.from(response.headers)
      .map((x:Array<string>) => ({ key: x[0], value: x[1] })) as Array<HttpHeader>;

    return response.text()
      .then(responseText => ({
        requestVerb: requestVerb,
        requestUri: requestUri,
        status: response.status,
        bodyText: responseText,
        headers: headers
      }))
      .catch(e => ({
        requestVerb: requestVerb,
        requestUri: requestUri,
        status: response.status,
        bodyText: e.message,
        headers: headers
      }));
  }

  function makeUrl(uri:string): string{
    if(!uri){
      debugger;
      throw new Error("Empty Rest Request Url encountered")
    }
    
    if(!hostUrl){
      return uri;
    }

    if(uri.startsWith('http')){
      return uri;
    }

    return `${hostUrl}/${uri}`;
  }

  function makeRequest(method, headers, body): RequestInit {   
    var request = { method, headers: {}, body:body, };
    headers.forEach(x => request.headers[x.key] = x.value);    
    return request; 
  }

  return { 
    get: ( uri, requestHeaders) => 
      fetch(makeUrl(uri), makeRequest('GET', requestHeaders, undefined))
        .then(response => makeHttpResponse(uri, 'GET', response)),
     
    post: (uri: string, body: any, requestHeaders) => 
      fetch(makeUrl(uri), makeRequest('POST', requestHeaders, body))
        .then(response => makeHttpResponse(uri, 'POST', response))
  }
}