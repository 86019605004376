import React, { Component } from 'react';
import {
  Modal,
  Grid,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import TimesheetForm from './TimesheetForm';
import TimesheetEntryEditor, { mapStateToProps, mapDispatchToProps } from './TimesheetEntryEditor';
import { assign } from '../../Utils';

export class TimesheetFormModal extends TimesheetEntryEditor {
  state = {
    origTimesheetEntry: null,
  }

  componentDidMount() {
    this.setState({ origTimesheetEntry: { ...this.props.timesheetEntry } });
  }

  onSaveClick = () => {
    this.setState({ origTimesheetEntry: null });
    this.props.onClose();
    this.props.onSaveClick();
  }

  onDeleteClick = (tse) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure?')) {
      this.props._deleteTimesheetEntry(assign(tse, { isNew: false }));
      setTimeout(this.onSaveClick, 200);
      this.props.onClose();
    }
  }

  handleCancelPress = (e, action) => {
    // if the entry has changed put it back
    const { origTimesheetEntry } = this.state;
    const { _editTimesheetEntry, timesheetEntryId, timesheetEntries } = this.props;
    const timesheetEntry = timesheetEntries.find(
      t => t.TimesheetEntryId === timesheetEntryId);
    if (JSON.stringify(origTimesheetEntry) !== JSON.stringify(timesheetEntry)) {
      _editTimesheetEntry(origTimesheetEntry);
    }
    this.setState({ origTimesheetEntry: null });
    if(origTimesheetEntry.isNew) {
      this.props._deleteTimesheetEntry(timesheetEntry);
    }
    this.props.onClose();
  }

  handleClose = (tse, action) => {
    const { onClose } = this.props;
    if (action === 'delete') {
      this.onDeleteClick();
    } else {
      this.setState({ origTimesheetEntry: null });
      onClose();
    }
  }

  render() {
    const {
      startDate, timesheetEntry,
    } = this.props;
    const isValid = this.isValid(timesheetEntry);
    return (
      <Modal
        open
        style={{ top: '40%' }}
      >
        <Grid container xs={12} justify="center" className="noselect" style={{ maxWidth: '1200px', margin: '0px auto 0px auto' }}>
          <Paper style={{ padding: '20px 12px 18px 20px' }}>
            <Grid container>
              <Grid xs={12}>
                <Typography variant="h6" gutterBottom>
                  { moment(startDate).format('dddd Do MMM') }
                </Typography>
              </Grid>
              <Grid xs={12} style={{ paddingRight: '8px' }}>
                <TimesheetForm
                  {...this.props}
                  isValid={isValid}
                  onInputChange={this.handleChange}
                  onClosePress={this.handleClose}
                  onDeleteClick={this.onDeleteClick}
                />
              </Grid>
              <Grid xs={10} />
              <Grid xs={1} justify="flex-end">
                <Button
                  color="secondary"
                  onClick={this.handleCancelPress}
                >
                  cancel
                </Button>
              </Grid>
              <Grid xs={1} justify="flex-end">
                <Button
                  color="primary"
                  onClick={this.onSaveClick}
                  disabled={!isValid}
                  id="save-timesheet-entry"
                >
                  save
                </Button>
              </Grid>
            </Grid>

          </Paper>
        </Grid>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetFormModal);
