import React,  { FunctionComponent, useEffect, useState} from "react";
import { connect } from "react-redux";
import { RouteProps, withRouter } from "react-router-dom";
import { RouterProps } from 'react-router';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AlternateEmailRoundedIcon from '@material-ui/icons/AlternateEmail';
import LocalPhoneRoundedIcon from '@material-ui/icons/LocalPhone';

import {  
  Avatar,
  Button,
  Box,
  Typography,
  TextField,
  TextareaAutosize,
  Grid,
  Theme,
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab'

import { CovidCaseActions } from '../../redux/actions/CovidCaseActions';
import { WorkerCovidActions } from '../../redux/actions/WorkersCovidActions';

import { RootReducerType } from "../../redux/store/rootReducer";
import { CovidCaseState } from '../../redux/reducers/CovidCaseReducer';
import { WorkersState } from '../../redux/reducers/WorkersCovidReducer';
import { FormModelBase, FormFieldType, FormOption, makeSubmittFormData, RestModelBase } from "../../RestClient/RestApiMicroFormat";
import { parseUrlQueryParameters, ISODateTimeOffsetToDisplayDate} from '../../Utils';
import { PayloadAction } from "../../redux/actions/types";
import CovidCaseHistoryList from './CovidCaseHistoryList';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: 0,
    height: 'calc(100vh - 65px)',
    overflow: 'auto',
  },  
  buttonBox:{
    display: 'flex',
    justifyContent:'flex-end',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large_avitar: {
    width: theme.spacing(17),
    height: theme.spacing(17),
    marginBottom: theme.spacing(3),
  },
  groupedContainer: {    
    marginTop: theme.spacing(2),
  },

  groupedContainerRHS: {    
    marginTop: theme.spacing(3),  
  },
  textarea: {
    width: '100%',
  },

  iconField: {
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
  },

  icon : {
    color: 'grey',
    margin: '4px 8px 4px 0px',
  },

  grayText: {
    color: 'gray',
  }

}));


// Props passed from parent
interface CovidCaseFormOwnProps {}

interface NoteHistory {
  date:Date;
  text:string;
}

// State passed from Redux
export interface StateProps {
    loadingState :{
      isLoading: boolean;
      isReady: boolean;
      error?: string;
    }
    workerUri?: string;

    worker ?: {
      uri: string;
      avitarUri?: string;
      supervisorUri?:string;
      covidCasesUri: string;
      covidCaseCreateFormUri: string;

      workerName:string;
      workerEmail:string;
      payrollId: string,
      phoneNumber: string,
    }
    
    supervisor? : {
      name: string;
    }

    createForm?: FormModelBase;
    updateForm?: FormModelBase;
    
    covidCase? : {
      uri:string;
      updateFormUri?: string;
      caseStatusOption?:FormOption;
      caseDateTime?: string;
      note?:string;
    }

    noteHistory: Array<NoteHistory>;
    covidStatusList: Array<FormOption>;
    saveAction:string;
}

// Actions wrapped in dispatch
export interface DispatchProps {
  loadWorker(workerUrl: string): void;
  loadSupervisor(supervisorUrl:string) : void;
  loadExistingCase(uri:string) : void;
  loadCreateCaseForm(formUrl: string) : void;
  submitCreateForm(form:FormModelBase, data:any) : void;
  submitUpdateForm(uri: string, data:any, form: FormModelBase) : void;
  clearSelectedCovidCase() : void;

  loadUpdateCaseForm(formUrl: string): void;
}

// Combine all prop types
export type Props = {stateProps: StateProps, dispatchProps:DispatchProps} & CovidCaseFormOwnProps & RouteProps & RouterProps;

export const CovidCaseFormComponent: FunctionComponent<Props> = (props) : JSX.Element =>  {
  const classes = useStyles();
  const [status, setStatus] = useState("Loading");
  
  const { worker, supervisor, covidCase, createForm, updateForm } = props.stateProps;
  const { dispatchProps, stateProps, history } = props;

  const onFormSubmitt = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formFields = e.currentTarget.elements;   

    //TODO alert if the date is in future

    setStatus("Saving...");
    (covidCase && covidCase.uri) ? 
      dispatchProps.submitUpdateForm(covidCase.uri, formFields, updateForm!)  :
      dispatchProps.submitCreateForm(createForm!, formFields);  
    
    // TODO : This needs to be done better....
    history.goBack();
  }
  
  useEffect(()=> {
    if(!worker) {
      var workerUri = parseUrlQueryParameters(props, "workerUri");
      dispatchProps.loadWorker(workerUri!);
    }
  }, []);

  useEffect(() => {
    if(!worker) {
      return;
    }
    dispatchProps.loadCreateCaseForm(worker!.covidCaseCreateFormUri!);
    if(worker!.supervisorUri!) {
      dispatchProps.loadSupervisor(worker!.supervisorUri!);
    }

    dispatchProps.loadExistingCase(worker!.covidCasesUri!);
  }, [JSON.stringify(worker!)]);

  useEffect(() => {
    if(!(covidCase && covidCase!.uri!)) {
      return;
    }
    if(covidCase!.updateFormUri!){
      dispatchProps.loadUpdateCaseForm(covidCase!.updateFormUri!);
    }
  }, [JSON.stringify(covidCase!)]);

  const caseStatusOption = covidCase ? covidCase!.caseStatusOption : undefined;
  const caseDateTime = covidCase ? covidCase!.caseDateTime : undefined;
  const note = covidCase ? covidCase!.note : undefined;

  return (!stateProps.loadingState.isReady) ? 
    (<div>Loading...<span>{stateProps.loadingState.error}</span></div>) : 
    (
    <div id="covid-case-form" className={classes.root}>  
      <Grid container direction="column">
        
        <Grid
          container
          direction="row"
          justify="space-evenly"
        >        
          <Grid item xs={10} md={4}>          
            <Grid
              container
              direction="column"
              justify="space-evenly"
            >
              <div className={classes.groupedContainer}>
                <Avatar
                  alt={worker!.workerName}
                  src={worker!.avitarUri}
                  className={classes.large_avitar}
                />
                <Typography id="covid-case-worker-name" variant="h5">
                  {worker!.workerName}
                </Typography>

                <div className={classes.iconField}>
                  <AlternateEmailRoundedIcon className={classes.icon} />
                  <Typography id="covid-case-worker-email" variant="h6">
                    {worker!.workerEmail}
                  </Typography>
                </div>

                <div className={classes.iconField}>
                  <LocalPhoneRoundedIcon className={classes.icon}/>
                  <Typography id="covid-case-worker-phone" variant="h6">
                    {worker!.phoneNumber}
                  </Typography>
                </div>

              </div>

              <div className={classes.groupedContainer}>
                <Typography variant="h6">Supervisor</Typography>
                <Typography id="covid-case-supervisor" variant="h6" className={classes.grayText}>
                  {supervisor!.name}
                </Typography>
              </div>

              <div className={classes.groupedContainer}>
                <Typography variant="h6">Payroll ID</Typography>
                <Typography id="covid-case-worker-payroll" variant="h6" className={classes.grayText}>
                  {worker!.payrollId}
                </Typography>
              </div>

            </Grid>
          </Grid>
          <Grid item xs={10} md={4}>
            <Grid
              container
              direction="column"
              justify="space-evenly"
            >
              <form onSubmit={onFormSubmitt}>
                <Box className={classes.buttonBox}>
                  <Button 
                    onClick={() => {
                      dispatchProps.clearSelectedCovidCase();
                      history.goBack();
                    }}
                    variant="contained"
                    color="secondary">
                      Cancel
                  </Button>  
                  <Button type="submit" variant="contained" color="primary">{stateProps.saveAction}</Button>  
                </Box>

                <div className={classes.groupedContainerRHS} key={JSON.stringify(caseStatusOption)}>
                <Autocomplete
                    id="caseStatus"                  
                    options={stateProps.covidStatusList}
                    getOptionLabel={(o:FormOption) => o.description}
                    getOptionSelected={(option, value) => option.key === value.key}
                    renderInput={(params) => <TextField {...params} name="caseStatus" label="Select Case Status" required/>} 
                    defaultValue={ caseStatusOption }    
                />
                </div>

                <div className={classes.groupedContainerRHS} key={caseDateTime}>
                  <TextField  
                    name="caseDateTime"
                    label='Case Original Date' 
                    type='datetime-local'
                    defaultValue={ caseDateTime }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </div>

                <div className={classes.groupedContainerRHS} key={note}>
                <TextareaAutosize  
                  name="note"
                  aria-label="Note"                   
                  rowsMin={10} rowsMax={15} 
                  placeholder='Add Note'   
                  className={classes.textarea}
                  defaultValue={ note }
                  required
                />
                </div>
              </form>
            </Grid>
          </Grid>
        </Grid>
        <Grid item key={covidCase && covidCase.uri} xs={12}>
          <CovidCaseHistoryList />
        </Grid>
      </Grid>    
    </div>
  );
}

const mapStateToProps = (state: RootReducerType, ownProps: CovidCaseFormOwnProps): {stateProps:StateProps} => {
  const caseState: CovidCaseState = state.covidCase;
  const workersState: WorkersState = state.workersCovid;
  const worker = workersState.selectedWorker;

  const{ supervisor , createForm , covidCase, updateForm} = caseState;
  const newProps: StateProps = {
    loadingState :{
      error: caseState.error,
      isLoading: false,
      isReady : !!(worker && (!worker.supervisorUri || supervisor) && (createForm || updateForm)),      
    },
    noteHistory: [],    
    covidStatusList: [],
    createForm: createForm,
    updateForm: updateForm,
    saveAction: covidCase && covidCase.uri ? "Save" : "Create"
  };
  
  if(worker){
    newProps.worker = {
      uri: worker.uri,
      avitarUri: worker.workerImageUrl,
      supervisorUri: worker.supervisorUri,
      covidCasesUri: worker.covidCasesUri!,
      covidCaseCreateFormUri: worker.covidCaseCreateFormUri || '',

      workerName: worker.name,
      payrollId: worker.payrollId || '',
      workerEmail: worker.email || '',    
      phoneNumber: worker.mobile || '',              
    }

    if(!worker.supervisorUri){
      newProps.supervisor = {
        name:"No supervisor, this worker is a supervisor"
      }
    }
      
    if(supervisor){
      newProps.supervisor = {
        name:supervisor!.name
      }
    }
    
    if(createForm){
      const optionField = createForm.attributes.find(x => (x.type === FormFieldType.OPTIONS && x.name === "caseStatus"));
      newProps.covidStatusList =  (optionField && optionField.options) ? optionField.options! : [];
      if(covidCase)
      {
        newProps.covidCase = {
          ...covidCase,
          caseStatusOption: newProps.covidStatusList.find(x => x.key === covidCase!.caseStatus),
          caseDateTime: ISODateTimeOffsetToDisplayDate(covidCase!.caseDateTime),     
        };   
      } else {
        newProps.covidCase = { uri: '' };
      }
      }
  }

  return {stateProps:newProps};
}

const mapDispatchToProps = (dispatch:<T>(v:any) => Promise<PayloadAction<T>>, ownProps: CovidCaseFormOwnProps): {dispatchProps:DispatchProps} => {
  const dispProps : DispatchProps =  {
    loadWorker: (workerUrl: string) => dispatch(WorkerCovidActions.setSelectedWorker(workerUrl)),
    loadSupervisor: (supervisorUrl:string) => dispatch(CovidCaseActions.getSupervisor(supervisorUrl)),
    loadCreateCaseForm: (formUrl: string) => dispatch(CovidCaseActions.getCreateCovidCaseForm(formUrl)),
    loadUpdateCaseForm: (formUrl: string) => dispatch(CovidCaseActions.getUpdateCovidCaseForm(formUrl)),
    loadExistingCase : (uri:string) => dispatch(CovidCaseActions.findWorkersCovidCase(uri)),   
    submitCreateForm: (form:FormModelBase, data:any) => dispatch(CovidCaseActions.postCovidCaseForm(form.uri, makeSubmittFormData(form, data))),
    submitUpdateForm: (uri: string, data:any, form: FormModelBase) => dispatch(CovidCaseActions.putCovidCaseForm(uri, makeSubmittFormData(form, data))),
    clearSelectedCovidCase: () => dispatch(CovidCaseActions.clearSelectedCovidCase()),
  };

  return {dispatchProps: dispProps};
};

export const CovidCaseForm = withRouter( connect(mapStateToProps, mapDispatchToProps)(CovidCaseFormComponent) );
