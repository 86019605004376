import {makeFetchHttpClient} from './FetchClient';
import {makeRestClient} from './RestClient';
import {Options, RestClient, HttpClient} from './RestClientTypes';
import { EnvironmentVariables } from '../Constants/EnvironmentVariables';
import localForage from 'localforage';

export function makeFetchHttpRestClient(baseUrl: string,  options:Partial<Options> = {}) : RestClient {
  const url = baseUrl.toLowerCase();
  const httpClient = makeFetchHttpClient(url);
  return makeRestClient(httpClient, options);
}

export function LocalStorageAccessToken(env:EnvironmentVariables) :  (token:string, client: HttpClient) => Promise<string>{

  return (token:string, client: HttpClient) : Promise<string> => {
    return localForage.getItem<string>('access_token')
    .then(t => t ? t : "No Token")
  }
}