import { PayloadAction } from './types';

export enum CONTACTTRACING_ActionTypes {
  SET_WORKER_TRACK = "CONTACT_TRACING.SET_WORKER_TRACT",
  SET_CONTACT_GEOJSON = "CONTACT_TRACING.SET_CONTACT_GEOJSON",
  SET_CONTACT_TRACING_FORM_VALUES = "CONTACT_TRACING.SET_CONTACT_TRACING_FORM_VALUES"
}

export class ContactTracingActions {
  static setWorkerTrack(payload: any): PayloadAction {
    return ({ type: CONTACTTRACING_ActionTypes.SET_WORKER_TRACK, payload, })
  };

  static setContactGeojson(payload: any): PayloadAction {
    return ({ type: CONTACTTRACING_ActionTypes.SET_CONTACT_GEOJSON, payload, })
  };

  static setContactTracingFormValues(payload: any): PayloadAction {
    return ({ type: CONTACTTRACING_ActionTypes.SET_CONTACT_TRACING_FORM_VALUES, payload, })
  };

}

