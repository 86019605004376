import React, { Component } from 'react';
import moment from 'moment';
import { getZoneNamesFromContact } from './ContactTracingResultList';
import { printToPDF } from '../Utils/PrintPdf';
import { makeContactTraceHTML } from './HTMLContactTrace';
import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { makeContactLog } from './ContactTracingResultList';
import { connect } from 'react-redux';



export class ContactReportPrintButton extends Component {

  printCovidReport = () => {

    const { 
      covidCaseStatus,
      firstSymptomDate,
      selectedWorker,
       workers,
       sites,
       zones,
       fromDate,
       toDate,
       contactLog,
       contactGeojson,
       mapInstanceRef,
    } = this.props;
    const supervisor = workers.find(
      s => s.WorkerId === selectedWorker.supervisor);
     
    const { payrollId, mobile, name, email } = selectedWorker;

    const contacts = contactLog ? contactLog.map(c => {
      const worker = workers.find(w => w.WorkerId === c.workerId);
      const zoneNames = getZoneNamesFromContact(contactGeojson, zones, c);
      const siteNames = getZoneNamesFromContact(contactGeojson, sites, c);
      const supervisor = workers.find(s => s.WorkerId === worker.Supervisor); 
      return {
        ...worker,
        zoneNames,
        siteNames,
        supervisorName: supervisor ? supervisor.Name : '',
        supervisorMobile: supervisor ? supervisor.Mobile : '',
      };
    }) : [];

    const details = {
      fromDate: `${fromDate.format('YYYY-MM-DD')}`,
      toDate: `${toDate.format('YYYY-MM-DD')}`,
      printDate: moment().format('YYYY-MM-DD, HH:mm'),
      covidCaseStatus,
      firstSymptomDate: moment(firstSymptomDate).format('YYYY-MM-DD'),
      payrollId,
      mobile,
      name,
      email,
      contacts,
      supervisorName: supervisor ? supervisor.Name : '',
      superVisorMobile: supervisor ? supervisor.Mobile : '',
    };

    const options = {
      filename: `contact-report-${name.replace(' ', '')}`,
      jsPDF: {  orientation: 'landscape' },
      //pagebreak: {avoid: 'tr'},
      margin: 5,
    };

    console.log(this.props.mapRef);
    const dataUrl = this.props.mapRef.getCanvas().toDataURL('image/png');

    printToPDF(makeContactTraceHTML(details, dataUrl), options);
  }

  render() {
    return (
      <IconButton
      aria-label="Search"
      disabled={!this.props.canPrint}
      color="primary"
      onClick={ this.printCovidReport }
    >
      <PrintIcon />
    </IconButton>  
    );
  }

}

const mapStateToProps = (state) => {
  const { contactTracing, zones, sites, workers, workersCovid } = state;
  const { 
    contactGeojson,
    fromDate,
    toDate,
    startTime,
    endTime,
  } = contactTracing;
  const contactLog = makeContactLog(contactGeojson);

  return {
    contactLog,
    zones: zones.all,
    workers: workers.all,
    selectedWorker: workersCovid.selectedWorker,
    contactGeojson,
    fromDate,
    toDate,
    startTime,
    endTime,
    sites: sites.all,
    canPrint: !!contactGeojson.features.length,
  };
}

export default connect(mapStateToProps, {})(ContactReportPrintButton);
