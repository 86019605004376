import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { 
  getEventItemsByWorkerAndDate,
  getDeviceEventItemsByWorkerAndDate,
  approvalAllTimesheets,
} from '../../API';


import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
  Hidden,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getTimesheetEntries,
  getUnlockedTimesheets,
  getApprovalsData,
  getTimesheetNotes,
  updateTimesheetEntries,
  getUnpaidTimesheetEntries,
  getWorkerShiftsForWorkerAndRange,
  getWorkerTimesheets,
} from '../../API';
import {
  setSites, WorkerActions,
  setTimesheets, 
  setTimesheetEntries,
  setApprovalTimesheet,
  setTimesheetNotes,
  setLoading,
  setUnpaidTimesheetEntries,
  setApprovalsWorkerShifts,
} from '../../redux/actions/AllActions';
import ApprovalsFormMobile from './ApprovalsFormMobile';
import ApprovalsFormLarge from './ApprovalsFormLarge';
import TimesheetNotesForm from './TimesheetNotesForm';
import TimesheetNotesList from './TimesheetNotesList';
import TimesheetTable from './TimesheetTable';
import TimesheetPanelList from './TimesheetPanelList';
import getActiveApprovalTimesheet from '../../redux/selectors/approvalCurrentTimesheet';
import { EventsList } from '../SuperAdmin/WorkerTimeline';

const drawerWidth = 240;

export const styles = theme => ({
  root: {

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    width: '100vw',
    height: 'calc(100vh - 68px)',
    overflowY: 'scroll',
  },
  innerGrid: { maxWidth: '1400px', margin: '0px auto 0px auto' },
  heading: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  headingNotes: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  textField: {
    minWidth: '300px',
  }
});


export class ApprovalsClass extends Component {
  state = {
    loaded: false,
    events: [],
    timesheetWorkerIds: [],
    selectedWorker: null,
  }

  title = 'Approvals';

  componentDidMount() {
    const { timesheet, _setLoading } = this.props;
    this.getData();
  }

  componentWillReceiveProps(nextProps, nextState){
    if(!this.props.me.IsSuperAdministrator) {
      return;
    }
    if(nextProps.timesheet && JSON.stringify(nextProps.timesheet) !== JSON.stringify(this.props.timesheet)) {
      // go get the events and put them in state
      const { WorkerId, StartDateTime, FinishDateTime } = nextProps.timesheet;
      
      if(this.props.timesheet && 
          `${WorkerId}-${StartDateTime}` === `${this.props.timesheet.WorkerId}-${this.props.timesheet.StartDateTime}`) {
        return;
      }

      if(this.props.me.IsSuperAdministrator) {
        /*Promise.all([
          getEventItemsByWorkerAndDate(WorkerId, StartDateTime, FinishDateTime),
          getDeviceEventItemsByWorkerAndDate(WorkerId, StartDateTime, FinishDateTime),
        ]).then(data => {
          const events = [...data[0], ...data[1] ]
          events.sort((a, b) => {
            var ad = new Date(a.EventTime);
            var bd = new Date(b.EventTime);
            return bd - ad;
          }).reverse();
          this.setState({ events });
        });*/
      }
    }
  }

  getData = async () => {
    this.props._setLoading(true);
    const data = await getApprovalsData();
    const { sites, workers, timesheetWorkerIds } = data;
    const { _setWorkers, _setLoading } = this.props;
    let timesheets = [];
    if(!this.state.selectedWorker) {
      const usefulWorkers = workers.filter(
        w => timesheetWorkerIds.includes(w.WorkerId) &! w.IsArchived && w.IsEnabled);
      timesheets = await getWorkerTimesheets(usefulWorkers[0].WorkerId);
      if(usefulWorkers.length) {
        this.setState({ selectedWorker: usefulWorkers[0] });
      }
    } else {
      timesheets = await getWorkerTimesheets(this.state.selectedWorker.WorkerId);
    }
    
    _setWorkers(workers);

    const timesheet = this.props.timesheet || timesheets[0];
    
    if (!timesheet) {
      _setLoading(false);
      this.setState({
        timesheetWorkerIds,
        loaded: true,
      });
      return;
    }

    const {
      workerShifts,
      timesheetEntries,
      unpaidTimesheetEntries,
      timesheetNotes,
    } = await this.getTimesheetData(timesheet);

    this.setData(sites, workers, timesheets,
      timesheetEntries, 
      timesheet.TimesheetId,
      timesheetNotes,
      unpaidTimesheetEntries,
      workerShifts,
    );
    _setLoading(false);
    this.setState({
      timesheetWorkerIds,
      loaded: true,
    });
    
  }

  getTimesheetData = async (timesheet) => {
    const workerShifts = await getWorkerShiftsForWorkerAndRange(timesheet.WorkerId, timesheet.StartDateTime, timesheet.FinishDateTime);
    let timesheetEntries = await getTimesheetEntries(timesheet.TimesheetId);
    const unpaidTimesheetEntries = await getUnpaidTimesheetEntries(timesheet.TimesheetId);
    const timesheetNotes = await getTimesheetNotes(timesheet.TimesheetId);
    timesheetEntries = timesheetEntries.map(tse => ({
      ...tse, edited: false, isNew: false, deleted: false,
    }));
    return {
      workerShifts,
      timesheetEntries,
      unpaidTimesheetEntries,
      timesheetNotes,
    };
  }

  setData(sites, workers, timesheets, timesheetEntries, tsId, notes, unpaidTimesheetEntries, workerShifts) {
    const {
      _setSites, _setWorkers, _setTimesheets,
      _setTimesheetEntries, _setApprovalTimesheet,
      _setTimesheetNotes,
      _setUnpaidTimesheetEntries,
      _setApprovalsWorkerShifts,
    } = this.props;

    _setSites(sites);
    _setWorkers(workers);
    _setTimesheets(timesheets);
    _setApprovalTimesheet(tsId);
    _setTimesheetEntries(timesheetEntries);
    _setTimesheetNotes(notes);
    _setUnpaidTimesheetEntries(unpaidTimesheetEntries);
    _setApprovalsWorkerShifts(workerShifts);
    console.log(this.state.timesheetWorkerIds, 'ts2')
  }

  handleSaveClick = async () => {
    // TODO save things when needed;
    const {
      timesheet, _setLoading,
    } = this.props;
    _setLoading(true);
    await updateTimesheetEntries(
      timesheet.timesheetEntryList.getEntriesForPost(), timesheet.TimesheetId,
    );
    await this.getData();
    _setLoading(false);
  }

  onNotesPanelChange = (e, expanded) => {
    this.setState({ expanded });
  }

  onWorkerSelectChange = async (workerId) => {
    const selectedWorker = this.props.workers.find(w => w.WorkerId == workerId);
    this.setState({ selectedWorker });
    await this.getData();
    const timesheets = this.props.timesheets;
    const timesheet = timesheets[0];
    const {
      workerShifts,
      timesheetEntries,
      unpaidTimesheetEntries,
      timesheetNotes,
    } = await this.getTimesheetData(timesheets[0]);
    this.props._setApprovalTimesheet(timesheet.TimesheetId);
    this.props._setTimesheetEntries(timesheetEntries);
    this.props._setTimesheetNotes(timesheetNotes);
    this.props._setUnpaidTimesheetEntries(unpaidTimesheetEntries);
    this.props._setApprovalsWorkerShifts(workerShifts);
  }

  handleApprovalAllPress = async () => {
    if(!this.state.selectedWorker) {
      return;
    }
    const { Name, WorkerId } = this.state.selectedWorker;
    var doIt = window.confirm(`Approve All Timesheets for ${Name}`);
    if(!doIt) {
      return;
    }
    await approvalAllTimesheets(WorkerId);
    this.setState({ selectedWorker: null});
    await this.getData();
    if(this.state.selectedWorker) {
      await this.onWorkerSelectChange(this.state.selectedWorker.WorkerId);
    }
  }

  render() {
    const {
      timesheet, classes, numNotes, token, me
    } = this.props;
    const { expanded, loaded, events, timesheetWorkerIds, selectedWorker } = this.state;
    const notesOpen = !!expanded;
    const superAdmin = false;//me.IsSuperAdministrator;
    console.log(timesheetWorkerIds)
    return (
      <main className={classes.content} data-loaded={loaded}>
        <CssBaseline />
        <Grid container className={classes.innerGrid}>
          <Grid item xs={12} style={{ paddingBottom: '15px' }}>
            <Paper className={classes.heading} elevation={1}>
              <Hidden only={['md', 'lg', 'xl']}>
                <ApprovalsFormMobile
                  key="approvals-form-m"
                  token={token}
                  timesheet={timesheet}
                />
              </Hidden>
              <Hidden only={['xs', 'sm']}>
                <ApprovalsFormLarge
                  key="approvals-form-l"
                  token={token}
                  timesheet={timesheet}
                  timesheetWorkerIds={timesheetWorkerIds}
                  selectedWorker={selectedWorker}
                  onWorkerSelectChange={this.onWorkerSelectChange}
                  onApproveAllTimesheetsPress={this.handleApprovalAllPress}
                />
              </Hidden>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '15px' }}>
            <ExpansionPanel expanded={notesOpen} onChange={this.onNotesPanelChange}>
              <ExpansionPanelSummary id="timesheet-note-summary" expandIcon={<ExpandMoreIcon className="expand-ts-notes" />}>
                <Typography variant="h6">{`Timesheet Notes (${numNotes})`}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={10}>
                  <Grid item xs={11} d={4}>
                    <TimesheetNotesForm id="timesheets-notes-form" token={token} />
                  </Grid>
                  <Grid xs={1} />
                  <Grid item xs={12}>
                    <TimesheetNotesList token={token} />
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Hidden only={['xs', 'sm']}>
          { superAdmin && (
            <Grid item xs={5}>
              <EventsList 
                items={events}
                classes={classes}>
              </EventsList>
            </Grid>
          )}
            <Grid item xs={superAdmin ? 7 : 12}>
              {timesheet && (
                <TimesheetTable
                  onSaveClick={this.handleSaveClick}
                  timesheet={timesheet}
                />
              )}
            </Grid>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Grid item xs={12}>
              <TimesheetPanelList
                timesheet={timesheet}
                handleSaveClick={this.handleSaveClick}
              />
            </Grid>
          </Hidden>
        </Grid>
      </main>
    );
  }
}

export const mapStateToProps = (state) => {
  const {
    timesheetNotes,
    workers,
    me,
  } = state;
  const timesheet = getActiveApprovalTimesheet(state, {});
  return {
    timesheet,
    numNotes: timesheetNotes.all.length,
    me: me.me,
    workers: workers.all,
    timesheets: state.timesheets.all,
  };
};

export const mapDispatchToProps = dispatch => ({
  _setSites: x => dispatch(setSites(x)),
  _setWorkers: x => dispatch(WorkerActions.setWorkers(x)),
  _setTimesheets: x => dispatch(setTimesheets(x)),
  _setTimesheetEntries: x => dispatch(setTimesheetEntries(x)),
  _setApprovalTimesheet: x => dispatch(setApprovalTimesheet(x)),
  _setTimesheetNotes: x => dispatch(setTimesheetNotes(x)),
  _setLoading: x => dispatch(setLoading(x)),
  _setUnpaidTimesheetEntries: x => dispatch(setUnpaidTimesheetEntries(x)),
  _setApprovalsWorkerShifts: x => dispatch(setApprovalsWorkerShifts(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(ApprovalsClass)));
