import { PromiseAction, PayloadAction, AsyncAction } from './types'
import { CovidCaseApiModel } from '../reducers/CovidCaseReducer';
import {  RestClient } from '../../RestClient/RestClientTypes';
import { FormModelBase, CompanyRestModel, RestFeedModel} from '../../RestClient/RestApiMicroFormat';
import { Worker, CovidCaseHistoryItem, CovidCaseHistory } from '../../api/index';
import { ConfigurationState } from '../reducers/ConfigurationReducer';
import { getCovidCaseHistoryItems } from '../../API';



export enum COVIDCASE_ActionTypes {
  GET_SUPERVISOR = "COVIDCASE.GET_SUPERVISOR",
  GET_CASE_STATUS_LIST = "COVIDCASE.GET_CASE_STATUS_LIST",
  GET_CASE_FORM = "COVIDCASE.GET_CASE_FORM",
  GET_CASE = "COVIDCASE.GET",
  CREATE_CASE = "COVIDCASE.CREATE",
  UPDATE_CASE = "COVIDCASE.UPDATE",
  SET_COVIDCASE_LIST = "COVIDCASE.SET_COVIDCASE_LIST",
  CLEAR_SELECTED_COVID_CASE = "COVIDCASE.CLEAR_SELECTED_COVID_CASE",
  FIND_WORKERS_CASE = "COVIDCASE.FIND_WORKERS_CASE",
  GET_UPDATE_FORM = "COVIDCASE.GET_UPDATE_FORM",
  GET_HISTORY_LIST = "COVIDCASE.GET_HISTORY_LIST",
};


function makeRestclient(state: () => any): RestClient{
  const configuration: ConfigurationState = state().configuration;
  return configuration.client!;
}

export class CovidCaseActions {

  static getSupervisor(supervisorUrl: string) : AsyncAction {
    return (dispatch, state)  => {
      const restClient = makeRestclient(state);
      dispatch({
        type: COVIDCASE_ActionTypes.GET_SUPERVISOR, 
        promise: restClient.get<Worker>(supervisorUrl)
      });
    }
  };

  static getCreateCovidCaseForm(formUrl: string) : AsyncAction {
    return (dispatch, state)  => {
      const restClient = makeRestclient(state);
      dispatch ({
        type: COVIDCASE_ActionTypes.GET_CASE_FORM, 
        promise: restClient.get<FormModelBase>(formUrl),
      });
    }
  }

  
  static getUpdateCovidCaseForm(formUrl: string): AsyncAction  {
    return (dispatch, state)  => {
      const restClient = makeRestclient(state);
      dispatch ({
        type: COVIDCASE_ActionTypes.GET_UPDATE_FORM, 
        promise: restClient.get<FormModelBase>(formUrl),
      });
    }
  }

  static getCovidCase(uri: string):  AsyncAction {    
    return (dispatch, state)  => {
      const restClient = makeRestclient(state);
      const action:  PromiseAction<CovidCaseApiModel, COVIDCASE_ActionTypes> =  ({
        type: COVIDCASE_ActionTypes.GET_CASE, 
        promise: restClient.get<CovidCaseApiModel>(uri)
      });

      dispatch(action);
    }
  }

  static findWorkersCovidCase(uri: string) : AsyncAction {
    return (dispatch, state)  => {
      const restClient = makeRestclient(state);
      const action:  PromiseAction<CovidCaseApiModel, COVIDCASE_ActionTypes> =  ({
        type: COVIDCASE_ActionTypes.FIND_WORKERS_CASE, 
        promise: restClient.get<RestFeedModel>(uri).then(
          feed => restClient.getFeedItems<CovidCaseApiModel>(feed).then(items => items[0])),
      });

      dispatch(action);
    }
  }
  

  static postCovidCaseForm<T=any>(uri: string, data:T) : AsyncAction {
    return (dispatch, state)  => {
      const restClient = makeRestclient(state);
      const action: PromiseAction<CovidCaseApiModel, COVIDCASE_ActionTypes> =  ({
        type: COVIDCASE_ActionTypes.CREATE_CASE, 
        promise: restClient.create(uri, data)
          .then(response => restClient.get<CovidCaseApiModel>(response.resourceUri)),          
      });
      dispatch(action);
    }
  }
  

  static putCovidCaseForm<T=any>(uri: string, data:T) : AsyncAction {
    return (dispatch, state)  => {
      const restClient = makeRestclient(state);
      const action: PromiseAction<CovidCaseApiModel, COVIDCASE_ActionTypes> = ({
        type: COVIDCASE_ActionTypes.UPDATE_CASE,
        promise: restClient.update(uri, data)
          .then(response => restClient.get<CovidCaseApiModel>(response.uri!)),
      });

      dispatch(action)
    }
  }

  static getCovidCaseList(companyUri: string) : AsyncAction {
    return (dispatch, state)  => {
      const restClient = makeRestclient(state);
      const action : PromiseAction<Array<CovidCaseApiModel>, COVIDCASE_ActionTypes> =  ({
        type: COVIDCASE_ActionTypes.SET_COVIDCASE_LIST,
        promise: restClient.get<CompanyRestModel>(companyUri)
          .then((value: CompanyRestModel) => restClient.get<RestFeedModel>(value.covidCasesUri)
          .then(feed => restClient.getFeedItems<CovidCaseApiModel>(feed))),
      });

      dispatch(action)
    }
  }

  static clearSelectedCovidCase() : PayloadAction {
    return ({
      type: COVIDCASE_ActionTypes.CLEAR_SELECTED_COVID_CASE,
      payload: "",
    });
  }

  static getcovidCaseHistory(uri: string):  AsyncAction {    
    return (dispatch)  => {
      const action:  PromiseAction<CovidCaseHistory, COVIDCASE_ActionTypes> =  ({
        type: COVIDCASE_ActionTypes.GET_HISTORY_LIST, 
        promise: getCovidCaseHistoryItems(uri),
      });
      dispatch(action);
    }
  }

}