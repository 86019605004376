import {
  EDIT_TIMESHEET_ENTRY,
  SET_TIMESHEET_ENTRIES,
  DELETE_TIMESHEET_ENTRY,
  ADD_TIMESHEET_ENTRY,
} from './actions/ActionTypes';
import { assign, replaceInArray } from '../Utils';

const id = 'TimesheetEntryId';

const initialState = {
  all: [],
};

export default function (state = initialState, action) {
  const { all } = state;
  const { payload } = action;
  switch (action.type) {
    case EDIT_TIMESHEET_ENTRY: {
      return assign(state, { all: replaceInArray(all, payload, id) });
    }
    case DELETE_TIMESHEET_ENTRY: {
      return assign(state, { all: state.all.filter(t => t.TimesheetEntryId !== payload.TimesheetEntryId) });
    }
    case ADD_TIMESHEET_ENTRY: {
      const newList = [...state.all, payload];
      return assign(state, { all: newList });
    }
    case SET_TIMESHEET_ENTRIES: {
      return assign(state, { all: payload });
    }
    default:
      return state;
  }
}
