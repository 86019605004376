import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid, Button,
} from '@material-ui/core';
import ApprovalsForm, {
  mapDispatchToProps,
  mapStateToProps,
} from '../Approvals/ApprovalsForm';


class MyTimesheetForm extends ApprovalsForm {
  render() {
    const {
      timesheet, timesheetList,
    } = this.props;
    const index = this.getIndex();
    return (
      <Grid container spacing={24}>
        <Grid item xs={12} alignContent="space-between" style={{ paddingTop: '20px' }}>
          <Button
            disabled={index === (timesheetList.objects.length - 1)}
            variant="contained"
            color="primary"
            onClick={this.onNextClick}
          >
            Back
          </Button>
          <Button
            disabled={!timesheet || index === 0}
            variant="contained"
            color="secondary"
            onClick={this.onBackClick}
            style={{ marginLeft: '20px' }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTimesheetForm));
