import {
  SET_SNACKBAR,
  SET_LOADING,
  SET_PATHNAME,
  SET_DASHBOARD_WORKER_ID,
} from './actions/ActionTypes';
import { assign } from '../Utils';

const initialState = {
  snackbar: {
    openTime: null,
    loading: false,
    pathname: '',
  },
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_SNACKBAR: {
      return assign(state, { snackbar: payload });
    }
    case SET_LOADING: {
      return assign(state, { loading: payload });
    }
    case SET_PATHNAME: {
      return assign(state, { pathname: payload });
    }
    case SET_DASHBOARD_WORKER_ID: {
      return assign(state, { selectedDashboardWorkerId: payload });
    }
    default:
      return state;
  }
}
