import React from 'react';
import TimeSheetPanel from './TimesheetPanel';
import { weekOfDates } from '../../Utils';


export default function TimesheetPanelList({ timesheet, handleSaveClick }) {
  if (!timesheet) {
    return null;
  }
  const week = weekOfDates(timesheet.StartDateTime, timesheet.FinishDateTime);
  const panels = week.map(
    (dt, i) => (
      <TimeSheetPanel
        index={i}
        startDate={dt}
        timesheet={timesheet}
        timesheetEntries={timesheet.timesheetEntryList.getEntriesByDay(dt)}
        key={i}
        onSaveClick={handleSaveClick}
      />
    ),
  );
  return (
    <div>
      { panels }
    </div>
  );
}
