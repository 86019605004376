import moment from 'moment';
import BaseModel from './BaseModel';
import TimesheetEntryList from './TimesheetEntryList';
import {
  weekOfDates,
  trimTimeStr,
  setTimeFromString,
  replaceInArray,
  areSameDay,
  uniqInArray,
} from '../Utils';
import { HTMLTimesheet } from '../HelperClasses/HTMLTimesheet';


export default class Timesheet extends BaseModel {
  setTimesheetEntryList = (timesheetEntries) => {
    this.timesheetEntryList = new TimesheetEntryList(timesheetEntries);
  }

  setCompany = (company) => {
    this.company = company;
  }

  setWorker = (worker) => {
    this.worker = worker;
  }

  setWorkerShifts = (workerShifts) => {
    this.workerShifts = workerShifts;
  }

  setUnpaidEntries = (unpaidTimesheetEntries) => {
    this.timesheetEntryList.setUnpaidEntries(unpaidTimesheetEntries);
  }

  getDateDescription = () => {
    const sTime = this.mStartDateTime.format('DD MMM');
    const fTime = moment(this.mFinishDateTime).add(-1, 'days').format('DD MMM');
    return `${sTime} - ${fTime}`;
  }

  getTimesheetDayTableRow(mDay, entries, unpaidEntry) {
    const dateStr = mDay.format('dddd Do MMM');
    const totalStr = this.timesheetEntryList.formatTotalTimeStr(entries, unpaidEntry);
    const breakStr = unpaidEntry && unpaidEntry.UnpaidTime.slice(0, 5);
    // [ datesite, start, end, reported-total, desc, add, delete ]
    return [
      `${dateStr}`,
      breakStr,
      '',
      '',
      { value: totalStr, fontWeight: 700 },
      mDay,
      '',
    ];
  }

  getMyTimesheetTableRows = () => {
    const rows = this.getTableRows();
    return rows.map(r => r.slice(0, 5));
  };

  getTableRows = () => {
    const { siteList, unpaidTimesheetEntries } = this.timesheetEntryList;
    let rows = [];
    const daysInTimesheet = weekOfDates(this.StartDateTime, this.FinishDateTime);
    const dailyEntries = daysInTimesheet.map(
      (day) => {
        var mDay = moment(day);
        var unpaidEntry = unpaidTimesheetEntries.find(e => moment(e.DateTime).day() === mDay.day());
        const entries = this.timesheetEntryList.getEntriesByDay(mDay);
        const dayRow = this.getTimesheetDayTableRow(mDay, entries, unpaidEntry);
        const entryRows = entries.map((e) => {
          const site = siteList.findById(e.SiteId);
          return e.getTableRow(site && site.Name);
        });
        return { dayRow, entryRows };
      });
    dailyEntries.forEach((d) => {
      rows = [...rows, d.dayRow, ...d.entryRows];
    });
    return rows;
  }

  genTimesheetEntry = modWorkerId => ({
    TimesheetId: this.TimesheetId,
    StartDateTime: this.StartDateTime,
    FinishDateTime: this.StartDateTime,
    Travel: false,
    SiteId: -1,
    TimesheetEntryId: new Date().getTime(),
    isNew: true,
    // TODO GET RID OF THE DELETED FLAG
    deleted: false,
    ModifiedWorkerId: modWorkerId,
    Description: 'Not yet saved',
  });

  getNewTimesheetEntry = (startDate, modWorkerId, workStartStr,
    workEndStr, lunchStartStr, lunchEndStr) => {
    const entry = this.genTimesheetEntry(modWorkerId);
    return this.setNewTSEntryTime(entry, startDate, workStartStr,
      workEndStr, lunchStartStr, lunchEndStr);
  }

  setNewTSEntryTime(entry, startDate, workStartStr, workEndStr, lunchStartStr, lunchEndStr) {
    const tse = { ...entry };
    const setTimeStr = (d1, d2) => setTimeFromString(d1, d2).format('YYYY-MM-DD HH:mm:ss');
    const entries = this.timesheetEntryList.getEntriesByDay(startDate);
    if (!entries.find(t => !t.isNew)) {
      if (entries.length === 0) {
        tse.StartDateTime = setTimeStr(startDate, workStartStr);
        tse.FinishDateTime = setTimeStr(startDate, lunchStartStr);
      } else if (entries.length === 1) {
        tse.StartDateTime = setTimeStr(startDate, lunchEndStr);
        tse.FinishDateTime = setTimeStr(startDate, workEndStr);
      }
    } else {
      tse.StartDateTime = setTimeStr(startDate, '00:00:00');
      tse.FinishDateTime = setTimeStr(startDate, '00:00:00');
    }
    return tse;
  }

  // generates an html page - that goes into an iframe for html2canvas + jspdf to print a timesheet
  printMyself = (workerShifts) => {
    const printParams = {
      workerName: this.worker.Name,
      companyName: this.company.Name,
      endDateStr: this.mFinishDateTime.format('DD-MM-YYYY'),
      totalWorkTimeStr: this.timesheetEntryList.formatTotalTimeStr(
        this.timesheetEntryList.objects),
      timesheetEntryDays: [], // timesheet entries grouped by day
      printDateStr: moment().format(),
      imageDataURI: this.worker.Base64Image,
    };
    const tableRows = this.getTableRows();
    let index = -1;
    // just use the table rows and parse them to fit the htmlhelper funtion
    tableRows.forEach((tr) => {
      if (tr[5]._isAMomentObject) {
        index += 1;
        const matchingShift = workerShifts.find(w => moment(w.StartDateTime).day() == tr[5].day());
        printParams.timesheetEntryDays.push({
          dayStr: tr[0],
          lunchTotalStr: tr[1],
          workTotalStr: tr[4].value,
          entries: [],
          scheduledStartTime: matchingShift ? moment(matchingShift.StartDateTime).format('HH:mm') : '',
          scheduledEndTime: matchingShift ?  moment(matchingShift.EndDateTime).format('HH:mm') : '',
        });
      } else {
        // if the previous entry is on the same site - then group them
        // by extending the end time rather than creating an new rowEntry
        // this way we lump lunchtime into the row rather than split up the rows
        // note the return statement!

        const todaysRow = printParams.timesheetEntryDays[index];
        const todaysEntries = todaysRow.entries;
        const previousEntryRow = todaysEntries[todaysEntries.length - 1];
        if (previousEntryRow && previousEntryRow.siteStr === tr[0].siteStr) {
          // eslint-disable-next-line prefer-destructuring
          previousEntryRow.endStr = tr[3];
          return;
        }

        const eRow = {
          siteStr: tr[0].siteStr,
          startStr: tr[2],
          endStr: tr[3],
        };
        printParams.timesheetEntryDays[index].entries.push(eRow);
      }
    });

    return HTMLTimesheet(printParams);
  }

  getFilename = () => {
    const name = this.worker.Name.replace(/[\W_]+/g, '');
    return `${name}-${this.mFinishDateTime.format('DD-MM-YY')}`;
  }
}
