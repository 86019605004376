import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


const style = {
  position: 'fixed',
  top: 0,
  maxWidth: '100vw',
  /* Reuben hack */ backgroundColor: '#f5c800',
  color: '#564d4b',
};

export default function NoAuthNavBar({ companyName }) {
  return (
    <AppBar position="static" style={style}>
      <Toolbar>
        <Typography variant="h6" color="inherit">
          { companyName }
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
