import React, { Component } from 'react';
import MapGL, { GeoJSONLayer, Popup } from 'react-mapbox-gl';
import * as turf from '@turf/turf';
import {
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import { MAPBOX_TOKEN, SAT_MAP_URI, SITE_FILL_STYLE, ZONE_FILL_STYLE } from '../../Constants/Constants';
import { snapLocationTimestampsToSite } from '../../GeoFunctions';
import { formatUtcDateAsNZTime } from '../../Utils';


const nzBbox = [180.4833, -34.05265942137597, 163.65234, -47.57652];
const nzBounds = [[nzBbox[0], nzBbox[1]], [nzBbox[2], nzBbox[3]]];

const Map = MapGL({
  accessToken: MAPBOX_TOKEN,
});

const CIRCLE_STYLE = {
  'circle-opacity': 0.5,
  'circle-radius': 6,
  'circle-color': '#0bc3c6',
};


const CIRCLE_STYLE_RAW = {
  'circle-opacity': 0.8,
  'circle-radius': 7,
  'circle-color': {
    "property": "eventType",
    "type": "categorical",
    "stops": [
      ['2', '#c43b00' ], //NORMAL
      ['3', '#bac400' ], //YELLOW SOS
      ['4', '#001eff'], //BLUE //LOW BATTERY
      ['5', '#00ffee'], //CYAN //Flat Battery 
      ['6', '#38ff56' ], //Green //Charging Start
      ['7', '#ff00ea'], //PINK //Charging Full
      ['8', '#ffffff'], //White - Wake Up From Sleep (power on) mode 1
      ['9', '#000000'] //Black - Go Back To Sleep (power off) mode 1
    ]
  }
};

const LINE_STYLE = {
  'line-color': '#1badc6',
  'line-width': 1.5,
  'line-opacity': 0.8,
};

const MIN_DURATION_MINUTES = 25;

class DevicesReportMap extends Component {
  state = {
    lines: null,
    polygons: null,
    points: null,
    bounds: nzBounds,
    bboxPadding: 1,
    popup: null,
  }

  componentWillReceiveProps(props) {
    const { locationTimestamps, sites } = props;

    const snappedLocs = snapLocationTimestampsToSite(sites, locationTimestamps);

    const lines = this.getLocationLinesFeatures(snappedLocs);
    const polygons = this.getSiteFeatures(sites);
    const points = this.getLocationPointsFeatures(snappedLocs);

    this.setState({
      polygons: turf.featureCollection(polygons),
      lines: turf.featureCollection(lines),
      points: turf.featureCollection(points),
      bounds: this.getBounds(polygons, lines),
      bboxPadding: this.getBBoxPadding(sites, snappedLocs),
    });
  }

  getLocationLinesFeatures = (locs) => {
    const coords = locs.map(x => [x.Longitude, x.Latitude]);
    if (coords.length < 2) {
      return [];
    }
    return [turf.lineString(coords)];
  }

  getLocationPointsFeatures = locs => locs.map(
    lt => turf.point([lt.Longitude, lt.Latitude], lt))

  shouldComponentUpdate() {
    setTimeout(
      // hack to keep the map the right size all the time
      // eslint-disable-next-line no-undef
      () => window.dispatchEvent(new Event('resize')), 300);
    return true;
  }

  getSiteFeatures = (sites) => {
    if (!sites.length) {
      return [];
    }
    const feats = sites.filter(
      s => !!s.GeoJSON,
    ).map(b => b.GeoJSON);
    return feats;
  }

  getBounds = (polygons, lines) => {
    if (lines.length) {
      const bbox = turf.bbox(turf.featureCollection(lines));
      return [[bbox[0], bbox[1]], [bbox[2], bbox[3]]];
    }
    if (polygons.length) {
      const bbox = turf.bbox(turf.featureCollection(polygons));
      return [[bbox[0], bbox[1]], [bbox[2], bbox[3]]];
    }
    // nz bbox
    return nzBounds;
  }

  getBBoxPadding = (sites, locationTimestamps) => {
    if (locationTimestamps.length) {
      return 140;
    }
    if (sites.length) {
      return 50;
    }
    return 1;
  }

  handleStyleLoad = map => (map && map.resize())

  handleLocationHover = (e) => {
    const { features, lngLat, type } = e;

    if (type === 'mouseleave') {
      return this.setState({ popup: null });
    }

    const labels = [];
    features.sort(
      (a, b) => (moment(a.properties.LocationDateTime).isBefore(
        moment(b.properties.LocationDateTime)) ? -1 : 1));

    features.forEach(({ properties }) => {
      const d1 = moment(properties.LocationDateTime);

      if (labels.length > 1) {
        const previous = labels.slice(-2);
        const previousDiff = Math.abs(moment.duration(previous[0].date.diff(previous[1].date)).asMinutes());
        const currentDiff = Math.abs(moment.duration(previous[0].date.diff(d1)).asMinutes());
        if (previousDiff < MIN_DURATION_MINUTES && currentDiff < MIN_DURATION_MINUTES) {
          labels.pop();
        }
      }

      labels.push({ date: d1, battery: properties.Battery });
    });

    const popup = (
      <Popup
        key={`popup${Math.random()}`}
        coordinates={[lngLat.lng, lngLat.lat]}
        offset={{
          'bottom-left': [12, -38], bottom: [0, -38], 'bottom-right': [-12, -38],
        }}
      >
        { labels.map(
          (dt, i) => (
            <div key={i}>
              { `${dt.date.format('hh:mm:ss a')} (${dt.battery}%)` }
            </div>
          )) }
      </Popup>
    );
    this.setState({ popup });
  }

  handleRawHover = (e) => {
    const { features, lngLat, type } = e;
  
    if (type === 'mouseleave') {
      return this.setState({ popup: null });
    }

    const r = features[0].properties;
    const datee = moment.utc(r.str.split(';')[3], 'YYMMDDHHmmss');
    const dateStrr = formatUtcDateAsNZTime(datee, 'HH:mm:ss a');
    const popup = (
      <Popup
        key={`popup${Math.random()}`}
        coordinates={[lngLat.lng, lngLat.lat]}
        offset={{
          'bottom-left': [12, -38], bottom: [0, -38], 'bottom-right': [-12, -38],
        }}
      >
        { dateStrr }
      </Popup>);
  
      this.setState({ popup });

  }

  render() {
    const {
      bounds, bboxPadding, popup, points, polygons, lines,
    } = this.state;

    var rawlocs = this.props.rawdats.filter(r => !!parseInt(r.split(',')[1]) &! r.includes('R')).map(r => {
      var parts = r.split(',');
      var evt = r.split(';')[7];
      return turf.point([parseFloat(parts[2]) , parseFloat(parts[1])], { raw: true, str: r, eventType: evt });
    });

    var rawCol = turf.featureCollection(rawlocs);

    var zones = this.props.zones.map(z => z.GeoJSON);
    var zoneColl = turf.featureCollection(zones);
    //console.log(zoneColl);
    /*
             
    */
    return (
      <Grid item xs={12}>
        <Map
          style={SAT_MAP_URI}
          fitBounds={bounds}
          fitBoundsOptions={{ padding: bboxPadding }}
          onZoomEnd={this.handleZoom}
          onMove={this.handleMove}
          containerStyle={{
            height: 'calc(100vh - 62px)',
            width: '100%',
          }}
          onStyleLoad={this.handleStyleLoad}
        >
          {[
            <GeoJSONLayer
              key="polys-asx"
              id="polygons-layer"
              data={polygons}
              fillPaint={SITE_FILL_STYLE}
            />,
            <GeoJSONLayer
              key="zones-asx"
              id="zones-layer"
              data={zoneColl}
              fillPaint={ZONE_FILL_STYLE}
            />,
            <GeoJSONLayer
              key="points-asx-raw"
              id="points-layer-raw"
              data={rawCol}
              circlePaint={CIRCLE_STYLE_RAW}
              circleOnMouseEnter={this.handleRawHover}
              circleOnMouseLeave={this.handleRawHover}
            />,
            <GeoJSONLayer
              key="lines-asx"
              id="lines-layers"
              data={lines}
              linePaint={LINE_STYLE}
            />,
            <GeoJSONLayer
              key="points-asx"
              id="points-layer"
              data={points}
              circlePaint={CIRCLE_STYLE}
              circleOnMouseEnter={this.handleLocationHover}
              circleOnMouseLeave={this.handleLocationHover}
            />,
            popup,
          ]}
        </Map>

      </Grid>
    );
  }
}

export default DevicesReportMap;
