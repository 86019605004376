import React, { useState, useEffect } from 'react';
import {
  Grid,
} from '@material-ui/core';
import WifiBeaconForm from './WifiBeaconForm';
import WifiBeaconMap from './WifiBeaconMap';
import WifiBeaconList from './WifiBeaconList';
import {
  getWifiBeacons,
} from '../../../API';



export default function(props) {
  
  const [wifiBeacons, setWifiBeacons] = useState([]);
  const [updateableWifiBeacon, setUpdateableWifiBeacon] = useState({});
  
  const [lnglat, setLnglat] = useState({}); 
  
  const handleNewWifiBeacon = () => {
    getWifiBeacons().then(setWifiBeacons);
  }

  useEffect(() => {
    handleNewWifiBeacon();
  }, []);

  const handleMapClick = (map, e) => {
    setLnglat(e.lngLat || {});
  }

  const handleListClick = (beacon) => {
    setUpdateableWifiBeacon(beacon);
    setLnglat({ lat: beacon.Latitude, lng: beacon.Longitude });
  }

  const handleCancel = () => {
    setLnglat({});
    setUpdateableWifiBeacon({});
  }

  return <Grid container style={{height: '85vh', width: '100vw', position: 'relative'}}>
    <div style={{ position: 'absolute', top: '5px', left: '5px', zIndex: "2" }}>
      <WifiBeaconForm
        lnglat={lnglat}
        handleNewWifiBeacon={handleNewWifiBeacon}
        updateableWifiBeacon={updateableWifiBeacon}
        handleCancel={handleCancel}
      />
    </div>
    <div style={{ position: 'absolute', top: '300px', left: '5px', zIndex: "2", height: 'calc(100vh - 450px', overflow: 'scroll' }}>
      <WifiBeaconList beacons={wifiBeacons} handleListClick={handleListClick} />
    </div>
    <div style={{height: '85vh', width: '100vw', position: 'relative'}}>
      <WifiBeaconMap
        handleMapClick={handleMapClick}
        lnglat={lnglat}
      />
    </div>
  </Grid>


}