import {HttpClient, HttpResponse, Options,  RestClient } from './RestClientTypes';
import {FormModelBase, RestModelBase, RestFeedModel, CreateFormResult } from './RestApiMicroFormat';
import {makeHttpAuthHandler} from './AuthHandler';


const defaultOptions: Options = {
  auth: {
    bearerToken: "",
    onAuthFailed: (token:string, client: HttpClient) => {
      console.error('!!You must override this function to retrieve an access token!!!'); 
      return Promise.resolve("put your token here");
    }
  }
}; 

export function makeRestClient(httpClient: HttpClient, options: Partial<Options> = {}): RestClient {
  const httpOptions = {...defaultOptions, ...options};
  const authHandler = makeHttpAuthHandler(httpClient, httpOptions);
  
  function get<T extends RestModelBase = RestModelBase>(uri: string) : Promise<T>
  {
    return authHandler
      .get(uri)
      .then(validateStatus)
      .then(validateBody)
      .then(response => makeRepresentation<T>(response));
  }

  function getFeedItems<T extends RestModelBase>(feed: RestFeedModel) : Promise<Array<T>>{
    return Promise.all(feed.items.map(item => get<T>(item.uri)));
  }

  function find(uri: string, searchCriteria: any): Promise<RestFeedModel> {
   return authHandler
    .post(uri, JSON.stringify(searchCriteria))
    .then(response => makeRepresentation<RestFeedModel>(response));
  }

  function findItems<T extends RestModelBase>(uri: string, searchCriteria: any) : Promise<Array<T>> {
     return find(uri, searchCriteria)
      .then(feed => getFeedItems<T>(feed)); 
  }

  function create(createFormUri: string , bodyContent: any): Promise<CreateFormResult> {
     return get<FormModelBase>(createFormUri)
      .then(form => authHandler.post(form.createResourceUri, JSON.stringify(bodyContent)))
      .then(x => parseJsonResponse<CreateFormResult>(x));
  }

  function update(updateFormUri: string, bodyContent: any): Promise<RestModelBase> {
    return authHandler.post(updateFormUri, JSON.stringify(bodyContent))
      .then(x => parseJsonResponse<RestModelBase>(x));
  }

  const client: RestClient =  { 
    get, 
    getFeedItems, 
    find, 
    findItems, 
    create,
    update,
  };

  return client;
}

function makeRepresentation<T extends RestModelBase>(response) : T {
  const representation = parseJsonResponse<T>(response);
  if (!representation.uri) {
    throw new Error(`${response.requestVerb} '${response.requestUri}' response has no uri`);
  }
 
  return representation;
 }

 function parseJsonResponse<T>(response: HttpResponse): T {
  try {
    return JSON.parse(response.bodyText);
  } catch {
    throw new Error(`${response.requestVerb} '${response.requestUri}' response json could not be parsed`);
  }
 }

 function validateStatus(response: HttpResponse) {
  if (response.status < 400) {
    return response;
  }
 
  throw new HttpResponseError(response);
 }
 
 function validateBody(response: HttpResponse) {
  if (!response.bodyText || response.bodyText.trim().length === 0) {
    throw new Error(`${response.requestVerb} '${response.requestUri}' response doesnt have any content`);
  }
 
  return response;
 }

 class HttpResponseError extends Error {
  constructor(public response: HttpResponse) {
    super(`${response.requestVerb} '${response.requestUri}' returned status code: ${response.status}`);     
 }
}
