/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import MapGL, {
  GeoJSONLayer,
} from 'react-mapbox-gl';
import * as turf from '@turf/turf';
import {
  MAPBOX_TOKEN,
  SAT_MAP_URI,
} from '../../Constants/Constants';


const Map = MapGL({
  accessToken: MAPBOX_TOKEN,
});

const LINE_STYLE = {
    'line-color': '#1badc6',
    'line-width': 2.5,
    'line-opacity': 0.8,
  };

const CIRCLE_STYLE = {
  'circle-opacity': 0.9,
  'circle-radius': 8,
  'circle-color': '#e33d3d',
};

export default class DashboardMapBase extends Component {
  getSiteFeatureCollection = () => {
    const { sites } = this.props;
    return sites ? turf.featureCollection(sites.map(s => s.GeoJSON)) : [];
  }

  getWorkerMarkers = () => {
    const { workerLocations, selectedWorkerId } = this.props;
    const features = turf.featureCollection(workerLocations.filter(wl => wl.WorkerId !== selectedWorkerId).map(l => (
      turf.feature({
        type: 'Point',
        coordinates: [l.Longitude, l.Latitude],
      }))));
    return features;
  }

  getSiteCenter = (feat) => {
    if (feat) {
      const centroid = turf.centroid(feat);
      return centroid.geometry.coordinates;
    }
    return this.state.center;
  }

  setCenter = () => {
    this.setState({ center: null });
  }

  getMapURI = () => {
    const { customSettings } = this.props;
    return customSettings ? customSettings.map_uri : SAT_MAP_URI;
  }

  getBounds = (feat, buffer) => {
    const bbox = turf.bbox(turf.buffer(feat, buffer || 570, { units: 'meters' }));
    return [[bbox[0], bbox[1]], [bbox[2], bbox[3]]];
  }

  setFitboundsFromNextProps = ({ siteSelected, selectedWorkerId }) => {
    if (siteSelected && JSON.stringify(siteSelected) !== JSON.stringify(this.props.siteSelected)) {
      this.setState({
        fitBounds: this.getBounds(siteSelected.GeoJSON),
      });
    } else if (selectedWorkerId && selectedWorkerId !== this.props.selectedWorkerId) {

      try {
        const bbox = this.getBounds(this.getWorkerFeature(selectedWorkerId), 550);
        this.setState({ fitBounds: bbox });
      } catch (e) {
        console.log(e);
      }
    } else if (!selectedWorkerId && selectedWorkerId !== this.props.selectedWorkerId && this.props.siteSelected && siteSelected) {
      this.setState({
        fitBounds: this.getBounds(siteSelected.GeoJSON),
      });
    } else if (this.props.sites && this.props.sites.length && !this.state.fitBoundsSet) {
      const bbox = turf.bbox(this.getSiteFeatureCollection());
      this.setState({ fitBounds: [[bbox[0], bbox[1]], [bbox[2], bbox[3]]], fitBoundsSet: true });
    }
  }

  handleStyleLoad = map => (map && map.resize())

  render() {
    const {
      siteSelected, selectedWorkerId, workerLocations, contactGeojson, workerTrack,
    } = this.props;

    const extraLayer = contactGeojson && (
    <GeoJSONLayer
      key="points-asx-00"
      id="points-layer-22"
      data={contactGeojson}
      circlePaint={{
        'circle-opacity': 0.85,
        'circle-radius': 11,
        'circle-color': '#fff',
      }}
    />
    );

    const extraLayer2 = contactGeojson && (
    <GeoJSONLayer
      key="points-asx-001"
      id="points-layer-221"
      data={contactGeojson}
      circlePaint={{
        'circle-opacity': 0.8,
        'circle-radius': 14,
        'circle-color': '#000',
      }}
    />
    );

    const extraLayer3 = workerTrack && (
    <GeoJSONLayer
      key="points-asx-00122"
      id="points-layer-221333"
      data={workerTrack}
      circlePaint={{
        'circle-opacity': 0.8,
        'circle-radius': 8,
        'circle-color': '#fc4103',
      }}
      linePaint={LINE_STYLE}

    />
    );

    const mapProps = this.getMapProps();
    return (
      <Map
        {...mapProps}
      >
        {
          [(
            <GeoJSONLayer
              key="polys-asx"
              data={this.getSiteFeatureCollection()}
              linePaint={{
                'line-color': '#a69924',
                'line-width': 8,
              }}
              fillExtrusionPaint={{
                'fill-extrusion-color': '#f5e342',
                'fill-extrusion-height': 19,
                'fill-extrusion-opacity': 0.35,
              }}
              type="fill-extrusion"
              extruded
            />
          ),
          extraLayer2, extraLayer, extraLayer3,
          ]
        }
      </Map>
    );
  }
}