import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as turf from '@turf/turf';
import moment from 'moment';
import {
  Grid,
} from '@material-ui/core';
import {
  getWorkerLocations,
  getWorkers,
  getAllSites,
  getAllZones,
} from '../../API';
import {
  WorkerActions,
  setSites,
  setLocations,
  setLoading,
  setSnackbar,  
} from '../../redux/actions/AllActions';
import WorkerLocationTable from './WorkerLocationTable';
import WorkerLocationMap from './WorkerLocationMap';
import SitePicker from './SitePicker';
import { snapLocationTimestampsToSite } from '../../GeoFunctions';



class Dashboard extends Component {
  state = {
    rowsSelected: [],
    siteSelected: null,
    workerGeo: null,
    zones: [],
  }

  componentDidMount() {
    this.props.setLoading(true);
    this.loadData();
    this.timer = setInterval(this.loadData, 300000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  loadData = async () => {
    const {
      setAllWorkers, setWorkerLocations, setAllSites, setLoading,
    } = this.props;
    try {
      const data = await Promise.all(
        [getWorkerLocations(), getAllSites(), getWorkers(), getAllZones()]);
      
      const [workerLocations, sites, workers, zones] = data;
      var activeSites = data[1].filter(s => s.Active);
      // this has to be first because sitetile checks it on componentdidmount
      if (!this.state.siteSelected) {
        setTimeout(() => {
          this.setState({ siteSelected: activeSites.length && activeSites[0] });
        }, 1500);
      }

      this.setState({ zones });

      setAllWorkers(workers);
      setAllSites(sites);
      setWorkerLocations(workerLocations);

      if (data[0]) {
        const filteredLocs = workerLocations.filter(
          wl => wl.Latitude && wl.Longitude && wl.ClosestSiteId && moment(wl.LocationDateTime).isSame(moment(), 'day')
        );
        const workerFeatures = snapLocationTimestampsToSite(sites, filteredLocs).map(
          l => turf.feature({
            type: 'Point',
              coordinates: [l.Longitude, l.Latitude],
            }, l));
        if(workerFeatures.length) {
          this.setState({ workerGeo: turf.featureCollection(workerFeatures) });
        }
      }

      setLoading(false);
    } catch (e) {
      console.warn(e);
      this.setState({zones: []});
      setAllWorkers([]);
      setAllSites([]);
      setWorkerLocations([]);
      setLoading(false);
    }
  }

  onRowClick = (data, rowMeta) => {
    const { workerLocations } = this.props;
    const { workerGeo } = this.state;
    const wl = workerLocations.find(
      w => w.WorkerId === data[2] && workerGeo && workerGeo.features.find(f => f.properties.WorkerId === data[2]));

    if (!wl) {
      this.props.setSnackbar({
        message: `Current location of ${data[0]} is unknown`,
        openTime: new Date().getTime(),
      });
      return;
    }
    
    if (this.props.selectedWorkerId === data[2]) {
      this.setState({
        rowsSelected: [],
        selectedWorkerId: null,
      });
      this.props.setSelectedWorker(null);
      return;
    }

    this.props.setSelectedWorker(data[2]);
    this.setState({
      rowsSelected: [rowMeta.rowIndex],
      selectedWorkerId: data[2],
    });
  }

  onRowsDelete = () => {
    this.setState({ rowsSelected: [] });
  }

  onRowsSelect = (selectedRows) => {
    const { rowsSelected } = this.state;
    const { index } = selectedRows[0];
    const stateIndex = rowsSelected.indexOf(index);
    if (stateIndex !== -1) {
      if (rowsSelected.length === 1) {
        this.setState({ rowsSelected: [] });
      } else {
        this.setState({ rowsSelected: rowsSelected.splice(stateIndex, 1) });
      }
    } else {
      this.setState({ rowsSelected: [...rowsSelected, index] });
    }
  }

  getTableRecords = () => {
    const { workers, workerLocations } = this.props;

    const ws = workerLocations.filter(
      wl => workers.find(ww => ww.WorkerId === wl.WorkerId)).map(
      (wl) => {
        const w = workers.find(ww => ww.WorkerId === wl.WorkerId);
        const {
          ClosestSiteDescription,
          LocationDateTimeDescription,
          BatteryDescription,
          LocationDateTime,
          DeviceId,
        } = wl;
        return [
        // { img: w.Base64Image || '/img/user.png', status: WORKER_STATUS[LocationStatus] },
          w.Name,
          ClosestSiteDescription,
          w.WorkerId,
          LocationDateTimeDescription,
          BatteryDescription,
          LocationDateTime,
          DeviceId,
        ].map(t => t || 'not available');
      });
    return ws;
  }

  onSiteTileClick = (site) => {
    this.setState({ siteSelected: site });
  }

  render() {
    const { sites, workers, selectedWorkerId } = this.props;
    const { rowsSelected, siteSelected } = this.state;
    const records = this.getTableRecords();
    return (
      <Grid container className="dashboard-container">        
        <Grid item className="left" xs={12} md={7}>
          <Grid itemxs={12} >
            <SitePicker sites={sites.filter(s => s.Active)} siteSelected={siteSelected} onClick={this.onSiteTileClick} />
          </Grid>
          <Grid item xs={12}>
            <WorkerLocationMap
              workerLocations={this.state.workerGeo}
              workers={workers}
              siteSelected={siteSelected}
              sites={sites.filter(s => s.Active)}
              zones={this.state.zones}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} className="right dashboard-table">
          <WorkerLocationTable
            onRowsSelect={this.onRowsSelect}
            rowsSelected={rowsSelected}
            rows={records}
            selectedWorkerId={selectedWorkerId}
            onRowClick={this.onRowClick}
            onRowsDelete={this.onRowsDelete}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { workers, sites } = state;
  return {
    workers: workers.all,
    sites: sites.all,
    workerLocations: workers.locations,
    selectedWorkerId: state.ui.selectedDashboardWorkerId,
  };
};

const mapDispatchToProps = dispatch => ({
  setAllWorkers: ws => dispatch(WorkerActions.setWorkers(ws)),
  setAllSites: ws => dispatch(setSites(ws)),
  setWorkerLocations: ws => dispatch(setLocations(ws)),
  setSnackbar: ws => dispatch(setSnackbar(ws)),
  setLoading: ws => dispatch(setLoading(ws)),
  setSelectedWorker: workerId => dispatch(WorkerActions.setSelectedDashboardWorker(workerId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
