import React, { Component } from "react";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Button,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/AccountCircle';
import {
  getWorkers,
  getCloseContacts,
  getAllSites,
  getAllZones,
  getWorkerLocationsByDateRange,
} from '../../API';
import moment from 'moment-timezone';
import { ContactTracingMap, mapStateToProps } from "../ContactTracing/ContactTracingMap";
import  { 
  clusteriseContactTracingResults,
  locationTimestampsToPoints,
} from '../../GeoFunctions';
import { uniqInArray, randomColor } from "../../Utils";
import * as turf from '@turf/turf';
import wkt from 'terraformer-wkt-parser';
import { printToPDF } from '../Utils/PrintPdf';
import { makeIncidentReportHTML } from './HTMLIncidentReport';


export function WitnessListItem(props) {
  const { WorkerId, Distance, ContactName, Color } = props.feature.properties;
  return (
    <ListItem key={`list-item-${WorkerId}`}>
      <ListItemIcon>
        <PersonIcon style={{ color: Color }} />
      </ListItemIcon>
      <ListItemText primary={ContactName} secondary={`distance ${Distance}m`} />
    </ListItem>
  );
}


export default class IncidentReportPage extends Component {

  state = {
    selectedWorker: undefined,
    workers: [],
    sites: [],
    zones: [],
    incidentDate: moment(),
    incidentTime: '09:00',
    contactGeojson: { type: 'FeatureCollection', features: [] },
    workerTrack: { type: 'FeatureCollection', features: [] },
    mapRef: undefined,
  }

  componentDidMount() {
    Promise.all([
      getWorkers(),
      getAllSites(),
      getAllZones(),
    ]).then(data => {
      const colorMap = {};
      const mapColorStops = [];
      data[0].forEach((w) => {
        colorMap[`${w.WorkerId}`] = randomColor();
        mapColorStops.push([w.WorkerId, colorMap[`${w.WorkerId}`]]);
      });
      this.setState({
        workers: data[0].filter(x => x.IsEnabled && x.IsWorker),
        sites: data[1],
        zones: data[2],
        colorMap, mapColorStops,
      });
    });
  }

  setMapRef = (mapRef) => {
    console.log(mapRef);
    this.setState({ mapRef });
  }

  handleDateChange = ({ target }) => {
    this.setState({ incidentDate: moment(target.value, 'YYYY-MM-DD') });
  }

  handleTimeChange = ({ target }) => {
    this.setState({ incidentTime: target.value });
  }

  handleWorkerSelectChange = ({ target }) => {
    const selectedWorker = this.state.workers.find(x => x.WorkerId == target.value);
    this.setState({ selectedWorker });
  }

  handleSubmit = async () => {
    const { incidentDate, incidentTime, selectedWorker } = this.state;
    const dt = moment.tz(`${incidentDate.format('YYYY-MM-DD')} ${incidentTime}`, "Pacific/Auckland");
    const start = moment(dt).add(-30, 'minutes');
    const end = moment(dt).add(30, 'minutes');
    const workerLocations = await getWorkerLocationsByDateRange({ 
      WorkerId: selectedWorker.WorkerId,
      StartDateTimeUTC: start.utc().format('YYYY-MM-DD HH:mm'),
      EndDateTimeUTC: end.utc().format('YYYY-MM-DD HH:mm'),
    });
    if(!workerLocations.length) {
      return;
    }
    const sortBySmallestDiff = (a, b) => {
      const aa = moment.tz(a.LocationDateTime, 'YYYY-MM-DD HH:mm',  "Pacific/Auckland");
      const bb = moment.tz(b.LocationDateTime, 'YYYY-MM-DD HH:mm',  "Pacific/Auckland");
      const aDiff = Math.abs(dt.diff(aa));
      const bDiff = Math.abs(dt.diff(bb));
      if(aDiff < bDiff) {
        return -1;
      }
      if(aDiff < bDiff) {
        return 1;
      }
      return 0;
    }

    workerLocations.sort(sortBySmallestDiff);
    const workerTrack = locationTimestampsToPoints([workerLocations[0]]);
    let contactData = await getCloseContacts(selectedWorker.WorkerId,
      start.utc().format('YYYY-MM-DD'),
      end.utc().format('YYYY-MM-DD'),
      30,
      200,
      start.utc().format('HH:mm'),
      end.utc().format('HH:mm')
    );
    if(!contactData) {
      contactData = [];
    }
    contactData = contactData.map(c => {
      const utcdt = moment.utc(c.LocationDateTime, 'YYYY-MM-DD HH:mm');
      return { ...c,
        LocationDateTime: utcdt.clone().tz("Pacific/Auckland").format('YYYY-MM-DD HH:mm'),
      }
    })
    
    const workerIds = uniqInArray(contactData.map(x => x.WorkerId));
    
    const closestContactFeatures = workerIds.map(id => {
      const contats = contactData.filter(c => c.WorkerId === id);
      contats.sort(sortBySmallestDiff);
      const { wkt_point, WorkerId, LocationTimestampId,
        LocationDateTime, ClosestSiteId, Name, ContactName, ZoneId,
        NumberOfSatellites, ClosestSiteDistance 
      } = contats[0];
      console.log(contats[0])
      const point = turf.point(wkt.parse(wkt_point).coordinates, 
            { WorkerId, LocationTimestampId, LocationDateTime,
            ClosestSiteId, Name, ContactName,  NumberOfSatellites, ClosestSiteDistance, ZoneId, 
            compositeId: `${WorkerId}-${ClosestSiteId}-${ZoneId}` });
      const distance = turf.distance(point.geometry.coordinates, workerTrack.features[0].geometry.coordinates);
      point.properties.Distance = Math.floor(distance * 1000);
      point.properties.Color = this.state.colorMap[WorkerId];
      return point;
    });
    const contactGeojson = turf.featureCollection(closestContactFeatures);
    contactGeojson.features.sort((a, b) => {
      if(a.properties.Distance < b.properties.Distance){
        return -1;
      }
      if(a.properties.Distance < b.properties.Distance){
        return 1;
      }
      return 0;
    });
    this.setState({
      contactGeojson,
      workerTrack,
    });
  }

  printReport = () => {
    if(!this.state.selectedWorker) {
      return;
    }
    const options = {
      //filename: `contact-report-${this.state.selectedWorker.Name.replace(' ', '')}`,
      filename: Math.floor(Math.random() * 1000000),
      jsPDF: {  orientation: 'portrait' },
      pagebreak: {avoid: 'li'},
      margin: 5,
    };
    const { selectedWorker, contactGeojson, incidentDate, incidentTime } = this.state;
    const contactProperties = contactGeojson.features.map(f => f.properties);
    const dataUrl = this.state.mapRef.getCanvas().toDataURL('image/png');
    const dt = moment();
    const html = makeIncidentReportHTML({
      name: selectedWorker.Name,
      incidentDate: incidentDate.format('DD/MM/YYYY'),
      incidentTime: moment(incidentTime, 'HH:mm').format('hh:mm a'),
      printDate: dt.format('DD/MM/YYYY'),
      printTime: dt.format('hh:mm a'),
      contacts: contactProperties,
    }, dataUrl);
    printToPDF(html, options);
  }

  render() {
    const { incidentDate, incidentTime, selectedWorker, workers,
      contactGeojson, workerTrack, sites, zones, mapColorStops } = this.state;

    return (
      <Grid container className="dashboard-container">
        <Grid item className="left" xs={12} md={8} lg={9}>
          <Grid item xs={12}>
            <ContactTracingMap contactGeojson={contactGeojson}
              workerTrack={workerTrack} sites={sites} zones={zones} setMapRef={this.setMapRef}
              colorStops={ mapColorStops } />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={3} className="right" style={
          { backgroundColor: '#fff', maxWidth: '325px', paddingLeft: '20px', paddingTop: '5px' }}>
          <Grid item xs={12} style={{ padding: '12px' }}>
            <Typography variant="h4" gutterBottom>
              Incident Report
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{marginLeft: '20px'}}>
              <FormControl>
                <InputLabel>Worker</InputLabel>
                <Select
                  style={{ width: '100%', maxWidth: '300px' }}
                  value={selectedWorker ? selectedWorker.WorkerId : 0}
                  onChange={this.handleWorkerSelectChange}
                  autoWidth
                >
                  {workers.map(
                    x => <MenuItem value={x.WorkerId} key={x.WorkerId}>
                      {x.Name}
                    </MenuItem>)}
                </Select>
                <FormHelperText>Select the injured worker</FormHelperText>
              </FormControl>
              <FormControl>
                <TextField
                  type='date'
                  label='Date'
                  value={incidentDate.format('YYYY-MM-DD')}
                  onChange={this.handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="time"
                  label="Time"
                  value={incidentTime}
                  onChange={this.handleTimeChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText>Select date and time of the injury</FormHelperText>
              </FormControl>
            </div>
            <div style={{marginLeft: '20px', paddingTop: '8px'}}>
              <FormControl>
                <Button variant="outlined" onClick={this.handleSubmit} style={{float: 'right'}}>Submit</Button>
              </FormControl>
            </div>
            <div style={{marginLeft: '20px', paddingTop: '8px'}}>
              <FormControl>
                <Button variant="outlined" onClick={this.printReport} style={{float: 'right'}}>Print</Button>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
          <List
            style={{ maxHeight: '100%' }}
            component="div"
          >
            <ListItem key="keyskeys">
              <ListItemText primary="Closest Witnesses" />
            </ListItem>
            { contactGeojson.features.map((f) => (<WitnessListItem feature={f} />)) }
          </List>
          </Grid>
        </Grid>
      </Grid>
    )
  }

}