import localForage from 'localforage';
import { Dispatch } from 'redux';
import request from 'superagent'; // eslint-disable-line
import Auth from './Components/Auth/Auth';
import { getEnvVariables } from './Constants/EnvironmentVariables';
import { setLoading } from './redux/actions/AllActions';


export interface Worker {
  WorkerId: Number;
  Name: String;
  Mobile: String;
  CompanyId: String;
}


async function authPost(path: string, body: any) {
  const envVariables = await getEnvVariables();
  /* eslint-disable */
  const token = await localForage.getItem('access_token');
  /* eslint-enable */
  return request.post(`${envVariables.backendServer}/${path}`)
    .set('authorization', `Bearer ${token}`)
    .set('Content-Type', 'application/json')
    .send(body);
}

async function authGet(path: string) {
  const envVariables = await getEnvVariables();
  /* eslint-disable */
  const token = await localForage.getItem('access_token');
  /* eslint-enable */
  const route = `${envVariables.backendServer}/${path}`;
  return request.get(route)
    .set('authorization', `Bearer ${token}`)
    .set('Content-Type', 'application/json');
}

async function authGetFullUri(path: string) {
  const envVariables = await getEnvVariables();
  /* eslint-disable */
  const token = await localForage.getItem('access_token');
  /* eslint-enable */
  return request.get(path)
    .set('authorization', `Bearer ${token}`)
    .set('Content-Type', 'application/json');
}

async function awaitRequestBody(res) {
  try {
    const { body, statusCode } = await res;
    return body;    
  } catch (e) {
    if(e.status === 401) {
      new Auth().login();
    } else if(e.status === 500) {
      alert('Something is wrong - please let us know and try again later');
    }
    return;
  }
}


export async function withLoading<T>(dispatch: Dispatch, req: Promise<T>) {
  dispatch(setLoading(true));

  const data = await req;
  dispatch(setLoading(false));
  return data;
}

export async function getTimesheetEntries(id) {
  return awaitRequestBody(authGet(`api/timesheetentriesv2/${id}`));
}

export async function getUnlockedTimesheets() {
  return awaitRequestBody(authGet('api/unlockedtimesheets'));
}

export async function getApprovedTimesheetCount(id) {
  return awaitRequestBody(authGet(`api/getapprovedtimesheetcount/${id}`));
}

export async function regenerateTimesheetEntries(id) {
  return awaitRequestBody(authGet(`api/regeneratetimesheetentries/${id}`));
}

export async function getApprovalsData() {
  return awaitRequestBody(authGet('api/approvalsdata'));
}

export async function getTimesheetNotes(timesheetId) {
  return awaitRequestBody(authGet(`api/timesheetnotes/${timesheetId}`));
}

export function postTimesheetNote(timesheetNote) {
  return awaitRequestBody(authPost('api/addtimesheetnote', timesheetNote));
}

export function updateTimesheetEntries(entries, timesheetId) {
  return awaitRequestBody(authPost('api/updatetimesheetentries',
    { Entries: entries, TimesheetId: timesheetId }));
}

export async function getDevicesReport(reportDate, workerId) {
  return awaitRequestBody(authPost('api/devicereport',
    { ReportDate: reportDate, WorkerId: workerId || null }));
}

export async function postSetTimesheetStatus(payload) {
  return awaitRequestBody(authPost('api/settimesheetstatus', payload));
}

export async function getDistinctTimesheets() {
  return awaitRequestBody(authGet('api/distincttimesheets'));
}

export async function getMyDistinctTimesheets(token) {
  return awaitRequestBody(authGet(`api/mydistincttimesheets/${token}`));
}

export async function getTimesheetReport(timesheetId, format) {
  return awaitRequestBody(authGet(`api/timesheetreport/${timesheetId}/${format}`));
}

export async function getExportTimesheet(timesheetId, format) {
  return awaitRequestBody(authGet(`api/timesheetexport/${timesheetId}/${format}`));
}

export async function lockTimesheets(timesheetId) {
  return awaitRequestBody(authGet(`api/locktimesheets/${timesheetId}`));
}

export async function getWorkers() {
  return awaitRequestBody(authGet('api/workers'));
}

export async function getWorkersFromToken(token) {
  return awaitRequestBody(authGet(`api/workers/${token}`));
}

export async function createWorker(worker) {
  return awaitRequestBody(authPost('api/createworker', worker));
}

export async function editWorker(worker) {
  return awaitRequestBody(authPost('api/editWorker', worker));
}

export async function getSupervisor() {
  return awaitRequestBody(authGet('api/supervisors'));
}

export async function getAllSites() {
  return awaitRequestBody(authGet('api/allsites'));
}

export async function getSitesByCompany(companyId) {
  return awaitRequestBody(authGet(`api/sitesbycompany/${companyId}`));
}

export async function getBatteryConsumptionReport(siteIds: number[], startDate: string, endDate: string) {
  return awaitRequestBody(authPost(`api/getbatteryconsumptionreport`, { siteIds, startDate, endDate }));
}

export async function generateTimesheetsForCompany(companyId, earliestDateTime) {
  return awaitRequestBody(authPost('api/createhistoricaltimesheetsforcompany', {companyId, earliestDateTime }));
}

export async function getWorkersForCompany(companyId) {
  return awaitRequestBody(authGet(`api/getworkersforcompany/${companyId}`));
}

export async function deleteEmptyTimesheets() {
  return awaitRequestBody(authGet(`api/deleteemptytimesheets`));
}

export async function generateTimesheetEntries(companyId, earliestDateTime, latestDateTime, minHoursForLunch, minMinutesForBreak, distanceFromSite, workerId) {
  return awaitRequestBody(authPost('api/generatetimesheetentries', {companyId, earliestDateTime, latestDateTime, minHoursForLunch, minMinutesForBreak, distanceFromSite, workerId}));
}

export async function createSite(site) {
  return awaitRequestBody(authPost('api/createsite', site));
}

export async function getSiteDeviceSettings(siteId) {
  return awaitRequestBody(authGet(`api/sitedevicesettings/${siteId}`));
}

export async function updateSite(site) {
  return awaitRequestBody(authPost('api/updatesite', site));
}

export async function getAllZones() {
  return awaitRequestBody(authGet('api/allzones'));
}

export async function createZone(zone) {
  return awaitRequestBody(authPost('api/createzone', zone));
}

export async function updateZone(zone) {
  return awaitRequestBody(authPost('api/updatezone', zone));
}

export async function getMe() {
  return awaitRequestBody(authGet('api/currentuser'));
}

export async function getCompanyName() {
  return awaitRequestBody(authGet('api/companyname'));
}

export async function getCompany() {
  return awaitRequestBody(authGet('api/getcompany'));
}

export async function updateCompany(company) {
  return awaitRequestBody(authPost('api/updatecompany', company));
}

export async function getCompanyNameFromToken(token) {
  return awaitRequestBody(authGet(`api/companyname/${token}`));
}

export async function getCompanyFromToken(token) {
  return awaitRequestBody(authGet(`api/company/${token}`));
}

export async function getMyTimesheetData(token) {
  return awaitRequestBody(authGet(`api/mytimesheet/data/${token}`));
}

export async function getMyTimesheetEntries(token, id) {
  return awaitRequestBody(authGet(`api/mytimesheet/timesheetentries/${token}/${id}`));
}

export async function getMyTimesheetNotes(token, id) {
  return awaitRequestBody(authGet(`api/mytimesheet/timesheetnotes/${token}/${id}`));
}

export async function postMyTimesheetNote(token, note) {
  return awaitRequestBody(authPost(`api/mytimesheet/addtimesheetnote/${token}`, note));
}

export async function getWorkerLocations() {
  return awaitRequestBody(authGet('api/latestlocationtimestamps'));
}

export async function getAvailableDevices() {
  return awaitRequestBody(authGet('api/getavailabledevices'));
}

export async function getAllDevices() {
  return awaitRequestBody(authGet('api/alldevices'));
}

export async function getAllDevicesAllCompanies() {
  return awaitRequestBody(authGet('api/alldevicesallcompanies'));
}

export async function getCompanies() {
  return awaitRequestBody(authGet('api/getallcompanies'));
}

export async function switchCompany(id) {
  return awaitRequestBody(authGet(`api/switchcompany/${id}`));
}

export async function createCompany(params) {
  return awaitRequestBody(authPost('api/createcompany', params));
}

export async function getAllUsersAllCompanies() {
  return awaitRequestBody(authGet('api/allworkersallcompanies'));
}

export async function postUploadManyUsers(data) {
  return awaitRequestBody(authPost('api/uploadmanyusers', data));
}

export async function postUploadManyDevices(data) {
  return awaitRequestBody(authPost('api/uploadmanydevices', data));
}

export async function getCloseContacts(workerId, startDate, endDate,
  timeBuffer, distanceBuffer, startTime, endTime) {
  /* eslint-disable */
  const response = await awaitRequestBody(authPost('api/closecontact',
    {
      WorkerId: workerId, StartDate: startDate,
      EndDate: endDate, TimeBuffer: timeBuffer, DistanceBuffer: distanceBuffer,
      StartTime: startTime, EndTime: endTime
    }));
  return response;
  /* eslint-enable */
}

export async function getWorkerLocationsByDateRange(dateRangeParams) {
  return awaitRequestBody(authPost('api/getlocationtimestampsinrange', dateRangeParams));
}

export async function postAddSystemAdmin(email) {
  return awaitRequestBody(authPost(`api/createsuperadmin/${email}`, {}));
}

export async function getApiRoot() {
  let root = await localForage.getItem('root');
  if(root) {
    return root;
  }
  root = await awaitRequestBody(authGet('api/root'));
  await localForage.setItem('root', root);
  return root;
}

export async function getCovidCaseHistoryItems(uri) {
  return awaitRequestBody(authGetFullUri(uri));
}

export async function getDeviceVersions() {
  return awaitRequestBody(authGet('devices/firmware-versions'));
}
export async function updateDevicesVersion(deviceId, versionId) {
  return awaitRequestBody(authGet(`devices/update-device-firmware-version/${deviceId}/${versionId}`));
}

export async function getEventItemsByWorkerAndDate(workerId: string, startDatetime: string, endDatetime: string) {
  return awaitRequestBody(authGet(`api/geteventsforworker?workerId=${workerId}&startDate=${startDatetime}&endDate=${endDatetime}`));
}

export async function getDeviceEventItemsByWorkerAndDate(workerId: string, startDatetime: string, endDatetime: string) {
  return awaitRequestBody(authGet(`api/getdeviceeventsforworker?workerId=${workerId}&startDate=${startDatetime}&endDate=${endDatetime}`));
}

export async function getUnpaidTimesheetEntries(timesheetId: string, update: boolean = false) {
  return awaitRequestBody(authGet(`api/getunpaidtimesheetentry/${timesheetId}/${update}`));
}

export async function createUnpaidTimesheetEntry(worktimesetting: any) {
  return awaitRequestBody(authPost(`api/createunpaidtimesheetentry`, worktimesetting));
}

export async function updateUnpaidTimesheetEntry(worktimesetting: any) {
  return awaitRequestBody(authPost(`api/updateunpaidtimesheetentry`, worktimesetting));
}

export async function getWorkerGroups() {
  return awaitRequestBody(authGet(`api/allworkergroups`));
}

export async function createWorkerGroup(name, mode, interval) {
  return awaitRequestBody(authGet(`api/createworkergroup/${name}/${mode}/${interval}`));
}

export async function addWorkerToGroup(workerGroupId, workerId) {
  return awaitRequestBody(authGet(`api/addworkertogroup/${workerGroupId}/${workerId}`));
}

export async function removeWorkerFromGroup(workerId) {
  return awaitRequestBody(authGet(`api/removeworkerfromgroup/${workerId}`));
}

export async function deleteWorkerGroup(workerGroupId) {
  return awaitRequestBody(authGet(`api/deleteworkergroup/${workerGroupId}`));
}

export async function createShift(shift) {
  return awaitRequestBody(authPost(`api/createshift`, shift));
}

export async function archiveShift(shiftId) {
  return awaitRequestBody(authGet(`api/archiveshift/${shiftId}`));
}

export async function getShifts() {
  return awaitRequestBody(authGet(`api/allshifts`));
}

export async function getWorkerShifts(start, end) {
  return awaitRequestBody(authPost(`api/allworkershifts`, { start, end }));
}

export async function postWorkerShift(workerShift) {
  debugger;
  return awaitRequestBody(authPost(`api/createworkershift`, workerShift));
}

export async function copyWorkerShifts(start) {
  return awaitRequestBody(authPost(`api/copyworkershiftweek`, { start, end: null }));
}

export async function clearWorkerShifts(workerId, workerGroupId, day) {
  return awaitRequestBody(authPost(`api/clearworkershifts`, { workerId, workerGroupId, day }));
}

export async function getRawData(workerId, startDateTime, endDateTime) {
  return awaitRequestBody(authPost(`api/getrawdata`, { workerId, startDateTime, endDateTime }));
}

export async function getWifiBeacons() {
  return awaitRequestBody(authGet(`api/wifibeacons`));
}

export async function postNewWifiBeacon({ name, macAddress, lat, lng }) {
  return awaitRequestBody(authPost('api/new-wifibeacon', { name, macAddress, lng, lat }));
}

export async function postUpdateWifiBeacon(id, { name, macAddress, lat, lng }) {
  return awaitRequestBody(authPost(`api/update-wifibeacon/${id}`, { name, macAddress, lng, lat }));
}

export async function getSetDeviceConfigRequired(deviceId) {
  return awaitRequestBody(authGet(`devices/set-rconf-required/${deviceId}`));
}

export async function getSetDeviceRebootRequired(deviceId) {
  return awaitRequestBody(authGet(`devices/set-reboot-required/${deviceId}`));
}

export async function deactivateSite(siteId) {
  return awaitRequestBody(authGet(`api/deactivatesite/${siteId}`));
}

export async function reactivateSite(siteId) {
  return awaitRequestBody(authGet(`api/reactivatesite/${siteId}`));
}

export async function getWorkerStatus(workerId, refreshcache) {
  return awaitRequestBody(authGet(`api/workerstatus/${workerId}${refreshcache ? `?v=${Math.random()}` : ''}`));
}

export async function postManualLocation(loc) {
  return awaitRequestBody(authPost(`api/manual-location`, loc));
}

export async function postManualOffsiteEvent(workerId, eventTimeLocal) {
  return awaitRequestBody(authPost(`api/manual-offsite`, { workerId, eventTimeLocal }));
}

export async function getLabourAgencies() {
  return awaitRequestBody(authGet('api/alllabouragencies'));
}

export async function postAddLabourAgency(name) {
  return awaitRequestBody(authGet(`api/createlabouragency/${name}`));
}

export async function postUpdateLabourAgency(labourAgency) {
  return awaitRequestBody(authPost('api/updatelabouragency', labourAgency));
}

export async function getWorkerShiftsForWorkerAndRange(workerId, start, end){
  return awaitRequestBody(authPost(`api/getworkershifts/${workerId}`, { start, end }));
}

export async function getEmailPreview(payload) {
  return awaitRequestBody(authPost('api/previewmanualemail', payload));
}

export async function sendManualEmail(payload) {
  return awaitRequestBody(authPost('api/sendmanualemail', payload));
}

export async function getWorkerTimesheets(workerId) {
  return awaitRequestBody(authGet(`api/workertimesheets/${workerId}`));
}

export async function approvalAllTimesheets(workerId) {
  return awaitRequestBody(authGet(`api/approveall/${workerId}`));
}

export async function getWorkerEmailData(workerId) {
  return awaitRequestBody(authGet(`api/workeronsites/${workerId}`));
}

export async function getAgencyConsultants(agencyId) {
  return awaitRequestBody(authGet(`api/getagencyconsultants/${agencyId}`));
}

export async function getAllAgencyConsultants() {
  return awaitRequestBody(authGet(`api/getallagencyconsultants`));
}

export async function postAgencyConsultant(payload) {
  return awaitRequestBody(authPost('api/createagencyconsultant', payload));
}


export async function deleteAgencyConsultant(agcid) {
  return awaitRequestBody(authGet(`api/deleteagencyconsultant/${agcid}`));
}

export async function getMobileViewPayload(accessToken) {
  return awaitRequestBody(authGet(`api/m/${accessToken}`));
}

export async function getMobileViewWorkerStatus(accessToken, workerId) {
  return awaitRequestBody(authGet(`api/m1/${accessToken}/${workerId}`));
}

export async function getSupportWorkerStatus(workerId) {
  return awaitRequestBody(authGet(`api/workerstatussupport/${workerId}`));
}

export async function getSupportWorkerLatestIncomingSMS(workerId) {
  return awaitRequestBody(authGet(`api/getlatestincomingsms/${workerId}`));
}

export async function getGeneratedTextmessages() {
  return awaitRequestBody(authGet(`api/generateonsitesmschecks`));
}

export async function postTextmessage(payload) {
  return awaitRequestBody(authPost(`api/createsmstosend`, payload));
}

export async function getIncomingMessages(workerId) {
  return awaitRequestBody(authGet(`api/getincomingsms/${workerId}`));
}

export async function getNotificationsByWorkerId(workerId) {
  return awaitRequestBody(authGet(`api/getnotificationsbyworker/${workerId}`));
}

export async function getWorkerNotes(workerId) {
  return awaitRequestBody(authGet(`api/workernotes/${workerId}`));
}

export async function addWorkerNote(workerNote) {
  return awaitRequestBody(authPost('api/createworkernote', workerNote));
}

export async function getZoneReport(startDate, endDate) {
  return awaitRequestBody(authPost('api/zonereport', { startDate, endDate }));
}

export async function getWorkerTotals(supervisorId, agencyId, startDatetimeUtc, endDatetimeUtc) {
  return awaitRequestBody(authPost('api/workertotals', { supervisorId, agencyId, siteId: null, startDatetimeUtc, endDatetimeUtc }));
}
