import {
  SET_WORKER_SHIFTS,
  SET_SCHEDULE_GROUP_VIEW,
  SET_SCHEDULE_NEXT_WEEK,
  SET_SCHEDULE_PREVIOUS_WEEK,
} from './actions/ActionTypes';
import { getCurrentWeek, getNextWeek, getPreviousWeek } from '../Utils';

const initialState = {
  all: [],
  uiState: {
    week: getCurrentWeek(),
    showGroups: true,
  }
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_WORKER_SHIFTS: {
      return { ...state, all: payload };
    }
    case SET_SCHEDULE_GROUP_VIEW: {
      const uiState = { ...state.uiState, showGroups: payload };
      return { ...state, uiState };
    }
    case SET_SCHEDULE_PREVIOUS_WEEK: {
      const uiState = { ...state.uiState, week: getPreviousWeek(state.uiState.week) };
      return { ...state, uiState };
    }
    case SET_SCHEDULE_NEXT_WEEK: {
      const uiState = { ...state.uiState, week: getNextWeek(state.uiState.week) };
      return { ...state, uiState };
    }
    default:
      return state;
  }
}
