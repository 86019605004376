import { Options , RestClient } from '../../RestClient/RestClientTypes';
import { makeFetchHttpRestClient, LocalStorageAccessToken} from '../../RestClient/RestClientFactory'
import { PromiseAction } from './types'
import { getEnvVariables } from '../../Constants/EnvironmentVariables';

export enum RESTCLIENT_ActionTypes {
  ENVIRONMENT = "REST_CLIENT.ENVIRONMENT",
  RESTCLIENT = "REST_CLIENT.RESTCLIENT",  
};

export class RestClientActions {

  static getEnvironment() : PromiseAction<any, RESTCLIENT_ActionTypes> {
    return({
      type: RESTCLIENT_ActionTypes.ENVIRONMENT, 
      promise: getEnvVariables()
    });
  };

  static getRestClient() : PromiseAction<RestClient, RESTCLIENT_ActionTypes> {
    return({
      type: RESTCLIENT_ActionTypes.RESTCLIENT, 
      promise: initialise()
    });
  };
}

function initialise(): Promise<RestClient> {
  return getEnvVariables()
  .then(e => {
      const url = e.backendServer.toLowerCase();

      const options : Partial<Options> = {
        auth:{
          bearerToken: "",
          onAuthFailed: LocalStorageAccessToken(e)
        }
      };

      return makeFetchHttpRestClient(url, options);
    })    
}