import React, { Component } from 'react';
import {
  FormHelperText,
  Input,
  FormControl,
  Button,
  Paper,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Common/FormStyles';
import { createCompany } from '../../API';
import { validateEmail, validateMobile } from '../../Utils';

class CreateCompanyForm extends Component {
  state = {
    email: '',
    name: '',
    mobile: '',
    companyName: '',
  }

  handleButton = async () => {
    const {
      email, name, mobile, companyName,
    } = this.state;
    if (name && companyName && validateEmail(email) && validateMobile(mobile)) {
      const res = await createCompany(this.state);
      alert(res);
      this.setState({
        email: '',
        name: '',
        mobile: '',
        companyName: '',
      });
    } else {
      alert('fix the details and try again');
    }
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  }

  render() {
    const {
      companyName, email, name, mobile,
    } = this.state;
    const { classes } = this.props;
    return (
      <Paper style={{ padding: '70px', margin: '70px', minWidth: '500px' }}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <Input onChange={this.handleChange} value={companyName} name="companyName" />
              <FormHelperText error={false}>Company Name</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <Input onChange={this.handleChange} value={name} name="name" />
              <FormHelperText error={false}>User Name</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <Input onChange={this.handleChange} value={email} type="email" name="email" />
              <FormHelperText error={false}>User Email</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <Input onChange={this.handleChange} value={mobile} name="mobile" />
              <FormHelperText error={false}>User Cell</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth style={{ marginTop: '30px' }}>
              <Button fullWidth color="primary" onClick={this.handleButton}>Create Company</Button>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CreateCompanyForm);
