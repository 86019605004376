import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CssBaseline from '@material-ui/core/CssBaseline';
import classNames from 'classnames';
import Autocomplete from '@material-ui/lab/Autocomplete';


import {
  Select,
  MenuItem,
  Grid,
  FormHelperText,
  FormControlLabel,
  Input,
  FormControl,
  Switch,
  FormGroup,
  Button,
  TextField,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import ObjectEditForm from '../Common/ObjectEditForm';
import styles from '../Common/FormStyles';
import { WorkerActions } from '../../redux/actions/AllActions';
import {
  base64FromFile,
  resizeB64Image,
  assign,
  fixImageRotaionUsingExif,
  validateEmail,
  validateMobile,
  arrayUniqueByKey,
} from '../../Utils';


const colStlye = { paddingLeft: '40px' };

export class UserForm extends ObjectEditForm {
  state = {
    editingObj: {},
    rawImage: null,
  }

  save = async (user) => {
    const { rawImage } = this.state;
    if (user.WorkerId) {
      this.props.editWorker(assign(user,
        { Base64Image: rawImage || user.Base64Image }));
    } else {
      const sending = assign(user, {
        WorkerId: `no-id${Math.random()}`,
        Base64Image: rawImage || user.Base64Image,
      });
      this.props.createWorker(sending);
    }
  }

  handleDeviceSelect = (device) => {
    const { editingObj } = this.state;
    this.setState({ editingObj: { ...editingObj, DeviceId: device ? device.DeviceId : ''} });
  }

  validateMobile(value) {
    return validateMobile(value);
  }

  validateEmail(value) {
    return validateEmail(value);
  }

  handleUserTypeChange({ target }) {
  }

  handleIsWorkerChange = ({ target }, checked) => {
    const { editingObj } = this.state;
    const { Supervisor } = editingObj;
    this.setState({
      editingObj: assign(editingObj, {
        IsWorker: !!checked,
        Supervisor: checked ? Supervisor : '',
      }),
    });
  }

  onFileUpload = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      // eslint-disable-next-line no-undef
      alert('unexpected photo rejection - please try something else');
      return;
    }
    try {
      const f = acceptedFiles[0];
      const blobFixed = await fixImageRotaionUsingExif(f);
      const b64 = await base64FromFile(blobFixed);
      const resizedImgB64 = await resizeB64Image(b64, 64, 64, 'jpeg', 0.6);
      this.setState({ rawImage: resizedImgB64 });
      this.handleTextChange({ target: { Base64Image: resizedImgB64 } });
    } catch (e) {
      console.warn(e);
      // eslint-disable-next-line no-undef
      alert('unexpected photo rejection - please try something else');
    }
  }

  deletePhoto = () => {
    this.setState({ rawImage: null });
    this.handleTextChange({ target: { Base64Image: null } });
  }

  render() {
    const {
      originalObj,
      supervisors,
      classes,
      onCancel,
      devices,
      extraDevices,
      agencyConsultants,
      labourAgencies,
    } = this.props;
    const { editingObj, rawImage } = this.state;
    const user = assign(originalObj, editingObj);
    const {
      IsEnabled, IsAdministrator, IsWorker, Name,
      IsSupervisor, Email, EmailNotifications, PayrollId,
      Mobile, MobileNotifications, Supervisor,
      DeviceId, Base64Image, AgencyConsultantId, IsArchived,
    } = user;
    const img = rawImage || Base64Image;
    const mobileValid = this.validateMobile(Mobile);
    const emailValid = this.validateEmail(Email);
    const payrollValid = !!PayrollId;
    const nameValid = !!Name;
    const workerRoleValid = !IsEnabled || (IsAdministrator || IsSupervisor || IsWorker);
    const notifsValid = (mobileValid && MobileNotifications) || (emailValid && EmailNotifications);
    
    const supervisorValid = !IsWorker || (IsWorker && !!Supervisor);
    const allValid = notifsValid && nameValid && payrollValid && supervisorValid;
    const supervisorOptions = supervisors.map(
      (w, i) => (<MenuItem key={i} value={w.WorkerId}>{w.Name}</MenuItem>));
    console.log(supervisors)
    const agencyOptions = agencyConsultants.map(
        (w, i) => {
          const name = labourAgencies.find(l => l.LabourAgencyId === w.LabourAgencyId).Name;
          return <MenuItem key={i} value={w.AgencyConsultantId}>{`(${name}) ${w.Name}`}</MenuItem>
        });
    const selectedDevice = [...devices, ...extraDevices].find(d => d.DeviceId === DeviceId);
    return (
      <Grid container style={{ flexGrow: 1 }}>
        <CssBaseline />
        <ValidatorForm
          className={classes.container}
          autoComplete="off"
          onSubmit={() => {}}
        >
          <Grid item sm={3} style={colStlye}>
            <Grid item>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  name="PayrollId"
                  label="Payroll ID"
                  onChange={this.handleTextChange}
                  value={PayrollId || ''}
                  error={!payrollValid}
                  helperText="required"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required className={classes.formControl} fullWidth>
                <Autocomplete 
                  id="device-select-auto-user"
                  options={arrayUniqueByKey([...devices, ...extraDevices], 'DeviceId')}
                  onChange={(event, newValue) => {
                    this.handleDeviceSelect(newValue);
                  }}
                  value={selectedDevice}
                  getOptionLabel={(opt) => opt.Description}
                  renderInput={(params) => <TextField { ...params } label="Select Device" variant="outlined" />}
                />
                <FormHelperText error={false}>Select Device</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  name="Name"
                  label="Name"
                  onChange={this.handleTextChange}
                  value={Name || ''}
                  error={!nameValid}
                  helperText="required"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required className={classes.formControl} fullWidth>
                <Select
                  name="Supervisor"
                  value={IsWorker ? Supervisor : ''}
                  disabled={!IsWorker}
                  onChange={this.handleSelectChange}
                  input={(
                    <Input
                      placeholder="Supervisor"
                      margin="dense"
                    />
                   )}
                  variant="outlined"
                >
                  { supervisorOptions }
                </Select>
                <FormHelperText error={!supervisorValid}>Select Supervisor</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required className={classes.formControl} fullWidth>
                <Select
                  name="AgencyConsultantId"
                  value={AgencyConsultantId}
                  disabled={!IsWorker}
                  onChange={this.handleSelectChange}
                  input={(
                    <Input
                      placeholder="Labour Agency"
                      margin="dense"
                    />
                   )}
                  variant="outlined"
                >
                  <MenuItem key={'no agency'} value={null}>No Agency</MenuItem>
                  { agencyOptions }
                </Select>
                <FormHelperText>Select Agency</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  name="Email"
                  label="Email"
                  onChange={this.handleTextChange}
                  value={Email}
                  error={!((mobileValid && !Email) || emailValid)}
                  helperText="a valid email or mobile is required"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  name="Mobile"
                  label="Mobile"
                  onChange={this.handleTextChange}
                  value={Mobile}
                  error={!((emailValid && !Mobile) || mobileValid)}
                  helperText="a valid email or mobile is required"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item sm={3} style={colStlye}>
            <Grid item>
              <FormGroup row className={classes.formControl}>
                <Dropzone onDrop={this.onFileUpload}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      {...getRootProps()}
                      className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
                    >
                      { /* eslint-disable-next-line jsx-a11y/alt-text */}
                      { img && <img src={img} className="user-form-img" /> }
                      <input {...getInputProps()} />
                    </div>
                  )}
                </Dropzone>
                <FormHelperText>
                  {'Click or drag & drop to select an image'}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup row className={classes.formControl}>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      name="EmailNotifications"
                      checked={!!EmailNotifications}
                      onChange={this.handleCheckboxChange}
                    />
                  )}
                  label="Email Notifications"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup row className={classes.formControl}>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      name="MobileNotifications"
                      checked={!!MobileNotifications}
                      onChange={this.handleCheckboxChange}
                    />
                  )}
                  label="Mobile Notifications"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup row className={classes.formControl}>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      name="IsAdministrator"
                      checked={!!IsAdministrator}
                      onChange={this.handleCheckboxChange}
                    />
)}
                  label="Admin"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup row className={classes.formControl}>

                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      name="IsSupervisor"
                      checked={!!IsSupervisor}
                      onChange={this.handleCheckboxChange}
                    />
)}
                  label="Supervisor"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup row className={classes.formControl}>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      name="IsWorker"
                      checked={!!IsWorker}
                      onChange={this.handleIsWorkerChange}
                    />
)}
                  label="Worker"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup row className={classes.formControl}>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      name="IsEnabled"
                      checked={!!IsEnabled}
                      onChange={this.handleCheckboxChange}
                    />
)}
                  label="Enabled"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup row className={classes.formControl}>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      disabled={IsEnabled}
                      name="IsArchived"
                      checked={!!IsArchived}
                      onChange={this.handleCheckboxChange}
                    />
)}
                  label="Archived (disabled only)"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item sm={3}>
            <FormGroup row className={classes.formControl} style={{ paddingBottom: '5px' }}>
              <FormHelperText error={!notifsValid}>
                {notifsValid ? 'nofications ok' : 'Select a notification type'}
              </FormHelperText>
            </FormGroup>
            <FormGroup row className={classes.formControl} style={{ paddingBottom: '5px' }}>
              <FormHelperText error={!workerRoleValid}>
                {workerRoleValid ? 'user role ok' : 'Select a user role'}
              </FormHelperText>
            </FormGroup>
            <FormGroup row className={classes.formControl} style={{ paddingBottom: '5px' }}>
              <FormHelperText error={!supervisorValid}>
                {supervisorValid ? 'supervisor ok' : 'Select a supervisor'}
              </FormHelperText>
            </FormGroup>
            <FormGroup row className={classes.formControl}>
              <Grid container style={{ height: '120px' }}>
                <Grid item xs={6}>
                  <Button
                    type="Button"
                    variant="contained"
                    color="secondary"
                    onClick={onCancel}
                  >
                      Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="Button"
                    variant="contained"
                    color="primary"
                    disabled={!allValid}
                    onClick={this.handleComplete}
                  >
                      Save
                  </Button>
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </ValidatorForm>
      </Grid>
    );
  }
}

UserForm.propTypes = assign(ObjectEditForm.propTypes, {
  originalObj: PropTypes.object.isRequired,
});

const mapStateToProps = state => ({
  supervisors: state.workers.all.filter(w => !!w.IsSupervisor && !w.IsSuperAdministrator && !w.IsArchived),
  devices: state.me.devices,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  editWorker: x => dispatch(WorkerActions.editWorker(x)),
  createWorker: x => dispatch(WorkerActions.createWorker(x)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, { withTheme: true })(UserForm)));
