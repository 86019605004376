
import React, { Component } from 'react';
import {
  Grid,
  FormGroup,
  FormControl,
  Select,
  FormHelperText,
  Button,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Input,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import styles from '../Common/FormStyles';
import { CSV_FORMAT_MAP } from '../../Constants/Constants';


class TimesheetsForm extends Component {
  componentDidMount() {

  }

  render() {
    const {
      classes, timesheets, selectedTimesheetId, onDateChange,
      onExportClick, onLockClick,
    } = this.props;
    const opts = timesheets.map((ts, i) => (
      <MenuItem value={ts.TimesheetId} key={i}>
        {moment(ts.StartDateTime).format('Do MMM')}
        {'  -  '}
        {moment(ts.FinishDateTime).add(-1, 'days').format('Do MMM')}
      </MenuItem>
    ));
    return (
      <div>
        <CssBaseline />
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <div className="explanation">
              <p>
                <b>Weekly process for timesheets:</b>
              </p>
              <ol>
                <li>
                  Supervisors <Link to="/approvals">approve</Link> timesheets.
                </li>
                <li>
                  Administrator locks timesheets.
                </li>
                <li>
                  CSV export for payroll.
                </li>
              </ol>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.container}
          style={{ marginTop: '20px', paddingBottom: '20px' }}
        >
          <FormGroup row className={classes.formControl}>
            <Grid item xs={6}>
              <Button
                type="Button"
                variant="contained"
                color="primary"
                disabled={!selectedTimesheetId}
                id="LocksubmitButton"
                onClick={onLockClick}
              >
                Lock
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="Button"
                variant="contained"
                color="primary"
                disabled={!selectedTimesheetId}
                id="submitButton"
                onClick={onExportClick}
              >
                Export CSV
              </Button>

            </Grid>
          </FormGroup>
        </Grid>
        <Grid container className={classes.container}>
          <FormControl required className={classes.formControl} fullwidth>
            <Select
              name="DateRange"
              value={selectedTimesheetId || ''}
              onChange={onDateChange}
              input={(
                <Input
                  placeholder="Select Date Range"
                  margin="dense"
                />
             )}
              variant="outlined"
            >
              { opts }
            </Select>
            <FormHelperText>Select Date Range</FormHelperText>
          </FormControl>
        </Grid>

        <Grid container className={classes.container} style={{ marginTop: '20px' }}>
          <FormGroup row className={classes.formControl}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" style={{ color: '#000' }}>CSV Type</FormLabel>
              <RadioGroup
                name="csvformat"
                aria-label="csv format"
                className={classes.group}
                value={this.props.csvFormat}
                onChange={this.props.onCsvFormatChange}
              >
                {
                  Object.keys(CSV_FORMAT_MAP).map(
                    k => (
                      <FormControlLabel
                        key={k}
                        value={k}
                        control={<Radio />}
                        label={CSV_FORMAT_MAP[k]}
                      />
                    ))
                }
              </RadioGroup>
            </FormControl>
          </FormGroup>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TimesheetsForm);
