import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import MapGL, {
  GeoJSONLayer,
  Popup,
} from 'react-mapbox-gl';
import {
  MAPBOX_TOKEN,
  SAT_MAP_URI,
} from '../../Constants/Constants';
import { uniqInArray } from '../../Utils';
import * as turf from '@turf/turf';
import { snapGeoJSONFeaturesToSite } from '../../GeoFunctions';


const Map = MapGL({
  accessToken: MAPBOX_TOKEN,
  preserveDrawingBuffer: true,
});


export class ContactTracingMap extends Component {
  state = {
    zoom: 7,
    pitch: 45,
    center: [174.822493, -36.855736],
  }

  componentDidMount() {
  }

  handleMapClick = (e, e1, e2) => {
    console.log(e, e1, e2);
    debugger;
  }

  handleStyleLoad = map => {
    if(map) {
      map.resize();
    }
  }

  handleCircleHover = (e) => {
    const { features, lngLat, type } = e;

    if (type === 'mouseleave') {
      return this.setState({ popup: null });
    }

    /* eslint-disable */
    const names = uniqInArray(features.map(
      ({ properties }) => `${properties.ContactName} ${moment(properties.LocationDateTime).format('MMM DD HH:mm')}`));
    const toShow = names.map((n) => {
      return (
        <div key={n}>
          {n}
        </div>
      );
    });
    /* eslint-enable */

    const popup = (
      <Popup
        key={`popup${Math.random()}`}
        coordinates={[lngLat.lng, lngLat.lat]}
        offset={{
          'bottom-left': [12, -38], bottom: [0, -38], 'bottom-right': [-12, -38],
        }}
      >
        { toShow }
      </Popup>
    );
    this.setState({ popup });
  }

  handleTrackHover = (e) => {
    const { features, lngLat, type } = e;

    if (type === 'mouseleave') {
      return this.setState({ popup2: null });
    }
    const feature = features.find(f => !!f.properties.LocationDateTime);
    const popup2 = feature && (
      <Popup
        key={`popup${Math.random()}`}
        coordinates={[lngLat.lng, lngLat.lat]}
        offset={{
          'bottom-left': [12, -38], bottom: [0, -38], 'bottom-right': [-12, -38],
        }}
      >
        { moment(feature.properties.LocationDateTime).format('MMM DD HH:mm') }
      </Popup>
    );
    this.setState({ popup2 });
  }

  shouldComponentUpdate({
      contactGeojson, workerTrack, zones, sites,
    }, { popup, popup2 }) {
    /* eslint-disable */
    const isWorkerTrackChange = JSON.stringify(workerTrack) !== JSON.stringify(this.props.workerTrack);
    const isContactChange = JSON.stringify(contactGeojson) !== JSON.stringify(this.props.contactGeojson);
    const isSiteChange = JSON.stringify([zones, sites]) !== JSON.stringify([this.props.zones, this.props.sites]);
    const isPopupChange =  popup !== this.state.popup || popup2 !== this.state.popup2;
    return !!(isSiteChange || isWorkerTrackChange || isContactChange || isPopupChange);
    /* eslint-enable */
  }

  getMapProps = () => {
    const center = this.props.center || this.state.center;
    return {
      center,
      style: SAT_MAP_URI,
      onMove: this.handleMove,
      className: 'dashboard-map dashboard-responsive',
      onStyleLoad: this.handleStyleLoad,
      onClick: this.handleMapClick,
    };
  }

  render() {
    const {
      colorStops,
      contactGeojson,
      workerTrack,
      sites,
      zones,
      setMapRef,
    } = this.props;
    console.log(setMapRef);
    const { popup, popup2 } = this.state;

    const workerTrackRender = workerTrack && workerTrack.features && workerTrack; 
    const extraLayer = contactGeojson.features.length && (
    <GeoJSONLayer
      key="points-asx-00"
      id="points-layer-22"
      data={contactGeojson}
      circlePaint={{
        'circle-opacity': 1,
        'circle-radius': 16,
        'circle-color': {
          property: 'WorkerId',
          type: 'categorical',
          stops: colorStops,
        },
      }}
      circleOnMouseEnter={this.handleCircleHover}
      circleOnMouseLeave={this.handleCircleHover}
    />
    );

    const extraLayer3 = workerTrack.features.length && (
    <GeoJSONLayer
      key="points-asx-00122"
      id="points-layer-221333"
      data={workerTrackRender}
      circlePaint={{
        'circle-opacity': 1,
        'circle-radius': 18,
        'circle-color': '#fc4103',
      }}
      circleOnMouseEnter={this.handleTrackHover}
      circleOnMouseLeave={this.handleTrackHover}
      linePaint={{
        'line-color': '#1badc6',
        'line-width': 2.5,
        'line-opacity': 0,
      }}
    />
    );
    const feat = workerTrackRender.features[0];
    let bbox = undefined;
    if(feat) {
      const buffered = turf.buffer(feat, 0.2);
      bbox = turf.bbox(buffered);
    }
    const mapProps = this.getMapProps();
    return (
      <Map
        {...mapProps }
        fitBounds={bbox}
        onRender={(map) => { setMapRef && setMapRef(map) }}
        preserveDrawingBuffer={true}
      >
        {
          [(
            <GeoJSONLayer
              key="polys-asx"
              data={turf.featureCollection(sites.map(s => s.GeoJSON))}
              linePaint={{
                'line-color': '#a69924',
                'line-width': 4,
              }}
              fillExtrusionPaint={{
                'fill-extrusion-color': '#f5e342',
                'fill-extrusion-height': 0.2,
                'fill-extrusion-opacity': 0.2,
              }}
              type="fill-extrusion"
              extruded
            />
          ),
          (<GeoJSONLayer
            key="polys-as2x"
            data={turf.featureCollection(zones.map(s => s.GeoJSON))}
            linePaint={{
              'line-color': '#000',
              'line-width': 2,
            }}
            fillExtrusionPaint={{
              'fill-extrusion-color': '#fff',
              'fill-extrusion-height': 0.4,
              'fill-extrusion-opacity': 0.3,
            }}
            type="fill-extrusion"
            extruded
          />
          ),
          extraLayer3, extraLayer, popup2, popup,
          ]
        }
      </Map>
    );
  }
}

export const mapStateToProps = state => (state) => {
  const { zones, contactTracing, sites } = state;
  const { contactGeojson, workerTrack } = contactTracing;
  const snappedGeo = snapGeoJSONFeaturesToSite(sites.all, contactGeojson);
  const center = snappedGeo.features.length && turf.center(snappedGeo).geometry.coordinates;
  return {
    contactGeojson: snappedGeo,
    workerTrack: snapGeoJSONFeaturesToSite(sites.all, workerTrack),
    sites: sites.all,
    zones: zones.all,
    center,
  }
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactTracingMap);
