/* eslint-disable */
export default (theme) => ({
  container: {
    display: 'flex',
    padding: '0px 10px 10px 10px',
    flex: 1,
  },
  formControl: {
    paddingTop: '5px',
    minWidth: 180,
    /*marginLeft: theme.spacing.unit,

    flex: 1,*/
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  switchLabel: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  durationInputWrapper: {
   flex: 1,
   paddingTop: '5px',
  },
  durationInput: {
    display: 'inline',
    width: '80px',
    textAlign: 'right',
    paddingRight: '30px',
  },
  durationInputFormControl: {
    marginTop: '13px',
  },
  durationInputLabel: {
    transform: 'translate(0px, 5px) scale(0.8)'
  },
  durationInputLabelInline: {
    transform: 'translate(48px, 10px) scale(0.8)'
  },
  textField: {
    minWidth: '250px',
  }
});
