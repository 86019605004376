import {
  ADD_TIMESHEET_NOTE,
  SET_TIMESHEET_NOTES,
} from './actions/ActionTypes';
import { assign } from '../Utils';

const initialState = {
  all: [],
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_TIMESHEET_NOTES: {
      return assign(state, { all: payload });
    }
    case ADD_TIMESHEET_NOTE: {
      const newList = [...state.all, payload];
      return assign(state, { all: newList });
    }
    default:
      return state;
  }
}
