import {
  SET_TIMESHEETS,
  SET_APPROVAL_TIMESHEET,
  EDIT_TIMESHEET,
  SET_TIMESHEET_STATUS,
  SET_WORK_DAY_TIME_SETTINGS,
  SET_APPROVALS_WORKER_SHIFTS,
} from './actions/ActionTypes';
import { assign, replaceInArray } from '../Utils';

const id = 'TimesheetId';

const initialState = {
  all: [],
  approvalCurrentTimesheetId: null,
  workerShifts: [],
};

export default function (state = initialState, action) {
  const { all } = state;
  const { payload } = action;
  switch (action.type) {
    case SET_TIMESHEETS: {
      return assign(state, { all: payload });
    }
    case SET_APPROVAL_TIMESHEET: {
      return assign(state, { approvalCurrentTimesheetId: payload });
    }
    case EDIT_TIMESHEET: {
      return assign(state, { all: replaceInArray(all, payload, id) });
    }
    case SET_TIMESHEET_STATUS: {
      const ts = state.all.find(t => t.TimesheetId === payload.Id);
      const tsheets = replaceInArray(all, assign(ts, { Status: payload.Status }), id);
      return assign(state, { all: tsheets });
    }
    case SET_APPROVALS_WORKER_SHIFTS: {
      return assign(state, { workerShifts: payload });
    }

    default:
      return state;
  }
}
