import { createSelector } from 'reselect';
import TimesheetList from '../../Models/TimesheetList';
import {
  getWorkers,
  getTimesheets,
} from './common';


const getters = [
  getWorkers,
  getTimesheets,
];

const getTimesheetList = createSelector(getters,
  (workers, timesheets) => {
    const tsl = new TimesheetList(timesheets);
    tsl.setWorkerList(workers);
    return tsl;
  });

export default getTimesheetList;
