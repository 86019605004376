interface KeyValuePair<T> {
  [key:string] : T;
}

export interface RestModelBase {
  uri:string;
  updateFormUri?: string;
}

export interface RestFeedModel extends RestModelBase{
  items : Array<{uri:string}>
}

export interface CompanyRestModel extends RestModelBase {
  name: string;
  workerSearchFormUri: string;
  covidCasesUri: string;
  workersUri: string;
}

export enum FormFieldType {  
  TEXT= "text",
  OPTIONS = "options",
  DATETIME = "datetimeoffset",
  DATETIME_OLD = "datetime"  
}


export interface FormOption<T=string> {
  key: T,
  description:string
}
export interface FormAttribute<T=string> {
    name: string, 
    description:string, 
    type:FormFieldType, 
    options?:Array<FormOption<T>>;
};

export interface FormModelBase extends RestModelBase{
  createResourceUri: string;
  attributes: Array<FormAttribute>;  
}

export interface CreateFormResult {
  resourceUri: string;
  message: string;
}

export function makeSubmittFormData(formModel :FormModelBase, formData: KeyValuePair<any>): KeyValuePair<any>{
  

  const  validateFormField = (form: KeyValuePair<any>, attribute: FormAttribute) : KeyValuePair<any> => {
    const formValue = formData[attribute.name].value;
    form[attribute.name] = formValue;

    if(attribute.type === FormFieldType.OPTIONS){
      const selectedOption = attribute.options ? 
        attribute.options.find(option => option.key === formValue || option.description === formValue) : 
        undefined;

      if(selectedOption) {
        form[attribute.name] = selectedOption.key;
      }
    }

    if(attribute.type === FormFieldType.DATETIME ||  attribute.type === FormFieldType.DATETIME_OLD){
      if(typeof formValue === 'string'){
        const tz = (new Date()).getTimezoneOffset() / -60;
        const sign = tz >= 0 ? '+' : '-';
        const offset = Math.abs(tz).toFixed(0)
      
        form[attribute.name] =  `${formValue}${sign}${offset}:00`;
      }
    }

    return form;
  }

  const createdForm = formModel.attributes
    .reduce((previous, attribute) => validateFormField(previous, attribute), {});
  return createdForm;
}
