import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid, Switch, FormControl, FormControlLabel, FormGroup, Select, Input, MenuItem, InputLabel
} from '@material-ui/core';
import wkt from 'terraformer-wkt-parser';
import * as turf from '@turf/turf';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { assign } from '../../Utils';
import styles from '../Common/FormStyles';
import {
  createSite,
  updateSite,
} from '../../redux/actions/AllActions';
import {
  getSiteDeviceSettings
} from '../../API';

// Mode names
const DEEP_SLEEP_INTERVAL_MODE = 1;
const GPS_SLEEP_MODE = 5;
const GPS_ALWAYS_ON_MODE = 6;
const POWER_SAVING_MODE = 7;

class SiteForm extends Component {
  state = {
    Name: '',
    checked: undefined,
    MinsBeforeStart: 10,
    MinsAfterStart: 10,
    StartMode: GPS_SLEEP_MODE,
    StartInterval: 5,
    DuringMode: GPS_SLEEP_MODE,
    DuringInterval: 5,
    MinsBeforeFinish: 10,
    MinsAfterFinish: 10,
    FinishMode: GPS_SLEEP_MODE,
    FinishInterval: 5,
    OvertimeMode: GPS_SLEEP_MODE,
    OvertimeInterval: 5,
    SleepMode: DEEP_SLEEP_INTERVAL_MODE,
    SleepInterval: 120,
    DistanceBufferMeters: 0,
    MinimumWorkingTimeHoursForBreak: 0,
    MinimumBreakTimeMinsForWorkDay: 0,
    ShouldUseSkyhook: true,
  }

  componentDidMount() {
    if(!this.props.site.SiteId)
    {
      return;
    }
    
    getSiteDeviceSettings(this.props.site.SiteId).then(siteDeviceSettings => {
      this.setState({ 
        MinsBeforeStart: siteDeviceSettings.MinsBeforeStart,
        MinsAfterStart: siteDeviceSettings.MinsAfterStart,
        StartMode: siteDeviceSettings.StartMode,
        StartInterval: siteDeviceSettings.StartInterval,
        DuringMode: siteDeviceSettings.DuringMode,
        DuringInterval: siteDeviceSettings.DuringInterval,
        MinsBeforeFinish: siteDeviceSettings.MinsBeforeFinish,
        MinsAfterFinish: siteDeviceSettings.MinsAfterFinish,
        FinishMode: siteDeviceSettings.FinishMode,
        FinishInterval: siteDeviceSettings.FinishInterval,
        OvertimeMode: siteDeviceSettings.OvertimeMode,
        OvertimeInterval: siteDeviceSettings.OvertimeInterval,
        SleepMode: siteDeviceSettings.SleepMode,
        SleepInterval: siteDeviceSettings.SleepInterval,
        ShouldUseSkyhook: siteDeviceSettings.ShouldUseSkyhook,
      });
    });
    
  }

  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  getOptionsForRegularModes = () => {
    return [DEEP_SLEEP_INTERVAL_MODE, GPS_SLEEP_MODE, GPS_ALWAYS_ON_MODE, POWER_SAVING_MODE].map((s, i) => (
      <MenuItem key={i} value={s}>
        { s }
      </MenuItem>
    )) 
  }

  getOptionsForSleepModes = () => {
    return [DEEP_SLEEP_INTERVAL_MODE, GPS_SLEEP_MODE].map((s, i) => (
      <MenuItem key={i} value={s}>
        { s }
      </MenuItem>
    )) 
  }

  // eslint-disable-next-line no-unused-vars
  handleCheckboxChange = ({ target }, checked) => {
    this.setState({ checked });
  };

  handleSaveClick = () => {
    const { 
      Name, 
      checked, 
      SitePayrollId,
      MinsBeforeStart,
      MinsAfterStart,
      StartMode,
      StartInterval,
      DuringMode,
      DuringInterval,
      MinsBeforeFinish,
      MinsAfterFinish,
      FinishMode,
      FinishInterval,
      OvertimeMode,
      OvertimeInterval,
      SleepMode,
      SleepInterval,
      MinimumBreakTimeMinsForWorkDay,
      MinimumWorkingTimeHoursForBreak,
      DistanceBufferMeters,
      ShouldUseSkyhook,
    } = this.state;
    const { site, polygon } = this.props;
    const p = polygon || wkt.convert(site.GeoJSON.geometry);
    const updatedSite = {
      Name: Name || site.Name,
      Active: checked === undefined ? site.Active : checked,
      WKT: p,
      GeoJSON: turf.feature(wkt.parse(p)),
      SitePayrollId: SitePayrollId || site.SitePayrollId,
      DistanceBufferMeters: DistanceBufferMeters || site.DistanceBufferMeters,
      MinimumWorkingTimeHoursForBreak: MinimumWorkingTimeHoursForBreak || site.MinimumWorkingTimeHoursForBreak,
      MinimumBreakTimeMinsForWorkDay: MinimumBreakTimeMinsForWorkDay || site.MinimumBreakTimeMinsForWorkDay,
      SiteDeviceSettings: {
        SiteId: site.SiteId || 0,
        MinsBeforeStart,
        MinsAfterStart,
        StartMode,
        StartInterval,
        DuringMode,
        DuringInterval,
        MinsBeforeFinish,
        MinsAfterFinish,
        FinishMode,
        FinishInterval,
        OvertimeMode,
        OvertimeInterval,
        SleepMode,
        SleepInterval,
        ShouldUseSkyhook,
      }
    };
    debugger;
    const toSave = assign(site, updatedSite);
    if (toSave.SiteId) {
      this.props.updateSite(toSave);
    } else {
      this.props.createSite(assign(toSave, { SiteId: `${Math.random()}` }));
    }
    this.props.onCancelClick();
  }

  isValid = () => {
    const { Name } = this.state;
    const { site, polygon } = this.props;
    const { GeoJSON, Geom } = site;
    return !!((Name || site.Name) && (polygon || GeoJSON || Geom.Geography.WellKnownText));
  }

  render() {
    const {
      classes, onCancelClick, site, IsSuperAdministrator
    } = this.props;

    const {
      Name,
      checked,
      SitePayrollId, 
      DistanceBufferMeters,
      MinimumWorkingTimeHoursForBreak,
      MinimumBreakTimeMinsForWorkDay,
      ShouldUseSkyhook,
    } = this.state;

    return (
      <div>
        <ValidatorForm
          className={classes.container}
          autoComplete="off"
          onSubmit={() => {}}
        >
          <Grid container>
            <FormControl className={classes.formControl} fullWidth>
              <TextValidator
                id="site-name-input"
                name="Name"
                label="Site Name"
                className={classes.textField}
                value={Name || site.Name}
                onChange={this.handleChange}
                error={!(Name || site.Name)}
                helperText="required"
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextValidator
                id="site-name-input"
                name="SitePayrollId"
                label="Site ID"
                className={classes.textField}
                value={SitePayrollId || site.SitePayrollId}
                onChange={this.handleChange}
                error={false}
                helperText="required"
              />
            </FormControl>
            { IsSuperAdministrator && <React.Fragment>
              <FormControl className={classes.formControl} fullWidth>
              <TextValidator
                id="site-distancebuffermeters-input"
                name="DistanceBufferMeters"
                label="Distance Buffer Meters"
                className={classes.textField}
                value={DistanceBufferMeters || site.DistanceBufferMeters}
                onChange={this.handleChange}
                type="number"
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextValidator
                id="site-minimumbreaktimeminsforworkday-input"
                name="MinimumBreakTimeMinsForWorkDay"
                label="Minimum Break Time Mins For Work Day"
                className={classes.textField}
                value={MinimumBreakTimeMinsForWorkDay || site.MinimumBreakTimeMinsForWorkDay}
                onChange={this.handleChange}
                type="number"
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextValidator
                id="site-minimumworkingtimehoursforbreak-input"
                name="MinimumWorkingTimeHoursForBreak"
                label="Minimum Working Time Hours For Break"
                className={classes.textField}
                value={MinimumWorkingTimeHoursForBreak || site.MinimumWorkingTimeHoursForBreak}
                onChange={this.handleChange}
                type="number"
              />
            </FormControl>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextValidator
                  id="site-minsbeforestart-input"
                  name="MinsBeforeStart"
                  label="Mins Before Start"
                  className={classes.textField}
                  value={this.state.MinsBeforeStart}
                  onChange={this.handleChange}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextValidator
                  id="site-minsafterstart-input"
                  name="MinsAfterStart"
                  label="Mins After Start"
                  className={classes.textField}
                  value={this.state.MinsAfterStart}
                  onChange={this.handleChange}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Start Mode</InputLabel>
                <Select
                  name="StartMode"
                  value={this.state.StartMode}
                  onChange={this.handleChange}
                  input={(
                    <Input
                      variant="outlined"
                      style={{ maxWidth: '130px' }}
                      margin="dense"
                      inputProps={{ label: 'Site' }}
                    />
                    )}
                  variant="outlined"
                > { this.getOptionsForRegularModes() }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  id="site-startinterval-input"
                  name="StartInterval"
                  label="Start Interval"
                  className={classes.textField}
                  value={this.state.StartInterval}
                  onChange={this.handleChange}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>During Mode</InputLabel>
                <Select
                  name="DuringMode"
                  value={this.state.DuringMode}
                  onChange={this.handleChange}
                  input={(
                    <Input
                      variant="outlined"
                      style={{ maxWidth: '130px' }}
                      margin="dense"
                      inputProps={{ label: 'Site' }}
                    />
                    )}
                  variant="outlined"
                > { this.getOptionsForRegularModes() }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  id="site-duringinterval-input"
                  name="DuringInterval"
                  label="During Interval"
                  className={classes.textField}
                  value={this.state.DuringInterval}
                  onChange={this.handleChange}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  id="site-minsbeforefinish-input"
                  name="MinsBeforeFinish"
                  label="Mins Before Finish"
                  className={classes.textField}
                  value={this.state.MinsBeforeFinish}
                  onChange={this.handleChange}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  id="site-minsafterfinish-input"
                  name="MinsAfterFinish"
                  label="Mins After Finish"
                  className={classes.textField}
                  value={this.state.MinsAfterFinish}
                  onChange={this.handleChange}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Finish Mode</InputLabel>
                <Select
                  name="FinishMode"
                  value={this.state.FinishMode}
                  onChange={this.handleChange}
                  input={(
                    <Input
                      variant="outlined"
                      style={{ maxWidth: '130px' }}
                      margin="dense"
                      inputProps={{ label: 'Site' }}
                    />
                    )}
                  variant="outlined"
                > { this.getOptionsForRegularModes() }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  id="site-finishinterval-input"
                  name="FinishInterval"
                  label="Finish Interval"
                  className={classes.textField}
                  value={this.state.FinishInterval}
                  onChange={this.handleChange}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Overtime Mode</InputLabel>
                <Select
                  name="OvertimeMode"
                  value={this.state.OvertimeMode}
                  onChange={this.handleChange}
                  input={(
                    <Input
                      variant="outlined"
                      style={{ maxWidth: '130px' }}
                      margin="dense"
                      inputProps={{ label: 'Site' }}
                    />
                    )}
                  variant="outlined"
                > { this.getOptionsForRegularModes() }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl className={classes.formControl} fullWidth>
              <TextValidator
                id="site-overtimeinterval-input"
                name="OvertimeInterval"
                label="Overtime Interval"
                className={classes.textField}
                value={this.state.OvertimeInterval}
                onChange={this.handleChange}
                type="number"
              />
            </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Sleep Mode</InputLabel>
                <Select
                  name="SleepMode"
                  value={this.state.SleepMode}
                  onChange={this.handleChange}
                  input={(
                    <Input
                      variant="outlined"
                      style={{ maxWidth: '130px' }}
                      margin="dense"
                      inputProps={{ label: 'Site' }}
                    />
                    )}
                  variant="outlined"
                > { this.getOptionsForSleepModes() }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <TextValidator
                  id="site-sleepinterval-input"
                  name="SleepInterval"
                  label="Sleep Interval"
                  className={classes.textField}
                  value={this.state.SleepInterval}
                  onChange={this.handleChange}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      id="site-should-use-skyhook-input"
                      name="ShouldUseSkyhook"
                      checked={!!ShouldUseSkyhook}
                      onChange={(c) => { 
                        this.setState({ ShouldUseSkyhook: !ShouldUseSkyhook })
                      }}
                    />
                  }
                  label="Should Use Skyhook"
                />
              </FormControl>
            </Grid> 
            </React.Fragment>}
          </Grid>
        </ValidatorForm>
        <FormGroup row className={classes.formControl}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                type="Button"
                variant="contained"
                color="secondary"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="Button"
                variant="contained"
                color="primary"
                disabled={!this.isValid()}
                onClick={this.handleSaveClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </div>
    );
  }
}

SiteForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


// eslint-disable-next-line no-unused-vars
const mapStateToProps = _state => ({});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch, _ownProps) => ({
  createSite: x => dispatch(createSite(x)),
  updateSite: x => dispatch(updateSite(x)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SiteForm)));
