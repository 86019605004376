import moment from 'moment';

export default class BaseModel {
  dataKeys = [];

  constructor(data) {
    this.updateData(data);
    return this;
  }

  updateData = (data) => {
    // TODO comment lots on this and the lists
    const keys = data.dataKeys || Object.keys(data);
    keys.forEach((k) => {
      this[k] = data[k];
      this.dataKeys.push(k);
    });
    this.setMomentDates();
  }

  setMomentDate = (key, dateStr) => {
    const m = moment(dateStr, 'YYYY-MM-DD HH:mm:ss');
    this[`m${key}`] = m;
  }

  setMomentDates = () => {
    const keys = this.dataKeys.filter(k => k.includes('Date'));
    keys.forEach(k => this.setMomentDate(k, this[k]));
  }

}
