export default class BaseModelList {
  constructor(objects, idKey, ObjectClass) {
    this.objects = objects.map(o => new ObjectClass(o));
    this.idKey = idKey;
  }

  findById = id => this.objects.find(o => o[this.idKey] === id);

  filterBy = (key, value) => this.objects.filter(o => o[key] === value);

  findBy = (key, value) => this.objects.find(o => o[key] === value);

  findIndex = id => this.objects.findIndex(o => o[this.idKey] === id);

  filterByFunction = func => this.objects.filter(func);
}
