import {
  SET_WORKERS,
  EDIT_WORKER,
  UPDATE_WORKERS_TABLE,
  SET_LOCATIONS,
} from './actions/ActionTypes';
import { assign, replaceInArray, dynamicSort } from '../Utils';

const id = 'WorkerId';

const metroLocations = [
  [172.626296, -43.537566],
  [172.626361, -43.537986],
  [172.626897, -43.536757],
];

const prisonLocations = [
  [172.477911, -43.515063],
  [172.474135, -43.520291],
  [172.470186, -43.515685],
];

const conventionLocations = [
  [172.636129, -43.529030],
  [172.636032, -43.528975],
];

const genLocation = (coords, workerId, desc, siteId) => ({
  Latitude: coords[1],
  LocationDateTime: '2019-08-15 13:20:27',
  LocationDateTimeDescription: '3 min ago',
  LocationStatus: 4,
  LocationTimestampId: 2061198,
  Longitude: coords[0],
  WorkerId: workerId,
  ClosestSiteDescription: `${desc} since 7:00am`,
  ClosestSiteDistance: 0,
  ClosestSiteId: siteId,
  CompanyId: 17,
  BatteryDescription: '100%',
});

const acrowFakeLocations = [
  genLocation(metroLocations[0], 789, 'On Site', 275),
  genLocation(metroLocations[1], 788, 'On Site', 275),
  genLocation(metroLocations[2], 787, 'On Site', 275),
  genLocation(prisonLocations[0], 786, 'On Site', 273),
  genLocation(prisonLocations[1], 785, 'On Site', 273),
  genLocation(prisonLocations[2], 784, 'On Site', 273),
  genLocation(conventionLocations[0], 783, 'On Site', 274),
];

const initialState = {
  all: [],
  locations: [],
  acrowFakeLocations,
};


export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_WORKERS: {
      payload.sort(dynamicSort('Name'));
      return assign(state, { all: payload });
    }
    case SET_LOCATIONS: {
      return assign(state, { locations: payload });
    }
    case EDIT_WORKER: {
      return assign(state, { all: replaceInArray(state.all, payload, id) });
    }
    case UPDATE_WORKERS_TABLE: {
      return assign(state, { all: payload });
    }
    default:
      return state;
  }
}
