import BaseModelList from './BaseModelList';
import Site from './Site';

export default class SiteList extends BaseModelList {
  constructor(data) {
    super(data, 'SiteId', Site);
  }

  getSelectOptions = () => this.objects.map(s => ({
    text: s.Name,
    SiteId: s.SiteId,
  }));
}
