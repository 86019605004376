import React,  { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { formatDuration } from '../../Utils';
import styles from '../Common/FormStyles';
import {
  FormHelperText,
  InputLabel,
  Input,
  Grid,
  FormControl,
} from '@material-ui/core';

class DurationInput extends Component {

  onChange = ({ target }) => {
    const { value, onChange, name } = this.props;
    const { mins, hours } = this.parseTimeDuration(value);
    const change = {
      hours,
      mins,
    };
    change[target.name] = parseInt(target.value);
    onChange({ target: {
        name,
        value:  formatDuration(change.hours, change.mins),
      }
    });
  }

  parseTimeDuration = (durationStr) => {
    const parts = durationStr.split(':');
    return {
      hours: parseInt(parts[0]),
      mins: parseInt(parts[1]),
    };
  }

  render() {
    const { value, label, id, classes } = this.props;
    const { mins, hours } = this.parseTimeDuration(value);
    const minVal = mins > 9 ? mins : `0${mins}`;
    const hrsVal = hours > 9 ? hours : `0${hours}`;
    return (
        <Grid container id={id} className={classes.durationInputWrapper}>
          <Grid item xs={12}>
            <InputLabel className={classes.durationInputLabel}>
              { label }
            </InputLabel>
          </Grid>
          <Grid item>
            <FormControl className={classes.durationInputFormControl}>
              <Input
                className={classes.durationInput}
                type="number"
                name="hours"
                min="0"
                max="24"
                onChange={this.onChange}
                size="2"
                value={hrsVal}
              />
              <InputLabel className={classes.durationInputLabelInline}>
                hrs
              </InputLabel>
            </FormControl>
            <FormControl className={classes.durationInputFormControl} style={{padding: '0px 5px'}}>
            </FormControl>
            <FormControl className={classes.durationInputFormControl}>
              <Input
                className={classes.durationInput}
                type="number"
                name="mins"
                min="0"
                max="60"
                onChange={this.onChange}
                size="2"
                value={minVal}
              />
              <InputLabel className={classes.durationInputLabelInline}>
                min
              </InputLabel>
            </FormControl>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles, { withTheme: true })(DurationInput);
