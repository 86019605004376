import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Select,
  MenuItem,
  Grid,
  FormControlLabel,
  InputLabel,
  FormHelperText,
  Input,
  FormControl,
  Switch,
  Typography,
  Button,
  Paper,
  TextField,
} from '@material-ui/core';
import { setCompany, setLoading } from '../../redux/actions/AllActions';
import styles from '../Common/FormStyles';
import DurationInput from '../Common/DurationInput';
import { assign } from '../../Utils';
import { getCompany } from '../../API';


class SettingsForm extends Component {

  componentDidMount() {
    getCompany().then(this.props.setCompany);
  }

  onSaveClick = () => {
    const { company, setLoading, setCompany } = this.props;
    setLoading(true);
    const comped = assign(company, { needSave: true });
    this.props.setCompany(comped);
    setTimeout(() => {
      getCompany().then((c) => {
        setCompany(c);
        setLoading(false);
      });
    }, 1000);
  }

  handleTextChange = ({ target }) => {
    const { name, value } = target;
    const { company } = this.props;
    const comped = assign(company, { [name]: value });
    this.props.setCompany(comped);
  }

  handleSwitchChange = ({ target }, value) => {
    this.handleTextChange({
      target: {
        name: target.name,
        value,
      },
    });
  }

  handleSelectChange = (e) => {
    this.handleTextChange(e);
  }

  render() {
    const { classes, company } = this.props;
    const days = ['Sunday', 'Monday', 'Tuesday',
      'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const opts = days.map((d, i) => <MenuItem key={i} value={i}>{d}</MenuItem>);

    const {
      Name,
      StartDayOfWeek,
      MinimumWorkingDayDuration,
      DailyTimesheetProcessing,
      DailyApprovalReminder,
      WorkingDayEarliestStart,
      WorkingDayDefaultStart,
      WorkingDayDefaultFinish,
      WorkingDayLatestFinish,
      MinimumLunchStart,
      DefaultLunchStart,
      DefaultLunchEnd,
      MaximumLunchEnd,
      MinimumWorkingTimeToRemoveLunchBreak,
      PrivateModeStart,
      PrivateModeFinish,
      GlitchRemovalPeriod,
      IsSmsEnabled,
      IsEmailEnabled,
      Timezone,
    } = company;
    const headingStyle = { paddingTop: '15px' };

    return (
      <Grid container style={{ overflow: 'scroll', paddingTop: '10px', height: 'calc(100vh - 60px)'}}>
        <ValidatorForm
          className={classes.container}
          autoComplete="off"
          onSubmit={() => {}}
        >
          <Grid item sm={6} md={4} xs={12}>
            <Paper elevation={1} style={{ padding: '5px 40px 20px 20px' }}>
              <Grid item style={{ padding: '10px 0px 10px 0px' }}>
              < Typography variant="h6" style={headingStyle}>Company Info</Typography>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextValidator
                    name="Name"
                    label="Company Name"
                    onChange={this.handleTextChange}
                    value={Name}
                    error={!Name}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      id="IsSmsEnabled-input-settings"
                      name="IsSmsEnabled"
                      checked={company.IsSmsEnabled || false}
                      onChange={this.handleSwitchChange}
                    />
                  )}
                  label="Sms Enabled"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      id="IsEmailEnabled-input-settings"
                      name="IsEmailEnabled"
                      checked={company.IsEmailEnabled || false}
                      onChange={this.handleSwitchChange}
                    />
                  )}
                  label="Email Enabled"
                />
              </Grid>
              <Grid>
                <FormControl>
                  <FormHelperText>TimeZone</FormHelperText>
                  <Select
                    name="Timezone"
                    value={Timezone || "New Zealand Standard Time"}
                    onChange={this.handleSelectChange}
                    variant="outlined"
                  >
                    <MenuItem key={"New Zealand Standard Time"} value={"New Zealand Standard Time"}>
                      {"New Zealand Standard Time"}
                    </MenuItem>
                    <MenuItem key={"Australia/Perth"} value={"Australia/Perth"}>
                      {"Australia/Perth"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              { /*<Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>
                      Start Day Of Week
                  </InputLabel>
                  <Select
                    name="StartDayOfWeek"
                    value={StartDayOfWeek}
                    onChange={this.handleTextChange}
                    input={(
                      <Input
                        placeholder=""
                        margin="dense"
                      />
                     )}
                    variant="outlined"
                  >
                    { opts }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <DurationInput
                    id="MinimumWorkingDayDuration-input-settings"
                    label="Minimum Working Day Duration"
                    value={MinimumWorkingDayDuration}
                    name="MinimumWorkingDayDuration"
                    onChange={this.handleTextChange}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="DailyTimesheetProcessing-input-settings"
                    label="Timesheet Processing Time"
                    type="time"
                    name="DailyTimesheetProcessing"
                    value={DailyTimesheetProcessing}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="DailyApprovalReminder-input-settings"
                    label="Approval Reminder Time"
                    type="time"
                    name="DailyApprovalReminder"
                    value={DailyApprovalReminder}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item style={{ padding: '10px 0px 10px 0px' }}>
                <Typography variant="h6" style={headingStyle}>Working Day</Typography>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="Settings-WorkingDayEarliestStart-input-settings"
                    label="Working Day Earliest Start"
                    type="time"
                    name="WorkingDayEarliestStart"
                    value={WorkingDayEarliestStart}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="WorkingDayDefaultStart-input-settings"
                    label="Working Day Default Start"
                    type="time"
                    name="WorkingDayDefaultStart"
                    value={WorkingDayDefaultStart}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="WorkingDayDefaultFinish-input-settings"
                    label="Working Day Default Finish"
                    type="time"
                    name="WorkingDayDefaultFinish"
                    value={WorkingDayDefaultFinish}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="WorkingDayLatestFinish-input-settings"
                    label="Working Day Latest Finish"
                    type="time"
                    name="WorkingDayLatestFinish"
                    value={WorkingDayLatestFinish}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item style={{ padding: '10px 0px 10px 0px' }}>
                <Typography variant="h6" style={headingStyle}>Lunch Break</Typography>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="MinimumLunchStart-input-settings"
                    label="Lunch Earliest Start"
                    type="time"
                    name="MinimumLunchStart"
                    value={MinimumLunchStart}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="DefaultLunchStart-input-settings"
                    label="Lunch Default Start"
                    type="time"
                    name="DefaultLunchStart"
                    value={DefaultLunchStart}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="DefaultLunchEnd-input-settings"
                    label="Lunch Default Finish Time"
                    type="time"
                    name="DefaultLunchEnd"
                    value={DefaultLunchEnd}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="MaximumLunchEnd-input-settings"
                    label="Lunch Latest Finish Time"
                    type="time"
                    name="MaximumLunchEnd"
                    value={MaximumLunchEnd}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <DurationInput
                    id="MinimumWorkingTimeToRemoveLunchBreak-input-settings"
                    label="Minimum Working Time To Remove Lunch Break"
                    value={MinimumWorkingTimeToRemoveLunchBreak}
                    name="MinimumWorkingTimeToRemoveLunchBreak"
                    onChange={this.handleTextChange}
                  />
                </FormControl>
              </Grid>
              {/*
              <Grid item style={{ padding: '10px 0px 10px 0px' }}>
                <Typography variant="h6" style={headingStyle}>Advanced Settings</Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={(
                    <Switch
                      id="PrivateMode-input-settings"
                      name="PrivateMode"
                      checked
                      onChange={this.handleSwitchChange}
                    />
                  )}
                  label="Private Mode"
                />
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="PrivateModeStart-input-settings"
                    label="Private Mode Start"
                    type="time"
                    name="PrivateModeStart"
                    value={PrivateModeStart}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id="PrivateModeFinish-input-settings"
                    label="Private Mode Finish"
                    type="time"
                    name="PrivateModeFinish"
                    value={PrivateModeFinish}
                    className={classes.textField}
                    onChange={this.handleTextChange}
                    InputLabelProps={{
                      shrink: false,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <DurationInput
                    id="GlitchRemovalPeriod-input-settings"
                    label="Glitch Removal Period"
                    name="GlitchRemovalPeriod"
                    value={GlitchRemovalPeriod}
                    onChange={this.handleTextChange}
                  />
                </FormControl>
              </Grid>*/ }
              <Grid item style={{ padding: '12px' }}>
                <FormControl className={classes.formControl} fullWidth>
                  <Button
                    type="Button"
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleTextChange(
                      { target: { name: 'needSave', value: true } })}
                  >
                      Save
                  </Button>
                </FormControl>
              </Grid>
            </Paper>
          </Grid>
        </ValidatorForm>

      </Grid>
    );
  }
}


const mapStateToProps = (state) => {
  const { me } = state;
  return {
    company: me.company,
  };
};

const mapDispatchToProps = dispatch => ({
  setCompany: x => dispatch(setCompany(x)),
  setLoading: x => dispatch(setLoading(x)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, { withTheme: true })(SettingsForm)));
