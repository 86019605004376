import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  getDeviceVersions,
  getAllDevicesAllCompanies,
  updateDevicesVersion,
  getSetDeviceConfigRequired,
  getSetDeviceRebootRequired,
} from '../../API';
import { Grid, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RootReducerType } from '../../redux/store/rootReducer';
import { setDeviceVersions, setDevices } from '../../redux/actions/AllActions';
import { DeviceFirmware, Device } from '../../api/index';

interface StateProps {
  deviceFirmwares: Array<DeviceFirmware>;
  devices: Array<Device>;
};

interface DispatchProps {
  _setDevices(ws: any): void;
  _setDeviceVersions(ws: any): void;
};

interface DeviceFirmwareFormState {
  selectedDevice: Device;
  selectedDeviceFirmware: DeviceFirmware;
}

type DeviceFirmwareFormProps = StateProps & DispatchProps;

export class DeviceFirmwareForm extends Component<DeviceFirmwareFormProps> {

  state:DeviceFirmwareFormState = {
    selectedDevice: null,
    selectedDeviceFirmware: null,
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { _setDevices, _setDeviceVersions } = this.props;
    const deviceFirmwares = await getDeviceVersions() as Array<DeviceFirmware>;
    const devices = await getAllDevicesAllCompanies() as Array<Device>;

    _setDevices(devices);
    _setDeviceVersions(deviceFirmwares);
    const selectedDeviceFirmware = deviceFirmwares.find(d => d.deviceFirmwareVersionId === devices[0].ExpectedDeviceFirmwareVersionId);

    this.setState({ selectedDevice:devices[0], selectedDeviceFirmware });
  }

  handleDeviceSelect = (selectedDevice: Device) => {
    const { deviceFirmwares } = this.props;
    if(!selectedDevice) {
      return;
    }
    this.setState({ 
      selectedDevice,
      selectedDeviceFirmware: deviceFirmwares.find(
        d => d.deviceFirmwareVersionId === selectedDevice.ExpectedDeviceFirmwareVersionId)
    });
  }

  handleDeviceFirmwareVersionChange = (selectedDeviceFirmware: DeviceFirmware) => {
    if(!selectedDeviceFirmware) {
      return;
    }
    this.setState({ selectedDeviceFirmware });
  }

  handleSaveClick = async () => {
    const { selectedDeviceFirmware, selectedDevice } = this.state;
    if(selectedDevice.ExpectedDeviceFirmwareVersionId === selectedDeviceFirmware.deviceFirmwareVersionId) {
      alert('this device already has this firmware');
      return;
    }
    if(selectedDeviceFirmware.sequence !== 3) {
      alert('NOPE we only update to the latest at the monent');
      return;
    }
    const answer = prompt('Are You Sure You Want To Do this enter yes for yes?', "no");
    if(answer === 'yes') {
      await updateDevicesVersion(selectedDevice.DeviceId, selectedDeviceFirmware.deviceFirmwareVersionId);
      this.loadData();
    }
  }

  handleRequestRconf = async () => {
    const { selectedDevice } = this.state;
    var yesno = window.confirm("require rconf message from this device?");
    if(yesno) {
      getSetDeviceConfigRequired(selectedDevice.DeviceId).then(
        (g) => alert(JSON.stringify(g))).then(this.loadData);
    } else {
      alert('no');
    }
  }

  handleRequestReboot = async () => {
    const { selectedDevice } = this.state;
    var yesno = window.confirm("require reboot message from this device?");
    if(yesno) {
      getSetDeviceRebootRequired(selectedDevice.DeviceId).then(
        (g) => alert(JSON.stringify(g))).then(this.loadData);
    } else {
      alert('no');
    }
  }

  render() {
    const { devices, deviceFirmwares } = this.props;
    const { selectedDevice, selectedDeviceFirmware } = this.state;
    if(!selectedDevice) {
      return <div />;
    }
    return <Grid item xs={10} style={{ margin: 'auto'}}>
        <div>
          <Autocomplete 
            id="device-select-auto"
            options={devices}
            onChange={(event, newValue) => {
              const device = newValue as Device;
              this.handleDeviceSelect(device);
            }}
            value={selectedDevice}
            getOptionLabel={(opt) => opt.DeviceId}
            renderInput={(params) => <TextField { ...params } label="Select Device" variant="outlined" />}
          />
        </div>
        <div style={{marginTop: '10px'}}>
        <Autocomplete 
            id="devicefirmware-select-auto"
            options={deviceFirmwares}
            onChange={(event, newValue) => {
              const deviceFirmware = newValue as DeviceFirmware;
              this.handleDeviceFirmwareVersionChange(deviceFirmware);
            }}
            getOptionLabel={(opt) => opt.name}
            value={selectedDeviceFirmware}
            renderInput={(params) => <TextField { ...params } label="Select Firmware" variant="outlined" />}
          />
        </div>
        <div>
          <h3>{ selectedDevice.DeviceId }</h3>
          <p>{ selectedDeviceFirmware.name }</p>
          <p>{ selectedDeviceFirmware.description }</p>
          <p>{ selectedDeviceFirmware.version }</p>
          <p>config requested:{ `${selectedDevice.ConfigMsgRequired}` }</p>
          <p>reboot requested { `${selectedDevice.RebootRequired}` }</p>
        </div>
        <div>
          <Button color="primary" onClick={this.handleSaveClick}>
            Save Firmware change
          </Button>
        </div>
        <div>
          <Button color="primary" onClick={this.handleRequestRconf}>
            Request Rconf
          </Button>
        </div>
        <div>
          <Button color="primary" onClick={this.handleRequestReboot}>
            Request Reboot
          </Button>
        </div>
        <div>
          <span>
            Requesting a config msg or a reboot will cancel any previous requests
          </span>
        </div>
      </Grid>
  }

}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  _setDevices: (w) => dispatch(setDevices(w)),
  _setDeviceVersions: (w) => dispatch(setDeviceVersions(w)),
});

export const mapStateToProps = (state: RootReducerType):StateProps => {
  const { deviceFirmware, me } = state;
  return {
    devices: me.devices,
    deviceFirmwares: deviceFirmware.all,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceFirmwareForm);