import {
  SET_SHIFTS,
} from './actions/ActionTypes';

const initialState = {
  all: [],
  editingShift: null,
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_SHIFTS: {
      return { ...state, all: payload };
    }
    default:
      return state;
  }
}
