import BaseModel from './BaseModel';
import { NO_SITE_NAME } from '../Constants/Constants';
import { formatTimeDiff } from '../Utils';
export default class TimesheetEntry extends BaseModel {
  getTotalStr = () => {
  }
  // TODO get siteName from backend
  getTableRow = (siteName) => {
    const {
      mStartDateTime,
      Travel,
      Flag,
      SiteId,
      mFinishDateTime,
      Description,
      ModifiedWorkerId,
    } = this;
    const siteStr = siteName || NO_SITE_NAME;
    const startStr = mStartDateTime.format('HH:mm');
    const endStr = mFinishDateTime.format('HH:mm');
    const totalStr = formatTimeDiff(mStartDateTime, mFinishDateTime);
    const iconColor = (Flag !== 0 || !SiteId) ? '#ffae42' :  '#2196f3';
    const siteDesc = {
      siteStr, Description, ModifiedWorkerId, SiteId, iconColor,
    };
    // [ date, site, start, end, break, reported-total, desc, add, delete ]
    return [siteDesc, '', startStr, endStr, { value: totalStr, fontWeight: 200 }, '', this];
  }
}