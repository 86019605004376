import React from 'react';
import { render } from 'react-dom';
import matchSorter from 'match-sorter';
import {
  Checkbox,
} from '@material-ui/core';

const statuses = {
  0: 'draft',
  1: 'approved',
  2: 'locked',
};

const test = {
  true: 1,
  false: 0,
};


export const usersTableColumns = [
  {
    name: 'Photo',
    options: {
      filter: false,
      customBodyRender: (value) => {
        return (
          value ? <img className="user-table-img" src={value}></img> : <span>No Photo</span>
        );
      },
    },
  },
  {
    name: 'Payroll ID',
    options: {
      filter: false,
    },
  },
  {
    name: 'Device ID',
  },
  {
    name: 'name',
  },
  {
    name: 'Enabled',
    options: { 
      customBodyRender: v => <Checkbox checked={v} />,
      customFilterListOptions: { render: v => `Enabled` },
      filterType: "checkbox",
      filterOptions: {
      names: [true],
      },
    },
  },
  {
    name: 'Worker',
    options: { 
      customBodyRender: v => <Checkbox checked={v} />,
      customFilterListOptions: { render: v => `Worker` },
      filterType: "checkbox",
      filterOptions: {
      names: [true],
      },
    },
  },
  {
    name: 'Supervisor',
    options: { 
      customBodyRender: v => <Checkbox checked={v} />,
      customFilterListOptions: { render: v => `Supervisor` },
      filterType: "checkbox",
      filterOptions: {
      names: [true],
      },
    },
  },
  {
    name: 'Administrator',
    options: { 
      customBodyRender: v => <Checkbox checked={v} />,
      customFilterListOptions: { render: v => `Administrator` },
      filterType: "checkbox",
      filterOptions: {
      names: [true],
      },
    },
  },
  {
    name: 'WorkerId',
    options: { 
      display: false,
      filter: false,
    }
  }
];

export const usersForSupervisorTableColumns = [
  {
    name: 'Payroll ID',
    options: { customBodyRender: v => <Checkbox checked={v} /> },
  },
  {
    name: 'name',
    options: { customBodyRender: v => <Checkbox checked={v} /> },
  },
  {
    name: 'Enabled',
    options: { customBodyRender: v => <Checkbox checked={v} /> },
  },
  {
    name: 'Worker',
    options: { customBodyRender: v => <Checkbox checked={v} /> },
  },
  {
    name: 'Supervisor',
    options: { customBodyRender: v => <Checkbox checked={v} /> },
  },
  {
    name: 'Administrator',
    options: { customBodyRender: v => <Checkbox checked={v} /> },
  },

];
