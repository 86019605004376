import auth0 from 'auth0-js';
import localForage from 'localforage';
import { getEnvVariables } from '../../Constants/EnvironmentVariables';

export default class Auth {
  
  auth0;

  makeOptionsForWebAuth = async() => {
    const envVariables = await getEnvVariables();
    const optionsForWebAuth = {
        domain: envVariables.auth0Domain,
        audience: envVariables.audience,
        clientID: envVariables.clientID,
       redirectUri: `${envVariables.callbackUri}/`,
      responseType: 'token id_token',
      scope: 'openid email profile',
      nonce: `${parseInt(Math.random() * 100000, 0)}`,
    };
    this.auth0 = new auth0.WebAuth(optionsForWebAuth);
  }


  login = async () => {
    await this.makeOptionsForWebAuth();
    /* eslint-disable */
    await localForage.removeItem('access_token');
    await localForage.removeItem('id_token');
    await localForage.removeItem('expires_at');
    await this.auth0.authorize();
  }

  handleAuthentication = async () => {
    if(!this.auth0) {
      await this.makeOptionsForWebAuth(); 
    }
    return new Promise(async (res, rej) => {
      this.auth0.parseHash(async (err, authResult) => {
        if(err) {
          rej(err);
          window.location.replace('/');
          return;
        }
        if(authResult) {
          await this.setSession(authResult);
        }
        res(authResult);
      });
    });
  }

 setSession = async (authResult) => {
   // Set the time that the Access Token will expire at
   const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
   /* eslint-disable */
   await localForage.setItem('access_token', authResult.accessToken);
   await localForage.setItem('id_token', authResult.idToken);
   await localForage.setItem('expires_at', expiresAt);
   // navigate to the home route
  }

  logout = async () => {
    // Clear Access Token and ID Token from local storage
    await localForage.removeItem('access_token');
    await localForage.removeItem('id_token');
    await localForage.removeItem('expires_at');
    window.location.replace('/');
    // navigate to the home route
  }

 isAuthenticated = async () => {
   // Check whether the current time is past the
   // Access Token's expiry time
   const accessToken = await localForage.getItem('access_token');
   const expiresAtJson = await localForage.getItem('expires_at');
   const idToken = await localForage.getItem('id_token');
   const expiresAt = JSON.parse(expiresAtJson);
   if(expiresAt && accessToken && new Date().getTime() < expiresAt) {
     document.body.removeAttribute("style");
     return { accessToken, expiresAt, idToken };
   } else {
     document.body.setAttribute("style", "display: none");
     return {};
   }
 }
}
