import React, { Component } from 'react';
import {
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { getCompanies } from '../../API';

class CompaniesList extends Component {
  state = {
    companies: [],
  }

  componentDidMount() {
    this.getCompanies();
  }

  getCompanies = async () => {
    const data = await getCompanies();
    this.setState({ companies: data });
  }

  renderCompany = (company) => {
    return (
      <ListItem
        selected={company.IsArchived}
        key={company.CompanyId}
        onClick={() => this.props.onClick(company).then(this.getCompanies)}
      >
        <ListItemText
          primary={company.Name}
          secondary={company.IsArchived ? 'archived' : '' }
        />
      </ListItem>
    );
  }

  render() {
    return (<List>{ this.state.companies.map(this.renderCompany) }</List>)
  }
}

export default CompaniesList;
