import { combineReducers } from 'redux';
import timesheets from '../TimesheetStore';
import timesheetEntries from '../TimesheetEntryStore';
import workers from '../WorkerStore';
import sites from '../SiteStore';
import timesheetNotes from '../TimesheetNoteStore';
import timesheetReport from '../TimesheetReportStore';
import ui from '../UiStore';
import me from '../MeReducer';
import zones from '../ZonesStore';
import covidCase from '../reducers/CovidCaseReducer';
import workersCovid from '../reducers/WorkersCovidReducer';
import {configurationReducer} from '../reducers/ConfigurationReducer';
import { contactTraceReducer } from '../reducers/ContactTraceReducer';
import { deviceFirmwareReducer } from '../reducers/DeviceFirmwareReducer';
import unpaidTimesheetEntries from '../UnpaidTimesheetEntryStore';
import workerGroups from '../WorkerGroupReducer';
import shifts from '../ShiftReducer';
import workerShifts from '../WorkerShiftReducer';


const rootReducer = combineReducers(
  {
    timesheets,
    sites,
    workers,
    timesheetEntries,
    timesheetNotes,
    timesheetReport,
    me,
    ui,
    zones,
    covidCase,
    workersCovid,
    configuration: configurationReducer,
    contactTracing: contactTraceReducer,
    deviceFirmware: deviceFirmwareReducer,
    unpaidTimesheetEntries,
    workerGroups,
    shifts,
    workerShifts,
  },
);


export default rootReducer;
export type RootReducerType = ReturnType<typeof rootReducer>
