import React, { Component } from "react";
import {
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  getWorkers,
  
} from '../../API';
import moment from 'moment-timezone';
import { printToPDF } from '../Utils/PrintPdf';
import { getWorkerTotals, getLabourAgencies, getAllSites } from "../../API";
import MUIDataTable from 'mui-datatables';
import { makeSupervisorTotalsReportHTML } from "./HTMLWorkerTotalsReport";


const columns = [
  { name: 'Worker' },
  { name: 'Total Breaks' },
  { name: 'Total Hours' },
]

function reportRow(item) {
  const { workerName, paidHours, paidMinutes, unpaidHours, unpaidMinutes } = item;

  return [workerName, `${unpaidHours}:${unpaidMinutes}h`,  `${paidHours}:${paidMinutes}h` ];
}

const options = {
  filterType: 'multiselect',
  selectableRows: false,
  responsive: 'scroll',
  rowsPerPage: 50,
  rowsPerPageOptions: [10, 20, 30, 40, 50],
};

export default class WorkerTotalsReportPage extends Component {

  state = {
    startDate: moment().add(-9, 'days'),
    endDate: moment().add(-2, 'days'),
    results: [],
    workers: [],
    labourAgencys: [],
    supervisors: [],
    selectedSupervisorId: 0,
    selectedAgencyId: 0,
  }

  componentDidMount() {
    Promise.all([
      getAllSites(),
      getWorkers(),
      getLabourAgencies(),
    ]).then(data => {
      const [sites, workers, labourAgencys] = data;
      const enabledWorkers = workers.filter(w => w.IsWorker && w.IsEnabled);
      const supervisors = workers.filter(w => w.IsSupervisor);
      this.setState({ supervisors, workers: enabledWorkers, selectedSupervisorId: supervisors[0].WorkerId, sites, labourAgencys });
    });
  }

  handleSupervisorChange = ({ target }) => {
    this.setState({ 
      selectedSupervisorId: target.value,
      selectedAgencyId: 0,
    });
  }

  handleLabourAgencyChange = ({ target }) => {
    this.setState({ 
      selectedAgencyId: target.value,
      selectedSupervisorId: 0,
    });
  }

  handleStartDateChange = ({ target }) => {
    this.setState({ startDate: moment(target.value, 'YYYY-MM-DD') });
  }

  handleEndDateChange = ({ target }) => {
    this.setState({ endDate: moment(target.value, 'YYYY-MM-DD') });
  }

  handleSubmit = async () => {
    const { selectedSupervisorId, selectedAgencyId, startDate, endDate } = this.state;
    const results = await getWorkerTotals(
      selectedSupervisorId ? selectedSupervisorId : null,
      selectedAgencyId ? selectedAgencyId : null,
      moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      moment(endDate).utc().add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
    );  
    this.setState({ results });
  }

  printReport = () => {
    const { results, startDate, endDate } = this.state;
    const options = {
      //filename: `contact-report-${this.state.selectedWorker.Name.replace(' ', '')}`,
      filename: Math.floor(Math.random() * 1000000),
      jsPDF: { orientation: 'portrait' },
      pagebreak: {avoid: ['li', 'h3']},
      margin: 5,
    };
    const dt = moment();
    const printVals = {
      printDate: dt.format('DD/MM/YYYY'),
      printTime: dt.format('hh:mm a'),
      startDate: startDate.format('DD/MM/YYYY'),
      endDate: endDate.format('DD/MM/YYYY'),
      workerReports: results.map(reportRow),
    };
    const html = makeSupervisorTotalsReportHTML(printVals);
    printToPDF(html, options);
  }

  render() {
    const { supervisors, results, startDate, endDate, labourAgencys } = this.state;
    const supervisorOptions = supervisors.map((s) => <MenuItem value={s.WorkerId} key={s.WorkerId}>{s.Name}</MenuItem>);
    const agencyOptions = labourAgencys.map((s) => <MenuItem value={s.LabourAgencyId} key={s.LabourAgencyId}>{s.Name}</MenuItem>)
    const rows = results.map(reportRow);
    return (
      <Grid container className="dashboard-container">
        <Grid item xs={12} md={6} lg={6} style={
          { backgroundColor: '#fff', paddingLeft: '20px', paddingTop: '15px' }}>
          <Grid item xs={12}>
            <h3>Total hours by supervisor or agency</h3>
            <h4>Only showing APPROVED hours.  Make sure to approve timesheets before reporting</h4>
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingBottom: '20px'}}>
              <Select name="supervisor" onChange={this.handleSupervisorChange} value={this.state.selectedSupervisorId}>
                <MenuItem value={0} key={'none'}>All</MenuItem>
                { supervisorOptions }
              </Select>
              <FormHelperText>Select supervisor</FormHelperText>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingBottom: '20px'}}>
              <Select name="angency" onChange={this.handleLabourAgencyChange} value={this.state.selectedAgencyId}>
                <MenuItem value={0} key={'none'}>One Scope</MenuItem>
                { agencyOptions }
              </Select>
              <FormHelperText>Select agency</FormHelperText>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <TextField
                type='date'
                label='Start Date'
                value={startDate.format('YYYY-MM-DD')}
                onChange={this.handleStartDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type='date'
                label='End Date'
                value={endDate.format('YYYY-MM-DD')}
                onChange={this.handleEndDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormHelperText>Select start date and end date</FormHelperText>
            </div>
            <div>
              <div style={{ paddingTop: '8px', display: 'inline'}}>
                <FormControl>
                  <Button variant="outlined" onClick={this.handleSubmit} style={{float: 'right'}}>Submit</Button>
                </FormControl>
              </div>
              <div style={{ paddingTop: '8px', paddingLeft: '10px', display: 'inline'}}>
                <FormControl>
                  <Button variant="outlined" onClick={this.printReport} style={{float: 'right'}}>Print</Button>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MUIDataTable
            data={rows}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    )
  }

}