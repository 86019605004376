import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { 
  Grid,
  Input,
  Button,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import {
  getWorkers,
  getWorkerStatus,
  getCompany,
  getAllSites,
  getEmailPreview,
  sendManualEmail,
  getWorkerEmailData,
} from '../../API';


function WorkerStatusRow(props) {
  const { workerStatus } = props;
  const { workerName, siteName, onsite, startTime } = workerStatus;
  const { onChange, siteNames } = props;
  
  return <Grid container key={workerName} spacing={4}>
    <Grid item xs={3}>
      <label>on site</label>
      <input 
        name="onsite"
        type="checkbox"
        checked={onsite}
        onChange={(e) => onChange(e, workerStatus)}
      />
    </Grid>
    <Grid item xs={3}>
      { workerName }
    </Grid>
    <Grid item xs={3}>
      <Input value={startTime} name="startTime" type="time" onChange={(e) => onChange(e, workerStatus)} />
    </Grid>
    <Grid item xs={3}>
      <Select name="siteName" value={siteName || ''} onChange={(e) => onChange(e, workerStatus)}>
        { siteNames.map(sn => <MenuItem key={`menu-${sn}`} name="siteName" value={sn}>{sn}</MenuItem>)}
      </Select>
    </Grid>
  </Grid>
}


export default function ManualEmailSender(props) {

  const [workerStatuses, setWorkerStatus ] = useState([]);
  const initaialEmailDets = {
    email: '',
    toName: '',
    companyName: '',
    subject: 'attendance report',
  };
  const [emailDets, setEmailDets] = useState(initaialEmailDets);
  const [siteNames, setSiteNames] = useState([]);
  const [preview, setPreview] = useState(null);

  const onChangeWorkerStatus = (evt, workerStatus) => {
    const elem = evt.currentTarget.name ? evt.currentTarget : evt.target;
    const { name, value } = elem;
    let ws;

    if(name == 'onsite') {
      ws =  { ...workerStatus, onsite: !workerStatus.onsite };
    } else {
      ws = { ...workerStatus, [name]: value }
    }

    const wss = workerStatuses.map(w => w.workerName == ws.workerName ? ws : w);
    setWorkerStatus(wss);
    setPreview(null);
    document.getElementById("preview-email").innerHTML = null;
  }

  const onChangeEmailDets = (evt) => {
    const { name, value } = evt.currentTarget;
    setEmailDets({ ...emailDets, ...{ [name]: value } });
  }

  function genEmailPayload() {
    const onSiteWorkers = workerStatuses.filter(w => !!w.onsite);
    const offSiteWorkers = workerStatuses.filter(w => !w.onsite).map(r => r.workerName);
    return {
      onSiteWorkers,
      offSiteWorkers,
      toName,
      email,
      subject,
      companyName,
    }
  }

  const getPreview = async () => {
    const payload = genEmailPayload();
    const previewHtml = await getEmailPreview(payload);
    setPreview(previewHtml);
    document.getElementById("preview-email").innerHTML = previewHtml;
  }

  const sendEmail = async () => {
    const payload = genEmailPayload();
    await sendManualEmail(payload);
    alert('great job you have sent an email');
  }

  useEffect(() => {
    async function getData() {
      const company = await getCompany();
      const workers = await getWorkers();
      const sites = await getAllSites();
      setSiteNames(sites.filter(s => s.Active).map(s => s.Name));
      const workerData = await Promise.all(workers.filter(w => w.IsWorker).map(w => getWorkerEmailData(w.WorkerId)));
      setWorkerStatus(workerData.map(d => ({ ...d, onsite: !!d.siteName })));
      setEmailDets({ ...emailDets, companyName: company.Name });
    }
    getData();
  }, []);

  const { email, subject, toName, companyName } = emailDets;
  const canSend =  preview && email && subject && toName;
  return (
    <div style={{ padding: '15px', minHeight: '70vh' }}>
      <Grid container spacing={1}>
          <Grid item xs={12}>
            <h3>Create an onsite email</h3>
            { !workerStatuses.length && ' ..... please wait loading '}
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <Input onChange={onChangeEmailDets} name='toName' placeholder='to name' value={toName} />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <Input onChange={onChangeEmailDets} type="email" name='email' placeholder='email' value={email} />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <Input onChange={onChangeEmailDets}  name='subject' placeholder='subject' value={subject} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            { workerStatuses.map(w => <WorkerStatusRow workerStatus={w} siteNames={siteNames} onChange={onChangeWorkerStatus} key={w.workerName} />) }
         </Grid>
         <Grid item xs={6}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={3}><Button onClick={getPreview} variant="outlined">Preview</Button></Grid>
              {canSend && <Grid onClick={sendEmail} item xs={6} md={3}><Button variant="outlined">Send</Button></Grid> }
            </Grid>
         </Grid>
         <Grid item id="preview-email" xs={6} style={{ minHeight: '60vh' }}>
         </Grid>
      </Grid>
    </div>
  )

}