/* eslint-disable no-unused-vars */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import {
  Tooltip,
  Button,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import LocationIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  editTimesheetEntry,
  addTimesheetEntry,
  deleteTimesheetEntry,
  editUnpaidTimesheetEntry,
  setUnpaidTimesheetEntries,
} from '../../redux/actions/AllActions';
import TimesheetEntryEditor from './TimesheetEntryEditor';
// eslint-disable-next-line import/no-named-as-default
import TimesheetFormModal from './TimesheetFormModal';
import TimesheetTableHeader from './TimesheetTableHeader';
import { 
  getUnpaidTimesheetEntries,
  updateUnpaidTimesheetEntry,
} from '../../API';
import EditIcon from '@material-ui/icons/Edit';


const tableStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  minHeight: '600px',
};

function renderSiteCell({
  siteStr, Description, ModifiedWorkerId, iconColor,
}, tableMeta, updateValue) {
  if (!siteStr) {
    return <span />;
  }
  const iconProps = { style: { color: iconColor, opacity: 0.5 }, fontSize: 'small' };
  const icon = ModifiedWorkerId ? <PersonIcon {...iconProps} /> : <LocationIcon {...iconProps} />;
  return (
    <Tooltip title={Description || ''}>
      <div style={{
        display: 'inline-block', position: 'relative', minWidth: '100px', paddingLeft: '20px',
      }}
      >
        { icon }
        <span style={{
          marginLeft: '5px',
          position: 'absolute',
          bottom: '5px',
          verticalAlign: 'middle',
          display: 'inline-block',
          minWidth: '300px',
          textOverflow: 'ellipsis',
        }}
        >{siteStr}
        </span>
      </div>
    </Tooltip>
  );
}



function renderWorkTimes(setting) {
  return setting && (
    <div>
      <span style={{color: '#595959', fontSize: '0.95em'}}>{`${moment(setting.StartDateTime).format('HH:mm')} - ${moment(setting.EndDateTime).format('HH:mm')}`}</span>
    </div>
  );
}

function renderUnpaidTime(value, tableMeta, openBreakTimeEditor) {
  return value ? (
    <div onClick={() => openBreakTimeEditor(tableMeta.rowData[5])}>
      <b>{value}</b>
      <EditIcon fontSize={'small'} style={{marginLeft: '5px', paddingRight: '5px', color: '#999' }} />
    </div>
  ) : '';
}

function getCorrespondingWorkerShift(dayString, workerShifts) {

  var daynum = moment().day(dayString.split(' ')[0]).day();
  var setting = workerShifts.find(wt => moment(wt.StartDateTime).day() == daynum);
  return setting;

}

function renderDateSiteCell(value, tableMeta, updateValue, workerShifts) {
  const setting = !value.siteStr && getCorrespondingWorkerShift(value, workerShifts);
  return value.siteStr ? renderSiteCell(value) : (<div><b>{value}</b>{renderWorkTimes(setting)}</div>);
}


export function getLabels(onAddClick, workerShifts, openBreakTimeEditor) {
  return [
    {
      name: 'date / site',
      options: {
        filter: false,
        customBodyRender: (a, b, c) => workerShifts && renderDateSiteCell(a, b, c, workerShifts),
      },
    },
    {
      name: 'Lunch',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value && renderUnpaidTime(value, tableMeta, openBreakTimeEditor));
        },
      },
    },
    {
      name: 'Start',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <span className={`tse-start-time-${value.replace(':', '')}`}>{value}</span>
        ),
      },

    },
    {
      name: 'Finish',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <span
            className={`tse-end-time-${value.replace(':', '')}`}
          >
            {value}
          </span>
        ),
      },
    },
    {
      name: 'Hours',
      options: {
        customBodyRender: ({ value, fontWeight }, tableMeta, updateValue) => (
          <span style={{ fontWeight }}>{value}</span>
        ),
      },
    },
    {
      name: 'add row',
      options: {
        customBodyRender: mDay => mDay && (
          <Button
            id={`add-ts-entry-${mDay.format('dddd')}`}
            onClick={() => onAddClick(mDay)}
            color="primary"
          >
              Add Row
          </Button>
        ),
      },
    },
    {
      name: 'data',
      options: {
        display: 'excluded',
      },
    },
  ];
}

export function getOptions(toolbar, onRowClick, timesheet, worker) {
  return {
    filterType: 'multiselect',
    selectableRows: false,
    rowsPerPageOptions: [1000],
    rowsPerPage: 1000,
    fixedHeader: false,
    filter: false,
    search: false,
    print: false,
    viewColumns: false,
    download: false,
    customToolbar: () => (<Typography variant="h6">{toolbar}</Typography>),
    onRowClick,
  };
}

export class TimesheetTableClass extends TimesheetEntryEditor {
  state = {
    timesheetEntryEditingId: null,
    showWorkTimePopup: false,
    unpaidHours: undefined,
    unpaidMinutes: undefined,
    editingUnpaidTimesheetEntry: null,
  }

  editField() {

  }

  handleUnpaidTimeEntrySaveClick = async () => {
    const { unpaidHours, unpaidMinutes, editingUnpaidTimesheetEntry } = this.state;
    const { _setUnpaidTimesheetEntries } = this.props;
    const minStr = unpaidMinutes > 10 ? unpaidMinutes : `0${unpaidMinutes || 0}`;
    const hourStr = unpaidHours > 10 ? unpaidHours : `0${unpaidHours || 0}`;
    const newEntry = { ...editingUnpaidTimesheetEntry, UnpaidTime: `${hourStr}:${minStr}` };
    this.props._editUnpaidTimesheetEntry(newEntry);
    this.setState({ editingUnpaidTimesheetEntry: null, unpaidHours: undefined, unpaidMinutes: undefined });
    updateUnpaidTimesheetEntry(newEntry).then(
      data => getUnpaidTimesheetEntries(newEntry.TimesheetId).then(
        data => _setUnpaidTimesheetEntries(data)));
  }

  handleOpenUnpaidTimeEditor = (dt) => {
    this.setState({
      editingUnpaidTimesheetEntry: this.props.unpaidTimesheetEntries.find(d => moment(d.DateTime).day() === dt.day())
    });
  }

  addRow = (mStartDate) => {
    const entry = this.handleAddPress(mStartDate);
    setTimeout(() => this.setState({ timesheetEntryEditingId: entry.TimesheetEntryId }));
  }

  handleCloseModal = (tse) => {
    this.setState({ timesheetEntryEditingId: null });
  }

  handleRowClick = (rowData) => {
    this.setState({ timesheetEntryEditingId: rowData[6].TimesheetEntryId || null });
  }

  getOptions = () => {
    const { timesheet, worker } = this.props;
    return getOptions('Click a row to edit', this.handleRowClick, timesheet, worker);
  }

  getLabels = () => getLabels(this.addRow, this.props.workerShifts, this.handleOpenUnpaidTimeEditor)

  render() {
    const { timesheetEntryEditingId, showWorkTimePopup } = this.state;
    const {
      rows, timesheet, worker, style, workDayTimeSettings,
    } = this.props;
    const timesheetEntry = timesheet.timesheetEntryList.findById(timesheetEntryEditingId);
    return (
      <div id="approvals-table">
        { timesheetEntry
          && (
          <TimesheetFormModal
            onClose={this.handleCloseModal}
            startDate={timesheetEntry.StartDateTime}
            timesheetEntryId={timesheetEntry.TimesheetEntryId}
            timesheetEntry={timesheetEntry}
            timesheet={timesheet}
            onSaveClick={this.props.onSaveClick}
          />
          )
        }
        <Modal
          open={!!this.state.editingUnpaidTimesheetEntry}
          onBackdropClick={() => this.setState({ editingUnpaidTimesheetEntry: false })}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={modalStyle}
        >
          <div style={innerModal}>
            <div>
              <TextField 
                type='number'
                placeholder={'hours'}
                value={this.state.unpaidHours}
                onChange={(evt) => this.setState({ unpaidHours: evt.target.value <= 23 ? evt.target.value : 23 })}
              />
              <TextField 
                max={59}
                value={this.state.unpaidMinutes}
                placeholder="minutes" 
                type='number' 
                onChange={(evt) => this.setState({ unpaidMinutes: evt.target.value <= 59 ? evt.target.value : 59 })}
              />
            </div>
            <div>
              <Button onClick={this.handleUnpaidTimeEntrySaveClick}>Save</Button>
            </div>
          </div>
        </Modal>
        <div id="printable-table">
          <MUIDataTable
            id="timesheet-table"
            style={style}
            title={<TimesheetTableHeader timesheet={timesheet} worker={worker} />}
            data={rows}
            columns={this.getLabels()}
            options={this.getOptions()}
          />
        </div>
      </div>

    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const {
    me,
    workers,
    unpaidTimesheetEntries,
  } = state;
  const worker = workers.all.find(w => w.WorkerId === ownProps.timesheet.WorkerId);
  const workerShifts = state.timesheets.workerShifts;
  ownProps.timesheet.setWorkerShifts(workerShifts);
  return {
    me: me.me,
    company: me.company,
    worker,
    style: tableStyle,
    unpaidTimesheetEntries: unpaidTimesheetEntries.all,
    rows: ownProps.timesheet.getTableRows(unpaidTimesheetEntries.all),
    timesheetId: ownProps.timesheet.TimesheetId,
    workerShifts,
  };
};

const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

};

const innerModal = { 
  backgroundColor: '#fff',
  border: '2px black',
  padding: '22px',
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  _addTimesheetEntry: x => dispatch(addTimesheetEntry(x)),
  _deleteTimesheetEntry: x => dispatch(deleteTimesheetEntry(x)),
  _editTimesheetEntry: x => dispatch(editTimesheetEntry(x)),
  _editUnpaidTimesheetEntry: x => dispatch(editUnpaidTimesheetEntry(x)),
  _setUnpaidTimesheetEntries: x => dispatch(setUnpaidTimesheetEntries(x)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles({}, { withTheme: true })(TimesheetTableClass)));
