import logoTxt from '../Images/logo';

const stylesStr = `
#wrapper {
  width: 625px;
  margin: 0;
  padding: 50px;
  top: 0;
  left: 0;
  background-color: #fff;
}

body {
  padding: 0;
  font-size: 100%;
}

html, body {
  height: 1200px;
  width: 625px;
  margin:0;
  padding:0;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.3rem;
}

table {
  font-size: 11px;
}
th {
  font-size: 12px;
}
.left {
  float: left;
}
.right {
  float: right;
}
tr {
  padding-top: 10px;
  padding-bottom: 10px;
}
#header-table {
  margin-right: 25px;
}
div#header-table td{
  padding: 4px;
}
.main {
  margin-top: 20px;
  margin-left: 20px;
}

#footer {
  margin-top: : 100px;
  left: 50px;
}

#main-table, #signature-table {
  width: 600px;
  margin: 0px auto;
}

#main-table td {
  margin: 0;
  padding: 4px 0px 2px 8px;
  text-align: left;
}

tr.inner-row {
  border: none;
}

tr.inner-row td {
  padding: 11px 0px 11px 12px;
}

td {
  padding: 11px 0px 11px 12px;
}

tr.outer-row td {
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.bordered {
  border: 1px solid black;
}

small {
  color: #999;
}

.pad-left {
  padding-left: 25px;
}
#signature-table {
  margin: 50px auto;
}

.signature-cell {
  border: 1px black solid;
  height: 60px;
}
`;


function formatEntryRow({ siteStr, startStr, endStr }) {
  return `
  <tr class="inner-row">
    <td>${siteStr}</td>
    <td><b>${startStr}  ${endStr}</b></td>
  </tr>
  `;
}

function formatDayRow(
  {
    dayStr,
    lunchTotalStr,
    workTotalStr,
    scheduledStartTime,
    scheduledEndTime,
  }) {
  return `
  <tr class="outer-row">
    <td colspan="2">${dayStr} ${scheduledStartTime} ${scheduledEndTime}</td>
    <td>${lunchTotalStr}</td>
    <td>${workTotalStr}</td>
  </tr>
  `;
}

function formatTimesheetEntryDay(dayEntry) {
  const {
    entries,
  } = dayEntry;

  return `
    ${formatDayRow(dayEntry)}
    ${entries.map(formatEntryRow).join(' ')}
  `;
}

// TODO HOST THE SITEM8 Logo
// eslint-disable-next-line import/prefer-default-export
export function HTMLTimesheet(values) {
  const {
    workerName,
    companyName,
    endDateStr,
    totalWorkTimeStr,
    timesheetEntryDays, // timesheet entries grouped by day
    printDateStr,
    imageDataURI,
  } = values;
  const entryRows = timesheetEntryDays.map(formatTimesheetEntryDay).join(' ');
  return `
    <html>
      <head>
      <style>
        ${stylesStr}
      </style>
      </head>
      <body>
      <div id="wrapper">
      <div id="header">
        <div class="left">
          <div class="pad-left"><small>${printDateStr}</small></div>
          <div class="pad-left"><img src='${logoTxt}' style="width: 150px" /></div>
        </div>
      </div>
      <div class="left main">
        <table id="main-table">
          <tbody>
            <tr class="outer-row">
              <td colspan="2">
                <b>EMPLOYEE TIMESHEET</b>
              </td>
            </tr>
            <tr class="outer-row">
              <td>company</td>
              <td>${companyName}</td>
            </tr>
            <tr>
              <td>name</td>
              <td>${workerName}</td>
            </tr>
            <tr>
              <td>week ending</td>
              <td>${endDateStr}</td>
            </tr>
            <tr class="outer-row">
              <td colspan="1">date / site</td>
              <td colspan="1">start  finish</td>
              <td>lunch taken</td>
              <td>total hours</td>
            </tr>
            ${entryRows}
            <tr class="outer-row">
              <td colspan="3"><b>total</b></td>
              <td><b>${totalWorkTimeStr}</b></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="left main">
        <table id="signature-table">
            <tr class="outer-row">
              <td colspan="2">MANAGER</td>
              <td colspan="2">EMPLOYEE</td>
            </tr>
            <tr class="outer-row">
              <td>date</td>
              <td>signature</td>
              <td>date</td>
              <td>signature</td>
            <tr>
              <td class="signature-cell"></td>
              <td class="signature-cell"></td>
              <td class="signature-cell"></td>
              <td class="signature-cell"></td>
            </tr>
        </table>
      </div>
    </div>
      </body>
    </html>
  `;
}
