import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { styles } from '../Approvals/Approvals';
import {
  getWorkerGroups,
} from '../../API';
import {
  setWorkerGroups,
  setEditingWorkerGroup,
} from '../../redux/actions/AllActions';



export function WorkerGroupList(props) {

  useEffect(() => {
    getWorkerGroups().then(props._setWorkerGroups)
  }, []);

  const onGroupClick = (wg) => {
    return () => props._setEditingWorkerGroup(wg);
  }

  return (
    <List component="nav">
      { props.workerGroups.map(
        w => <ListItem  
          button
          key={w.WorkerGroupId} 
          onClick={onGroupClick(w)}
        >
          <ListItemText>{w.Name}</ListItemText>
        </ListItem> )}
    </List>
  );

}

export const mapStateToProps = (state) => {
  return {
    workerGroups: state.workerGroups.all,
  } 
};

export const mapDispatchToProps = dispatch => ({
  _setEditingWorkerGroup: x => dispatch(setEditingWorkerGroup(x)),
  _setWorkerGroups: x => dispatch(setWorkerGroups(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(WorkerGroupList)));
  