import React, { Component } from 'react';
import {
  Paper,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Button,
  Typography,
  Fab,
  TextareaAutosize,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Common/FormStyles';
import { getGeneratedTextmessages, postTextmessage, getWorkers, getIncomingMessages, getNotificationsByWorkerId } from '../../API';
import { replaceInArray } from '../../Utils';
import moment from 'moment';


export function SmsForm(props) {
  const { 
    Category,
    CreationDateTime,
    Description,
    Content,
    MobileNumber,
    SmsStatus,
    WorkerId,
    WorkerName,
    onSendClick,
    onTextAreaChange,
    NotificationId,
  } = props;

  return <ListItem style={{ maxWidth: '450px'}}>
      <ListItemText
          primary={WorkerName}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'block' }}
                component="p"
                variant="body2"
              >
                { !NotificationId && MobileNumber } { CreationDateTime ? moment(CreationDateTime).fromNow() : 'Send a new message' }
              </Typography>
              <TextareaAutosize
                rowsMin={4}
                value={Description || Content}
                style={{ width: 350 }}
                onChange={(e) => onTextAreaChange(e, WorkerId)}
                disabled={!onTextAreaChange}
              />
            </React.Fragment>
          }
      />
      <div>
          { onSendClick && <Button color='primary' style={{float: 'left'}} onClick={() => onSendClick(WorkerId)} disabled={!Content}>
            Send
          </Button> }
      </div>
    </ListItem>
}



class SMSInterface extends Component {
  state = {
    textMesssages: [],
    newMessage: "",
    message: '',
  }

  componentDidMount() {
    console.log(this.props);
    this.getData();
    this.interval = setInterval(this.getData, 7000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getData = async () => {
    var messages = [];
    this.setState({ message: 'getting messages...'});
    const textMesssages = await getIncomingMessages(this.props.workerStatus.WorkerId);
    var notifs = await getNotificationsByWorkerId(this.props.workerStatus.WorkerId);
    messages = [...textMesssages, ...notifs];
    messages.sort((g, a) => moment(g.CreationDateTime).isBefore(moment(a.CreationDateTime)) ? 1 : -1);
    this.setState({ textMesssages: messages });
    this.setState({ message: ''});
  }

  handleTextAreaChange = (e) => {
    this.setState({ newMessage: e.target.value });
  }

  handleSendClick = async () => {
    var payload = {
      Category: "SMSTOSEND",
      CreationDateTime: moment().format(),
      Description: this.state.newMessage,
      MobileNumber: this.props.workerStatus.Mobile,
      NotificationStatus: 0,
      WorkerId: this.props.workerStatus.WorkerId,
      WorkerName: "",
    };
    await postTextmessage(payload);
    this.setState({ newMessage: "" });
    this.getData();
  }

  render() {

    return (
      <div style={{ padding: '15px', minHeight: '70vh', overflow: 'auto' }}>
        <Grid container>
          <Grid item xs={12}>
            Text Messages
          </Grid>
          <Grid item xs={12}>
           <SmsForm 
             onSendClick={this.handleSendClick}
             onTextAreaChange={this.handleTextAreaChange}
             Content={this.state.newMessage}
           />
         </Grid>
         <Grid item xs={12}>{this.state.message}</Grid>
         <Grid item xs={12} style={{paddingTop: '20px'}}>
            <List>
              { this.state.textMesssages.map(
                (t, i) => <SmsForm { ...t } key={i} 
              />)}
            </List>
           </Grid>
        </Grid>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(SMSInterface);
