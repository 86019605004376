import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
} from '@material-ui/core';

export default function(props) {

  const onListClick = (b) => {
    return () => props.handleListClick(b);
  }

  return <Paper style={{ padding: '10px 5px 20px 0px', margin: '20px', width: '235px' }}>
    <List style={{minWidth: '250px'}}>
    { props.beacons.map((b, i) => <ListItem button key={i} onClick={onListClick(b)}>
      <ListItemText 
        primary={b.Name}
        secondary={b.MacAddress}
      />
      <Divider light />
      </ListItem>
     ) }
    </List>
  </Paper> 

}