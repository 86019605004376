import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid, Typography, Button,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { SelectInput } from '../FormComponents';
import ApprovalsForm, { mapDispatchToProps, mapStateToProps } from './ApprovalsForm';


class ApprovalsFormMobile extends ApprovalsForm {
  render() {
    const {
      timesheet, timesheetList,
    } = this.props;

    const index = this.getIndex();
    const tsOptions = timesheetList.getSelectOptions();

    return (
      <Grid container>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <div className="timesheet-id-select">
            <SelectInput
              textKey="text"
              onChange={this.onSelectChange}
              value={timesheet && timesheet.TimesheetId}
              list={tsOptions}
              name="TimesheetId"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingTop: '20px' }}>
          <Button
            disabled={!timesheet || index === 0}
            variant="contained"
            color="secondary"
            onClick={this.onBackClick}
          >
            Back
          </Button>
          <Button
            disabled={index === (timesheetList.objects.length - 1)}
            variant="contained"
            color="primary"
            onClick={this.onNextClick}
            style={{ marginLeft: '20px' }}
          >
            Next
          </Button>
        </Grid>
        <Grid container style={{ paddingLeft: '15px' }}>
          <Grid item>
            <Typography>Sites</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              { timesheet && timesheet.timesheetEntryList.siteDescription }
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ paddingLeft: '15px' }}>
          <Grid item>
            <Typography>Total hours</Typography>
          </Grid>
          <Grid item style={{padding: '1px 0px 0px 6px'}}>
            <Typography>{`${timesheet && timesheet.timesheetEntryList.formatTotalTimeStr(timesheet.timesheetEntryList.objects)}`}</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ paddingLeft: '15px', marginTop: '-25px' }}>
          <Grid item style={{ paddingTop: '25px' }}>
            <Typography>Status</Typography>
          </Grid>
          {timesheet && (
            <Grid item style={{ paddingLeft: '25px', paddingTop: '20px' }}>
              <Switch
                checked={timesheet.Status === 1}
                onChange={this.handleSwitchClick}
                value={timesheet.Status === 1}
                color="secondary"
              />
            </Grid>
          ) }

          <Grid item style={{ paddingTop: '25px', paddingBottom: '25px', paddingLeft: '18px' }}>
            <Typography variant="subtitle2">
              { timesheet && (timesheet.Status === 1 ? 'Approved' : 'Draft ')}
              { !timesheet && 'No available timesheets' }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApprovalsFormMobile));
