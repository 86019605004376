import {PayloadAction } from './types'
import {Worker} from '../../API'
import {
  WEEKDAY_NUMBERS,
} from '../../Constants/Constants';
import DEVICE_MODES from '../../Constants/DeviceModes';
import { timeSpanStringToTotalMinutes } from '../../Utils';
import {
  EDIT_TIMESHEET_ENTRY,
  SET_TIMESHEETS,
  SET_APPROVAL_TIMESHEET,
  SET_WORKERS,
  SET_TIMESHEET_ENTRIES,
  DELETE_TIMESHEET_ENTRY,
  ADD_TIMESHEET_ENTRY,
  SET_UNPAID_TIMESHEET_ENTRIES,
  EDIT_UNPAID_TIMESHEET_ENTRY,
  SET_SITES,
  EDIT_TIMESHEET,
  ADD_TIMESHEET_NOTE,
  SET_TIMESHEET_NOTES,
  SET_TIMESHEET_RANGE,
  UPDATE_TIMESHEET_REPORTTABLE,
  SET_REPORT_DATE,
  SET_TIMESHEET_STATUS,
  SET_DASHBOARD_WORKER_ID,
  UPDATE_WORKERS_TABLE,
  EDIT_WORKER,
  CREATE_WORKER,
  SET_AUTH,
  SET_ME,
  SET_COMPANY,
  CREATE_SITE,
  UPDATE_SITE,
  SET_LOCATIONS,
  SET_SNACKBAR,
  SET_DEVICES,
  SET_LOADING,
  SET_PATHNAME,
  CREATE_ZONE,
  SET_ZONES,
  UPDATE_ZONE,
  SET_API_ROOT,
  SET_DEVICE_VERSIONS,
  SET_WORKER_GROUPS,
  SET_EDITING_WORKERGROUP,
  SET_SHIFTS,
  SET_WORKER_SHIFTS,
  SET_SCHEDULE_GROUP_VIEW,
  SET_SCHEDULE_NEXT_WEEK,
  SET_SCHEDULE_PREVIOUS_WEEK,
  SET_APPROVALS_WORKER_SHIFTS,
} from './ActionTypes';
import {
  sortTimesheetEntries,
} from '../../Utils';
import { time } from 'console';



function getCompanyAssignmentDefaults(company) {
  const {
    WorkingDayEarliestStart,
    WorkingDayDefaultStart,
    WorkingDayDefaultFinish,
    WorkingDayLatestFinish,
    MinimumLunchStart,
    DefaultLunchStart,
    DefaultLunchEnd,
    MaximumLunchEnd,
    DefaultAfterShiftInterval,
    DefaultBeforeShiftInterval,
    DefaultDeviceRunningMode,
    DefaultDuringShiftInterval,
  } = company;
  return {
    WorkingDayEarliestStart,
    WorkingDayDefaultStart,
    WorkingDayDefaultFinish,
    WorkingDayLatestFinish,
    MinimumLunchStart,
    DefaultLunchStart,
    DefaultLunchEnd,
    MaximumLunchEnd,
    AfterShiftDeviceMode: DEVICE_MODES[DefaultDeviceRunningMode],
    AfterShiftTrackInterval: DefaultAfterShiftInterval,
    BeforeShiftDeviceMode: DEVICE_MODES[DefaultDeviceRunningMode],
    BeforeShiftTrackInterval: DefaultBeforeShiftInterval,
    DuringShiftDeviceMode: DEVICE_MODES[DefaultDeviceRunningMode],
    DuringShiftTrackInterval: DefaultDuringShiftInterval,
  };
}

export const setWorkerGroups = payload => ({
  type: SET_WORKER_GROUPS,
  payload,
});

export const setPathname = payload => ({
  type: SET_PATHNAME,
  payload,
});

export const setSnackbar = payload => ({
  type: SET_SNACKBAR,
  payload,
});


export const setLoading = payload => ({
  type: SET_LOADING,
  payload,
});

export const setAuth = payload => ({
  type: SET_AUTH,
  payload,
});

export const setMe = payload => ({
  type: SET_ME,
  payload,
});

export const setCompany = payload => ({
  type: SET_COMPANY,
  payload,
});

export const editTimesheetEntry = payload => ({
  type: EDIT_TIMESHEET_ENTRY,
  payload,
});

export const deleteTimesheetEntry = payload => ({
  type: DELETE_TIMESHEET_ENTRY,
  payload,
});

export const addTimesheetEntry = payload => ({
  type: ADD_TIMESHEET_ENTRY,
  payload,
});

export const setTimesheetEntries = payload => ({
  type: SET_TIMESHEET_ENTRIES,
  payload: sortTimesheetEntries(payload),
});

export const editUnpaidTimesheetEntry = (payload) => {
  var totalMinutes = timeSpanStringToTotalMinutes(payload.UnpaidTime);
  return {
    type: EDIT_UNPAID_TIMESHEET_ENTRY,
    payload: { totalMinutes, ...payload },
  };
};

export const setUnpaidTimesheetEntries = (payload) => {
  var unpaidEntries = payload.map(p => {
    var totalMinutes = timeSpanStringToTotalMinutes(p.UnpaidTime);
    return { totalMinutes, ...p };
  });
  return {
    type: SET_UNPAID_TIMESHEET_ENTRIES,
    payload: unpaidEntries,
  };
};

export const setTimesheets = payload => ({
  type: SET_TIMESHEETS,
  payload,
});

export const setApprovalTimesheet = payload => ({
  type: SET_APPROVAL_TIMESHEET,
  payload,
});


export const setSites = payload => ({
  type: SET_SITES,
  payload,
});


export const setZones = payload => ({
  type: SET_ZONES,
  payload,
});

export const editTimesheet = payload => ({
  type: EDIT_TIMESHEET,
  payload,
});

export const addTimesheetNote = (payload, token) => ({
  type: ADD_TIMESHEET_NOTE,
  payload,
  token,
});

export const setTimesheetNotes = payload => ({
  type: SET_TIMESHEET_NOTES,
  payload,
});

export const setTimesheetRange = payload => ({
  type: SET_TIMESHEET_RANGE,
  payload,
});

export const updateTimesheetReportTable = payload => ({
  type: UPDATE_TIMESHEET_REPORTTABLE,
  payload,
});

export const setReportDate = payload => ({
  type: SET_REPORT_DATE,
  payload,
});

export const setTimesheetStatus = payload => ({
  type: SET_TIMESHEET_STATUS,
  payload,
});

export const createSite = payload => ({
  type: CREATE_SITE,
  payload,
});

export const updateSite = payload => ({
  type: UPDATE_SITE,
  payload,
});

export const setDevices = payload => ({
  type: SET_DEVICES,
  payload,
});

export const createZone = payload => ({
  type: CREATE_ZONE,
  payload,
});

export const updateZone = payload => ({
  type: UPDATE_ZONE,
  payload,
});

export const setLocations = payload => ({
  type: SET_LOCATIONS,
  payload,
});

export const setApiRoot = payload => ({
  type: SET_API_ROOT,
  payload,
});

export const setDeviceVersions = payload => ({
  type: SET_DEVICE_VERSIONS,
  payload,
});

export const setEditingWorkerGroup = payload => ({
  type: SET_EDITING_WORKERGROUP,
  payload,
});

export const setShifts = payload => ({
  type: SET_SHIFTS,
  payload,
});

export const setWorkerShifts = payload => ({
  type: SET_WORKER_SHIFTS,
  payload,
});

export const setScheduleGroupView = payload => ({
  type: SET_SCHEDULE_GROUP_VIEW,
  payload,
});

export const setSchedulePreviousWeek = payload => ({
  type: SET_SCHEDULE_PREVIOUS_WEEK,
  payload,
});

export const setScheduleNextWeek = payload => ({
  type: SET_SCHEDULE_NEXT_WEEK,
  payload,
});

export const setApprovalsWorkerShifts  = payload => ({
  type: SET_APPROVALS_WORKER_SHIFTS,
  payload,
})

export class WorkerActions {  
  static setSelectedDashboardWorker(workerId: number) : PayloadAction<number> {
    return ({type: SET_DASHBOARD_WORKER_ID, payload:workerId});
  };

  static updateWorkersTable(payload: string) : PayloadAction<string> {
    return ({type: UPDATE_WORKERS_TABLE,payload});
  };

  static setWorkers(payload: Array<Worker>) : PayloadAction<Array<Worker>> { 
    return ({type: SET_WORKERS, payload});
  }
  
  static editWorker(payload:string): PayloadAction<string> {
    return  ({type: EDIT_WORKER,payload});
  }

  static createWorker(payload:string): PayloadAction<string> {
    return  ({type: CREATE_WORKER,payload});
  }

}

