import React, { Component } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import localForage from 'localforage';

import MainTopNav from './Components/Nav/MainTopNav';
import NoAuthNavBar from './Components/Nav/NoAuthNavBar';
import Snackbar from './Components/Common/Snackbar';
import {
  getMe, getCompanyName, getCompanyFromToken, getCompany,
} from './API';
import {
  setMe, setAuth, setCompany, setLoading, setPathname, setApiRoot,
} from './redux/actions/AllActions';
import Loading from './Components/Common/Loading';
import Auth from './Components/Auth/Auth';
import { getApiRoot } from './API';
import { isIOS } from 'react-device-detect';



const RoutedNavBar = withRouter(MainTopNav);
const RoutedNoAuthNavBar = withRouter(NoAuthNavBar);

const auth0 = new Auth();

const handleAuthentication = async (history) => {
  if (/access_token|id_token|error/.test(window.location.hash)) {// eslint-disable-line
    const auth = await auth0.handleAuthentication();
    if (auth) {
      /*if(isIOS) {
        // eslint-disable-line
        //history.push('/mobile');
      } else {
        // eslint-disable-line
        history.push('/dashboard');
      }*/
      history.push('/dashboard');
      return true;
    }
  }
  return false;
};


class Container extends Component {
  state = {
    forceOut: false,
  };

  tokenAuth() {
    /* eslint-disable */
    const path = window.location.pathname;
    if(path.split('/')[1] === 'm') {
      return path.split('/')[2];
    } else {
      return false;
    }
    /* eslint-enable */
  }

  componentDidMount() {

    const token = this.tokenAuth();
    if (token) {
      this.handleTokenAuth(token);
    } else {
      this.authorise();
    }
  }

  handleTokenAuth = async (token) => {
    this.props.setCompany({ });
    this.props.setAuth({ token });
    this.props.setMe({});
    this.props.setApiRoot({});
    document.body.removeAttribute("style");
  }

  authorise = async () => {
    let auth = await auth0.isAuthenticated();
    const { idToken, accessToken, expiresAt } = auth;

    if (!idToken) {
      const isAuthenticating = await handleAuthentication(this.props.history);
      if (!isAuthenticating) {
        await auth0.login();
      } else {
        auth = await auth0.isAuthenticated();
        if (!auth.idToken) {
          auth0.login();
          return;
        }
      }
    }
    await this.props.setAuth({ accessToken, expiresAt, idToken });
    await this.handleLoggedIn();
  }

  handleLoggedIn = async () => {
    const { accessToken, expiresAt, idToken } = await auth0.isAuthenticated();
    this.props.setAuth({ accessToken, expiresAt, idToken });
    
    const apiRoot = await getApiRoot();
    this.props.setApiRoot(apiRoot);

    const me = await getMe();
    if (!me && accessToken && idToken) {
      this.setState({ forceOut: true });
      return;
    }
    this.props.setMe(me);
    
    if (me.IsAdministrator || me.IsSupervisor) {
      const company = await getCompany();
      this.props.setCompany(company);
    } else {
      const Name = await getCompanyName();
      this.props.setCompany({ Name });
    }
    
  }

  render() {
    const {
      me, company, auth, children, location, pathname, apiRoot, loading,
    } = this.props;
    const { forceOut } = this.state;
    const admin = !!(me && me.IsAdministrator && me.IsEnabled);
    const { accessToken, token } = auth;
    const isUser = !!(accessToken || token);
    if(!apiRoot || !loading) {
      return <div></div>;
    }
    return (
      <div>
        { !token && (
          <RoutedNavBar
            forceOut={forceOut}
            admin={admin}
            superAdmin={me.IsSuperAdministrator}
            user={me}
            userImg={me ? me.Base64Image : null}
            companyName={company ? company.Name : ''}
            auth={auth0}
            accessToken={accessToken}
            onClick={setPathname}
          />
        ) }
        { token && <RoutedNoAuthNavBar companyName={company.Name} /> }
        { isUser
          && (
          <Grid container style={{ paddingTop: '60px' }} location={location} pathname={pathname}>
            { children }
          </Grid>
          )
        }
        <Loading />
        <Snackbar />
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.me,
  pathname: state.ui.pathname,
  loading: state.configuration.client });

const mapDispatchToProps = dispatch => ({
  setMe: ws => dispatch(setMe(ws)),
  setAuth: ws => dispatch(setAuth(ws)),
  setCompany: ws => dispatch(setCompany(ws)),
  setLoading: ws => dispatch(setLoading(ws)),
  setPathname: ws => dispatch(setPathname(ws)),
  setApiRoot: ws => dispatch(setApiRoot(ws)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
