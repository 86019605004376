import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import { 
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
  Autocomplete,
} from '@material-ui/lab';

import {
  Typography,
  Paper,
  TextField,
  Grid,
  FormControl,
} from '@material-ui/core';
import { getEventItemsByWorkerAndDate, getDeviceEventItemsByWorkerAndDate } from '../../API';
import RawDeviceLogs from './RawDeviceLogs';

//sosreport
import WarningIcon from '@material-ui/icons/Warning';
//lowbattery
import Battery20Icon from '@material-ui/icons/Battery20';
//batterypoweroff
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
//CHARGINGSTART
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';
//CHARGINGFULL
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
//POWERONUNDERPOWERSAVINGMODE
import WbSunnyIcon from '@material-ui/icons/WbSunny';
//POWEROffUNDERPOWERSAVINGMODE
import NightsStayIcon from '@material-ui/icons/NightsStay';
//offsite
//import CommuteIcon from '@material-ui/icons/Commute';
//import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
//import TrainIcon from '@material-ui/icons/Train';
import LocationOffIcon from '@material-ui/icons/LocationOff';
//onsite
//import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TuneIcon from '@material-ui/icons/Tune';
//import LocationCityIcon from '@material-ui/icons/LocationCity';
//import BuildIcon from '@material-ui/icons/Build';


interface eventItem {
  SiteName: string;
  WorkerName: string;
  EventType: string;
  EventTime: string;
  CreationDateTime: string;
  CreatedAtUtc: string;
  Description: string;
}

const eventTypeOnsite = 'ONSITE';
const eventTypeNoLocation = 'UNKNOWN';
const eventTypeOffsite = 'OFFSITE';
const eventTypeSosReport = 'SOSREPORT';
const eventTypeLowBattery = 'LOWBATTERY';
const eventTypeBatteryPowerOff = 'BATTERYPOWEROFF';
const eventTypeChargingStart = 'CHARGINGSTART';
const eventTypeChargingFull = 'CHARGINGFULL';
const eventTypePowerOnSleep = 'POWERONUNDERPOWERSAVINGMODE';
const eventTypePowerOffSleep = 'POWEROFFUNDERPOWERSAVINGMODE';
const eventTypeModeChangeSent = "MODECHANGESENT";
const eventTypeModeSuccessReceived = "MODESUCCESSRECEIVED";
const eventTypeModeFailReceived = "MODEFAILRECEIVED";


const eventTypeDescriptions = {
  [eventTypeOnsite]: 'On Site',
  [eventTypeOffsite]: 'Off Site',
  [eventTypeSosReport]: 'Button Pressed',
  [eventTypeLowBattery]: 'Low Battery',
  [eventTypeBatteryPowerOff]: 'Flat Battery',
  [eventTypeChargingStart]: 'Charging Start',
  [eventTypeChargingFull]: 'Charging Full',
  [eventTypePowerOnSleep]: 'Power On From Sleep Mode',
  [eventTypePowerOffSleep]: 'Power Off To Sleep Mode',
  [eventTypeNoLocation]: 'Location Not Available',
  [eventTypeModeChangeSent]: 'Config Change Sent',
  [eventTypeModeSuccessReceived]: 'Config Change Success Received',
  [eventTypeModeFailReceived]: 'Config Change Fail Received',
};

const eventTypeIcons = {
  [eventTypeOnsite]: <BusinessIcon />,
  [eventTypeOffsite]: <FlightTakeoffIcon />,
  [eventTypeSosReport]: <WarningIcon />,
  [eventTypeLowBattery]: <Battery20Icon />,
  [eventTypeBatteryPowerOff]: <BatteryAlertIcon />,
  [eventTypeChargingStart]: <BatteryCharging20Icon />,
  [eventTypeChargingFull]: <BatteryChargingFullIcon />,
  [eventTypePowerOnSleep]: <WbSunnyIcon />,
  [eventTypePowerOffSleep]: <NightsStayIcon />,
  [eventTypeNoLocation]: <LocationOffIcon />,
  [eventTypeModeChangeSent]: <TuneIcon />,
  [eventTypeModeSuccessReceived]: <ThumbUpIcon />,
  [eventTypeModeFailReceived]: <ThumbDownIcon />,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));


function EventItemRender(props) {
  const item:eventItem = props.item;
  const displayDate = item.EventTime;
  const displayCreationDate = item.CreationDateTime;
  const displayOnsiteOffsiteCreatedDate = item.CreatedAtUtc;
  const icon = eventTypeIcons[item.EventType];
  const eventTypeDescription = eventTypeDescriptions[item.EventType];
  return (
    <TimelineItem key={item.EventTime + item.EventTime}>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">
          { displayDate }
        </Typography>
        <Typography variant="caption" color="textSecondary">
          { displayCreationDate }
          { displayOnsiteOffsiteCreatedDate }
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot>
          { icon }
        </TimelineDot>
        <TimelineConnector className={props.classes.secondaryTail} />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={props.classes.paper}>
          <Typography variant="h6" component="h6">
            { eventTypeDescription }
          </Typography>
          <Typography>{ item.SiteName }</Typography>
          <Typography>{ item.Description }</Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  )
}


function WorkerTimelineForm(props) {
  const { workers, classes, handleDateChange, handleEndDateChange, startDate, endDate, handleShowHide, showhide, showLogs, onLogsChange } = props;
  return (
      <Grid item xs={12} style={{ paddingTop: '15px', paddingRight: '5px' }}>
        <input type="checkbox" onChange={handleShowHide} checked={showhide} /> show device events
        <input type="checkbox" onChange={onLogsChange} checked={showLogs} /> load all device logs (lots)
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            type="date"
            label="Timesheet Start"
            value={`${startDate}`}
            className={classes.textField}
            onChange={(evt) => handleDateChange(evt.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            type="date"
            label="Timesheet End"
            value={`${endDate}`}
            className={classes.textField}
            onChange={(evt) => handleEndDateChange(evt.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <Autocomplete
          id="workertimelineautocompletge"                  
          options={ workers.map(w => ({ key: w.WorkerId, value: w.Name })) }
          getOptionLabel={(o: any) => `${o.value}` }
          getOptionSelected={(option: any, value: any) => option.key === value.key}
          renderInput={(params) => <TextField {...params} name="name" label="" required/>} 
          defaultValue={ "" }
          onChange={props.onChange}
        />
      </Grid>
    
  );
}

export function EventItems({ items, classes }) {
  return <React.Fragment>
    { items.map((item, index) => <EventItemRender 
        item={item} 
        classes={classes} 
        key={index} 
      />) }
  </React.Fragment>
}

export function EventsList({ items, classes }) {
  return <Timeline align="right" style={{maxWidth: '800px' }}>
      <EventItems classes={classes} items={items} />
    </Timeline>
}

export default function EventTimeline(props) {
  const classes = useStyles();
  var day = null;
  const [ selectedWorkerId, setSelectedWorkerId] = useState(props.workers[0]);
  const [ eventItems, setEventItems ] = useState([]);
  const [ showDeviceEvents, setShowDeviceEvents] = useState(true);
  const [ deviceEventItems, setDeviceEventItems ] = useState([]);
  const [ startDate, setStartDate ] = useState(moment().format('yyyy-MM-DD'));
  const [ endDate, setEndDate ] = useState(moment().add('days', 1).format('yyyy-MM-DD'));
  const [ showLogs, setShowLogs ] = useState(false);

  const onWorkerChange = (_, opt) => {
    if(!opt) {
      return;
    }
    setSelectedWorkerId(opt.key);
    
    getEventItemsByWorkerAndDate(opt.key, 
      moment(startDate).format('yyyy-MM-DD HH:MM:SS'), moment(endDate).format('yyyy-MM-DD HH:MM:SS')).then(
      evts =>  {
        setEventItems(evts);
      });

    getDeviceEventItemsByWorkerAndDate(opt.key, 
      moment(startDate).format('yyyy-MM-DD HH:MM:SS'), moment(endDate).format('yyyy-MM-DD HH:MM:SS')).then(
      evts =>  {
        setDeviceEventItems(evts);
    });
  }

  const onShowHideChange = (evt) => {
    setShowDeviceEvents(evt.target.checked);
  }

  const onLogsChange = (evt) => {
    setShowLogs(evt.target.checked);
  }

  let allEvents = [...eventItems];
  if(showDeviceEvents) {
    allEvents = eventItems.concat(deviceEventItems);
  }
  allEvents.sort((a, b) => {
    var ad:any = new Date(a.EventTime);
    var bd:any = new Date(b.EventTime);
    return bd - ad;
  }).reverse();

  return (
    <Grid item xs={12} style={{height: '100%', minHeight: '70vh'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WorkerTimelineForm 
            workers={props.workers} 
            onChange={onWorkerChange} 
            handleDateChange={setStartDate} 
            handleEndDateChange={setEndDate}
            endDate={endDate}
            startDate={startDate}
            classes={classes}
            handleShowHide={onShowHideChange}
            onLogsChange={onLogsChange}
            showhide={showDeviceEvents}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{width: '50%', float: 'left'}}>
            <EventsList items={allEvents} classes={classes} />`
          </div>
          <div style={{width: '50%', float: 'right', overflowX: 'auto'}}>
            {showLogs && <RawDeviceLogs endDate={endDate} startDate={startDate} workerId={selectedWorkerId} /> }
          </div>
        </Grid>
      </Grid>
    </Grid>

  )
}
