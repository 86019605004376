import RootReducer from './rootReducer';
import {apiMiddleware} from '../ApiMiddleware';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { middleware as reduxPackMiddleware } from 'redux-pack';
import createLogger from 'redux-logger'
import { RestClientActions } from '../actions/ConfigurationActions'

const store = createStore(RootReducer, applyMiddleware(thunk, reduxPackMiddleware, apiMiddleware /*, createLogger */));

// window.store = store;
export default store;