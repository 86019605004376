/* eslint-disable no-unused-vars */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/named
import { TimesheetTableClass, getLabels, getOptions } from '../Approvals/TimesheetTable';
import MyTimesheetForm from './MyTimesheetForm';

const emptyness = () => {};
const tableStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  minHeight: '100%',
  height: '100%',
};

export class TimesheetsTable extends TimesheetTableClass {
  getOptions = () => {
    const { timesheet, worker, token } = this.props;
    const form = (
      <MyTimesheetForm
        key="approvals-form-l"
        token={token}
        timesheet={timesheet}
      />
    );
    return { ...getOptions(form, emptyness, timesheet, worker), responsive: 'scroll' };
  }

  getLabels = () => getLabels(emptyness, emptyness, emptyness).slice(0, 5)
}

const mapStateToProps = (state, ownProps) => {
  const {
    me,
    workers,
  } = state;
  const { WorkingDayDefaultStart, WorkingDayDefaultFinish } = me.company;
  const worker = workers.all.find(w => w.WorkerId === ownProps.timesheet.WorkerId);
  return {
    me: worker,
    company: me.company,
    worker,
    style: tableStyle,
    rows: ownProps.timesheet.getMyTimesheetTableRows(
      WorkingDayDefaultStart, WorkingDayDefaultFinish),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles({}, { withTheme: true })(TimesheetsTable)));
