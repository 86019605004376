import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid, Switch, FormControl, FormControlLabel, FormGroup,
} from '@material-ui/core';
import wkt from 'terraformer-wkt-parser';
import * as turf from '@turf/turf';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { assign } from '../../Utils';
import styles from '../Common/FormStyles';
import {
  createZone,
  updateZone,
} from '../../redux/actions/AllActions';


class ZoneForm extends Component {
  state = {
    Name: '',
    checked: undefined,
  }

  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  // eslint-disable-next-line no-unused-vars
  handleCheckboxChange = ({ target }, checked) => {
    this.setState({ checked });
  };

  handleSaveClick = () => {
    const { Name, checked, ZonePayrollId } = this.state;
    const { zone, polygon } = this.props;
    const p = polygon || wkt.convert(zone.GeoJSON.geometry);
    const updatedZone = {
      Name: Name || zone.Name,
      Active: checked === undefined ? zone.Active : checked,
      WKT: p,
      GeoJSON: turf.feature(wkt.parse(p)),
      ZonePayrollId: ZonePayrollId || zone.ZonePayrollId,
    };
    const toSave = assign(zone, updatedZone);
    if (toSave.ZoneId) {
      this.props.updateZone(toSave);
    } else {
      this.props.createZone(assign(toSave, { ZoneId: `${Math.random()}` }));
    }
    this.props.onCancelClick();
  }

  isValid = () => {
    const { Name } = this.state;
    const { zone, polygon } = this.props;
    const { GeoJSON } = zone;
    return !!((Name || zone.Name) && (polygon || GeoJSON));
  }

  render() {
    const {
      classes, onCancelClick, zone,
    } = this.props;
    const {
      Name, checked, ZonePayrollId,
    } = this.state;

    return (
      <div>
        <ValidatorForm
          className={classes.container}
          autoComplete="off"
          onSubmit={() => {}}
        >
          <Grid container>
            <FormControl className={classes.formControl} fullWidth>
              <TextValidator
                id="zone-name-input"
                name="Name"
                label="Zone Name"
                className={classes.textField}
                value={Name || zone.Name}
                onChange={this.handleChange}
                error={!(Name || zone.Name)}
                helperText="required"
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextValidator
                id="zone-name-input"
                name="ZonePayrollId"
                label="Zone ID"
                className={classes.textField}
                value={ZonePayrollId || zone.ZonePayrollId}
                onChange={this.handleChange}
                error={false}
                helperText="required"
              />
            </FormControl>
            {/* <FormControl className={classes.formControl} fullWidth>
              <TextField
                id="Notes"
                label="Notes"
                multiline
                rowsMax="4"
                name="notes"
                value={this.state.notes}
                onChange={this.handleChange}
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
            </FormControl> */}
            <FormGroup row className={classes.formControl}>
              <FormControlLabel
                classes={{ label: classes.switchLabel }}
                control={(
                  <Switch
                    id="zone-active-switch"
                    name="Active"
                    checked={checked === undefined ? zone.Active : checked}
                    onChange={this.handleCheckboxChange}
                  />
                )}
                label="Active"
              />
            </FormGroup>
          </Grid>
        </ValidatorForm>
        <FormGroup row className={classes.formControl}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                type="Button"
                variant="contained"
                color="secondary"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="Button"
                variant="contained"
                color="primary"
                disabled={!this.isValid()}
                onClick={this.handleSaveClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </div>
    );
  }
}

ZoneForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = _state => ({});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch, _ownProps) => ({
  createZone: x => dispatch(createZone(x)),
  updateZone: x => dispatch(updateZone(x)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ZoneForm)));
