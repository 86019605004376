// @ts-nocheck

/* eslint-disable no-undef */
import moment from 'moment';
import fixRotation from 'fix-image-rotation';
import momentDurationFormatSetup from 'moment-duration-format';
import { extendMoment } from 'moment-range';


/* tslint:disable-next-line */
momentDurationFormatSetup(moment);
/* tslint:disable-next-line */
extendMoment(moment);


export function replaceInArray(array, newItem, findKey) {
  const newArr = [...array];
  // I wan't types not to matter here
  // eslint-disable-next-line eqeqeq
  const index = newArr.findIndex(x => x[findKey] == newItem[findKey]);
  newArr[index] = newItem;
  return newArr;
}

export function formatUtcDateAsNZTime(dt, fmt) {
  return moment.tz(dt, 'Pacific/Auckland').format(fmt)
}

export function utcToNZT(dt) {
  return moment.tz(dt, 'Pacific/Auckland');
}

export function isoDateStringToDayFormat(isostring) {
  return moment(isostring).format('YYYY-MM-DD');
}

export function removeLeadingZero(str = '') {
  if (str.length > 1 && str[0] === '0') {
    return str.slice(1, str.length);
  }
  return str;
}

export function removeFromArray(array, toRemove, findKey) {
  const newArr = [...array];
  // I wan't types not to matter here
  // eslint-disable-next-line eqeqeq
  const index = newArr.findIndex(x => x[findKey] == toRemove[findKey]);
  newArr.splice(index, 1);
  return newArr;
}

export function assign(oldpairs, newpairs) {
  return Object.assign({}, oldpairs, newpairs);
}

export function assignWithTimestamp(oldpairs, newpairs) {
  return Object.assign({}, oldpairs, newpairs, { timestamp: new Date().getTime() });
}

export function weekOfDates(startDate, finishDate) {
  const numberOfDays = Math.abs(moment(startDate).diff(moment(finishDate), 'days'));
  return Array.from({ length: numberOfDays },
    (x, i) => i).map(i => moment(startDate).add(i, 'days').toDate());
}

// timeStr like HH:mm:ss or HH:mm becomes HH:mm
export function trimTimeStr(timeStr) {
  return timeStr.split(':').slice(0, 2).join(':');
}

export function dynamicSort(property) {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    // eslint-disable-next-line no-param-reassign
    property = property.substr(1);
  }

  // eslint-disable-next-line func-names
  return function (a, b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    }
    return a[property].localeCompare(b[property]);
  };
}

export function areSameDay(d1, d2) {
  const d1m = moment(d1);
  const d2m = moment(d2);
  return d1m.year() === d2m.year() && d1m.dayOfYear() === d2m.dayOfYear();
}

export function uniqInArray(arrArg) {
  return arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
}

export function formatDuration(hours = 0, mins = 0) {
  const totalMins = (hours * 60) + mins;
  const str = moment.duration(totalMins, 'minutes').format('HH:mm');
  if (totalMins < 60) {
    return `0:${str}`;
  }
  return str;
}

export function formatTimeDiff(start, end) {
  return formatDuration(0, moment.duration(end.diff(start)).asMinutes());
}

export function setTimeFromString(date, timeStr) {
  const nums = timeStr.split(':').map(n => parseInt(n, 0));
  const momentDate = moment(date);
  momentDate.hours(nums[0]);
  momentDate.minutes(nums[1]);
  momentDate.seconds(nums[2]);
  return momentDate;
}

export function dateRangeOverlaps(astart, aend, bstart, bend) {
  const rangeA = moment.range(astart, aend);
  const rangeB = moment.range(bstart, bend);
  return rangeB.overlaps(rangeA) || rangeA.overlaps(rangeB);
}

export function downloadCSVContent(content) {
  // eslint-disable-next-line no-undef
  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,${content}`);
  link.setAttribute('download', 'my_data.csv');
  // eslint-disable-next-line no-undef
  document.body.appendChild(link); // Required for FF
  link.click(); // This will download the data file named "my_data.csv".
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export async function fixImageRotaionUsingExif(file) {
  const fixedBlob = await fixRotation.fixRotation([file]);
  return fixedBlob[0];
}

export async function base64FromFile(blob) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export async function textFromFile(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = e => reject(e);
  });
}

export function jsonFromCsv(csvAsText) {
  const rows = csvAsText.split('\n');
  const attrs = rows[0].split(',');
  return rows.slice(1, rows.length).map((r) => {
    const item = {};
    const vals = r.split(',');
    attrs.forEach((a, i) => {
      item[a.trim()] = (vals[i] || '').trim();
    });
    return item;
  });
}

export function base64ToDataUri(base64, imgext) {
  return `data:image/${imgext};base64,${base64}`;
}

export function resizeB64Image(base64, width, height, ext = 'jpeg', quality = 1) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const img = new Image();
    // eslint-disable-next-line no-use-before-define
    img.onload = () => resolve(imageToSizedDataUri(img, width, height, ext, quality));
    img.onerror = reject;
    img.src = base64;
  });
}

export function imageToSizedDataUri(img, width, height, ext, quality) {
  // create an off-screen canvas
  // eslint-disable-next-line no-undef
  const canvas = document.createElement('canvas');


  const ctx = canvas.getContext('2d');

  // set its dimension to target size
  canvas.width = width;
  canvas.height = height;

  // draw source image into the off-screen canvas:
  ctx.drawImage(img, 0, 0, width, height);

  // encode image to data-uri with base64 version of compressed image
  return canvas.toDataURL(`image/${ext}`, quality);
}

export function sortTimesheetEntries(entries) {
  if(!entries)
  {
    return [];
  }
  entries.sort((a, b) => {
    if (moment(a.StartDateTime).toDate() < moment(b.StartDateTime).toDate()) {
      return -1;
    }
    return 1;
  });
  return entries;
}

export function combineTimespansToTotal(timespanStrs) {
  let totalHrs = 0;
  let totalMins = 0;
  timespanStrs.forEach((ts) => {
    const parts = ts.split(':');
    const hours = parseInt(parts[0], 0);
    const mins = parseInt(parts[1], 0);
    totalHrs += hours;
    totalMins += mins;
  });
  return formatDuration(totalHrs, totalMins);
}

export function validateMobile(value) {
  return value && /[0-9]{7,}/.test(value);
}

export function validateEmail(value) {
  return value && /(.+)@(.+){2,}\.(.+){2,}/.test(value);
}

export function downloadText(filename, data) {
  const blob = new Blob([data], { type: 'text/csv' });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

export function randomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function parseUrlQueryParameters(prop, name) {
  const { location } = prop;
  if(!location){
    return undefined;
  }

  const query = location.search || "";
  const queryTubles = query
    .substr(1)
    .split("&")
    .map(x => x.split("="))
    .map(x => ({key:x[0], value:x[1] }));

  const tupple = queryTubles.find(x => name === x.key);

  return tupple ? decodeURIComponent(tupple.value) : undefined;
}

export function ISODateTimeOffsetToDisplayDate(date) {
  if(!date){
    return undefined;
  }

  const utcDate = moment.parseZone(date).utc();
  const result = utcDate.local(false).format("YYYY-MM-DDTHH:mm:ss");
  return result;
}

export function IsoStringIsBeforeIsoString(dt1, dt2) {
  return moment(dt1).isBefore(moment(dt2));
}

export function timeSpanStringToTotalMinutes(timespanStr) {
  const [hours, mins, seconds] = timespanStr.split(':').map((num) => parseInt(num));
  return Math.round((hours * 60) + mins + (seconds / 60));
}

export function caseStatusColor(status) {
  switch(status) {
    case 'Suspect':
    case 'Confirmed':
    case 'Probable':
      return { 
        backgroundColor: 'rgba(232, 46, 105, 0.2)',
        color: '#d10026',
      }
    case 'UnderInvestigation':
      return {
        backgroundColor: 'rgba(252, 186, 3, 0.2)',
        color: 'rgba(252, 186, 3, 1)',
      }
    default:
      return {
        backgroundColor: 'rgba(62, 171, 50, 0.2)',
        color: 'rgba(62, 171, 50, 1)',
      }
  };
};

export function getCurrentWeek() {
  var currentDate = moment();

  var weekStart = currentDate.clone().startOf('isoWeek');
  var days = [];
  for (var i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days'));
  }
  days[6].hour(23).minute(59).second(59);
  return days;
}

export function getNextWeek(currentWeek) {
  return currentWeek.map(c => c.add(7, 'days'));
}

export function getPreviousWeek(currentWeek) {
  return currentWeek.map(c => c.add(-7, 'days'));
}

export function isNowBetweenMoments(start, end) {
  const now = moment();
  return now.isBetween(start, end);
}

export function isNowDuringOrBeforePeriod(start, end) {
  const now = moment();
  return now.isBetween(start, end) || now.isBefore(end);
}

export function arrayUniqueByKey(array, key) {
  return [...new Map(array.map(item => [item[key], item])).values()];
}

export function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function selectColor(i, total) {
  if (total < 1) total = 1; // defaults to one color - avoid divide by zero
  return {h: ((i + Math.random()) * (360 / total) % 360), s:100, l:50 };
}





