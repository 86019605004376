/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import ReactMapboxGl, { GeoJSONLayer } from 'react-mapbox-gl';
import ErrorBoundary from 'react-error-boundary';
import wkt from 'terraformer-wkt-parser';
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { MAPBOX_TOKEN, SITES_MAP_URI, SITE_FILL_STYLE, ZONE_FILL_STYLE } from '../../Constants/Constants';
// Don't forget to import the CSS
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';


const Map = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN,
});

class UpdateSiteMap extends Component {
  state = {
    createFeature: null,
    bbox: undefined,
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSite = prevProps.newSite || prevProps.editingSite;
    const newSite = this.props.newSite || this.props.editingSite;
    
    if (this.draw && (!newSite || (prevSite && (newSite.SiteId !== prevSite.SiteId)))) {
      this.deleteDrawFeatures();
    }

    if(this.props.editingSite && !prevProps.editingSite) {
      this.setState({ bbox: undefined });
      this.draw.add(JSON.parse(JSON.stringify(this.props.editingSite.GeoJSON)));
    }

    if (prevState.createFeature) {
      // I know its bad but I'm doing it anyways cos it works
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ createFeature: null });
    }
  }

  shouldComponentUpdate() {
    return true;
  }

  deleteDrawFeatures = () => {
    const { features } = this.draw.getAll();
    features.forEach(f => this.draw.delete(f.id));
  }

  onDrawModeChange = (e) => {
    console.log(e);
    if(e.mode == 'draw_polygon'){
      if(this.props.editingSite) {
        const { features } = this.draw.getAll();
        const toDelete = features.filter(x => !!x.properties.SiteId);
        toDelete.forEach(x => this.draw.delete(x.id));
      }
    }
  }

  addDraw = (map) => {
    if (map._controls.length < 3) {
      this.map = map;
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      });

      map.addControl(this.draw);
      map.on('draw.create', this.drawCreate);
      map.on('draw.delete', this.drawDelete);
      map.on('draw.update', this.drawUpdate);
      map.on('draw.modechange', (e) => this.onDrawModeChange(e));
    }
  }

  drawCreate = (e) => {
    const { features } = this.draw.getAll();
    const [lastFeature] = features.slice(-1);
    if (features.length > 1) {
      this.draw.delete(features[0].id);
    }
    // do not do this unless we have a site to edit
    this.props.onDrawCreate(
      wkt.convert(lastFeature.geometry));
    this.setState({ createFeature: wkt.convert(lastFeature.geometry) });
  }

  drawDelete = (e) => {
    this.props.onDrawDelete();
    this.setState({ createFeature: null });
  }

  drawUpdate = (e) => {
    const { features } = this.draw.getAll();
    const feature = features[0];
    this.props.onDrawUpdate(wkt.convert(feature.geometry));
    this.setState({ createFeature: wkt.convert(feature.geometry) });
  }

  getSiteFeatureCollection = () => {
    const { sites, editingSite, newSite } = this.props;

    const { createFeature } = this.state;
    if (createFeature) {
      // const featts = turf.buffer(turf.feature(wkt.parse(createFeature)), 50, { units: 'metres' });
      const featts = turf.feature(wkt.parse(createFeature));
      return turf.featureCollection([featts]);
    }

    const allSites = (editingSite && !newSite) ? [editingSite] : sites.filter(
      s => !!(s.GeoJSON || s.Geom),
    );

    const feats = allSites.map((b) => {
      const g = b.GeoJSON || turf.feature(wkt.parse(b.Geom.Geography.WellKnownText));
      const geom = g;
      geom.properties = { SiteId: b.SiteId };
      return geom;
    });
    return turf.featureCollection(feats);
  }

  getBounds = () => {
    const { sites } = this.props;

    if (sites.length > 0) {
      const bbox = turf.bbox(
        this.getSiteFeatureCollection());
      return [[bbox[0], bbox[1]], [bbox[2], bbox[3]]];
    }
    // nz bbox
    return [[180.4833, -34.05265942137597],
      [163.65234, -47.57652]];
  }

  getBBoxPadding = () => {
    const { sites } = this.props;
    if (sites.length > 1) {
      return 200;
    }
    // nz bbox
    return 10;
  }

  handleStyleLoad = (map) => {
    map.resize();
  }

  handleMapClick = (map, e) => {
    const features = map.queryRenderedFeatures(e.point);
    if (features.length) {
      this.props.onMapClick(features[0].properties.SiteId);
    }
  }

  handleZoom = (map, e) => {
    !this.state.bbox && this.setState({ bbox: true });
  }

  handleMouseMove = (map, e) => {
    const features = map.queryRenderedFeatures(e.point);
    // eslint-disable-next-line no-undef
    const mapelem = document.getElementsByClassName('mapboxgl-canvas')[0];
    if (features.find(f => f.source === 'geojase')) {
      mapelem.style.cursor = 'pointer';
    } else {
      mapelem.style.cursor = 'grab';
    }
  }

  render() {
    const { newSite, editingSite, zones } = this.props;
    const enableClick = !(newSite || editingSite);
    const bbox = this.getBounds();
    return (
      <div style={{ width: 'calc(100vw - 320px)' }}>
        <ErrorBoundary>
          <Map
            onError={e => console.warn(e)}
            style={this.props.baseMapUri}
            fitBounds={!this.state.bbox && bbox}
            fitBoundsOptions={{ padding: this.getBBoxPadding() }}
            onZoomEnd={this.handleZoom}
            onMove={this.handleMove}
            onRender={this.addDraw}
            onStyleLoad={this.handleStyleLoad}
            containerStyle={{
              height: 'calc(100vh - 60px)',
              width: '100%',
            }}
            onClick={enableClick && this.handleMapClick}
            onMouseMove={this.handleMouseMove}
            onMouseOut={this.handleMouseMove}
            onMouseEnter={this.handleMouseMove}
          >

            <GeoJSONLayer
              id="geojase"
              data={this.getSiteFeatureCollection()}
              fillPaint={SITE_FILL_STYLE}
            />
          </Map>
        </ErrorBoundary>
      </div>
    );
  }
}


export default UpdateSiteMap;
