import moment from 'moment';
import BaseModelList from './BaseModelList';
import TimesheetEntry from './TimesheetEntry';
import SiteList from './SiteList';
import { formatDuration, uniqInArray, areSameDay } from '../Utils';


export default class TimesheetEntryList extends BaseModelList {
  constructor(objects) {
    super(objects, 'TimesheetEntryId', TimesheetEntry);
  }

  unpaidTimesheetEntries = [];

  // TODO REMOVE THE DELETED FLAG COMPLETELY
  getLiveEntries = () => this.objects.filter(o => !o.deleted)

  setUnpaidEntries = (unpaidTimesheetEntries) => {
    this.unpaidTimesheetEntries = unpaidTimesheetEntries;
  }

  setSiteList = (sites) => {
    this.siteList = new SiteList(sites.map(
      ({ SiteId, Name }) => ({ SiteId, Name })));
  }

  getTotalTimeStr = () => {
    const objects = this.getLiveEntries();
    if (!objects.length) {
      return '';
    }
    return this.formatTotalTimeStr(objects);
  }

  formatStartTimeStr = entries => (entries.length ? entries[0].mStartDateTime.format('HH:mm') : '');

  formatEndTimeStr = entries => (entries.length ? entries[entries.length - 1].mFinishDateTime.format('HH:mm') : '');

  getSiteDescription = () => {
    const objects = this.getLiveEntries();
    const siteIds = uniqInArray(objects.map(e => e.SiteId));

    const descriptions = siteIds.map((id) => {
      const matchingEntries = objects.filter(e => e.SiteId === id);
      const site = this.siteList.findById(id);
      const siteName = site ? site.Name : 'Unknown';
      return `${siteName} (${this.formatTotalTimeStr(matchingEntries)})`;
    });

    return descriptions.join(', ');
  }

  // renders the total time of a group of entries minus the total time of 
  // corresponding unpaid time entries
  formatTotalTimeStr(entries) {
    let totalMins = entries.reduce((total, { mFinishDateTime, mStartDateTime }) => {
      const diff = moment.duration(mFinishDateTime.diff(mStartDateTime)).asMinutes();
      return total + diff;
    }, 0);

    let unpaidMins = 0;
    const days = uniqInArray(entries.map(e => moment(e.StartDateTime).day()));
    const matchingUnpaidEntries = this.unpaidTimesheetEntries.filter(e => days.includes(moment(e.DateTime).day()));
    matchingUnpaidEntries.forEach(p => unpaidMins += p.totalMinutes);
    if(totalMins < unpaidMins) {
      return '0:00';
    }
    return `${formatDuration(0, totalMins - unpaidMins)}`;
  }

  formatTotalBreakStr(entries) {
    const totalMins = entries.reduce((total, { mFinishDateTime, mStartDateTime }) => {
      const diff = moment.duration(mFinishDateTime.diff(mStartDateTime)).asMinutes();
      return total + diff;
    }, 0);
    let unpaidMins = 0;
    const days = uniqInArray(entries.map(e => moment(e.StartDateTime).day()));
    const matchingUnpaidEntries = this.unpaidTimesheetEntries.filter(e => days.includes(moment(e.DateTime).day()));
    matchingUnpaidEntries.forEach(p => unpaidMins += p.totalMinutes);
    if(totalMins < unpaidMins) {
      return '0:00';
    }
    return `${formatDuration(0, unpaidMins)}`;
  }

  getEntriesForPost = () => {
    const entriesToSend = this.getLiveEntries().map((tse) => {
      const newDates = {
        StartDateTime: tse.mStartDateTime.format('YYYY-MM-DD HH:mm'),
        FinishDateTime: tse.mFinishDateTime.format('YYYY-MM-DD HH:mm'),
      };
      let toSend = { ...tse, ...newDates };
      if (toSend.isNew) {
        toSend = Object.assign(toSend, { TimesheetEntryId: 0 });
      }
      // TODO REMOVE ALL ISNEW AND DELETED LOGIC
      delete toSend.isNew;
      delete toSend.deleted;
      delete toSend.mFinishDateTime;
      delete toSend.mFinishDateTime;
      return toSend;
    });
    return entriesToSend;
  }

  getEntriesByDay = day => this.objects.filter(tse => areSameDay(moment(day), tse.StartDateTime))
}
