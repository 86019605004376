
const stylesStr = `
#wrapper {
  width: 1000px;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: #fff;
}

body {
  padding: 0;
  font-size: 100%;
}

html, body {
  width: 1000px;
  margin:0;
  padding:0;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.3rem;
}

table {
  font-size: 11px;
  width: 1000px;
  border: 2px solid #000;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-size: 12px;
}

.left {
  margin-right: left;
}
.right {
  text-align: right;
}

tr {
  
}

td {
  border: 1px solid #000;
  padding: 2px;
  font-weight: 100;
}

th {
  border: 1px solid #000;
  background-color: #eee;
  font-weight: 800;
}

div {
  background-color: #FFF;
}

.main {
  margin-top: 20px;
  margin-left: 20px;
}

small {
  color: #999;
}

.pad-left {
  padding-left: 25px;
}

`;

function formatContactRow({ Name, Mobile, Email, PayrollId, supervisorName,
   supervisorMobile, zoneNames, siteNames }) {
  return `
  <tr class="inner-row">
    <td>${Name}</td>
    <td>${PayrollId}</td>
    <td>${Mobile}</td>
    <td>${Email}</td>
    <td>${supervisorName}</td>
    <td>${supervisorMobile}</td>
    <td>${siteNames} ${zoneNames}</td>
  </tr>
  `;
}

// eslint-disable-next-line import/prefer-default-export
export function makeContactTraceHTML(values, dataUrl) {
  const {
    fromDate,
    toDate,
    printDate,
    covidCaseStatus,
    firstSymptomDate,
    payrollId,
    mobile,
    name,
    email,
    contacts,
    supervisorName,
    superVisorMobile,
  } = values;
  
  const rows = contacts.map(formatContactRow).join('');
  const html = `
    <html>
      <head>
      <style>
        ${stylesStr}
      </style>
      </head>
      </head>
      <body>
      <div id="wrapper">
        <div id="header">
          <div>
            <div class="left" style="width: 50%;">
              <div class="pad-left">COVID-19 Contact Report</div>
            </div>
            <div class="right">
              <div class="pad-right"><small>Printed ${printDate}</small></div>
            </div>
          </div>
          <div>
            <div class="left">
              <div class="pad-left"><b>From:</b> ${fromDate}</div>
              <div class="pad-left"><b>To:</b> ${toDate}</div>
            </div>
            <div class="right">
              <div class="pad-right"><b>First Symptoms Date</div>
              <div class="pad-right"><small>${firstSymptomDate}</small></div>
            </div>
          </div>
        </div>

        <div class="left main">
          <table id="main-table">
            <thead>
              <th>Case Name</th>
              <th>Payroll ID</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Supervisor</th>
              <th>Supervisors Phone</th>
              <th>Covid-19 Status</th>
            </thead>
            <tbody>
              <tr class="outer-row">
                <td>${name}</td>
                <td>${payrollId}</td>
                <td>${mobile}</td>
                <td>${email}</td>
                <td>${supervisorName}</td>
                <td>${superVisorMobile}</td>
                <td>${covidCaseStatus}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="left main">
          <table id="main-table">
            <thead>
              <th>Contact Name</th>
              <th>Payroll ID</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Supervisor</th>
              <th>Supervisors Phone</th>
              <th>Sites & Zones in Contact</th>
            </thead>
            <tbody>
            ${rows}
            </tbody>
          </table>
        </div>
    </div>
  </body>
</html>
  `;
return html;
}
