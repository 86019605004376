export const EDIT_UNPAID_TIMESHEET_ENTRY = 'EDIT_UNPAID_TIMESHEET_ENTRY';
export const SET_UNPAID_TIMESHEET_ENTRIES = 'SET_UNPAID_TIMESHEET_ENTRIES';
export const EDIT_TIMESHEET_ENTRY = 'EDIT_TIMESHEET_ENTRY';
export const DELETE_TIMESHEET_ENTRY = 'DELETE_TIMESHEET_ENTRY';
export const SET_TIMESHEET_ENTRIES = 'SET_TIMESHEET_ENTRIES';
export const ADD_TIMESHEET_ENTRY = 'ADD_TIMESHEET_ENTRY';
export const SET_WORKERS = 'SET_WORKERS';
export const SET_TIMESHEETS = 'SET_TIMESHEETS';
export const SET_APPROVAL_TIMESHEET = 'SET_APPROVAL_TIMESHEET';
export const EDIT_TIMESHEET = 'EDIT_TIMESHEET';
export const ADD_TIMESHEET_NOTE = 'ADD_TIMESHEET_NOTE';
export const SET_TIMESHEET_NOTES = 'SET_TIMESHEET_NOTES';
export const SET_TIMESHEET_RANGE = 'SET_TIMESHEET_RANGE';
export const SET_REPORT_DATE = 'SET_REPORT_DATE';
export const UPDATE_TIMESHEET_REPORTTABLE = 'UPDATE_TIMESHEET_REPORTTABLE';
export const SET_TIMESHEET_STATUS = 'SET_TIMESHEET_STATUS';
export const UPDATE_WORKERS_TABLE = 'UPDATE_WORKERS_TABLE';
export const EDIT_WORKER = 'EDIT_WORKER';
export const CREATE_WORKER = 'CREATE_WORKER';
export const SET_AUTH = 'SET_AUTH';
export const SET_ME = 'SET_ME';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_SITES = 'SET_SITES';
export const CREATE_SITE = 'CREATE_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const CREATE_ZONE = 'CREATE_ZONE';
export const UPDATE_ZONE = 'UPDATE_ZONE';
export const SET_ZONES = 'SET_ZONES';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_LOADING = 'SET_LOADING';
export const SET_PATHNAME = 'SET_PATHNAME';
export const SET_DASHBOARD_WORKER_ID = 'SET_DASHBOARD_WORKER_ID';
export const SET_SITE_ASSIGNMENTS = 'SET_SITE_ASSIGNMENTS';
export const SET_SELECTED_ASSIGNMENT_SITE_ID = 'SET_SELECTED_ASSIGNMENT_SITE_ID';
export const SET_CURRENT_EDITING_SITE_ASSIGNMENT = 'SET_CURRENT_EDITING_SITE_ASSIGNMENT';
export const SET_API_ROOT = 'SET_API_ROOT';
export const SET_DEVICE_VERSIONS = 'SET_DEVICE_VERSIONS';
export const SET_WORKER_GROUPS = 'SET_WORKER_GROUPS';
export const SET_EDITING_WORKERGROUP = 'SET_EDITING_WORKERGROUP';
export const SET_SHIFTS = 'SET_SHIFTS';
export const SET_SCHEDULE_NEXT_WEEK = 'SET_SCHEDULE_NEXT_WEEK';
export const SET_SCHEDULE_PREVIOUS_WEEK = 'SET_SCHEDULE_PREVIOUS_WEEK';
export const SET_SCHEDULE_GROUP_VIEW = 'SET_SCHEDULE_GROUP_VIEW';
export const SET_WORKER_SHIFTS = 'SET_WORKER_SHIFTS';
export const ADD_WORKER_SHIFT = 'ADD_WORKER_SHIFT';
export const REMOVE_WORKER_SHIFT = 'REMOVE_WORKER_SHIFT';
export const SET_WORK_DAY_TIME_SETTINGS = 'SET_WORK_DAY_TIME_SETTINGS';
export const SET_APPROVALS_WORKER_SHIFTS = 'SET_APPROVALS_WORKER_SHIFTS';


