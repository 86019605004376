import React from 'react';
import { getEnvVariables } from '../../Constants/EnvironmentVariables';
import Auth from './Auth';


export default function(props) {
  getEnvVariables().then((envVariables) => {
    new Auth().logout().then(() => {
      window.location.replace(envVariables.logoutUri);
    })
  });
  return <div />;
}