import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  ListItem,
  ListItemText,
  List,
  Divider,
  FormControl,
  FormHelperText,
  Select,
  Input,
  MenuItem,
  Typography,
  ListItemIcon,
  Modal,
  Button,
} from '@material-ui/core';
import {
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryFull,
  BatteryAlert,
  Phone,
} from '@material-ui/icons';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { getAllSites, getWorkerStatus, getWorkers, postManualLocation, postManualOffsiteEvent, getMobileViewPayload, getMobileViewWorkerStatus } from '../../API';
import { setSites, setLoading } from '../../redux/actions/AllActions';
import moment from 'moment';
import { createCenterLocationTimestampFromSite } from '../../GeoFunctions';


function renderBatteryIcon(batpercentage) {

  var icons = [
    <BatteryAlert style={{ color: 'rgba(176, 26, 0, 0.7)' }} />,
    <Battery20 style={{ color: 'rgba(176, 26, 0, 0.7)' }} />,
    <Battery30 style={{ color: 'rgba(176, 100, 0, 0.7)' }} />,
    <Battery50 style={{ color: 'rgba(230, 219, 14, 0.9)' }} />,
    <Battery60 style={{ color: 'rgba(230, 219, 14, 0.9)' }} />,
    <Battery80 style={{ color: 'rgba(27, 209, 82, 0.8)' }} />,
    <Battery90 style={{ color: 'rgba(27, 209, 82, 0.8)' }} />,
    <BatteryFull style={{ color: 'rgba(27, 209, 82, 0.8)' }} />,
  ];

  let icon = icons[0];
  [15, 30, 40, 50, 60, 80, 90, 91].forEach((num, i) => {
    if(batpercentage > num) {
      icon = icons[i];
    }
  });
  return icon;
}


const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};


function OptionsModal(props) {

    const [selectedSiteId, setSelectedSiteId] = useState(0);
    const [location, setLocation] = useState(null);

    useEffect(() => {
      if(!props.selectedWorker) {
        setSelectedSiteId(0);
      } else {
        setSelectedSiteId(props.selectedWorker.ScheduledSiteId);
      }
    }, [JSON.stringify(props.selectedWorker)]);

    useEffect(() => {
      const site = props.sites.find(s => s.SiteId === selectedSiteId);
      const loc = site && createCenterLocationTimestampFromSite(site, props.selectedWorker.DeviceId);
      setLocation(loc);
    }, [selectedSiteId]);

    if(!props.selectedWorker) {
      return null;
    }

    const onsite = props.selectedWorker.LatestEvent.EventType === 'ONSITE';

    const createOnsiteLocation = () => {
      const { Name, ScheduledSiteId, DeviceLastSeenOnsite, WorkerId } = props.selectedWorker;

      if(!ScheduledSiteId) {
        alert('please schedule this worker');
      }

      const site = props.sites.find(s => s.SiteId === props.selectedWorker.ScheduledSiteId);
      const msg = `confirm set ${Name}'s location to ${site.Name}`;

      if(window.confirm(msg)) {
        if(DeviceLastSeenOnsite) {
          var dt = moment().add(-1, 'minutes').format('YYYY-MM-DD HH:mm');
          postManualOffsiteEvent(WorkerId, dt).then(
            () => postManualLocation(location).then(props.handleModalClose)
          );
        } else {
          postManualLocation(location).then(props.handleModalClose);
        }
      }
    }

    const createOffsiteLocation = () => {
      const { Name, WorkerId } = props.selectedWorker;
      const msg = `confirm set ${Name}'s location to OffSite`;
      if(window.confirm(msg)) {
        postManualOffsiteEvent(WorkerId, moment().format('YYYY-MM-DD HH:mm:ss')).then(props.handleModalClose);
      }
    }

    const site = props.sites.find(s => s.SiteId === selectedSiteId);

    return <Modal 
      style={modalStyle} 
      open={!!props.selectedWorker}
      onBackdropClick={props.handleModalClose}
    >
      <Paper style={{ backgroundColor: '#fff', width: '95vw', margin: 'auto', height: '50vh', padding: '25px' }}>
        <Typography style={{padding: '5px'}}>
          {props.selectedWorker.Name}
        </Typography>
        <Typography style={{padding: '5px'}}>
          { site ? site.Name : 'Not Assigned - please schedule workers before setting them onsite' }
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Button 
            disabled={!site && !onsite } 
            onClick={ onsite ? createOffsiteLocation : createOnsiteLocation } 
            color="primary" 
            variant='outlined'>
            { `Set Location to ${onsite ? 'Off Site' : 'On Site'}` }
          </Button>
        </div>
        <div style={{ paddingTop: '15px' }}>
          <Button 
            onClick={ props.handleModalClose } 
            color="secondary" 
            variant='outlined'>
            Cancel
          </Button>
        </div>
      </Paper>
    </Modal>
}


function renderRow(item, index, onRowClick) {
  const timeDesc = getWorkerTimeDescription(item.LatestEvent);
  var siteDesc = getSiteDescription(item.LatestEvent);
  const icon = renderBatteryIcon(item.BatteryPercentage);
  const scheduledTime = getScheduleTimeDescription(item.ClosestShift);
  return <React.Fragment key={index+item.Name}>
    <ListItem button style={{ paddingLeft: '5px' }} onClick={() => onRowClick(item)}>
      <ListItemIcon style={{ minWidth: '30px'}}>
        { icon }
      </ListItemIcon>
      <a href={`tel:${item.Mobile}`} 
        data-rel="external" 
        style={{position: 'absolute', right: '8px', color: 'rgba(27, 209, 82, 0.8)'}}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        <Phone />
      </a>
      <ListItemText 
        primary={ `${item.Name}` } 
        secondary={
          <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                style={{ display: 'inline' }}
                color="textPrimary"
              >
                { siteDesc }
              </Typography>
              { ` ${timeDesc}` }
          <Typography variant="caption" display="block" gutterBottom>
            { scheduledTime }
           </Typography>
        </React.Fragment>
        }/>
    </ListItem>
    <Divider />
  </React.Fragment>;
}


function getScheduleTimeDescription(closestShift) {
  if(!closestShift) {
    return "unscheduled";
  }

  const startTime = moment(closestShift.StartDateTime).format('DD MMM  HH:mm');
  const endTime = moment(closestShift.EndDateTime).format('DD MMM HH:mm');
  return `${startTime} ${endTime}`;
}

function getWorkerTimeDescription(latestEvent) {
  if(!latestEvent) {
    return "";
  }
  var timeStr = moment(latestEvent.EventTime).fromNow();
  switch(latestEvent.EventType) {
    case "ONSITE":
      return `updated ${timeStr}`;
    case "OFFSITE":
      return `updated ${timeStr}`;
    case "UNKNOWN":
      return `last seen ${timeStr}`;
  };
}

function getSiteDescription(latestEvent) {

  if(!latestEvent || !latestEvent.SiteName) {
    return "no site";
  }
  if(latestEvent.SiteName.length > 30) {
    return latestEvent.SiteName.substr(0, 30) + '...';
  } else {
    return latestEvent.SiteName;
  }

}


export function MobileDashboardPage(props) {

    const [workerStatuses, setWorkerStatuses] = useState([]);
    const [selectedSiteId, setSelectedSiteId] = useState(0);
    const [navValue, setNavValue] = useState("offsite");
    const [selectedWorker, setSelectedWorker] = useState(null);

    const handleRowClick = (workerStatus) => {
      //setSelectedWorker(workerStatus);
    }

    const getData = async (refreshData) => {

      props._setLoading(true);
      const token = props.auth.token;
      const data = await getMobileViewPayload(token);
      const wss = [];
      const workerStatus = await Promise.all(data.workers.map(w => getMobileViewWorkerStatus(token, w.WorkerId).then(ws => {
        ws && wss.push(ws);
        ws && setWorkerStatuses([...wss]);
      })));
      props._setLoading(false);
    }

    const handleModalClose = () => {
      setSelectedWorker(null);
      getData(true);
    }

    useEffect(() => {
      try {
        document.getElementById("open-nav").style.display = 'none';
      } catch(e) {
        console.log(e);
      }
      
      getData();
      //setInterval(() => getData(), 180000);
      /*const refresh = () => {
        if (document.visibilityState === 'visible') {
          getData(true);
        };      
      };
      document.addEventListener('visibilitychange', refresh);
      return function cleanup () {
        document.removeEventListener('visibilitychange', refresh);
      }*/
    }, []);

    const onNavigationChange = (value) => {
      setNavValue(value);
    }

    const height = window.innerHeight - 180;

    var visibleWorkers = workerStatuses.filter(w => {

      /*if(selectedSiteId && (selectedSiteId !== w.ScheduledSiteId || selectedSiteId !== w.LastVisitedSiteId)) {
        return false;
      } */

      switch(navValue) {
        case "onsite":
          return w.LatestEvent && w.LatestEvent.EventType == "ONSITE";
        case "offsite":
          return w.LatestEvent && (w.LatestEvent.EventType == "OFFSITE" || w.LatestEvent.EventType == "UNKNOWN");
      }
    });

    return <div style={{ width: '100%', height: '100%', paddingTop: '5px' }}>
      <OptionsModal selectedWorker={selectedWorker} handleModalClose={handleModalClose} sites={props.sites} />
      {/*<Paper style={{ padding: '5px' }}>
        <FormControl fullWidth variant="outlined">
          <Select
              name="siteChoiceMobileDashboard"
              value={selectedSiteId || 0}
              onChange={(e, s) => setSelectedSiteId(e.target.value)}
            >
              <MenuItem key={'all'} value={0}>{'All Sites'}</MenuItem>
                {props.sites.map(
                  (w, i) => <MenuItem key={i} value={w.SiteId}>{w.Name}</MenuItem>)}
            </Select>
        </FormControl>
                </Paper>*/}
      
      <Paper style={{maxHeight: height, overflow: 'auto'}}>
        <List>
          { visibleWorkers.map((t, i) => renderRow(t, i, handleRowClick)) }
        </List>
      </Paper>

      <BottomNavigation
        style={{ position: 'absolute', bottom: 0, width: '100%', paddingBottom: '6px'}}
        showLabels
        value={navValue}
        onChange={(e, v) => onNavigationChange(v)}
      >
        <BottomNavigationAction label="Onsite" value="onsite" icon={<Visibility />} />
        <BottomNavigationAction label="Offsite" value="offsite" icon={<VisibilityOff />} />
      </BottomNavigation>
    </div>

}




const mapDispatchToProps = (dispatch) => {
  return {
    _setSites: x => dispatch(setSites(x)),
    _setLoading: x => dispatch(setLoading(x)),
  }
}

const mapStateToProps = (state) => {
  return {
    sites: state.sites.all.filter(s => s.Active),
    auth: state.me.auth,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileDashboardPage));



