import {
  UPDATE_TIMESHEET_REPORTTABLE,
  SET_TIMESHEET_RANGE,
  SET_REPORT_DATE,
} from './actions/ActionTypes';
import { assign } from '../Utils';

const initialState = {
  all: [],
  dateChoices: [],
  currentDateChoice: null,
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case UPDATE_TIMESHEET_REPORTTABLE: {
      return assign(state, { all: payload });
    }
    case SET_TIMESHEET_RANGE: {
      return assign(state, { dateChoices: payload });
    }
    case SET_REPORT_DATE: {
      return assign(state, { currentDateChoice: payload });
    }
    default:
      return state;
  }
}
