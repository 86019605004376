import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid, Typography, Button, Hidden, Select, MenuItem, OutlinedInput
} from '@material-ui/core';
import { SelectInput } from '../FormComponents';
import ApprovalsForm, { mapDispatchToProps, mapStateToProps } from './ApprovalsForm';
import PrintTimesheetButton from './PrintTimesheetButton';
import { LargeAvatar } from '../Common/UserAvatars';

class ApprovalsFormLarge extends ApprovalsForm {
  renderUserImage(worker) {
    return (
      <Hidden only={['sm', 'xs']}>
        <Grid item>
          <div className="approvals-form-avatar">
            {<LargeAvatar worker={worker} />}
          </div>
        </Grid>
      </Hidden>
    );
  }

  onWorkerSelectChange = async ({ target }) => {
    this.props.onWorkerSelectChange(target.value);
  }

  render() {
    const {
      timesheetList, timesheet, worker, workerShifts, workers, timesheetWorkerIds,
      selectedWorker,
    } = this.props;
    const index = this.getIndex();
    const workersForList = workers.filter(w => timesheetWorkerIds.includes(w.WorkerId));
    return (
      <Grid container justify="space-between">
        <Grid item xs={12} sm={4} md={4} lg={3}>
        <div className="timesheet-id-select" style={{ paddingBottom: '9px' }}>
            <Select
              style={{ width: '90%', maxWidth: '300px'}}
              value={selectedWorker && selectedWorker.WorkerId}
              onChange={this.onWorkerSelectChange}
              input={<OutlinedInput labelWidth={0} value={selectedWorker && selectedWorker.Name} />}
              autoWidth
            >
              { workersForList.map(
                x => <MenuItem value={x.WorkerId} key={x.WorkerId}>
                  { x.Name }
                </MenuItem> )}
            </Select>
          </div>
          <div className="timesheet-id-select">
            <SelectInput
              textKey="text"
              onChange={this.onSelectChange}
              value={timesheet && timesheet.TimesheetId}
              list={timesheetList.getSelectOptions()}
              name="TimesheetId"
            />
          </div>
        </Grid>
        <Grid container xs={12} spacing={2} sm={6} md={6} style={{ paddingTop: '20px' }}>
          <Grid item>
            <Button
              disabled={!timesheet || index === 0}
              variant="contained"
              color="primary"
              onClick={this.onBackClick}
            >
             Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={index === (timesheetList.objects.length - 1)}
              variant="contained"
              color="primary"
              onClick={this.onNextClick}
            >
              Next
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={timesheet && timesheet.Status === 1 ? 'secondary' : 'primary'}
              onClick={this.handleSwitchClick}
            >
              { timesheet && timesheet.Status === 1 ? 'Unapprove' : 'Approve '}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={ 'secondary' }
              onClick={this.props.onApproveAllTimesheetsPress}
            >
              { 'Approve All'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={timesheet && timesheet.Status === 1 ? 'secondary' : 'primary'}
              onClick={this.handleRegenerateClick}
            >
              Regenerate
            </Button>
          </Grid>
          <Grid item>
            <div style={{ padding: '10px 15px' }}>
              { timesheet && timesheet.timesheetEntryList.totalTimeStr && (
              <Typography variant="subtitle2">
                {`${timesheet.timesheetEntryList.totalTimeStr}hrs`}
              </Typography>
              ) }
            </div>
          </Grid>
          <Grid item>
            <PrintTimesheetButton timesheet={timesheet} workerShifts={workerShifts} />
          </Grid>
        </Grid>
        { this.renderUserImage(worker) }
      </Grid>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApprovalsFormLarge));
