import React, { Component } from 'react';
import {
  Paper,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  Typography,
  Fab,
  Switch,
  Modal,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Common/FormStyles';
import { getAllUsersAllCompanies, getIncomingMessages, getSupportWorkerLatestIncomingSMS, getSupportWorkerStatus, getCompanies } from '../../API';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import SMSInterface from './SMSInterface';
import WorkerNotes from './WorkerNotes';
import { isNowBetweenMoments } from '../../Utils';

var data = {"WorkerId":1111,
"Name":"Robert Isip",
"Latitude":-36.867088,
"Longitude":174.758163,
"LastKnownLocationTime":"2022-10-19 07:20:02",
"LastDeviceUpdateTime":"2022-10-19 07:20:02",
"LastDistanceUpdateTime":"2022-10-19 07:20:02",
"LastDistanceToSite":0,
"LastClosestSite":"CRL MtEden",
"LatestEvent":{
  "SiteName":"CRL MtEden",
  "WorkerName":"Robert Isip",
  "EventType":"ONSITE",
  "EventTime":"2022-10-19 07:20:02",
  "CreatedAtUtc":"2022-10-18 18:55:38"
},
"BatteryPercentage":22,
"ClosestShift":{
  "IsOvernight":false,
  "SiteName":"CRL MtEden",
  "SiteId":419,
  "StartDateTime":"2022-10-19 06:00:00",
  "EndDateTime":"2022-10-19 18:00:00"
},
"LastVisitedSiteId":419,
"ScheduledSiteId":419,
"Mobile":"+642102790376",
"DeviceId":"351516172861130",
"DeviceLastSeenOnsite":true,
"Company":"Turbo Staff - Auckland",
"LatestCharge": {
  "LocationDateTime":"2022-10-17 04:29:31",
  "Battery":57,
  "PreviousBattery":45,
  "PreviousTime":"2022-10-17 04:14:30"
}
};

const problems = {
  NEVER_USED: 'Never used a device',
  NOT_AVAILABLE: 'Nothing from device',
  NOT_AVAILABLE_DAYS: 'Nothing from device 2 days',
  BATTERY_ZERO: 'Battery at zero for too long',
  NO_LOCATION_WORK_TIME: 'No Location during work',
  NOT_AT_WORK: 'Not at work',
  NOT_SCHEDULED: 'Not Scheduled',
  NONE: 'None',
}

const solutions = {
  [problems.NEVER_USED]: 'Onboarding + Charge clip & device',
  [problems.NOT_AVAILABLE_DAYS]: 'CALL Charge clip & device',
  [problems.NOT_AVAILABLE]: 'SMS Charge clip & device',
  [problems.BATTERY_ZERO]: 'SMS Charge clip & device',
  [problems.NO_LOCATION_WORK_TIME]: 'Ask if they are at work',
  [problems.NOT_AT_WORK]: 'Do Nothing',
  [problems.NOT_SCHEDULED]: 'Check schedule or Disable',
  [problems.NONE]: 'Do Nothing',
}

const problemsorder = {
  ['msg']: 0,
  [problems.NEVER_USED]: 1,
  [problems.NOT_SCHEDULED]: 2,
  [problems.NOT_AVAILABLE_DAYS]: 3,
  [problems.NOT_AVAILABLE]: 4,
  [problems.BATTERY_ZERO]: 5,
  [problems.NO_LOCATION_WORK_TIME]: 6,
  [problems.NOT_AT_WORK]: 7,
  [problems.NONE]: 8,
}

const problemsColor = {
  [problems.NEVER_USED]: 'rgba(255, 0, 0, 0.4)',
  [problems.NOT_SCHEDULED]: 'rgba(255, 0, 0, 0.35)',
  [problems.NOT_AVAILABLE_DAYS]: 'rgba(255, 0, 0, 0.3)',
  [problems.NOT_AVAILABLE]: 'rgba(255, 111, 0, 0.3)',
  [problems.BATTERY_ZERO]: 'rgba(255, 111, 0, 0.3)',
  [problems.NO_LOCATION_WORK_TIME]: 'rgba(255, 213, 0, 0.3)',
  [problems.NOT_AT_WORK]: 'rgba(255, 213, 0, 0.3)',
  [problems.NONE]: 'rgba(19, 173, 2, 0.3)',
}

export const columns = [
  /*{
    name: 'Photo',
    options: {
      filter: false,
      customBodyRender: (value) => {
        return (
          value ? <img className="user-table-img" src={value}></img> : <span>No Photo</span>
        );
      },
    },
  },
  {
    name: 'Payroll ID',
    options: {
      filter: false,
    },
  },
  {
    name: 'Device ID',
  },
  {
    name: 'name',
  },*/
  { name: 'Company', options: { display: false} },
  { name: 'Name', options: {
    customBodyRender: (val) => {
      if(!val) return '';
      if(!val.latestSMS){
        return val.Name;
      }
      const now = moment();
      const messageTime = moment(val.latestSMS.CreationDateTime);
      if(now.diff(messageTime, 'days') < 5)
      {
        return <div>
          <span style={{color: 'red', marginRight: '4px' }}>msg</span>
          <span>{val.Name}</span>
        </div>
      } else {
        return val.Name;
      }
    }
  } },
  { name: 'Latest reply', options: {
    customBodyRender: (val) => {
      if(!val || !val.latestSMS) {
        return '';
      }
      const { latestSMS } = val;
      return <div>
        <div><span>{ moment(latestSMS.CreationDateTime).fromNow()}</span></div>
        <div><span>{ latestSMS.Description }</span></div>
      </div>;
    },
    sort: true,
  }},
  { name: 'Site', options: { display: false} },
  { name: 'OnSite', options: { display: false} },
  { name: 'Charge' },
  { name: 'Updated at' },
  { name: 'Location',
    options: {
      customBodyRender: (val) => {
        if(!val.LastKnownLocationTime) return <div />;
        return <div>
          <div><span>{moment(val.LastKnownLocationTime).fromNow()}</span></div>
          <div><a target="_blank" href={`https://www.google.co.nz/maps/place/${val.Latitude},${val.Longitude}`}>link</a></div>
          </div>
      },
      display: false,
    }
  },
  { name: 'Site Distance', options: { display: false}},
  { name: 'Shift Start', options: { display: false} },
  { name: 'Mobile' },
  { name: 'Event', options: { display: false}},
  { name: 'DeviceId', options: { display: false}},
  { name: 'Problem', options: { customBodyRender: 
    (val) => (<div style={{backgroundColor: problemsColor[val], fontSize: '1.05em', fontWeight: 500 }}>{val}</div>) } },
  { name: 'Action' },
  { name: 'data', options: { display: false, customBodyRender: (v) => JSON.stringify(v) }}
]




function workerStatusProblem(workerStatus, checkMsgForOrder) {
  const {
    LastDeviceUpdateTime,
    LastKnownLocationTime,
    LastDistanceToSite,
    LastDistanceUpdateTime,
    ClosestShift,
    BatteryPercentage,
    latestSMS,
  } = workerStatus;

  if(latestSMS && checkMsgForOrder)
  {
    const n = moment();
    const messageTime = moment(latestSMS.CreationDateTime);
    if(n.diff(messageTime, 'days') < 5)
    {
       return 'msg';
    }
  }

  const now = moment();
  if(!LastDeviceUpdateTime)
  {
    return problems.NEVER_USED;
  }
  if(!ClosestShift)
  {
    return problems.NOT_SCHEDULED;
  }
  const shiftStart = moment(ClosestShift.StartDateTime);
  const shiftEnd = moment(ClosestShift.EndDateTime);
  const lastUpdated = moment(LastDeviceUpdateTime);
  const hoursAgo = lastUpdated.diff(now, 'hours');

  if(Math.abs(hoursAgo) > 48)
  {
    return problems.NOT_AVAILABLE_DAYS;
  }

  if(Math.abs(hoursAgo) > 16)
  {
    return problems.NOT_AVAILABLE;
  }

  const isScheduled = ClosestShift && now.isBetween(shiftStart, shiftEnd);
  if(!isScheduled) {
    return problems.NONE;
  }

  const minutesAgo = lastUpdated.diff(now, 'minutes');

  if(BatteryPercentage < 1 && Math.abs(minutesAgo) > 90)
  {
    return problems.BATTERY_ZERO;
  }

  if(moment(LastKnownLocationTime).isBefore(shiftStart.add(-1, 'hours')) && isScheduled)
  {
    return problems.NO_LOCATION_WORK_TIME;
  }

  if(moment(LastDistanceUpdateTime).diff(now, 'minutes') < 120 && LastDistanceToSite > 500)
  {
    return problems.NOT_AT_WORK;
  }

  return problems.NONE;
}

function statusRow(status) {
  const {
    Name,
    Latitude,
    Longitude,
    LastKnownLocationTime,
    LastDeviceUpdateTime,
    LastDistanceUpdateTime,
    LastDistanceToSite,
    LastClosestSite,
    LatestEvent,
    ClosestShift,
    Mobile,
    DeviceId,
    Company,
    LatestCharge,
    BatteryPercentage,
    latestSMS,
  } = status;
  var problem = workerStatusProblem(status);
  var row = [
    Company, 
    status,
    status,
    LastClosestSite, 
    moment(LastKnownLocationTime).fromNow(), 
    LatestCharge ? LatestCharge.Battery + "%" + " " + moment(LatestCharge.LocationDateTime).fromNow(): 'None',
    LastDeviceUpdateTime ? moment(LastDeviceUpdateTime).fromNow() + " " + BatteryPercentage + "% batt" : "",
    { LastKnownLocationTime, Latitude, Longitude },
    LastDistanceToSite + "m " + moment(LastDistanceUpdateTime).fromNow(),
    ClosestShift ?  moment(ClosestShift.StartDateTime).fromNow() : 'None',
    Mobile,
    LatestEvent ? LatestEvent.EventType + " " +  moment(LatestEvent.EventTime).fromNow(): 'None',
    DeviceId,
    problem,
    solutions[problem],
    status,
  ];
  return row;
}

const options = {
  filterType: 'multiselect',
  selectableRows: false,
  responsive: 'scroll',
  rowsPerPage: 500,
  rowsPerPageOptions: [10, 20, 50, 100],
  onRowClick: (a) => {
   
  },
  onChangeRowsPerPage: () => {},//this.handleChangeRowsPerPage,
};

const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function ActionModal(props) {
  if(!props.selectedStatus) {
    return null;
  }
  return <Modal 
    style={modalStyle} 
    open={!!props.selectedStatus}
    onBackdropClick={props.handleModalClose}
  >
    <Paper style={{ backgroundColor: '#fff', width: '95vw', margin: 'auto', height: '80vh', padding: '25px', overflow: 'scroll' }}>
      <Grid container>
        <Grid item xs={3}>
          { props.selectedRow.slice(0, 14).map((val, i) => {
               return <Grid item xs={12} key={i} style={{padding: '10px'}}>{columns[i].name}: {val}</Grid>
          })}
          <Grid item xs={12}><Button color='primary' onClick={props.handleModalClose}>Close</Button></Grid>
        </Grid>
        <Grid item xs={4}>
          { props.selectedStatus && <WorkerNotes workerStatus={props.selectedStatus} /> }
        </Grid>
        <Grid item xs={5}>
          { props.selectedStatus && <SMSInterface workerStatus={props.selectedStatus} /> }
        </Grid>
      </Grid>
    </Paper>
  </Modal>
}

class SupportDashboard extends Component {
  state = {
    statuses: [],
    selectedStatus: null,
    rows: [],
    selectedRow: null,
  }

  onRowClick = (row, dataIndex) => {
    const status = this.state.statuses[dataIndex.dataIndex];
    this.setState({ selectedStatus: status, selectedRow: row });
  }

  handleModalClose = () => {
    this.setState({ selectedStatus: null, selectedRow: null });
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const data = await Promise.all([getCompanies(), getAllUsersAllCompanies()])
    const companies = data[0];
    const goodCompanies = companies.filter(c => !c.IsArchived && !c.Name.toLowerCase().includes('test')).map(c => c.CompanyId);
    var statuses = await Promise.all(data[1].filter(
      w => goodCompanies.includes(w.CompanyId) && w.IsWorker 
        && w.IsEnabled &! w.IsAdministrator &! w.IsSuperAdministrator).map(
          async w => {
            const b = await getSupportWorkerStatus(w.WorkerId);
            const c = await getSupportWorkerLatestIncomingSMS(w.WorkerId);
            return { ...b, latestSMS: c};
          }));
    
    statuses.sort((a, b) => {
      return problemsorder[workerStatusProblem(a, true)] > problemsorder[workerStatusProblem(b, true)] ? 1 : -1;
    });

    const rows = statuses.filter(s => !!s).map(statusRow);
    this.setState({ statuses: statuses.filter(s => !!s), rows });
  }

  render() {
    const { rows } = this.state;
    options.onRowClick = this.onRowClick;
    return (
      <div style={{ padding: '15px', minHeight: '90vh', overflow: 'auto' }}>
        <ActionModal selectedStatus={this.state.selectedStatus} selectedRow={this.state.selectedRow} handleModalClose={this.handleModalClose} />
        <Grid container>
          <Grid item xs={12}>
            <MUIDataTable
              data={rows}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}


export default SupportDashboard;
