import momenttz from 'moment-timezone';
import moment from 'moment';
import * as turf from '@turf/turf';
import wkt from 'terraformer-wkt-parser';

class GPSKalmanFilter {
  constructor(decay = 3) {
    this.decay = decay;
    this.variance = -1;
    this.minAccuracy = 5;
  }

  process(lat, lng, orginalAccuracy, timestampInMs) {
    let accuracy = orginalAccuracy;
    if (accuracy < this.minAccuracy) {
      accuracy = this.minAccuracy;
    }

    if (this.variance < 0) {
      this.timestampInMs = timestampInMs;
      this.lat = lat;
      this.lng = lng;
      this.variance = accuracy * accuracy;
    } else {
      const timeIncMs = timestampInMs - this.timestampInMs;

      if (timeIncMs > 0) {
        this.variance += (timeIncMs * this.decay * this.decay) / 1000;
        this.timestampInMs = timestampInMs;
      }

      const _k = this.variance / (this.variance + (accuracy * accuracy));
      this.lat += _k * (lat - this.lat);
      this.lng += _k * (lng - this.lng);

      this.variance = (1 - _k) * this.variance;
    }

    return [this.lng, this.lat];
  }
}

export function snapLocationTimestampsToSite(sites, locs) {
  return locs.map((loc) => {
    if (loc.ClosestSiteDistance < 2 || !loc.ClosestSiteId) {
      return loc;
    }
    const site = sites.find(s => s.SiteId === loc.ClosestSiteId);
    const line = turf.polygonToLine(site.GeoJSON);
    const point = turf.point([loc.Longitude, loc.Latitude]);
    const snapped = turf.nearestPointOnLine(line, point, { units: 'metres' });
    return {
      ...loc,
      Latitude: snapped.geometry.coordinates[1],
      Longitude: snapped.geometry.coordinates[0],
    };
  });
}

export default function filterLocationTimestamps(locs) {
  const kalmanFilter = new GPSKalmanFilter(1.5);
  const updatedCoords = [];
  /* eslint-disable */
  for (let index = 0; index < locs.length; index++) {
    const { Latitude, Longitude, NumberOfSatellites, LocationDateTime } = locs[index];
    const timestampInMs = momenttz(LocationDateTime).unix() * 1000;
    const accuracy = NumberOfSatellites;
    const [lon, lat] = kalmanFilter.process(Latitude, Longitude, accuracy || 0, timestampInMs);
    updatedCoords[index] = { ...locs[index], Latitude: lat, Longitude: lon };
  }
  /* eslint-enable */

  return updatedCoords;
}

export function snapGeoJSONFeaturesToSite(sites, geojson) {
  const features = geojson.features.filter(f => f.geometry && !!f.properties.ClosestSiteId);
  const snapped = features.map((feat) => {
    const { properties } = feat;
    if (properties.ClosestSiteDistance < 1 || !properties.ClosestSiteId) {
      return feat;
    }
    const site = sites.find(s => s.SiteId === properties.ClosestSiteId);
    const line = turf.polygonToLine(site.GeoJSON);
    const point = feat;
    const snapped = turf.nearestPointOnLine(line, point, { units: 'metres' });
    return { ...feat, geometry: snapped.geometry };
  });

  return { ...geojson, features: snapped };
}

export function clusteriseContactTracingResults(locs, workerId) {

    const points = locs.map(({ wkt_point, WorkerId, LocationTimestampId,
    LocationDateTime, ClosestSiteId, Name, ContactName, ZoneId,
    NumberOfSatellites, ClosestSiteDistance }) => {
    return turf.point(wkt.parse(wkt_point).coordinates, 
        { WorkerId, LocationTimestampId, LocationDateTime: momenttz.utc(LocationDateTime).tz('Pacific/Auckland').toISOString(),
        ClosestSiteId, Name, ContactName,  NumberOfSatellites, ClosestSiteDistance, ZoneId, 
        compositeId: `${WorkerId}-${ClosestSiteId}-${ZoneId}` });
    });

    const featureCollection = turf.featureCollection(points);
    
    const workerSiteZoneIds = [...new Set(featureCollection.features.map(
      ({ properties }) => properties.compositeId))];

    const clustered = turf.clustersKmeans(featureCollection, { numberOfClusters: Math.ceil(Math.sqrt(points.length / 2)) });
    
    const clusters = workerSiteZoneIds.map((compositeId) => {
      return turf.getCluster(clustered, { compositeId });
    });

    const features = [].concat.apply([], clusters.map(({features}) => features));
    return turf.featureCollection(features);
}

export function locationTimestampsToPoints(locs) {
  return turf.featureCollection([...locs.map(
    wt => turf.point([wt.Longitude, wt.Latitude], wt))]);
}

export function createCenterLocationTimestampFromSite(site, deviceId) {

  const location = turf.centroid(site.GeoJSON);
  const loc = {
    deviceId,
    batteryPercentage: 0,
    deviceCharging: false,
    locationDateTime: momenttz().format('YYYY-MM-DD HH:mm'),
    sosButton: false,
    altitude: 0,
    rawDeviceData: "MANUAL",
    numberOfSatellites: 0,
    signalStrength: 0,
    location: {
      latitude: location.geometry.coordinates[1],
      longitude: location.geometry.coordinates[0]
    }
  }
  return loc;
}