import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import BlockIcon from '@material-ui/icons/Block';

import { styles } from '../Approvals/Approvals';
import {
  getShifts,
  getAllSites,
  archiveShift,
} from '../../API';
import {
  setShifts,
  setSites,
} from '../../redux/actions/AllActions';
import { ListItemIcon } from '@material-ui/core';



export function ShiftsList(props) {

  useEffect(() => {
    Promise.all([
      getAllSites(),
      getShifts(),
    ]).then(data => {
      props._setSites(data[0]);
      props._setShifts(data[1]);
    });
  }, []);
  
  const onDeleteClick = (shiftId) => {
    return () => archiveShift(shiftId).then(getShifts).then(props._setShifts);
  }

  return (
    <div style={{maxHeight: '60vh', overflow: 'scroll'}}>
      <List component="nav">
        { props.shifts.map(
          w => <ListItem  
            button
            key={w.ShiftId} 
          >
            <ListItemText
              primary={`${w.Name} - ${w.StartTime.slice(0, 5)} ${w.EndTime.slice(0, 5)}`}
              secondary={props.sites.find(s => s.SiteId == w.SiteId).Name}
            />
            <ListItemIcon onClick={onDeleteClick(w.ShiftId)}><BlockIcon /></ListItemIcon>
          </ListItem> )}
      </List>
    </div>
  );

  
}

export const mapStateToProps = (state) => {
  return {
    shifts: state.shifts.all.filter(a => !a.Archived),
    sites: state.sites.all,
    
  } 
};

export const mapDispatchToProps = dispatch => ({
  _setShifts: x => dispatch(setShifts(x)),
  _setSites: x => dispatch(setSites(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(ShiftsList)));
  