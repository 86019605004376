import { PayloadAction } from '../actions/types';
import { CONTACTTRACING_ActionTypes } from '../actions/ContactTracingActions';
import { FeatureCollection } from '@turf/turf';
import moment from 'moment';


export interface ContactTraceState {
  contactGeojson: FeatureCollection;
  workerTrack: FeatureCollection;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  startTime: string;
  endTime: string;
  distanceBuffer: number;
  timeBuffer: number;
}

const initialState: ContactTraceState = {
  contactGeojson: { type: 'FeatureCollection', features: [] },
  workerTrack: { type: 'FeatureCollection', features: [] },
  fromDate: moment().add(-14, 'days'),
  toDate: moment(),
  startTime: '00:00',
  endTime: '23:59',
  distanceBuffer: 30,
  timeBuffer: 75,
};

export function contactTraceReducer (state: ContactTraceState, action: PayloadAction<any>): ContactTraceState {
  const newState = makeNewState(state, action);
  return newState;
}

function makeNewState(state: ContactTraceState = initialState, action: PayloadAction<any>): ContactTraceState {
  const { payload } = action;
  switch (action.type) {
    case CONTACTTRACING_ActionTypes.SET_CONTACT_GEOJSON: {
      return { ...state, contactGeojson: payload };
    }
    case CONTACTTRACING_ActionTypes.SET_WORKER_TRACK: {
      return { ...state, workerTrack: payload };
    }
    case CONTACTTRACING_ActionTypes.SET_CONTACT_TRACING_FORM_VALUES: {
      return { ...state, ...payload };
    }
    default:
      return state;
    }
}