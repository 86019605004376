import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import {
  Grid,
  Paper,
  Typography,
  FormHelperText,
  FormControlLabel,
  FormControl,
  ListSubheader,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemIcon,
  Button,
  Switch,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import {
  getWorkers,
  addWorkerToGroup,
  removeWorkerFromGroup,
  deleteWorkerGroup,
  getWorkerGroups,
} from '../../API';
import {
  setWorkerGroups,
  WorkerActions,
} from '../../redux/actions/AllActions';
import { styles } from '../Approvals/Approvals';


export function SetWorkersForGroupForm(props) {
  const [selectedWorker, setSelectedWorker] = useState({ WorkerId: 0, Name: '' });
  useEffect(() => {
    getWorkers().then(props._setWorkers);
  }, []);
  
  if(!props.editingWorkerGroup) {
    return <div />;
  }  

  const { Name, WorkerGroupId } = props.editingWorkerGroup;
  const addWorker = (worker) => {
    addWorkerToGroup(WorkerGroupId, worker.WorkerId).then(getWorkers).then(props._setWorkers);
    setSelectedWorker({ WorkerId: 0, Name: '' });
  }
  const removeWorker = (worker) => {
    removeWorkerFromGroup(worker.WorkerId).then(getWorkers).then(props._setWorkers);
    setSelectedWorker({ WorkerId: 0, Name: '' });
  }
  const removeGroup = () => { 
    if(window.confirm('are you sure?')) {
      deleteWorkerGroup(WorkerGroupId).then(
        getWorkers).then(props._setWorkers).then(getWorkerGroups).then(props._setWorkerGroups);
    }
  }

  const workersInGroup = props.workers.filter(w => w.WorkerGroupId === WorkerGroupId);
  const workersOutOfGroup = props.workers.filter(w => w.WorkerGroupId !== WorkerGroupId);

  return (
    <Grid container>
      <Grid item xs={12}>
        { Name }
      </Grid>
      <Grid item xs={8}>
        <Autocomplete 
          id="user-into-worker-group"
          options={workersOutOfGroup}
          getOptionSelected={(option, value) => option.WorkerId === value.WorkerId}
          onChange={(event, newValue) => {
            setSelectedWorker(newValue);
          }}
          value={selectedWorker}
          getOptionLabel={(opt) => opt.Name}
          renderInput={(params) => <TextField { ...params } label="Add A Worker" variant="outlined" />}
        />
      </Grid>
      <Grid item xs={4}>
        <Button onClick={() => selectedWorker.WorkerId && addWorker(selectedWorker)}>
          Add
        </Button>
      </Grid>
      <Grid item xs={12}>
        <List>
          { workersInGroup.map(w => {
            return <ListItem key={w.WorkerId}>
                <ListItemText>{w.Name}</ListItemText>
                <ListItemIcon onClick={() => removeWorker(w)}>
                  <BlockIcon />
                </ListItemIcon>
              </ListItem>
          })}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={removeGroup}>
          Delete Group
        </Button>
      </Grid>
    </Grid>
  )

}

export const mapStateToProps = (state) => {
  const {
    workerGroups,
    workers,
  } = state;

  const { editingWorkerGroup } = workerGroups;
  
  return {
    workerGroups: workerGroups.all,
    workers: workers.all.filter(w => w.IsWorker),
    editingWorkerGroup,
  };
};

export const mapDispatchToProps = dispatch => ({
  _setWorkers: x => dispatch(WorkerActions.setWorkers(x)),
  _setWorkerGroups: x => dispatch(setWorkerGroups(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(SetWorkersForGroupForm)));
  