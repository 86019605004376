import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 1000,
  },
});

const options = {
  filterType: 'multiselect',
  selectableRows: false,
  filter: true,
  download: false,
  rowsPerPageOptions: [10, 20, 50, 100],
};

const reportTableColumns = [
  'Payroll ID',
  { name: 'Worker', options: { sort: true, sortDirection: 'asc' } },
  'Site',
  'Day Worked',
  'Start Time',
  'Finish Time',
  'Hours Worked',
  'Hours Break',
  'Status',
];


function ReportingTable(props) {
  const { rows } = props;
  return (
    <MUIDataTable
      data={rows}
      columns={reportTableColumns}
      options={options}
    />
  );
}

export default withStyles(styles)(ReportingTable);
