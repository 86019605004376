import { handle, Handlers} from 'redux-pack';
import { PayloadAction } from '../actions/types';
import { SET_DEVICE_VERSIONS } from '../actions/ActionTypes';
import { DeviceFirmware } from '../../api/index';


export interface DeviceFirmwareState {  
  all: Array<DeviceFirmware>;
}
const initialState: DeviceFirmwareState = { all: [] };

export function deviceFirmwareReducer(state: DeviceFirmwareState, action: PayloadAction<any>): DeviceFirmwareState {
  const newState = makeNewState(state, action);
  return newState;
}


function makeNewState(state: DeviceFirmwareState = initialState, action: PayloadAction<any>): DeviceFirmwareState {
  switch(action.type){
    case SET_DEVICE_VERSIONS:
      return { ...state, all: action.payload };
    default:
      return state;
  }
}