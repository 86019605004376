import logoDataUrl from "../IncidentReport/logoDataUrl";
import { uniqInArray } from '../../Utils';


const stylesStr = `

.logo-img {
  width: 4cm;
}

#wrapper {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: #fff;
}

.header {
  margin: 0;
  padding: 1cm 1.5cm 0.8cm 1.5cm;
  width: 100%;
  display: table;
}

html, body {
  width:21cm; 
  margin:0;
  padding:0;
  top: 0;
  left: 0;
  font-family: Tahoma, sans-serif;
  line-height: 1.3rem;
}

.title {
  font-size: 2.2rem;
  font-weight: 100;
  letter-spacing: 0.41mm;
}

.logo-wrap {
  height: 2.5cm;
}

.center-text {
  marginLeft: auto;
  marginRight: auto;
  paddingTop: 0.5cm;
  text-align: center;
  height: 1.6cm;
}

.divider-title {
  border-top-style: solid;
  border-bottom-style: solid;
  border-color: #000000;
  border-width: 1px;
  background-color: #F5C800;
  margin: 0;
  padding-left: 1.5cm;
  padding-top: 1.5mm;
  padding-bottom: 1.1mm;
}

.left-section {
  display: table-cell;
  width: 50%;
}
.right-section {
  display: table-cell;
  width: 50%;
}

.map-img {
  height: 6.5cm;
  width: 6.5cm;
  margin-left: auto;
  margin-right: auto;
}

.witness-list {
  padding: 0cm 0cm 0cm 1.5cm;
}

`;

// eslint-disable-next-line import/prefer-default-export
export function makeSupervisorTotalsReportHTML(values) {
  const {
    workerReports,
    startDate,
    endDate,
    printDate,
    printTime,
  } = values;

  const workerReportsHtml = workerReports.map(z => `
    <div>
      <h3>
        ${z[0]} 
      </h3>
      <ul>
        <li>Total Breaks: ${z[1]}</li>
        <li>Total Hours: ${z[2]}</li>
      </ul>
    </div>
  `).join('\n');

  const html = `
    <html>
      <head>
      <style>
        ${stylesStr}
      </style>
      </head>
      </head>
    <body>
      <div id="wrapper">
        <div class="header">
          <div class="logo-wrap">
            <img class="logo-img" src="${logoDataUrl}" />
          </div>
          <div class="center-text">
            <span class="title">WORK TOTALS REPORT</span>
          </div>
          <div class="">
            <span>Reported on the ${printDate} at ${printTime}</span>
          </div>
          <div class="">
            <span><b>Start Date:</b> ${startDate}</span>
          </div>
          <div class="">
            <span><b>End Date:</b> ${endDate}</span>
          </div>
        </div>
      </div>
      <div class="divider-title" style="margin-top: 10px;">
        <span>WORKERS TOTALS</span>
      </div>
      <div class="header">
        <div class="witness-list">
          <div>
            ${workerReportsHtml}
          </div>
        </div>
      </div>
    </body>
  </html>
`;

return html;
}
