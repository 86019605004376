import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  createShift,
  getShifts,
} from '../../API';
import {
  setShifts,
} from '../../redux/actions/AllActions';
import { styles } from '../Approvals/Approvals';
import CreateShiftForm from './CreateShiftForm';
import ShiftsList from './ShiftsList';

const saveShift = (siteId, startTime, endTime, name, multiSite) => {
  const newShift = { 
    Name: name,
    SiteId: siteId,
    StartTime: startTime,
    EndTime: endTime,
    MultiSite: multiSite,
  };    
  
  return createShift(newShift);
}

export function ShiftsPage(props) {

  const onShiftSave = (siteId, startTime, endTime, name, multiSite) => {
    return saveShift(siteId, startTime, endTime, name, multiSite).then(getShifts).then(props._setShifts);
  }

  return (
    <Grid container style={{padding: '20px'}}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h2">Shifts</Typography>
      </Grid>
      <Grid item xs={4} lg={3}>
        <ShiftsList />
      </Grid>
      <Grid item xs={8} lg={4}>
        <CreateShiftForm saveShift={onShiftSave}/>
      </Grid>
    </Grid>
  );
}

export const mapStateToProps = (state) => {
  const {
    shifts 
  } = state;

  return {
    shifts: shifts.all,
  };
};

export const mapDispatchToProps = dispatch => ({
  _setShifts: (x) => setShifts(x),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(ShiftsPage)));
  