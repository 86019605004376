import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormHelperText,
  FormControl,
  Grid,
  Paper,
  Button,
} from '@material-ui/core';
import {
  postNewWifiBeacon,
  postUpdateWifiBeacon,
} from '../../../API';


const macAddressRegex = /^[0-9a-f]{1,2}([\.:-])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}$/;

export default function(props) {

  const [newWifiBeacon, setNewWifiBeacon] = useState({ name: '', lat: 0, lng: 0, macAddress: '' });
  const [beaconId, setBeaconId] = useState(undefined);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const change = { [name]: value };
    setNewWifiBeacon({ ...newWifiBeacon, ...change });
  }

  useEffect(() => {
    setNewWifiBeacon({ ...newWifiBeacon, ...props.lnglat });
  }, [props.lnglat.lat, props.lnglat.lat]);

  useEffect(() => {
    setNewWifiBeacon({
      name: props.updateableWifiBeacon.Name,
      lat: props.updateableWifiBeacon.Latitude,
      lng: props.updateableWifiBeacon.Longitude,
      macAddress: props.updateableWifiBeacon.MacAddress,
    });
    setBeaconId(props.updateableWifiBeacon.WifiBeaconId);
  }, [props.updateableWifiBeacon.WifiBeaconId]);

  const handleSaveClick = () => {
    if(!beaconId) {
      postNewWifiBeacon(newWifiBeacon).then(props.handleNewWifiBeacon);
    } else {
      postUpdateWifiBeacon(beaconId, newWifiBeacon).then(props.handleNewWifiBeacon);
    }
    setBeaconId(undefined);
    setNewWifiBeacon({name: '', macAddress: '', lat: 0, lng: 0});
  }

  const handleCancelClick = () => {
    setBeaconId(undefined);
    props.handleCancel();
    setNewWifiBeacon({name: '', macAddress: '', lat: 0, lng: 0});
  }

  const { name, macAddress, lat, lng } = newWifiBeacon;
  
  const macOk = macAddressRegex.test(macAddress);
  const latOk = lat >= -90 && lat <= 90;
  const lngOk = lng >= -180 && lng <= 180;

  return <Paper style={{ padding: '10px 30px 20px 30px', margin: '20px', width: '180px' }}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl>
            <TextField type="text" 
              name="name" 
              value={ name || '' } 
              onChange={handleFieldChange} 
            />
            <FormHelperText error={!name}>Name</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <TextField type="text" 
              name="macAddress" 
              value={ macAddress || ''} 
              onChange={handleFieldChange} 
            />
            <FormHelperText error={!macOk}>Mac</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <TextField InputProps={{
              inputProps: { 
                max: 90, min: -90 
              }}} 
              type="number" 
              name="lat" 
              value={ newWifiBeacon.lat || '' }
              onChange={handleFieldChange}
              style={{minWidth: '170px'}}
              disabled
            />
            <FormHelperText error={!latOk}>Latitude</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <TextField InputProps={{
              inputProps: { 
                max: 180, min: -180 
              }}} 
              type="number" 
              value={ newWifiBeacon.lng || '' }
              name="lng" 
              onChange={handleFieldChange}
              style={{minWidth: '170px'}}
              disabled
            />
            <FormHelperText error={!lngOk}>Longitude</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button disabled={ !name || !latOk || !macOk || !lngOk } onClick={handleSaveClick}>Save</Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </Grid>
      </Grid>
    </Paper>;
}