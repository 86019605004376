import { handle, Handlers} from 'redux-pack';
import { PayloadAction } from '../actions/types'
import { RESTCLIENT_ActionTypes } from '../actions/ConfigurationActions'
import { RestClient } from '../../RestClient/RestClientTypes';
import { EnvironmentVariables } from '../../Constants/EnvironmentVariables';


export interface ConfigurationState {  
  env?: EnvironmentVariables;
  client?: RestClient;
  error? : string;
}
const initialState: ConfigurationState = {};

export function configurationReducer(state: ConfigurationState, action: PayloadAction<any>): ConfigurationState {
  const newState = makeNewState(state, action);
  return newState;
}


function makeNewState(state: ConfigurationState = initialState, action: PayloadAction<any>): ConfigurationState {

  const commonOnStartHandler = (s: ConfigurationState, a: PayloadAction<any>) => ({
    ...s, 
  });

  const commonErrorHandler =   (s: ConfigurationState,a: PayloadAction<Error>) => ({
     ...s, 
     error: a.payload.message, 
  });
  

  switch(action.type){
    case RESTCLIENT_ActionTypes.ENVIRONMENT: {
      const handlerActions : Handlers<ConfigurationState, EnvironmentVariables, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => ({
          ...s, 
          env:a.payload, 
        }),
      };

      return handle(state, action, handlerActions);
    }

    case RESTCLIENT_ActionTypes.RESTCLIENT:{
      const handlerActions : Handlers<ConfigurationState, RestClient, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => ({
          ...s, 
          client:a.payload, 
        }),
      };

      return handle(state, action, handlerActions);
    }

    default:
      return state;
  }
}