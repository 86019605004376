import React, { Component } from 'react';
import {
  Paper,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Button,
  Typography,
  Fab,
  TextareaAutosize,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Common/FormStyles';
import { getWorkerNotes, addWorkerNote } from '../../API';
import { replaceInArray } from '../../Utils';
import moment from 'moment';


export function NotesForm(props) {
  const { 
    newNote,
    onTextAreaChange,
    onSendClick,
  } = props;

  return <ListItem style={{ maxWidth: '450px'}}>
      <ListItemText
          primary={'Notes'}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'block' }}
                component="p"
                variant="body2"
              >
                Add new note
              </Typography>
              <TextareaAutosize
                rowsMin={4}
                value={ newNote }
                style={{ width: 350 }}
                onChange={(e) => onTextAreaChange(e)}
                disabled={!onTextAreaChange}
              />
            </React.Fragment>
          }
      />
      <div>
        <Button color='primary' style={{float: 'left'}} onClick={() => onSendClick()} disabled={!newNote}>
          Save
        </Button>
      </div>
    </ListItem>
}



class WorkerNotes extends Component {
  state = {
    workerNotes: [],
    newNote: "",
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    var messages = [];
    this.setState({ message: 'notes...'});
    const notes = await getWorkerNotes(this.props.workerStatus.WorkerId);
    notes.sort((g, a) => moment(g.CreatedAt).isBefore(moment(a.CreatedAt)) ? 1 : -1);
    this.setState({ workerNotes: notes, message: ' ' });
  }

  handleTextAreaChange = (e) => {
    this.setState({ newNote: e.target.value });
  }

  handleSendClick = async () => {
    if(!this.state.newNote) {
      return;
    }
    var payload = {
      workerId: this.props.workerStatus.WorkerId,
      content: this.state.newNote,
    };
    await addWorkerNote(payload);
    this.setState({ newNote: "" });
    this.getData();
  }

  render() {
    const { workerNotes } = this.state;
    return (
      <div style={{ padding: '15px', minHeight: '70vh', overflow: 'auto' }}>
        <Grid container>
         <Grid item xs={12}>
           <NotesForm 
             onSendClick={this.handleSendClick}
             onTextAreaChange={this.handleTextAreaChange}
             newNote={this.state.newNote}
           />
         </Grid>
         <Grid item xs={12}>{this.state.message}</Grid>
         <Grid item xs={12} style={{paddingTop: '20px'}}>
            <List>
              { workerNotes.map(n => {
                return <ListItem key={n.WorkerNoteId }>
                  <ListItemText
                    primary={<Typography type="body2">{n.Content}</Typography>}
                    secondary={moment(n.CreatedAt).fromNow()}
                  />
                </ListItem>
              })}
              
            </List>
           </Grid>
        </Grid>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(WorkerNotes);
