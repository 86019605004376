import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import SiteM8Logo from '../Common/SiteM8Logo';


export default function TimesheetTableHeader({ timesheet, worker }) {
  const totalTimeStr = timesheet.timesheetEntryList.getTotalTimeStr();
  const dateDesc = timesheet.getDateDescription();
  return (
    <Grid container style={{ paddingTop: '15px' }}>
      <Grid item>
        <SiteM8Logo width="150px" />
      </Grid>
      <Grid item style={{ paddingLeft: '6px' }}>
        <Grid item>
          <Typography variant="h6">
            { worker.Name }
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            { `${dateDesc}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            { `${totalTimeStr} hrs`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
