import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/AddCircleOutline';


const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

const newZone = {
  Name: '',
  Active: true,
  WKT: '',
  Geom: { },
  ZoneId: '',
};

class ZonesList extends Component {
  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { zones, onZoneClick, onNewZoneClick } = this.props;
    const items = zones.map((s, i) => (
      <ListItem key={i} button onClick={() => onZoneClick(s)}>
        <ListItemText primary={s.Name} />
      </ListItem>
    ));

    return (
      <List
        component="nav"
        subheader={<ListSubheader component="div">Zones</ListSubheader>}
      >
        <ListItem button key="NewZone" onClick={() => onNewZoneClick(newZone)}>
          <ListItemText primary="New Zone" />
          <ListItemIcon><AddIcon /></ListItemIcon>
        </ListItem>
        { items }
      </List>
    );
  }
}

export default withStyles(styles)(ZonesList);
