import {
  SET_ZONES,
  CREATE_ZONE,
  UPDATE_ZONE,
} from './actions/ActionTypes';
import { assign, replaceInArray } from '../Utils';

const initialState = {
  all: [],
};
const id = 'ZoneId';
export default function (state = initialState, action) {
  const { all } = state;
  const { payload } = action;
  switch (action.type) {
    case SET_ZONES: {
      return assign(state, { all: payload });
    }
    case CREATE_ZONE: {
      return assign(state, { all: [...state.all, payload] });
    }
    case UPDATE_ZONE: {
      return assign(state, { all: replaceInArray(all, payload, id) });
    }
    default:
      return state;
  }
}
