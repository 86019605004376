import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  List, ListItem, ListItemText, Grid, Typography,
} from '@material-ui/core';


class TimesheetNotesList extends Component {
  renderNote = (note, i) => {
    const { Details, WorkerId, CreationDateTime } = note;
    let name = 'Super Admin User';
    const worker = this.props.workers.find(w => w.WorkerId === WorkerId);
    if (worker) {
      name = worker.Name;
    }
    const dt = moment(CreationDateTime).format('Do MMM HH:mm');
    const style = note.Priority > 0 ? { color: 'red' } : {};
    return (
      <ListItem key={i} className="ts-note-list-item">
        <ListItemText
          primary={<Typography type="body2" style={style}>{Details}</Typography>}
          secondary={`${dt} - ${name}`}
        />
      </ListItem>
    );
  }

  render() {
    const notes = this.props.timesheetNotes.map(
      this.renderNote,
    );
    return (
      <List>
        { notes }
      </List>
    );
  }
}

const mapStateToProps = state => ({
  timesheetNotes: state.timesheetNotes.all,
  workers: state.workers.all,
});

export default withRouter(connect(mapStateToProps)(TimesheetNotesList));
