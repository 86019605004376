import React, { Component } from "react";
import {
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Button,
} from '@material-ui/core';
import {
  getAllSites,
  getAllZones,
} from '../../API';
import moment from 'moment-timezone';
import { selectColor, hslToHex, randomColor } from "../../Utils";
import { printToPDF } from '../Utils/PrintPdf';
import { getZoneReport } from "../../API";
import MUIDataTable from 'mui-datatables';
import ZoneReportMap from "./ZoneReportMap";
import { makeZoneReportHTML } from "./HTMLZoneReport";
import * as turf from '@turf/turf';


const columns = [
  { name: 'Worker' },
  { name: 'Zone',
    options: {
      customBodyRender: (val) => {
        const colorlabelStyle = { backgroundColor: val.zone.color, height: '15px',
          width: '15px', display: 'inline-block' };
        return <div><div style={colorlabelStyle}></div><span style={{marginLeft: '5px'}}>{val.zoneName}</span></div>
      }
    },
  },
  { name: 'Time Spent' },
  { name: 'Number of locations' },
]

function zoneReportRow(item) {
  const { workerName, zoneName, totalMin, timeText, numLocs } = item;

  return [workerName, item, timeText, numLocs ];
}

const minsToStr = (r) => {
  const hours = Math.floor(r / 60);
  const mins = r % 60;
  const timeText = `${hours}h ${mins}m`;
  return timeText;
}

const options = {
  filterType: 'multiselect',
  selectableRows: false,
  responsive: 'scroll',
  rowsPerPage: 50,
  rowsPerPageOptions: [10, 20, 30, 40, 50],
};

export default class ZoneReportPage extends Component {

  state = {
    startDate: moment().add(-7, 'days'),
    endDate: moment(),
    results: [],
    sites: [],
    zones: [],
    selectedSite: null,
    colorMap: {},
  }

  componentDidMount() {
    Promise.all([
      getAllSites(),
      getAllZones(),
    ]).then(data => {
      const colorMap = {};
      data[1].forEach((d, i) => {
        colorMap[d.ZoneId] = randomColor();
        const centroid = turf.centroid(d.GeoJSON);
        d.color = colorMap[d.ZoneId];
        d.centroid = centroid;
      });
      this.setState({ sites: data[0], zones: data[1], selectedSite: data[0][0], colorMap });
    });
  }

  setMapRef = (mapRef) => {
    this.setState({ mapRef });
  }

  handleStartDateChange = ({ target }) => {
    this.setState({ startDate: moment(target.value, 'YYYY-MM-DD') });
  }

  handleEndDateChange = ({ target }) => {
    this.setState({ endDate: moment(target.value, 'YYYY-MM-DD') });
  }

  handleSubmit = async () => {
    const results = await getZoneReport(
      moment(this.state.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      moment(this.state.endDate).utc().add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
    );  

    const usefulResults = results ? results.filter(r => !!r.totalMin) : [];
    usefulResults.forEach(r => {
      r.timeText = minsToStr(r.totalMin);
    });
    this.setState({ results: usefulResults });
  }

  printReport = () => {
    const { zones, results, startDate, endDate, mapRef, colorMap } = this.state;
    const sortByTotalMins = (a, b) => {
      if(a.totalMin > b.totalMin) {
        return -1;
      } else if(a.totalMin < b.totalMin)
      {
        return 1;
      }
      return 0;
    };
    const zoneReports = this.state.zones.map(z => {
      const workerReports = results.filter(r => r.zoneId == z.ZoneId);
      workerReports.sort(sortByTotalMins);
      const totalMins = workerReports.length ? workerReports.map(w => w.totalMin).reduce((a, b) => a + b) : 0;
      return { name: z.Name, workerReports, total: minsToStr(totalMins), totalMin: totalMins, color: colorMap[z.ZoneId] };
    });
    const options = {
      //filename: `contact-report-${this.state.selectedWorker.Name.replace(' ', '')}`,
      filename: Math.floor(Math.random() * 1000000),
      jsPDF: { orientation: 'portrait' },
      pagebreak: {avoid: ['li', 'h3']},
      margin: 5,
    };
    const dt = moment();
    const dataUrl = mapRef.getCanvas().toDataURL('image/png');
    zoneReports.sort(sortByTotalMins);

    const printVals = {
      printDate: dt.format('DD/MM/YYYY'),
      printTime: dt.format('hh:mm a'),
      startDate: startDate.format('DD/MM/YYYY'),
      endDate: endDate.format('DD/MM/YYYY'),
      zoneReports: zoneReports.filter(zr => !!zr.workerReports.length),
    };
    const html = makeZoneReportHTML(printVals, dataUrl);
    printToPDF(html, options);
  }

  render() {
    const { startDate, endDate, results, zones, sites, colorMap } = this.state;
    const rows = results.map(r => {
      const rZone = {...r, zone: zones.find(z => r.zoneId == z.ZoneId)}
      return zoneReportRow(rZone);
    });;
    return (
      <Grid container className="dashboard-container">
        <Grid item xs={12} md={6} lg={6} style={
          { backgroundColor: '#fff', paddingLeft: '0px', paddingTop: '15px' }}>
          <Grid item xs={12}>
            <div style={{marginLeft: '20px'}}>
              <TextField
                type='date'
                label='Start Date'
                value={startDate.format('YYYY-MM-DD')}
                onChange={this.handleStartDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type='date'
                label='End Date'
                value={endDate.format('YYYY-MM-DD')}
                onChange={this.handleEndDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormHelperText>Select start date and end date</FormHelperText>
            </div>
            <div>
              <div style={{marginLeft: '20px', paddingTop: '8px', display: 'inline'}}>
                <FormControl>
                  <Button variant="outlined" onClick={this.handleSubmit} style={{float: 'right'}}>Submit</Button>
                </FormControl>
              </div>
              <div style={{marginLeft: '20px', paddingTop: '8px', display: 'inline'}}>
                <FormControl>
                  <Button variant="outlined" onClick={this.printReport} style={{float: 'right'}}>Print</Button>
                </FormControl>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '10px', paddingLeft: '0'}}>
            <ZoneReportMap
              zones={zones}
              sites={sites}
              setMapRef={this.setMapRef}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MUIDataTable
            data={rows}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    )
  }

}