import React, { Component } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  FilledInput,
  MenuItem,
} from '@material-ui/core';
import { getCompanies, switchCompany } from '../../API';

export default class SuperAdminPage extends Component {
  state = {
    company: 0,
    companies: [],
  }

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    const companies = await getCompanies();
    this.setState({ companies: companies.filter(c => !c.IsArchived) });
  }

  handleSelectChange = async (e, b) => {
    this.setState({ company: b.props.value });
    await switchCompany(b.props.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  }

  render() {
    const { companies } = this.state;
    const { companyName } = this.props;
    return (
      <form>
        <FormControl variant="filled" style={{ minWidth: '160px' }}>
          <InputLabel>{ companyName }</InputLabel>
          <Select
            value={this.state.company}
            onChange={this.handleSelectChange}
            input={<FilledInput />}
          >
            { companies.map(
              c => (
                <MenuItem key={c.CompanyId} value={c.CompanyId}>
                  { c.Name }
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </form>
    );
  }
}
