import React, { Component } from 'react';
import { postAddSystemAdmin } from '../../API';
import { validateEmail } from '../../Utils';
import { FormControl, Grid, Input, Button } from '@material-ui/core';


export default class AddSystemAdminForm extends Component {

  state = {
    email: '',
    emailIsValid: false,
    message: 'please enter a new email, or the email of an existing Admin user',
  }

  handleEmailInputChange = (email: string) => {
    const emailIsValid = validateEmail(email);
    this.setState({ email, emailIsValid });
  }

  handleButtonClick = async (e: React.MouseEvent) => {
    
    const newAdmin = await postAddSystemAdmin(this.state.email);
    if(newAdmin) {
      this.setState({
        email: '',
        emailIsValid: false,
        message: `${newAdmin.Name} ${newAdmin.Email} is a super admin now`,
      });
    } else {
      this.setState({ message: 'failure please use another email' });
    }

  }

  render() {
    const { email, emailIsValid, message } = this.state; 
    const emailHandler = ({ target }: any) => this.handleEmailInputChange(target.value);
    return <Grid item xs={6} style={{ margin: 'auto'}}>
        <FormControl>
          <Input id="add-system-admin-email-input" type="email" onChange={emailHandler} value={email} placeholder="enter email" />
        </FormControl>
        <FormControl>
          <Button id="add-system-admin-button" disabled={!emailIsValid} onClick={this.handleButtonClick}>
            Create System Admin
          </Button>
        </FormControl>
        <h5 id="add-system-admin-message">{ message }</h5>
      </Grid>
  }

}