import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid, Drawer,
  Typography, Button,
} from '@material-ui/core';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Modal from '@material-ui/core/Modal';
import {
  isMobile,
} from 'react-device-detect';
import ReportingTable from './ReportingTable';
import TimesheetsForm from './TimesheetsForm';
import { downloadCSVContent, uniqInArray, formatDuration } from '../../Utils';
import {
  setTimesheetRange,
  setReportDate,
  updateTimesheetReportTable,
  setLoading,
} from '../../redux/actions/AllActions';
import {
  getDistinctTimesheets,
  getTimesheetReport,
  getExportTimesheet,
  lockTimesheets,
  getAllSites,
  getApprovedTimesheetCount,
} from '../../API';
import { styles } from '../Common/GlobalStyles';

const statuses = {
  0: 'Draft',
  1: 'Approved',
  2: 'Locked',
};


function countStatus(report, status) {
  return uniqInArray(report.filter(
    r => r.Status === status).map(r => `${r.PayrollId}`)).length;
}

// Create a report component, both container and table
class ReportContainer extends Component {
    state = {
      timesheetRows: [],
      datesSpan: null,
      timesheets: [],
      selectedTimesheetId: null,
      statusCounts: {},
      open: true,//!isMobile,
      csvFormat: '0',
      loaded: false,
    }

    // Fixed Function that can initialize dom nodes
    componentDidMount() {
      this.setInitialData();
    }

    setInitialData = async () => {
      this.props.setLoading(true);
      const timesheets = await getDistinctTimesheets();
      const sites = await getAllSites();
      if (!timesheets.length) {
        return;
      }
      const ts = timesheets.length > 1 ? timesheets[1] : timesheets[0];
      await this.getReport(ts.TimesheetId);
      this.setState({
        datesSpan: `${moment(ts.StartDateTime).format('Do MMM')} - ${moment(ts.FinishDateTime).add(-1, 'days').format('Do MMM')}`,
        timesheets,
        selectedTimesheetId: ts.TimesheetId,
        sites,
        loaded: true,
      });
      this.props.setLoading(false);
    }

    onCsvFormatChange = async (e, csvFormat) => {
      this.setState({ csvFormat });
      const { selectedTimesheetId } = this.state;
      if (selectedTimesheetId) {
        this.getReport(selectedTimesheetId, csvFormat);
      }
    }

    getReport = async (tsId, format) => {
      const { csvFormat } = this.state;
      this.props.setLoading(true);
      const data = await Promise.all([
        getTimesheetReport(tsId, format || csvFormat),
        getApprovedTimesheetCount(tsId),
      ]);
      const approvedCount = data[1];
      const report = data[0];
      const draft = countStatus(report, 0);
      const locked = countStatus(report, 2);
      const draftTimesheets = uniqInArray(report.filter(
        r => r.Status === 0).map(r => `${r.PayrollId} - ${r.DayWorked}`)).length;
      this.setState({
        timesheetRows: report,
        statusCounts: {
          approved: approvedCount, locked, draft, draftTimesheets,
        },
      });
      this.props.setLoading(false);
    }

    onDateChange = async ({ target }) => {
      const { value } = target;
      const { timesheets } = this.state;
      const id = parseInt(value, 0);
      const ts = timesheets.find(t => t.TimesheetId === id);
      await this.getReport(id);
      this.setState({
        datesSpan: `${moment(ts.StartDateTime).format('Do MMM')} - ${moment(ts.FinishDateTime).add(-1, 'days').format('Do MMM')}`,
        selectedTimesheetId: id,
      });
    }

    // Export CSV function
    exportCSV = async () => {
      const { selectedTimesheetId, csvFormat } = this.state;
      // do a popup with the callback =
      const data = await getExportTimesheet(selectedTimesheetId, csvFormat);
      downloadCSVContent(data);
      this.setState({
        showAfterCSVModal: true,
      });
    }

    // Lock function
    lockTimesheets = async () => {
      const { selectedTimesheetId } = this.state;
      await lockTimesheets(selectedTimesheetId);
      await this.getReport(selectedTimesheetId);
      this.setState({
        showLockModal: false,
      });
    }

    showLockModal = async () => {
      this.setState({
        showLockModal: true,
      });
    }

    showAfterCSVModal = async () => {
      this.setState({
        showAfterCSVModal: false,
      });
    }

    afterModalClose = async () => {
      await this.getReport(this.state.selectedTimesheetId);
      this.setState({
        showAfterCSVModal: false,
        showLockModal: false,
      });
    }

    renderAfteCSVModal = () => {
      const { locked, approved, draft } = this.state.statusCounts;
      const { datesSpan } = this.state;
      const { classes } = this.props;
      const showNotExport = !!(approved + draft);
      return (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.showAfterCSVModal}
          onClose={this.afterModalClose}
        >
          <div className={classNames(classes.modalStyle, classes.modalPaper)}>
            <Typography variant="h6" id="modal-title">
              Export Completed
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              <p>{ `Locked timesheets exported for ${locked} users for ${datesSpan}.`}</p>
              { showNotExport && <p>{`Timesheets for ${approved + draft} users were NOT exported.`}</p> }
            </Typography>
            <Grid container spacing={24}>
              <Grid item xs={4}>
                <Button type="Button" color="primary" variant="contained" onClick={this.afterModalClose}>
                    OK
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      );
    }

    renderLockModal = () => {
      const { approved, draftTimesheets } = this.state.statusCounts;
      const { datesSpan } = this.state;
      const { classes } = this.props;
      const msg1 = `Please confirm you want to lock timesheets ${approved} users for ${datesSpan}.`;
      const msg2 = draftTimesheets ? `There are ${draftTimesheets} timesheets that cannot be locked` : '';
      return (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.showLockModal}
          onClose={this.afterModalClose}
        >
          <div className={classNames(classes.modalStyle, classes.modalPaper)}>
            <Typography variant="h6" id="modal-title">
              Please Confirm
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              <p>{ msg1}</p>
              { draftTimesheets > 0 && <p>{ msg2 }</p> }
            </Typography>

            <Grid container spacing={24}>
              <Grid item xs={8}>
                <Button onClick={this.lockTimesheets} color="primary" variant="contained">
                  <label className="dark-Button">
                    Confirm
                  </label>
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button color="secondary" variant="contained" onClick={this.afterModalClose}>
                    Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      );
    }

    toggleDrawer = (e, open = false) => {
      this.setState(
        prevState => ({ open: open || !prevState.open }));
    }

    renderDrawerContent = () => {
      const { timesheets, selectedTimesheetId, csvFormat } = this.state;
      const { classes } = this.props;
      const onDateChange = this.onDateChange.bind(this);
      return (
        <div>
          <TimesheetsForm
            classes={classes}
            timesheets={timesheets}
            selectedTimesheetId={selectedTimesheetId}
            onDateChange={onDateChange}
            onLockClick={this.showLockModal}
            onExportClick={this.exportCSV}
            csvFormat={csvFormat}
            onCsvFormatChange={this.onCsvFormatChange}
          />

        </div>

      );
    }

    render() {
      const {
        timesheetRows, open, showLockModal, showAfterCSVModal, sites, loaded,
      } = this.state;
      if (!(loaded && sites)) {
        return <div />;
      }
      const { classes } = this.props;
      const tableRows = timesheetRows.map((n) => {
        const breaktime = formatDuration(n.HoursBreak);
        const workTime = formatDuration(0, n.HoursWorked * 60);
        return [
          n.PayrollId || '',
          n.Name,
          n.SiteName || 'Unknown',
          n.DayWorked ? moment(n.DayWorked).format('YYYY-MM-DD') : '',
          moment(n.StartDateTime).format('H:mm'),
          moment(n.FinishDateTime).format('H:mm'),
          workTime,
          breaktime,
          statuses[n.Status],
        ];
      });

      return (

        <div className={classes.root}>
          <CssBaseline />
          { showLockModal && this.renderLockModal() }
          { showAfterCSVModal && this.renderAfteCSVModal() }
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
            classes={{
              paper: classNames(classes.drawer, open ? classes.drawerOpen : classes.drawerClose),
            }}
            open={open}
          >
            <div className={classes.toolbar} onClick={this.toggleDrawer}>
              <IconButton>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            { open && this.renderDrawerContent() }
          </Drawer>

          <main className={classes.content} style={{ width: '74vw' }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <ReportingTable rows={tableRows} />
              </Grid>
            </Grid>
          </main>
        </div>
      );
    }
}

const mapStateToProps = (state) => {
  const { timesheetReport } = state;
  return { timesheetReport: timesheetReport.all };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateTimesheetReportTable: x => dispatch(updateTimesheetReportTable(x)),
  setTimesheetRange: x => dispatch(setTimesheetRange(x)),
  setReportDate: x => dispatch(setReportDate(x)),
  setLoading: x => dispatch(setLoading(x)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, { withTheme: true })(ReportContainer)));
