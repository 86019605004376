/* eslint-disable no-case-declarations */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';


const COLUM_INDEXS = {
  NAME : 0,
  DESCRIPTION : 1,
  WORKEDID :2,
  LAST_UPDATED_COL:3,
  BATTERY :4,
  TIMESTAMP: 5
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    maxWidth: '100vw',
  },
});


const timesort = (data, colIndex, order) => data.sort( (a, b) => {    
    const orderedData = (order === 'asc') ? [a.data, b.data] : [b.data, a.data];
    switch (colIndex) {
      case COLUM_INDEXS.LAST_UPDATED_COL:
      case COLUM_INDEXS.TIMESTAMP:
        const isAfter = moment(orderedData[0][COLUM_INDEXS.TIMESTAMP]).isAfter(moment(orderedData[1][COLUM_INDEXS.TIMESTAMP]));
        return isAfter ? 1 : -1;
      default:
        return orderedData[0][colIndex] > orderedData[1][colIndex] ? 1 : -1;
    }
  });

const tableLabels = [
  /* {
    name: 'Photo',
    options: {
      filter: true,
      customBodyRender: value => (
        <Tooltip title={value.status.text} placement="right">
          <div className="table-img-container">
            <img
              alt="user avatar"
              src={value.img}
            />
            <div className="relative-pos-no-space">
              <div
                className={`worker-status-indicator ${value.status.className}`}
              />
            </div>
          </div>
        </Tooltip>
      ),
      display: true,
    },
  }, */

  { name: 'Name',         options: { sort: true } },
  { name: 'Description',  options: { display: true, sort: false }},
  { name: 'WorkerId',     options: { display: false, sort: false }},
  { name: 'Last Updated', options: {display: true, sort: false,}},
  { name: 'Battery',      options: { display: true, sort: false } },
  { name: 'Timestamp',    options: { display: false, sort: false } },
  { name: 'DeviceId',  options: { display: false, sort: false } },
];


class WorkerLocationTable extends Component {
  

  onChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ 
      options: { ...this.state.options, rowsPerPage }, 
      columns: this.state.columns 
    });
  }

  setRowProps = (row) => {
    if (row[COLUM_INDEXS.WORKEDID] === this.props.selectedWorkerId) {
      return {
        style: { background: 'lightgrey' },
      };
    }
  }
  
  onColumnsChanged = (columName, action) => {
    const columSource = tableLabels;
    const column = columSource.find(x => x.name === columName);
    if(column){
      column.options.display =  (action === 'add');
    }    
  }
  

  // This mast come after clas functions are assigned otherwise they are undefined
  state = {
    options: {
      selectableRows: false,
      rowsPerPage: 100,
      filter: false,
      download: false,
      print: false,
      viewColumns: true,
      rowsPerPageOptions: [20, 50, 100 ],
      responsive: 'scroll',
      customSort: timesort,
      onChangeRowsPerPage: this.onChangeRowsPerPage,
      setRowProps: this.setRowProps,
      onViewColumnsChange : this.onColumnsChanged,
      onColumnViewChange : this.onColumnsChanged,
    }
  }

  render() {
    const {rows, classes, onRowsSelect,onRowClick, onRowsDelete } = this.props;

    const options = {
      ...this.state.options,
      onRowClick,
      onRowsDelete,
      onRowsSelect,
    };
    

    return (
      <MUIDataTable
        style={{textOverflow: 'ellipsis',}}
        className={classes.table}        
        columns={tableLabels}
        data={rows}
        options={options}
        onRowsSelect={onRowsSelect}        
      />
    );
  }
}


export default withStyles(styles)(WorkerLocationTable);
