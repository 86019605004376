import React from 'react';
import GridList from '@material-ui/core/GridList';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { func } from 'prop-types';
import SitePickerTile from './SitePickerTile';


const styles = {
  gridList: {
    flexWrap: 'nowrap',
    backgroundColor: 'rgb(117, 102, 35)',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  leftPicker: {
    position: 'absolute',
    height: '120px',
    width: '22px',
    backgroundColor: 'rgba(0,0,0,0.5)',
    left: 0,
    top: 0,
    zIndex: 9,
  },
  rightPicker: {
    position: 'absolute',
    height: '120px',
    width: '22px',
    backgroundColor: 'rgba(0,0,0,0.5)',
    right: 0,
    top: 0,
    zIndex: 9,
  },
  leftChevron: {
    color: 'white',
    marginTop: '40px',
  },
};

function scrollGridLeft() {
  const glist = document.getElementById('site-picker-list');
  glist.scrollTo(glist.scrollLeft - 800, 0);
}

function scrollGridRight() {
  const glist = document.getElementById('site-picker-list');
  glist.scrollTo(glist.scrollLeft + 800, 0);
}

function removeUnusedIcons() {
  const gList = document.getElementById('site-picker-list');
  if (gList && gList.scrollWidth <= gList.offsetWidth) {
    document.getElementById('left-picker-icon').setAttribute('style', 'display: none');
    document.getElementById('right-picker-icon').setAttribute('style', 'display: none');
  }
}

export default function SiteGridList(props) {
  const { sites, siteSelected, onClick } = props;
  setTimeout(() => {
    removeUnusedIcons();
  }, 100);


  const tiles = sites.map((s, i) => (
    <SitePickerTile
      key={i}
      site={s}
      isSelected={siteSelected && s.SiteId === siteSelected.SiteId}
      onClick={() => onClick(s)}
    />
  ));
  return (
    <div id="site-picker-list-wrapper">
      <div id="left-picker-icon" style={styles.leftPicker} onClick={scrollGridLeft}><ChevronLeftIcon fontSize="default" style={styles.leftChevron} /></div>
      <GridList style={styles.gridList} cols={2.5} id="site-picker-list">
        {tiles}
      </GridList>
      <div id="right-picker-icon" style={styles.rightPicker} onClick={scrollGridRight}><ChevronRightIcon fontSize="default" style={styles.leftChevron} /></div>
    </div>
  );
}
