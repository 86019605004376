import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  getWorkerGroups,
  createWorkerGroup,
} from '../../API';
import {
  setWorkerGroups,
} from '../../redux/actions/AllActions';
import { styles } from '../Approvals/Approvals';


export function CreateWorkerGroupForm(props) {

  const [groupName, setGroupName] = useState('');
  const [deviceInterval, setDeviceInterval] = useState(null);
  const [deviceMode, setDeviceMode] = useState(null);

  const onSaveGroupClick = () => {
    createWorkerGroup(groupName, deviceMode, deviceInterval).then(
      () => getWorkerGroups().then(props._setWorkerGroups));
  }

  return (
    <div>
      <input 
        name="new-group-name" 
        value={groupName} 
        onChange={(e) => setGroupName(e.target.value)} 
      />
      <input
        placeholder="interval"
        step="0.5"
        type="number"
        name="new-group-interval"
        value={deviceInterval}
        onChange={(e) => setDeviceInterval(parseFloat(e.target.value))}
      />
      <input
        placeholder="mode"
        type="number"
        name="new-group-mode"
        value={deviceMode}
        onChange={(e) => setDeviceMode(parseInt(e.target.value))}
      />
      <button onClick={onSaveGroupClick}>Save New Group</button>
    </div>
  );

}

export const mapStateToProps = () => ({});

export const mapDispatchToProps = dispatch => ({
  _setWorkerGroups: x => dispatch(setWorkerGroups(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(CreateWorkerGroupForm)));
  