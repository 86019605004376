import React, { Dispatch, PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteProps, withRouter } from "react-router-dom";
import { RouterProps } from 'react-router';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Theme,
  Modal,
  Button,
} from "@material-ui/core";
import { Worker, CovidCase } from '../../api/index';
import { RootReducerType } from "../../redux/store/rootReducer";
import MUIDataTable from 'mui-datatables';
import { WorkerCovidActions } from '../../redux/actions/WorkersCovidActions';
import { CovidCaseActions } from '../../redux/actions/CovidCaseActions';
import { WorkersState } from '../../redux/reducers/WorkersCovidReducer';
import { CovidCaseState } from "../../redux/reducers/CovidCaseReducer";
import { isoDateStringToDayFormat, IsoStringIsBeforeIsoString } from '../../Utils';
import { FormFieldType, FormOption, FormModelBase } from "../../RestClient/RestApiMicroFormat";
import { PayloadAction } from "../../redux/actions/types";


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    margin: 10,
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


// Props passed from parent
//interface CovidCasesPageOwnProps : any;

// State passed from Redux
interface StateProps {
  tableRows: Array<Array<string>>;
  apiRoot: any;
  selectedWorker: Worker;
  createFormUri?: string;
}

// Actions wrapped in dispatch
interface DispatchProps {
  getWorkersList(uri: string): void;
  getCovidCasesList(uri: string): void;
  setSelectedWorker(uri: string) : void;
  clearCovidCase(): void;
  loadCreateCaseForm(formUrl: string) : Promise<PayloadAction<FormModelBase>>;
}


// Combine all prop types
type Props = StateProps & DispatchProps & RouteProps & RouterProps;

export function CovidCasesPage(props: PropsWithChildren<Props>): JSX.Element {
  const classes = useStyles();
  const [modalOpen, openModal] = useState(false);
  const [loadedCases, setCasesLoaded ] = useState(false);
  const [loadedWorkers, setLoadedWorkers ] = useState(false);
  const [loadedForm, setLoadedForm ] = useState(false);

  // componentDidMount
  useEffect(() => {
   
      if(!loadedWorkers) {
        props.getWorkersList(props.apiRoot.companyUri);
        setLoadedWorkers(true);
      }
      
      if(props.createFormUri && !loadedForm) {
        props.loadCreateCaseForm(props.createFormUri);
        setLoadedForm(true);
      }
      if(!loadedCases) {
        props.getCovidCasesList(props.apiRoot.companyUri);
        setCasesLoaded(true);
      }
  });

  return (
    <div className={classes.root} id="covid-cases-page">
      <MUIDataTable
        style={{ textOverflow: 'ellipsis' }}
        columns={workerCovidTableHeaders}
        data={props.tableRows}
        options={{
          selectableRows: 'none',
          onRowClick: (row) => {
            props.setSelectedWorker(row[0]);
            openModal(true)
          },
          viewColumns: false,
          responsive: 'scroll',
          rowsPerPage: 100,
        }}
        id={"worker-cases-table"}
      />
      <div>{ props.selectedWorker && props.selectedWorker.name }</div>
      <Modal
        open={modalOpen}
        onBackdropClick={() => openModal(false)}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <div className={classes.paper}>
          <h2 id="server-modal-title"> { props.selectedWorker && props.selectedWorker.name }</h2>
          <div>
            <Button 
              id="go-to-covid-case"
              onClick={() => {
                props.loadCreateCaseForm(props.selectedWorker.covidCaseCreateFormUri!);
                props.clearCovidCase();
                props.history.push({
                  pathname:'/createcovidcase', 
                  search:`?workerUri=${props.selectedWorker.uri}`
                })}
              }
            >
              Covid Case
            </Button>
          </div>
          <div>
            <Button 
              id="go-to-contact-tracing"
              onClick={() => {
                props.clearCovidCase();
                const { workerId, name, uri } = props.selectedWorker;
                props.history.push({
                  pathname:'/contact',
                  search:`?workerId=${workerId}&name=${name}&uri=${uri}`,
                });  
              }}
            >
              Contact Trace
            </Button>
          </div>
        </div>
       
      </Modal>
    </div>
  );
}


const workerCovidTableHeaders = [
  { name: 'workerUri', options: { display: false, filter: false, download: false, print: false }},
  { 
    name: 'Photo',
    options: {
      customBodyRender: (val:string) => { return val && <img src={val} /> }, 
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
  { name: 'Payroll ID', options: { filter: false} },
  { name: 'Name', options: { filter: false } },
  { 
    name: 'Active Case',
    options: {
      customBodyRender: (val: string) => <input type="checkbox" readOnly={true} checked={!!val} />,
      filter: true,
      sort: false,
    }
  },
  { 
    name: 'Case Status',
    options: {
      filter: true,
      filterType: 'multiselect',
      
    }
  },
  { name: 'First Symptoms', options: { filter: false, sort: false }},
  { name: 'Last Updated', options: { filter: false, sort: false }},
  { name: 'Supervisor', options: { display: false, filter: true }},
  { name: 'covidCaseUri', options: { display: false, filter: false, download: false, print: false  }},
]


function workerToTableRow(worker: Worker, covidCases: Array<CovidCase>, workers: Array<Worker>, formOptions: Array<FormOption>): Array<string> {
  const matchingCases:Array<CovidCase> = covidCases.filter(c => c.workerUri === worker.uri)!;
  const latestMatchingCase:CovidCase = matchingCases.sort((a, b) => IsoStringIsBeforeIsoString(a.createdAt, b.createdAt) ? 1 : -1)[0];
  const supervisor:Worker = workers.find(w => worker.supervisorUri === w.uri)!;
  const caseStatusOption = latestMatchingCase && formOptions.find(o => o.key === latestMatchingCase.caseStatus!);

  return [
    worker.uri,
    worker.workerImageUrl || '',
    worker.payrollId || '',
    worker.name,
    latestMatchingCase && latestMatchingCase.isActive ? 'Active' : '',
    caseStatusOption ? caseStatusOption.description : 'Not a Case',
    latestMatchingCase && latestMatchingCase.caseDateTime ? isoDateStringToDayFormat(latestMatchingCase.caseDateTime) : '',
    latestMatchingCase && latestMatchingCase.updatedAt ? isoDateStringToDayFormat(latestMatchingCase.updatedAt) : '',
    supervisor ? supervisor.name : '',
    latestMatchingCase ? latestMatchingCase.uri! : '',
  ];
}



export const mapStateToProps = (state: RootReducerType, ownProps: any): StateProps => {
  const workersState:WorkersState = state.workersCovid;
  const covidCaseState:CovidCaseState = state.covidCase;
  const { createForm, covidCaseList } = covidCaseState;
  const optionField = createForm && createForm.attributes.find(x => (x.type === FormFieldType.OPTIONS && x.name === "caseStatus"));
  const options = (optionField && optionField.options) ? optionField.options! : [];
  const firstWorker = workersState.workersList[0];
  const createFormUri = firstWorker && firstWorker.covidCaseCreateFormUri;
  return {
    //using this Uri top get the options
    createFormUri: createFormUri,
    tableRows: workersState.workersList.map(
      (w) => workerToTableRow(w, covidCaseList, workersState.workersList, options)),
    apiRoot: state.me.apiRoot,
    selectedWorker: workersState.selectedWorker!,
  };
}

export const mapDispatchToProps = (dispatch:<T>(v:any) => Promise<PayloadAction<T>>, ownProps: any): DispatchProps => {
  return {
    getWorkersList: (uri: string) => dispatch(WorkerCovidActions.getWorkersList(uri)),
    getCovidCasesList: (uri: string) => dispatch(CovidCaseActions.getCovidCaseList(uri)),
    setSelectedWorker: (uri: string) => dispatch(WorkerCovidActions.setSelectedWorker(uri)),
    clearCovidCase: () => dispatch(CovidCaseActions.clearSelectedCovidCase),
    loadCreateCaseForm: (formUrl: string) => dispatch(CovidCaseActions.getCreateCovidCaseForm(formUrl)),
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CovidCasesPage));

