
import React, { Component } from 'react';
import {
  Grid,
  FormControl,
  Select,
  FormHelperText,
  Input,
  MenuItem,
  TextField,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import styles from '../Common/FormStyles';


class ReportingDevicesForm extends Component {
  componentDidMount() {

  }

  render() {
    const {
      classes, workers, startDate, handleDateChange,
      selectedWorkerId, handleDeviceSelectChange,
      handleStartTimeChange, handleEndTimeChange,
      handleToggleTimeFilter, startTime, endTime,
      isTimeFiltering,
    } = this.props;
    const timeFiltering = (startTime && endTime && isTimeFiltering);
    const buttonText = timeFiltering ? 'Filtering Time' : 'Filter Time';
    const buttonType = timeFiltering ? 'secondary' : 'primary';
    return (
      <div>
        <form className={classes.container}>
          <CssBaseline />
          <Grid container>
            <FormControl className={classes.formControl} fullWidth>
              <Select
                name="deviceID"
                value={selectedWorkerId || ''}
                onChange={handleDeviceSelectChange}
                input={(
                  <Input
                    placeholder="Supervisor"
                    margin="dense"
                  />
                )}
                variant="outlined"
              >
                {workers.filter(ww => !!ww.WorkerId).map(
                  (w, i) => <MenuItem key={i} value={w.WorkerId}>{w.Name}</MenuItem>)}
              </Select>
              <FormHelperText>Select Worker</FormHelperText>
            </FormControl>
            <Grid item xs={12} style={{ paddingTop: '15px', paddingRight: '5px' }}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  type="date"
                  label="Timesheet Start"
                  value={`${startDate}`}
                  className={classes.textField}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '15px', paddingRight: '5px' }}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  type="time"
                  label="Start Time"
                  value={startTime}
                  disabled={isTimeFiltering}
                  className={classes.textField}
                  onChange={handleStartTimeChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '15px', paddingRight: '5px' }}>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  type="time"
                  label="End Time"
                  value={endTime}
                  disabled={isTimeFiltering}
                  className={classes.textField}
                  onChange={handleEndTimeChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '15px', paddingRight: '5px' }}>
              <FormControl className={classes.formControl} fullWidth>
                <Button
                  color={buttonType}
                  onClick={handleToggleTimeFilter}
                >
                  {buttonText}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ReportingDevicesForm);
