import React from 'react';
import {
  ListItemText,
  ListItem,
  Typography,
  ListItemSecondaryAction,
  Grid,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
    left: 0,
  },
}));

function renderHeader(fieldName, changeType, createdBy) {
  if(fieldName == 'Note') {
    return `Note by ${createdBy}`;
  }
  return `${changeType} by ${createdBy}`;
}

function renderBody(fieldName, prefix, value) {
  if(fieldName === 'Note') {
    return value;
  }
  if(fieldName ==='Status') {
    //uncamelcase the value
    return `${prefix} ${fieldName}: ${value.replace(/([A-Z])/g, ' $1')}`;
  }
  if(fieldName === 'Date') {
    return `${prefix} First Symptoms Date: ${moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD')}`;

  }
  return `${prefix} ${fieldName}: ${value}`;
}


export default function(props) {

  const { fieldName, changeType, date, createdBy, value, prefix } = props;
  const header = renderHeader(fieldName, changeType, createdBy);
  const body = renderBody(fieldName, prefix, value);
  const classes = useStyles();
  return <ListItem>
    <Grid container
      direction="row"
      justify="center"
      alignItems="flex-start"
    >
      <Grid item xs={10}>
        <ListItemText 
          style={{paddingRight: '200px', marginLeft: '14px' }}
          primary={header}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
              >
                {body}
              </Typography>
            </React.Fragment>
          }
        />


      <ListItemSecondaryAction style={{marginRight: '8%' }}>
        <Typography
          component="span"
          variant="body2"
        >
          { moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') }
        </Typography>
      </ListItemSecondaryAction>
      </Grid>
    </Grid>
   
   
  </ListItem>;
}
