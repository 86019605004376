import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import { Link, withRouter } from 'react-router-dom';
import AssesmentIcon from '@material-ui/icons/Assessment';
import DoneIcon from '@material-ui/icons/Done';
import PersonPinCircle from '@material-ui/icons/PersonPinCircle';
import GroupIcon from '@material-ui/icons/Group';
import WatchLater from '@material-ui/icons/WatchLater';
import MapIcon from '@material-ui/icons/Map';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import logo from '../../Images/logo';
import { LINK_NAMES } from '../../Constants/Constants';

export const iconMap = {
  dashboard: <AssesmentIcon />,
  approvals: <DoneIcon />,
  locations: <PersonPinCircle />,
  timesheets: <WatchLater />,
  users: <PeopleOutlineIcon />,
  sites: <MapIcon />,
  zones: <MapIcon />,
  company: <SettingsIcon />,
  superAdmin: <SettingsIcon />,
  covidcases: <PersonPinCircle />,
  workergroups: <GroupIcon />,
  shifts: <WatchLater />,
  schedule: <AccessTimeIcon />,
  mobile: <MobileFriendlyIcon />,
  incident: <PeopleOutlineIcon />,
  productivity: <DoneIcon />,
  supervisorTotals: <DoneIcon />,
};


const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

function ListItemLink(props) {
  return (
    <Link to={props.href} id={`link-to-${props.href}`}>
      <ListItem button>
        { props.children }
      </ListItem>
    </Link>
  );
}

function renderLinks(l, onClick) {
  return l.map((x, i) => (
    <div key={i + x} onClick={() => onClick && onClick(x)}>
      <ListItemLink key={i} href={`/${x.toLowerCase().replace(' ', '')}`}>
        <ListItemIcon>{iconMap[x]}</ListItemIcon><ListItemText primary={LINK_NAMES[x]} />
      </ListItemLink>
    </div>

  ));
}

const allowedForSupervisor = [
  'locations',
  'dashboard',
  'incident',
]

function filterLinks(links, user, superAdmin) {
  if(user.IsSupervisor && !user.IsAdmin && !superAdmin) {
    return links.filter(l => allowedForSupervisor.includes(l));
  }
  return links
}

function Links(props) {
  const {
    classes, admin, companyName, onClick, superAdmin, user,
  } = props;
  const mainLinks = renderLinks(filterLinks(['dashboard', 'locations', 'incident'], user, superAdmin), onClick);
  let reportingLinks = renderLinks(filterLinks(['timesheets', 'covidcases',  'approvals', 'productivity', 'supervisorTotals'], user, superAdmin), onClick);
  let setups = []; //filterLinks(['users', 'sites', 'zones', 'workergroups', 'shifts', 'company', 'schedule'], user, superAdmin);
  if (superAdmin) {
    reportingLinks = renderLinks(['timesheets', 'covidcases',  'approvals', 'productivity', 'supervisorTotals']);
    setups = ['users', 'sites', 'zones', 'workergroups', 'shifts', 'company', 'schedule'];
    setups.push('superAdmin');
    setups.push('mobile');
  }

  if(user.IsAdministrator &! superAdmin)
  {
    reportingLinks = renderLinks(['timesheets', 'covidcases',  'approvals', 'productivity', 'supervisorTotals']);
    setups = ['users', 'sites', 'zones', 'workergroups', 'shifts', 'company', 'schedule'];
    setups.push('mobile');
  }
  const setupLinks = renderLinks(setups, onClick);
  return (
    <div className={classes.root}>
      <List component="nav">
        <ListItem>
          <img src={logo} style={{ maxHeight: '70px'}} />
        </ListItem>
        <ListItem>
          <Typography variant="h6">{ companyName }</Typography>
        </ListItem>
        <Divider />
        { mainLinks }
      </List>
      <div>
        <Divider />
        <List
          component="nav"
          subheader={<ListSubheader component="div">Reporting</ListSubheader>}
        >
          { reportingLinks }
        </List>
        <Divider />
        <List
          component="nav"
          subheader={<ListSubheader component="div">Setup</ListSubheader>}
        >
          { setupLinks }
        </List>
      </div>
    </div>
  );
}

export default withStyles(styles)(withRouter(Links));
