import { AsyncAction } from './types'
import { RestClient } from '../../RestClient/RestClientTypes';
import { RestFeedModel, CompanyRestModel } from '../../RestClient/RestApiMicroFormat';
import { Worker } from '../../api/index';
import { ConfigurationState } from '../reducers/ConfigurationReducer';

export enum WORKER_ActionTypes {
  SET_SELECTED_WORKER = "WORKER.SET_SELECTED_WORKER",
  SET_WORKER_LIST = "WORKER.SET_WORKER_LIST",
};


function makeRestclient(state: () => any): RestClient{
  const configuration: ConfigurationState = state().configuration;
  return configuration.client!;
}


export class WorkerCovidActions {

  static setSelectedWorker(workerUrl: string): AsyncAction {
    return (dispatch, state) => {
      const restClient = makeRestclient(state);
      dispatch ({
        type: WORKER_ActionTypes.SET_SELECTED_WORKER,
        promise: restClient.get<Worker>(workerUrl)
      });
    }
  };

  static getWorkersList(companyUri: string): AsyncAction {
    return (dispatch, state) => {
      const restClient = makeRestclient(state);
      dispatch ({
        type: WORKER_ActionTypes.SET_WORKER_LIST,
        promise: restClient.get<CompanyRestModel>(companyUri)
          .then((value: CompanyRestModel) => restClient.get<RestFeedModel>(value.workersUri)
          .then(feed => restClient.getFeedItems<Worker>(feed))),
      });
    }
  }
}

