import {
  SET_SITES,
  CREATE_SITE,
  UPDATE_SITE,
} from './actions/ActionTypes';
import { assign, replaceInArray } from '../Utils';

const initialState = {
  all: [],
};
const id = 'SiteId';
export default function (state = initialState, action) {
  const { all } = state;
  const { payload } = action;
  switch (action.type) {
    case SET_SITES: {
      return assign(state, { all: payload });
    }
    case CREATE_SITE: {
      return assign(state, { all: [...state.all, payload] });
    }
    case UPDATE_SITE: {
      return assign(state, { all: replaceInArray(all, payload, id) });
    }
    default:
      return state;
  }
}
