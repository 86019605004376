import React, { Component } from 'react';
import {
  Paper,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  Typography,
  Fab,
  TextareaAutosize,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Common/FormStyles';
import { getGeneratedTextmessages, postTextmessage } from '../../API';
import { replaceInArray } from '../../Utils';


export function SmsForm(props) {
  const { Category,
    CreationDateTime,
    Description,
    MobileNumber,
    SmsStatus,
    WorkerId,
    WorkerName,
    onSendClick,
    onTextAreaChange,
  } = props;
  return <ListItem style={{ maxWidth: '450px'}}
      button 
    >
      <ListItemText
          primary={WorkerName}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'block' }}
                component="p"
                variant="body2"
              >
                { MobileNumber } { SmsStatus }
              </Typography>
              <TextareaAutosize
                rowsMin={4}
                value={Description}
                style={{ width: 300 }}
                onChange={(e) => onTextAreaChange(e, WorkerId)}
                disabled={SmsStatus != 0}
              />
            </React.Fragment>
          }
      />
      <div>
          { (SmsStatus == 0) && <Button color='primary' style={{float: 'left'}} onClick={() => onSendClick(WorkerId)}>
            Send
          </Button> }
          { (SmsStatus == 1) && <Button color='secondary' style={{float: 'left'}} disabled>
            Sent
          </Button> }
      </div>
    </ListItem>
}



class SMSSendingPage extends Component {
  state = {
    textMesssages: [],
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const msgs = await getGeneratedTextmessages();
    this.setState({ textMesssages: msgs });
  }

  handleTextAreaChange = (e, WorkerId) => {
    const { textMesssages } = this.state;
    const newMessage = { 
      ...textMesssages.find(t => t.WorkerId === WorkerId),
      Description: e.target.value,
    };
    const newList = replaceInArray(textMesssages, newMessage, 'WorkerId');
    this.setState({ textMesssages: newList });
  }

  handleSendClick = async (workerId) => {
    const payload = this.state.textMesssages.find(t => t.WorkerId == workerId);
    await postTextmessage(payload);
    this.getData();
  }

  render() {

    return (
      <div style={{ padding: '15px', minHeight: '70vh', overflow: 'auto' }}>
        <Grid container>
           <Grid item xs={10}>
            { this.state.textMesssages.map(
              (t, i) => <SmsForm { ...t } key={i} 
                onTextAreaChange={this.handleTextAreaChange}
                onSendClick={this.handleSendClick}
             />)}
           </Grid>
        </Grid>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(SMSSendingPage);
