import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid, TextField,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {
  addTimesheetNote,
} from '../../redux/actions/AllActions';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 3,
  },
  addNote: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 3,
  },
});

class TimesheetNotesForm extends Component {
  state = {
    noteText: '',
  }

  onTextChange = ({ target }) => {
    if (target.value.length <= 160) {
      this.setState({
        noteText: target.value,
      });
    }
  }

  onSendClick = () => {
    const { timesheet, token, userId, user } = this.props;
    const timesheetNote = {
      Details: this.state.noteText,
      TimesheetId: timesheet.TimesheetId,
      // TODO when we have user - add the user ID here.
      WorkerId: userId,
    };
    if(user.IsSuperAdministrator) {
       alert(' no super admins can make these ok ');
       return;
    }
    this.props.addTimesheetNote(timesheetNote, token);
    this.setState({
      noteText: '',
    });
  }

  render() {
    const { classes, timesheet } = this.props;
    const { noteText } = this.state;
    return (
      <Grid container spacing={24}>
        <Grid item xs={11}>
          <TextField
            id="note-text"
            label="Add Note"
            value={noteText}
            onChange={this.onTextChange}
            margin="normal"
            disabled={!timesheet}
            variant="outlined"
            placeholder="Add Your Note Here (160 Character Limit)"
            style={{ minWidth: '300px', width: '100%' }}
          />
        </Grid>
        <Grid item xs={1}>
          <Fab size="small" className={classes.addNote} color="primary" disabled={timesheet && !noteText.length} id="add-timesheet-note-btn" onClick={this.onSendClick}>
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return { 
    timesheet: state.timesheets.all.find(
      ts => ts.TimesheetId === state.timesheets.approvalCurrentTimesheetId),
    userId: state.me.me.WorkerId,
    user: state.me.me,
  };
};

const mapDispatchToProps = dispatch => ({
  addTimesheetNote: (x, t) => dispatch(addTimesheetNote(x, t)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TimesheetNotesForm)));
