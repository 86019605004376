import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  TextField,
} from '@material-ui/core';
import { RootReducerType } from "../../redux/store/rootReducer";
import { ContactTracingActions } from '../../redux/actions/ContactTracingActions';
import moment from 'moment';


interface DispatchProps {
  onChange(changes: any): void; 
}

interface StateProps {
  showAdvanced: boolean;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  startTime: string;
  endTime: string;
  distanceBuffer: number;
  timeBuffer: number;
}

export type ContactTracingFormProps = StateProps & DispatchProps;


export class ContactTracingFormComponent extends Component<ContactTracingFormProps> {

  handleFromDateChange = ({ target }) => {
    this.props.onChange({ fromDate: moment(target.value, 'YYYY-MM-DD') } );
  }

  handleToDateChange = ({ target }) => {
    this.props.onChange({ toDate: moment(target.value, 'YYYY-MM-DD') });
  }

  handleStartTimeChange = ({ target }) => {
    this.props.onChange({ startTime: target.value });
  }

  handleEndTimeChange = ({ target }) => {
    this.props.onChange({ endTime: target.value });
  }

  handledistanceBufferChange = ({ target }) => {
    this.props.onChange({ distanceBuffer: target.value });
  }

  handleTimeBufferChange = ({ target }) => {
    this.props.onChange({ timeBuffer: target.value });
  }

  render() {
    const { fromDate, toDate, startTime, endTime, timeBuffer, distanceBuffer } = this.props;
    const props:ContactTracingFormProps = this.props;
    return (
      <div id='search-params' style={{ margin: '15px auto 0px auto'}}>
        <div>
          <FormControl style={{ marginLeft: '20px' }}>
            <TextField
              type='date'
              label='From Date'
              value={fromDate.format('YYYY-MM-DD')}
              onChange={this.handleFromDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              type="time"
              label="Start Time"
              value={startTime}
              onChange={this.handleStartTimeChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </div>

        <div>
          <FormControl style={{ backgroundColor: '#fff', marginLeft: '20px' }}>
            <TextField
              type="date"
              label="To Date"
              value={toDate.format('YYYY-MM-DD')}
              onChange={this.handleToDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              type="time"
              label="End Time"
              value={endTime}
              onChange={this.handleEndTimeChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </div>
      
        { props.showAdvanced && (
        <div>
          <FormControl style={{ backgroundColor: '#fff', marginLeft: '20px', maxWidth: '135px' }}>
            <TextField
              type="number"
              label="Distance Buffer metres"
              value={distanceBuffer}
              onChange={this.handledistanceBufferChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl style={{ backgroundColor: '#fff', maxWidth: '135px' }}>
            <TextField
              type="number"
              label="Time Buffer Minutes"
              value={timeBuffer}
              onChange={this.handleTimeBufferChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerType): StateProps => {
  const { me } = state;
  const { 
    fromDate, toDate, startTime, endTime, timeBuffer, distanceBuffer,
  } = state.contactTracing;
  return {
    showAdvanced: !!me.me.IsSuperAdministrator,
    fromDate, toDate, startTime, endTime, timeBuffer, distanceBuffer,
  };
};

const dispatchProps = (dispatch) => ({
  onChange: (w) => dispatch(ContactTracingActions.setContactTracingFormValues(w)),
})

export const ContactTracingForm = connect(mapStateToProps, dispatchProps)(ContactTracingFormComponent);