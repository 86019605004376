import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getMyTimesheetEntries,
  getMyDistinctTimesheets,
  getMyTimesheetData,
  getMyTimesheetNotes,
  getWorkersFromToken,
} from '../../API';
import Timesheet from '../../Models/Timesheet';
import { ApprovalsClass, mapDispatchToProps, styles } from '../Approvals/Approvals';
import TimesheetList from '../../Models/TimesheetList';
import MyTimesheetTable from './MyTimesheetTable';
import TimesheetNotesForm from '../Approvals/TimesheetNotesForm';
import TimesheetNotesList from '../Approvals/TimesheetNotesList';

class MyTimesheet extends ApprovalsClass {
  title='My Timesheet';

  componentDidMount() {
    if (this.props.token) {
      this.getData();
    }
  }

  componentDidUpdate() {
    if (this.props.token && !this.state.requested) {
      this.getData();
    }
  }

  getData = async () => {
    const { timesheet, token } = this.props;
    this.setState({ requested: true });
    const data = await Promise.all([
      getMyTimesheetData(token),
      getMyDistinctTimesheets(token),
    ]);
    const timesheets = data[1];
    const { sites, workers } = data[0];
    const mytimesheet = timesheet || timesheets[0];
    if (!mytimesheet) {
      this.setData(sites, workers, [],
        [], null, []);
      return;
    }
    const timesheetEntries = await getMyTimesheetEntries(token, mytimesheet.TimesheetId);
    const timesheetNotes = await getMyTimesheetNotes(token, mytimesheet.TimesheetId);
    this.setData(sites, workers, timesheets,
      timesheetEntries, mytimesheet.TimesheetId, timesheetNotes);
    /* I know how bad this is */
    // eslint-disable-next-line no-undef
    document.body.removeAttribute('style');
  }

  render() {
    const {
      timesheet, classes, token, numNotes,
    } = this.props;
    const { expanded } = this.state;
    if (!timesheet) {
      return <div />;
    }
    const table = timesheet && (
    <MyTimesheetTable
      {...this.props}
    />
    );
    return (
      <main className={classes.content} data-loaded>
        <Grid container spacing={24} className={classes.innerGrid}>
          <Grid item xs={12}>
            <ExpansionPanel expanded={expanded} onChange={this.onNotesPanelChange}>
              <ExpansionPanelSummary id="timesheet-note-summary" expandIcon={<ExpandMoreIcon className="expand-ts-notes" />}>
                <Typography variant="h6">{`Timesheet Notes (${numNotes})`}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item>
                  <TimesheetNotesForm id="timesheets-notes-form" token={token} />
                  <TimesheetNotesList token={token} />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '35px' }}>
            { table }
          </Grid>
        </Grid>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    me, timesheets, timesheetEntries, sites, workers,
    timesheetNotes,
  } = state;
  const timesheetList = new TimesheetList(timesheets.all);

  if (!timesheets.approvalCurrentTimesheetId) {
    return { token: me.auth.token };
  }
  const worker = workers.all.find(
    w => w.WorkerId === timesheets.approvalCurrentTimesheetId.TimesheetId);
  const timesheet = new Timesheet(timesheets.approvalCurrentTimesheetId);
  timesheet.setCompany(me.company);
  timesheet.setTimesheetEntryList(timesheetEntries.all.filter(
    t => t.TimesheetId === timesheet.TimesheetId));
  timesheet.timesheetEntryList.setSiteList(sites.all);
  return {
    timesheetList,
    timesheet,
    worker,
    me: worker,
    token: me.auth.token,
    numNotes: timesheetNotes.all.length,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, { withTheme: true })(MyTimesheet)));
