
export const getapprovalCurrentTimesheet = ({ timesheets }) => timesheets.all.filter(
  t => t.TimesheetId === timesheets.approvalCurrentTimesheetId)[0];

export const getSites = state => state.sites.all;

export const getTimesheets = state => state.timesheets.all;

export const getWorkers = state => state.workers.all;

export const getCompany = state => state.me.company;

export const getApprovalsTimesheetEntries = ({ timesheets, timesheetEntries }) => (
  timesheets.approvalCurrentTimesheetId ? timesheetEntries.all.filter(
    tse => tse.TimesheetId === timesheets.approvalCurrentTimesheetId) : []);

export const getTimesheetEntries = state => state.timesheetEntries.all;

export const getUnpaidTimesheetEntries = state => state.unpaidTimesheetEntries.all;
