import React, { Component } from 'react';
import geojson2svg from 'geojson2svg';
import geojsonbounds from 'geojson-bounds';
import proj4 from 'proj4';
import reproject from 'reproject';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';


function parseSVG(s) {
  // eslint-disable-next-line no-undef
  const div = document.createElementNS('http://www.w3.org/1999/xhtml', 'div');
  div.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg">${s}</svg>`;
  // eslint-disable-next-line no-undef
  const frag = document.createDocumentFragment();
  while (div.firstChild.firstChild) { frag.appendChild(div.firstChild.firstChild); }
  return frag;
}

export default class SitePickerTile extends Component {
  getStrokeWidth = (newProps) => {
    const { isSelected } = newProps;
    return isSelected ? '3' : '1';
  }

  getStyle = props => `stroke:#F7D74A; fill: #564d4b;stroke-width:${this.getStrokeWidth(props)};`

  componentDidMount() {
    const { site } = this.props;

    const geojson3857 = reproject.reproject(
      site.GeoJSON, 'EPSG:4326', 'EPSG:3857', proj4.defs);
    const bounds = geojsonbounds.extent(geojson3857);
    const style = this.getStyle(this.props);
    const converter = geojson2svg(
      {
        mapExtent: {
          left: bounds[0], bottom: bounds[1], right: bounds[2], top: bounds[3],
        },
        attributes: {
          id: `site-tile-svg-${site.SiteId}`,
          style,
          'vector-effect': 'non-scaling-stroke',
        },
        viewportSize: {
          width: 120,
          height: 120,
        },
        explode: false,
        output: 'svg',
      });

    const svgElements = converter.convert(geojson3857);
    // eslint-disable-next-line no-undef
    const svgMap = document.getElementById(`site-tile-${site.SiteId}`);
    svgElements.forEach((svgStr) => {
      const svg = parseSVG(svgStr);
      svgMap.appendChild(svg);
    });
  }

  componentWillReceiveProps(newProps) {
    const { site } = newProps;

    // eslint-disable-next-line no-undef
    const svg = document.getElementById(`site-tile-svg-${site.SiteId}`);
    const style = this.getStyle(newProps);
    svg.setAttribute('style', style);
  }

  render() {
    const { site, onClick, isSelected } = this.props;
    return (
      <GridListTile onClick={onClick} style={{ backgroundColor: '#000' }}>
        <div style={{
          backgroundColor: '#756623',
          height: '145px',
          padding: '15px 5px 0px 5px',
          opacity: isSelected ? '0.9' : '0.8',
        }}
        >
          <svg
            id={`site-tile-${site.SiteId}`}
            xmlns="http://www.w3.org/2000/svg"
            height="145"
            width="155"
            x="0"
            y="0"
          />
        </div>
        <GridListTileBar title={site.Name} style={{ textAlign: 'left', paddingLeft: '15px' }} />
      </GridListTile>
    );
  }
}
