import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearWorkerShifts, getWorkerShifts } from '../../API';
import { setWorkerShifts } from '../../redux/actions/AllActions';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { formatUtcDateAsNZTime } from '../../Utils';


function onClick(props) {
  return (e) => {
    e.stopPropagation();
    window.confirm('delete') && clearWorkerShifts(
      props.workerId, props.workerGroupId, props.day.format('YYYY-MM-DD 00:00')).then(
    () => getWorkerShifts(props.start, props.end).then(props._setWorkerShifts));
  }
};


function ClearDayButton(props) {

  return <IconButton size={'small'} style={{ zIndex: 2, color:'#cfcfcf' }} onClick={onClick(props)}>
    <DeleteIcon size={'small'}></DeleteIcon>
  </IconButton>

}


export const mapStateToProps = (state, ownProps) => {
  return {
    start: state.workerShifts.uiState.week[0].format('YYYY-MM-DD HH:mm'),
    end: state.workerShifts.uiState.week[6].format('YYYY-MM-DD HH:mm'),
  };
};

export const mapDispatchToProps = dispatch => ({
  _setWorkerShifts: x => dispatch(setWorkerShifts(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClearDayButton));
  
