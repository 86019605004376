

// Common to all environments.
export const MAPBOX_TOKEN = 'pk.eyJ1IjoicmltdW9yYiIsImEiOiJjam5ieWowaTQxaHM2M3BwZXMwbDR0c3ZmIn0.i1Em6tRYqlDCV2ElemiY7g';
export const STREET_MAP_URI = 'mapbox://styles/mapbox/streets-v8';

//export const SITES_MAP_URI = 'mapbox://styles/rimuorb/ck9rrob9b8ux01imwjic1dcjg';
//export const SITES_MAP_URI = 'mapbox://styles/rimuorb/ckwv4kuca21wk15pglzadsk0a';
export const SITES_MAP_URI = STREET_MAP_URI;
//export const SAT_MAP_URI = 'mapbox://styles/rimuorb/ck01kshgo5kcj1cs2p7wtpvo5';
export const SAT_MAP_URI = 'mapbox://styles/mapbox/satellite-streets-v12';
export const SITEM8_MAP_URI = 'mapbox://styles/rimuorb/cjz7myimp24u31cldpfgg3rms';
export const RACE_MAP_URI = 'mapbox://styles/rimuorb/ck7g2n6s300q81iqydbh6ufm1';

export const SITE_FILL_STYLE = {
  'fill-color': '#Da1743',
  'fill-opacity': 0.50,
  'fill-outline-color': '#111111',
};

export const ZONE_FILL_STYLE = {
  'fill-color': '#f5dd42',
  'fill-opacity': 0.50,
  'fill-outline-color': '#f5dd42',
};

export const WORKER_STATUS_MAP = {
  1: { text: 'On Site', color: '#3de534', className: 'onsite-status' },
  2: { text: 'Off Site', color: '#ffb71c', className: 'offsite-status' },
  3: { text: 'Private', color: '#a5a5a5', className: 'private-status' },
  4: { text: 'Offline', color: '#3a3a3a', className: 'offline-status' },
  0: { text: 'Error', color: '#fff', className: 'error-status' },
};


export const CSV_FORMAT_MAP = {
  0: 'Site totals for each day',
  1: 'Daily totals',
  2: 'All time entries',
};

export const NO_SITE_NAME = 'Unknown';

export const DAYS_OF_WEEK = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
};

export const WEEKDAY_NUMBERS = [1, 2, 3, 4 ,5 ,6, 0];


export const LINK_NAMES = {
  dashboard: 'Dashboard',
  approvals: 'Approvals',
  locations: 'Locations',
  timesheets: 'Timesheets',
  users: 'Users',
  sites: 'Sites',
  company: 'Company',
  superAdmin: 'System Admin',
  contact: 'Contact Tracing',
  zones: 'Zones',
  covidcases: 'Case Management',
  workergroups: 'Manage Worker Groups',
  shifts: 'Manage Shifts',
  schedule: 'Schedule',
  mobile: 'Mobile',
  incident: 'Incident',
  productivity: 'Productive Zones',
  supervisorTotals: 'Worker Totals',
};

export const CUSTOMER_MAP_TYPES = {
  nz_standard: 'NZ_STANDARD',
  island: 'ROTATING_ISLAND',
  vehicle_tracking: 'VEHICLE_TRACKING',
};
