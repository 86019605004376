import React, { Component } from 'react';
import moment from 'moment';
import {
  Grid, Switch, FormControlLabel, TextField, InputLabel, Select,
  MenuItem, FormControl, FormHelperText, Input,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import formStyles from '../Common/FormStyles';


const styles = {
  padTop: { paddingTop: '30px' },
};


class TimesheetForm extends Component {
  onTimeChange = ({ target }) => {
    const { value, name } = target;
    const tse = this.props.timesheetEntry;
    const tsTime = moment(tse[name]);
    const hrs = parseInt(value.split(':')[0], 0);
    const mins = parseInt(value.split(':')[1], 0);
    tsTime.hours(hrs);
    tsTime.minutes(mins);
    const changeEvt = {
      target:
      { name, value: tsTime.toDate() },
    };
    this.props.onInputChange(
      changeEvt, tse,
    );
  }

  handleDelete = () => {
    const { onDeleteClick, timesheetEntry } = this.props;
    onDeleteClick(timesheetEntry);
  }

  render() {
    const {
      onInputChange, timesheetEntry, sites, onClosePress,
      classes, theme,
    } = this.props;
    const {
      mStartDateTime, mFinishDateTime, Travel, SiteId, Description,
    } = timesheetEntry;
    const sitesPlus = [{ SiteId: -1, Name: 'Unknown' }, ...sites];
    const startTimeStr = mStartDateTime.format('HH:mm');
    const finishTimeStr = mFinishDateTime.format('HH:mm');
    const warningStyle = { color: theme.palette.warning.main };
    const siteWarningStyle = !SiteId || SiteId === -1 ? warningStyle : {};
    return (
      <Grid container spacing={16} style={{ paddingLeft: '10px', paddingRight: '32px', paddingBottom: '30px' }}>
        <Grid item>
          <TextField
            id="ts-start-time-input"
            label="Start"
            type="time"
            name="StartDateTime"
            value={startTimeStr}
            error={!this.props.isValid}
            className={classes.textField}
            onChange={this.onTimeChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="ts-end-time-input"
            label="Finish"
            type="time"
            name="FinishDateTime"
            value={finishTimeStr}
            error={!this.props.isValid}
            className={classes.textField}
            onChange={this.onTimeChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item style={{ paddingTop: '15px', minWidth: '140px' }}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={(
              <Switch
                name="Travel"
                checked={!Travel}
                onChange={onInputChange}
              />
            )}
            label={Travel ? 'Travel To' : 'On Site'}
          />
        </Grid>
        <Grid item style={{ minWidth: '180px' }}>
          <FormControl>
            <FormHelperText style={siteWarningStyle}>Site</FormHelperText>
            <Select
              name="SiteId"
              value={SiteId || -1}
              onChange={onInputChange}
              input={(
                <Input
                  variant="outlined"
                  style={{ minWidth: '180px' }}
                  margin="dense"
                  inputProps={{ label: 'Site' }}
                />
                 )}
              variant="outlined"
            >
              { sitesPlus.map((s, i) => (
                <MenuItem key={i} value={s.SiteId}>
                  { s.Name }
                </MenuItem>
              )) }
            </Select>
          </FormControl>
        </Grid>
        <Grid item style={styles.padTop}>
          <InputLabel style={{ textOverflow: 'ellipsis' }}>{Description}</InputLabel>
        </Grid>
        <Grid item style={{ paddingTop: '20px', flexGrow: 1 }}>
          <div style={{ float: 'right' }}>
            <Fab size="small" color="secondary" onClick={this.handleDelete} className={classes.fab}>
              <DeleteIcon className="delete-timesheet-entry" />
            </Fab>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(formStyles, { withTheme: true })(TimesheetForm);
