import React, { Component } from 'react';
import moment from 'moment';
import {
  editTimesheetEntry,
  addTimesheetEntry,
  deleteTimesheetEntry,
} from '../../redux/actions/AllActions';
import {
  assign,
  dateRangeOverlaps,
  uniqInArray,
} from '../../Utils';


class TimesheetEntryEditor extends Component {
  handleInputChange = ({ name, value, checked }, tsEntry) => {
    let val = value;
    if (name === 'Travel') {
      val = !checked;
    }
    const changes = { [name]: val, edited: true };
    // eslint-disable-next-line no-underscore-dangle
    this.props._editTimesheetEntry(assign(tsEntry, changes));
  }


  onDeleteClick = (tse) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure?')) {
      this.props._deleteTimesheetEntry(assign(tse, { isNew: false }));
      setTimeout(this.onSaveClick, 200);
    }
  }

  handleAddPress = (dayStr) => {
    const entry = this.createNewTimesheetEntry(dayStr);
    return entry;
  }

  handleChange = (args) => {
    const { timesheetEntry } = this.props;
    /* handle the time input change */
    if (args.target.name.includes('DateTime')) {
      const parsedArgs = assign(args.target, {
        value: moment(args.target.value).format('YYYY-MM-DD HH:mm:ss'),
      });
      return this.handleInputChange(
        parsedArgs, timesheetEntry);
    }
    return this.handleInputChange(args.target, timesheetEntry);
  }

  isValid = (entry) => {
    const {
      deleted,
      StartDateTime,
      FinishDateTime,
      TimesheetEntryId,
    } = entry;
    const { timesheetEntries } = this.props;
    // TODO remove deleted
    if (deleted) {
      return true;
    }
    if (moment(FinishDateTime).isBefore(moment(StartDateTime)) || !(StartDateTime && FinishDateTime)) {
      return false;
    }
    const overlapsOtherEntry = !!timesheetEntries.filter(
      tse => !tse.deleted && tse.TimesheetEntryId !== TimesheetEntryId).find(
      tse => dateRangeOverlaps(
        StartDateTime, FinishDateTime, tse.StartDateTime, tse.FinishDateTime));
    return !overlapsOtherEntry;
  }

  createNewTimesheetEntry = (startDateStr) => {
    const {
      timesheet,
      me,
      company,
      _addTimesheetEntry,
    } = this.props;
    const newEntry = timesheet.getNewTimesheetEntry(
      moment(startDateStr),
      me.WorkerId,
      '07:00',
      '19:00',
      '12:00',
      '12:30'
    );
    _addTimesheetEntry(newEntry);
    return newEntry;
  
  }

  render() {
    return (
      <div>
        { this.props.children }
      </div>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const {
    sites, me,
  } = state;
  const ts = ownProps.timesheet;
  // get all the timesheetentries for the timesheet and for the day;
  const entries = ts ? ts.timesheetEntryList.getEntriesByDay(ownProps.startDate) : [];
  const {
    WorkingDayDefaultStart,
    DefaultLunchEnd,
    DefaultLunchStart,
    WorkingDayDefaultFinish,
  } = me.company;
  const siteNames = uniqInArray(entries.map((e) => {
    const site = sites.all.find(s => s.SiteId === e.SiteId);
    return site ? site.Name : 'Unknown';
  })).join(' ,');
  return {
    timesheet: ts,
    sites: sites.all,
    timesheetEntries: entries,
    me: me.me,
    WorkingDayDefaultStart,
    DefaultLunchEnd,
    DefaultLunchStart,
    WorkingDayDefaultFinish,
    company: me.company,
    siteNames,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  _addTimesheetEntry: x => dispatch(addTimesheetEntry(x)),
  _deleteTimesheetEntry: x => dispatch(deleteTimesheetEntry(x)),
  _editTimesheetEntry: x => dispatch(editTimesheetEntry(x)),
});

export default TimesheetEntryEditor;
