import { Component } from 'react';
import PropTypes from 'prop-types';
import { assign } from '../../Utils';


class ObjectEditForm extends Component {
  save(toSave) {
  }

  handleComplete = () => {
    const {
      originalObj, initialObj, onComplete,
    } = this.props;
    const { editingObj } = this.state;
    const toSave = assign(originalObj, editingObj);
    this.save(toSave);
    this.setState({ editingObj: initialObj });
    onComplete();
  }

  handleTextChange = ({ target }) => {
    const { editingObj } = this.state;
    const {
      name, value,
    } = target;
    const edited = assign(editingObj, { [name]: value });
    this.setState({ editingObj: edited });
  }

  handleSelectChange = ({ target }) => {
    const { editingObj } = this.state;
    const {
      name, value,
    } = target;
    this.setState({ editingObj: assign(editingObj, { [name]: value }) });
  }

  handleCheckboxChange = ({ target }, checked) => {
    const { editingObj } = this.state;
    const { name } = target;
    this.setState({ editingObj: assign(editingObj, { [name]: !!checked }) });
  }
}

ObjectEditForm.propTypes = {
  originalObj: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default ObjectEditForm;
