import {
    SET_WORKER_GROUPS,
    SET_EDITING_WORKERGROUP,
  } from './actions/ActionTypes';
  
  const initialState = {
    all: [],
    editingWorkerGroup: null,
  };

  export default function (state = initialState, action) {
    const { payload } = action;
    switch (action.type) {
      case SET_WORKER_GROUPS: {
        return { ...state, all: payload };
      }
      case SET_EDITING_WORKERGROUP: {
        return { ...state, editingWorkerGroup: payload };
      }
      default:
        return state;
    }
  }
  