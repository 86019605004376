import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  Grid, Typography, Button,
  ExpansionPanelDetails, ExpansionPanelSummary, ExpansionPanel,
  Hidden,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/WarningRounded';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TimesheetForm from './TimesheetForm';
import {
  removeLeadingZero, assign,
} from '../../Utils';
import TimesheetEntryEditor,
{ mapDispatchToProps } from './TimesheetEntryEditor';


class TimesheetPanel extends TimesheetEntryEditor {
  handleClosePress = (timeSheetEntry) => {
    if (window.confirm('Are you sure?')) {
      this.props._deleteTimesheetEntry(timeSheetEntry);
    }
  }

  handleChange = (args, tse) => {
    /* handle the time input change */
    if (args.target.name.includes('DateTime')) {
      const parsedArgs = assign(args.target, {
        value: moment(args.target.value).format('YYYY-MM-DD HH:mm:ss'),
      });
      return this.handleInputChange(
        parsedArgs, tse);
    }
    return this.handleInputChange(args.target, tse);
  }

  addRow = () => {
    this.handleAddPress(this.props.startDate);
  }

  render() {
    const {
      sites, timesheetEntries, theme,
      siteNames, index, timesheet, startDate,
    } = this.props;
    const dateStr = moment(startDate).format('ddd Do MMM');
    // TODO DO AWAY WITH THE DELETED FLAG
    const notDeleted = timesheetEntries.filter(t => !t.deleted);
    
    const totalTimeStr = timesheet.timesheetEntryList.formatTotalTimeStr(notDeleted);

    const warning = !!notDeleted.find(t => !t.SiteId || t.SiteId === -1);
    const warnStyle = { opacity: warning ? 1 : 0, color: theme.palette.warning.main };
    const forms = notDeleted.map(
      (tse, i) => (
        <TimesheetForm
          onInputChange={evt => this.handleChange(evt, tse)}
          onClosePress={() => this.handleClosePress(tse)}
          onDeleteClick={this.onDeleteClick}
          timesheetEntry={tse}
          isValid={this.isValid(tse)}
          key={i}
          sites={sites}
        />
      ),
    );

    const canSave = !notDeleted.find(
      t => !this.isValid(t),
    ) && timesheetEntries.find(
      t => t.edited || t.deleted || t.isNew,
    );

    let startTime = '';
    let endTime = '';
    let breakTime = '';

    if (notDeleted.length) {
      startTime = removeLeadingZero(timesheet.timesheetEntryList.formatStartTimeStr(notDeleted));
      endTime = removeLeadingZero(timesheet.timesheetEntryList.formatEndTimeStr(notDeleted));
      breakTime = timesheet.timesheetEntryList.formatTotalBreakStr(notDeleted);
    }

    return (
      <ExpansionPanel id={`timesheet-panel-${index}`} key={`timesheet-panel-${index}`}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="">
          <Grid container>
            <Grid item xs={12}>
              <div style={{ display: 'inline' }}>
                <Typography variant="h6" style={{ fontSize: '1.15rem', display: 'inline' }}>{ removeLeadingZero(dateStr) }</Typography>
              </div>
              <div style={{ display: 'inline', float: 'right' }}>
                <Typography
                  style={{
                    fontSize: '1.15rem', display: 'inline', height: '25px',
                  }}
                  variant="h6"
                >
                  { totalTimeStr === '0' ? '' : totalTimeStr }
                </Typography>
                <div style={{ position: 'absolute', top: '11px', right: '105px' }}>
                  <WarningIcon style={warnStyle} />
                </div>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={6} sm={3} md={2} className="approvals-summary-wrapper">
                <span className="approvals-summary">
                  {startTime && endTime && <b>{ `${startTime} to ${endTime}`}</b>}
                </span>
              </Grid>
              <Grid item xs={6} sm={7} md={8} className="center approvals-summary-wrapper elips">
                <span className="approvals-summary">
                  { siteNames }
                </span>
              </Grid>
              <Hidden only="xs">
                <Grid item sm={2} md={2} className="approvals-summary-wrapper righttext">
                  <span className="approvals-summary">
                    { breakTime && <span>Breaks: <b>{ breakTime }</b></span> }
                  </span>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            <Grid item xs={12}>
              { forms }
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  style={{ marginRight: 'auto' }}
                  disabled={!canSave}
                  variant="contained"
                  id={`save-entry-btn-${index}-${!!canSave}`}
                  onClick={this.props.onSaveClick}
                >
                      Save
                </Button>
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={2} container>
                <div style={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                  <Fab size="small" color="primary" onClick={this.addRow} id={`add-entry-btn-${index}`}>
                    <AddIcon />
                  </Fab>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  return { sites: state.sites.all, me: state.me.me, company: state.me.company };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles({}, { withTheme: true })(TimesheetPanel)));
