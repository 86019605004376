/* eslint-disable import/prefer-default-export */
import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';

export function LargeAvatar({ worker }) {
  if (!(worker && worker.Base64Image)) {
    return (
      <AccountCircle
        color="disabled"
        style={{ height: '65px', width: '65px', margin: 'auto' }}
      />
    );
  }
  return <img className="approvals-form-avatar" src={worker.Base64Image} alt="user-img" />;
}

export function ListAvatar({ worker }) {
  const src = worker.Base64Image || null;
  return (
    <ListItemAvatar>
      <Avatar alt={worker.Name} src={src} />
    </ListItemAvatar>
  );
}
