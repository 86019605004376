import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ScheduleCell, { cellStyle } from './ScheduleCell';
import { areSameDay } from '../../Utils';

export function ScheduleRow(props) {
  const { workerShifts, shifts, sites, workerGroup, worker } = props;
  return (
    <Grid container style={{overflowY: 'auto' }} justify="flex-start" alignItems="stretch">
      <Grid item style={{minWidth: '12%'}} style={{ ...cellStyle, padding: '10px' }}>
        <div style={{marginLeft: 'auto'}}>
          { workerGroup && workerGroup.Name }
          { worker && worker.Name }
        </div>
      </Grid>
      { props.week.map((day, i) => 
        {
          const theShifts = workerShifts.filter(
            ws => areSameDay(ws.StartDateTime, day));/*.map(ws)
              ws => ws.ShiftId ? shifts.find(s => s.ShiftId == ws.ShiftId) : ws);*/
   
          return <ScheduleCell 
            key={`${day.toISOString()} ${i}`} 
            day={day} 
            shifts={theShifts.filter(s => !!s)} 
            sites={sites} 
            worker={worker} 
            workerGroup={workerGroup} 
          />
      })}
    </Grid>
  );

}


export const mapStateToProps = (state, ownProps) => {
  const {
    workerShifts,
    shifts,
    workers,
    sites,
  } = state;
  
  const { workerGroup, worker } = ownProps;
  const workerIds = workerGroup ? workers.all.filter(
    w => w.WorkerGroupId === workerGroup.WorkerGroupId).map(w => w.WorkerId) : [worker.WorkerId];
  
  const workerShiftsToDisplay = workerShifts.all.filter(
    ws => workerIds.includes(ws.WorkerId));

  return {
    week: workerShifts.uiState.week,
    workerShifts: workerShiftsToDisplay,
    shifts: shifts.all,
    sites: sites.all,
  };
};

export const mapDispatchToProps = dispatch => ({
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleRow));
  