import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import Button from '@material-ui/core/Button';

export default class PrintTimesheetButton extends Component {
  handlePrintClick = () => {
    const { timesheet, workerShifts } = this.props;
    // prep the data to format the html
    const htmlToPrint = timesheet.printMyself(workerShifts);
    /* eslint-disable */
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    setTimeout(function(){
      iframe.contentWindow.document.write(htmlToPrint)
      var iframedoc=iframe.contentDocument||iframe.contentWindow.document;
      html2canvas(iframedoc.body).then(canvas => {
        const imgDataUrl = canvas.toDataURL('image/png');
        const pdf = new jspdf();
        pdf.addImage(imgDataUrl, 'PNG', 0, 0);
        pdf.save(timesheet.getFilename());
      });
    });
    /* eslint-enable */
  }


  render() {
    const { timesheet } = this.props;
    return (
      <Button
        variant="contained"
        disabled={!(timesheet && timesheet.Status === 1)}
        color="primary"
        onClick={this.handlePrintClick}
      >
        Print
      </Button>
    );
  }
}
