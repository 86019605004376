import React, { useState } from 'react';
import { 
  Grid,
} from '@material-ui/core';
import ScheduleShiftsModal from './ScheduleShiftsModal';
import ClearDayButton from './ClearDayButton';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';



function renderNamedShift(sites, s) {
  return <div>
    <div>
      <span>{s.Name}</span>
    </div>
    <div>
      <span>{ getSiteName(sites, s) }</span>
    </div>
    <div>
      <span>{`${s.StartTime.slice(0, 5)} - ${s.EndTime.slice(0, 5)}`}</span>
    </div>
  </div>;
} 

function renderWorkerShift(sites, s) {
  return <div>
    <div>
      <span>{ getSiteName(sites, s) }</span>
    </div>
    <div>
      <span>{`${s.StartDateTime.split(' ')[1].slice(0, 5)} - ${s.EndDateTime.split(' ')[1].slice(0, 5)}`}</span>
    </div>
  </div>;
}

function getSiteName(sites, shift) {
  var site = sites.find(s => s.SiteId === shift.SiteId);
  return shift.MultiSite ? '*All' : site.Name;
}


export default function ScheduleCell(props) {
  const { sites, shifts, day, worker, workerGroup } = props;
  const [open, setOpen] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const toClose = (x) => {
    setTimeout(setOpen(x));
  }
  const onMouseEnter = () => setShowClear(true);
  const onMouseLeave = () => setShowClear(false);
  const usedDisplay = [];
  return <Grid item 
      style={cellStyle} 
      onClick={() => !open && setOpen(!open)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
    { shifts.filter(s => !!s).map((s, i) => {
      if(!usedDisplay.includes(s.ShiftId)) {
        usedDisplay.push(s.ShiftId);
        const key = `${s.ShiftId}-${s.WorkerShiftId}-${i}`;
        return (<div style={cellTextStyle} key={ key }>
        { 
            s.WorkerShiftId ? renderWorkerShift(sites, s) : renderNamedShift(sites, s) 
        }
        </div>);
      }
      })}
      <div style={{position: 'absolute', 'top': 0, 'right': '0px', zIndex: 9 }}>
        { shifts.length && showClear ? <ClearDayButton workerId={worker && worker.WorkerId} workerGroupId={workerGroup && workerGroup.WorkerGroupId} day={day} /> : null}
      </div>
      <ScheduleShiftsModal 
        open={!!open} 
        shifts={shifts} 
        day={day} 
        toClose={toClose}
        workerGroup={workerGroup} 
        worker={worker} 
      />
    </Grid>
}

export const cellStyle = {
  border: '1px black solid',
  minHeight: '55px',
  padding: '3px',
  width:"12%",
  textOverflow: 'elipsis',
  position: 'relative',
}
export const cellTextStyle = {
  fontSize: '0.7em',
  marginBlockStart: '1px',
  marginBlockEnd: '3px',
}
  