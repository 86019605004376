import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  Fab,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CssBaseline from '@material-ui/core/CssBaseline';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { getWorkers, getAvailableDevices,
  getAllDevicesAllCompanies, getCompanies,
  getAllUsersAllCompanies, getAllAgencyConsultants,
  getLabourAgencies,
} from '../../API';
import { WorkerActions, setDevices, setSnackbar } from '../../redux/actions/AllActions';
import { assign } from '../../Utils';
import UsersTable from './UsersTable';
import { usersTableColumns } from './UsersTableColumns';
import UsersForm from './UsersForm';
import { styles } from '../Common/GlobalStyles';


const initialUser = {
  DeviceId: '',
  PayrollId: '',
  Name: '',
  Email: '',
  Mobile: '',
  MobileNotifications: false,
  EmailNotifications: false,
  IsEnabled: true,
  IsWorker: true,
  IsSupervisor: false,
  IsAdministrator: false,
  Supervisor: '',
  IsArchived: false,
};


// Create ManageUsers Container
class ManageUsersContainer extends Component {
  // Set up state, fileter's default is showAll, editingUser is null
  state = {
    editingUser: null,
    open: false,
    snackOpen: false,
    extraDevices: [],
    showArchived: false,
  }

  // initialize constructor

  // Set initial
  componentDidMount() {
    setTimeout(() => {
      this.setInitialData();
      //this.pollNewDevices();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  createSuperUserDeviceDescription(device, workers, companies) {
    const company = companies.find(c => c.CompanyId === device.CompanyId);
    const worker = workers.find(w => w.DeviceId === device.DeviceId);
    const desc = `* ${device.DeviceId} (${company.Name}) ${worker ? worker.Name.substr(0, 10) : ''}`;
    return desc;
  }

  // using Api to get workers and using Redux to update view.
  setInitialData = async () => {
    const workers = await getWorkers();
    const agencyConsultants = await getAllAgencyConsultants();
    const labourAgencies = await getLabourAgencies();
    this.setState({ agencyConsultants, labourAgencies });

    if (workers.filter(u => u.IsEnabled).length < 3) {
      this.setState({ snackOpen: true });
    }

    this.props.setWorkers(workers);
    if (this.props.currentUser.IsSuperAdministrator) {
      const allDevicesAllCompanies = await getAllDevicesAllCompanies();
      const allCompanies = await getCompanies();
      const allWorkersAllCompanies = await getAllUsersAllCompanies();
      /* eslint-disable */
      allDevicesAllCompanies.sort((a, b) => {
          return a.CompanyId < b.CompanyId ? -1 : 1;
      });
      const extraDevices = allDevicesAllCompanies.filter(
        (d) => d.DeviceId[0] === '8' || d.DeviceId[0] === '3' || d.DeviceId[0] == '9').map(
          d => ({ ...d, Description: this.createSuperUserDeviceDescription(
            d, allWorkersAllCompanies, allCompanies)}));
      /* eslint-enable */
      this.setState({ extraDevices });
    }
  }

  pollNewDevices = async () => {
    const devices = await getAvailableDevices();

    if (!devices) {
      return;
    }

    const newDevice = devices.find(
      d => d.DeviceId
        && !this.props.users.find(d2 => d.DeviceId === d2.DeviceId)
        && !this.props.devices.find(d2 => d.DeviceId === d2.DeviceId));

    if (newDevice && !newDevice.Assigned) {
      this.props.setSnackbar({
        message: `Device ready to assign ${newDevice.Description}`,
        openTime: new Date().getTime(),
      });
    }
    this.props.setDevices(devices);
  }

  selectChange = (event) => {
    this.setState({ filter: event.target.value });
  }

  filterUsers = (users) => {
    if(this.state.showArchived) {
      return users;
    }
    return users.filter(u => !u.IsArchived);
  }

  onEditClick = (workerId) => {
    const user = this.props.users.find(u => u.WorkerId === workerId);
    this.setState({
      editingUser: user,
      open: true,
    });
  }

  onAddUserClick = () => {
    this.setState({
      editingUser: initialUser,
      open: true,
    });
  }

  onComplete = () => {
    this.setState({
      editingUser: null,
      open: false,
    });
  }

  toggleDrawer = (open) => {
    this.setState({ open });
  }

  render() {
    const {
      users, supervisors, classes,
    } = this.props;
    const { editingUser, open, extraDevices, agencyConsultants, labourAgencies } = this.state;

    const tableRows = this.filterUsers(users);

    const tablerr = tableRows.map(n => [
      n.Base64Image || '',
      n.PayrollId || '',
      n.DeviceId || '',
      n.Name || '',
      !!n.IsEnabled,
      !!n.IsWorker,
      !!n.IsSupervisor,
      !!n.IsAdministrator,
      n.WorkerId,
    ]);
    const openClass = open ? classes.drawerOpenWide : classes.drawerHidden;
    return (
      <div className={`${classes.root} manage-users`}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, openClass)}
          classes={{
            paper: classNames(classes.drawer, openClass),
          }}
          open={open}
        >
          {open && (
            <div className={classes.toolbar} onClick={() => { this.toggleDrawer(false); }}>
              <IconButton>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          )
          }
          {open && editingUser && (
            <div>
              <UsersForm
                onComplete={this.onComplete}
                onCancel={this.onComplete}
                originalObj={editingUser}
                supervisors={supervisors}
                extraDevices={extraDevices}
                agencyConsultants={agencyConsultants}
                labourAgencies={labourAgencies}
              />
            </div>
          )}
        </Drawer>
        <main className={`${classes.content} manage-users-content`}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={25000}
            open={this.state.snackOpen}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={(
              <span id="message-id">
                {'Always setup company and sites before supervisors and workers.'}
              </span>
            )}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={() => this.setState({ snackOpen: false })}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
          <div
            className="add-user-btn"
            style={{ position: 'absolute', zIndex: 2, padding: '15px' }}
            onClick={() => {
              this.toggleDrawer(true); this.onAddUserClick();
            }}
          >
            <Fab color="primary" size="small" aria-label="Add" className={classes.fab}>
              <AddIcon />
            </Fab>
          </div>
          <div
            className="show-archived"
            style={{ position: 'absolute', right: '10px', zIndex: 2, padding: '15px' }}
            onClick={() => {
              this.setState({ showArchived: !this.state.showArchived })
            }}
          >
            <Fab color={this.state.showArchived ? "primary" : "secondary"} size="small" aria-label="Add" className={classes.fab}>
              <ArchiveIcon />
            </Fab>
          </div>
          <UsersTable rows={tablerr} labels={usersTableColumns} onRowClick={this.onEditClick} />
        </main>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { workers, me } = state;
  const supervisors = workers.all.filter(x => x.IsSupervisor).map(
    x => assign(x, { Supervisor: x.WorkerId }));
  return {
    users: workers.all,
    supervisors,
    devices: me.devices,
    currentUser: me.me,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setWorkers: x => dispatch(WorkerActions.setWorkers(x)),
  setDevices: x => dispatch(setDevices(x)),
  setSnackbar: x => dispatch(setSnackbar(x)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, { withTheme: true })(ManageUsersContainer)));

// export default withRouter(ManageUsersContainer);
