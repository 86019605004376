import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  isMobile,
} from 'react-device-detect';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Drawer,
  IconButton,
  Button,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import SiteForm from './SiteForm';
import SitesList from './SitesList';
import UpdateSiteMap from './UpdateSiteMap';
import {
  setSites,
} from '../../redux/actions/AllActions';
import { getAllSites, getAllZones } from '../../API';
import { styles, mapStyle } from '../Common/GlobalStyles';
import { deactivateSite, reactivateSite } from '../../API';
import {
  STREET_MAP_URI,
  SITEM8_MAP_URI,
  RACE_MAP_URI,
  SAT_MAP_URI,
} from '../../Constants/Constants';


export function hideDrawControls() {
  const elems = document.getElementsByClassName('mapboxgl-ctrl-group');
  [...elems].forEach(el => el.classList.add('disabled'));
}

export function showDrawControls() {
  const elems = document.getElementsByClassName('mapboxgl-ctrl-group');
  [...elems].forEach(el => el.classList.remove('disabled'));
}


export class SetupSite extends Component {
  state = {
    editingSite: null,
    newSite: null,
    polygon: null,
    open: true,//!isMobile,
    showInactive: false,
    zones: [],
    baseMapUri: SAT_MAP_URI,
  }

  handleBaseMapChange = (e) => {
    this.setState({ baseMapUri: e.target.value});
  }

  toggleDrawer = (open) => {
    this.setState({ open });
  }

  componentDidMount() {
    this.getData().then(hideDrawControls);
  }

  // Draw a new polygon
  handleDrawCreate = (wktPolygon) => {
    this.setState({ polygon: wktPolygon });
    showDrawControls();
  }

  // Delete polygon
  handleDrawDelete = () => {
    this.setState({ polygon: null });
  }


  handleDrawUpdate = (wktPolygon) => {
    this.setState({ polygon: wktPolygon });
  }


  handleSiteClick = (site) => {
    this.setState({ newSite: null, editingSite: site });
    showDrawControls();
  }

  handleMapClick = (siteId) => {
    const { newSite, editingSite, sites } = this.props;
    if (!newSite && !editingSite) {
      const site = sites.find(s => s.SiteId === siteId);

      if (site) {
        this.setState({ newSite: null, editingSite: site });
        showDrawControls();
      }
    }
  }

  handleCancelClick = () => {
    this.setState({ editingSite: null, newSite: null });
    this.setState({ polygon: null });
    hideDrawControls();
    setTimeout(this.getData, 500);
  }

  handleNewSiteClick = (newSite) => {
    this.setState({ newSite, editingSite: null });
    showDrawControls();
  }

  getData = async () => {
    const sites = await getAllSites();
    const {
      setAllSites,
    } = this.props;
    setAllSites(sites);
  }

  handleActivationClick = () => {
    const { editingSite } = this.state;
    if (!window.confirm('are you sure?')) {
      return;
    }
    if (editingSite.Active) {
      deactivateSite(editingSite.SiteId).then(this.getData).then(this.handleCancelClick);
    } else {
      reactivateSite(editingSite.SiteId).then(this.getData).then(this.handleCancelClick);
    }
  }

  render() {
    const { sites, classes, IsSuperAdministrator } = this.props;
    const {
      editingSite, newSite, polygon, open, zones,
    } = this.state;
    const showForm = !!(editingSite || newSite);
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
          classes={{
            paper: classNames(classes.drawer, open ? classes.drawerOpen : classes.drawerClose),
          }}
          open={open}
        >
          {open ? (
            <div className={classes.toolbar} onClick={() => this.toggleDrawer(false)}>
              <IconButton>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          ) : (
            <div onClick={() => this.toggleDrawer(true)}>
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </div>
          )}
          {open && !showForm && (
            <SitesList
              sites={this.state.showInactive ? sites : sites.filter(s => s.Active)}
              onSiteClick={this.handleSiteClick}
              onNewSiteClick={this.handleNewSiteClick}
            />
          )}
          <Grid item xs={6} style={{ marginTop: '10px', paddingBottom: '10px', marginLeft: '10px' }}>
            <Button
              type="Button"
              variant="contained"
              color="primary"
              onClick={() => this.setState({ showInactive: !this.state.showInactive })}>
              {this.state.showInactive ? 'Hide Inactive' : 'Show Inactive'}
            </Button>
          </Grid>
          {open && showForm && (
            <Grid item style={{ marginTop: '10px', paddingBottom: '10px', marginLeft: '10px' }}>
              <Select onChange={this.handleBaseMapChange} value={this.state.baseMapUri}>
                <MenuItem value={STREET_MAP_URI}>streets</MenuItem>
                <MenuItem value={SITEM8_MAP_URI}>custom</MenuItem>
                <MenuItem value={RACE_MAP_URI}>custom2</MenuItem>
                <MenuItem value={SAT_MAP_URI}>satellite</MenuItem>
              </Select>
            </Grid>
          )}
          {open && showForm && (
            <SiteForm
              IsSuperAdministrator={IsSuperAdministrator}
              onCancelClick={this.handleCancelClick}
              onSaveClick={this.handleSaveClick}
              site={editingSite || newSite}
              polygon={polygon}
              editingSite={editingSite}
              newSite={newSite}
              
            />
          )}
          {open && showForm && (
            <Grid item xs={6} style={{ marginTop: '10px' }}>
              <Button
                type="Button"
                variant="contained"
                color="secondary"
                onClick={this.handleActivationClick}>
                {editingSite && editingSite.Active ? 'Deactivate' : 'Reactivate'}
              </Button>
            </Grid>)
          }
        </Drawer>

        <main className={classes.content} style={{ padding: 0, flexGrow: 1 }}>
          <UpdateSiteMap
            open={open}
            onDrawCreate={this.handleDrawCreate}
            onDrawDelete={this.handleDrawDelete}
            onDrawUpdate={this.handleDrawUpdate}
            sites={sites}
            editingSite={editingSite}
            newSite={newSite}
            polygon={polygon}
            onMapClick={this.handleMapClick}
            zones={zones}
            baseMapUri={this.state.baseMapUri}
          />
        </main>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { sites, me } = state;
  const { IsSuperAdministrator } = me.me;
  return { sites: sites.all, IsSuperAdministrator };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAllSites: ws => dispatch(setSites(ws)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, { withTheme: true })(SetupSite)));


