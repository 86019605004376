import logoDataUrl from "./logoDataUrl";


const stylesStr = `

.logo-img {
  width: 4cm;
}

#wrapper {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: #fff;
}

.header {
  margin: 0;
  padding: 1cm 1.5cm 0.8cm 1.5cm;
  width: 100%;
  display: table;
}

html, body {
  width:21cm; 
  margin:0;
  padding:0;
  top: 0;
  left: 0;
  font-family: Tahoma, sans-serif;
  line-height: 1.3rem;
}

.title {
  font-size: 2.2rem;
  font-weight: 100;
  letter-spacing: 0.41mm;
}

.logo-wrap {
  height: 2.5cm;
}

.center-text {
  marginLeft: auto;
  marginRight: auto;
  paddingTop: 0.5cm;
  text-align: center;
  height: 1.6cm;
}

.divider-title {
  border-top-style: solid;
  border-bottom-style: solid;
  border-color: #000000;
  border-width: 1px;
  background-color: #F5C800;
  margin: 0;
  padding-left: 1.5cm;
  padding-top: 1.5mm;
  padding-bottom: 1.1mm;
}

.left-section {
  display: table-cell;
  width: 50%;
}
.right-section {
  display: table-cell;
  width: 50%;
}

.map-img {
  width: 100%;
  height: 12.5cm;
}

.witness-list {
  padding: 0cm 0cm 0cm 1.5cm;
}

`;

// eslint-disable-next-line import/prefer-default-export
export function makeIncidentReportHTML(values, mapImgDataUrl) {
  const {
    incidentDate,
    incidentTime,
    name,
    printDate,
    printTime,
    contacts,
  } = values;

  const within50m = contacts.filter(c => c.Distance <= 50).map(c => `<li>${c.ContactName}</li>`).join('\n');
  const within200m = contacts.filter(c => c.Distance > 50 && c.Distance < 200).map(c => `<li>${c.ContactName}</li>`).join('\n');
  
  const html = `
    <html>
      <head>
      <style>
        ${stylesStr}
      </style>
      </head>
      </head>
      <body>
      <div id="wrapper">
      <div class="header">
        <div class="logo-wrap">
          <img class="logo-img" src="${logoDataUrl}" />
        </div>
        <div class="center-text">
          <span class="title">ACCIDENT REPORT</span>
        </div>
        <div class="">
          <span>Reported on the ${printDate} at ${printTime}</span>
        </div>
        <div class="">
          <span><b>Worker Injured:</b> ${name}</span>
        </div>
      </div>
      <div class="divider-title">
        <span>ACCIDENT PARTICULARS</span>
      </div>
      <div class="header">
        <div class="">
          <span><b>Date:</b> ${incidentDate}</span>
        </div>
        <div class="">
          <span><b>Time:</b> ${incidentTime}</span>
        </div>
      </div>
      <div class="map-img">
        <img class="map-img" src="${mapImgDataUrl}" />
      </div>
      <div class="witness-list">
        <div class="">
          <p><b>Witnesses:</b></p>
        </div>
        <div class="">
          <span>Within 50m</span>
        </div>
        <ul>
          ${within50m}
        </ul>
        <div class="">
          <span>Within 200m</span>
        </div>
        <ul>
          ${within200m}
        </ul>
      </div>
    </div>
  </body>
</html>
  `;

return html;
}
