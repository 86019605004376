import React, { Component } from 'react';
import {
  getTimesheetEntries, getTimesheetNotes,
  getMyTimesheetNotes, getMyTimesheetEntries,
  getUnpaidTimesheetEntries,
  regenerateTimesheetEntries,
  getWorkerShiftsForWorkerAndRange,
} from '../../API';
import {
  setApprovalTimesheet, 
  setTimesheetNotes,
  editTimesheet, 
  setTimesheetEntries, 
  setTimesheetStatus,
  setUnpaidTimesheetEntries, 
  setApprovalsWorkerShifts,
  setLoading,
} from '../../redux/actions/AllActions';
import getTimesheetList from '../../redux/selectors/timesheetList';
import getActiveApprovalTimesheet from '../../redux/selectors/approvalCurrentTimesheet';


class ApprovalsForm extends Component {
  changeTimesheet = async (ts, updateEntries = false) => {
    let timesheetnotes;
    let entries;
    let workerShifts = [];
    const {
      token,
      _setTimesheetEntries,
      _setApprovalTimesheet,
      _setTimesheetNotes,
      _setUnpaidTimesheetEntries,
      _setApprovalsWorkerShifts,
      _setLoading,
    } = this.props;
    _setLoading(true);
    if (token) {
      entries = await getMyTimesheetEntries(token, ts.TimesheetId);
      timesheetnotes = await getMyTimesheetNotes(token, ts.TimesheetId);
    } else {
      entries = await getTimesheetEntries(ts.TimesheetId);
      timesheetnotes = await getTimesheetNotes(ts.TimesheetId);
    }
    const unpaidTimesheetEntries = await getUnpaidTimesheetEntries(ts.TimesheetId, updateEntries);
    const wdts = await getWorkerShiftsForWorkerAndRange(ts.WorkerId, ts.StartDateTime, ts.FinishDateTime);
    _setTimesheetEntries(entries);
    _setUnpaidTimesheetEntries(unpaidTimesheetEntries);
    _setApprovalTimesheet(ts.TimesheetId);
    _setTimesheetNotes(timesheetnotes);
    _setApprovalsWorkerShifts(wdts);
    _setLoading(false);
  }

  onNextClick = () => {
    const { timesheetList } = this.props;
    let index = this.getIndex();
    index += 1;
    if (index === (timesheetList.objects.length)) {
      this.changeTimesheet(timesheetList.objects[0]);
    } else {
      this.changeTimesheet(timesheetList.objects[index]);
    }
  }

  onBackClick = () => {
    const { timesheetList } = this.props;
    let index = this.getIndex();
    index -= 1;
    if (index === -1) {
      this.changeTimesheet(timesheetList.objects[timesheetList.objects.length - 1]);
    } else {
      this.changeTimesheet(timesheetList.objects[index]);
    }
  }

  onSelectChange = async ({ target }) => {
    const ts = this.props.timesheetList.findById(parseInt(target.value, 0));
    this.changeTimesheet(ts);
  }

  handleSwitchClick = () => {
    const { timesheet } = this.props;
    const Status = timesheet.Status === 0 ? 1 : 0;
    this.props._setTimesheetStatus({ Id: timesheet.TimesheetId, Status });
    this.props._setApprovalTimesheet(timesheet.TimesheetId);
  }

  handleRegenerateClick = async () => {
    const { timesheet } = this.props;
    // Call the regenerate endpoint
    await regenerateTimesheetEntries(timesheet.TimesheetId);
    this.changeTimesheet(timesheet, true);
  }

  getIndex = () => {
    const {
      timesheet, timesheetList,
    } = this.props;
    return timesheet && timesheetList.findIndex(timesheet.TimesheetId);
  }

  render() {
    return (<div />);
  }
}

export const mapStateToProps = (state) => {
  const {
    workers, me,
  } = state;
  const timesheetList = getTimesheetList(state);
  const timesheet = getActiveApprovalTimesheet(state);
  const worker = workers.all.find(w => timesheet && (w.WorkerId === timesheet.WorkerId));

  return {
    company: me.company,
    worker,
    timesheetList,
    timesheet,
    workerShifts: state.timesheets.workerShifts,
    workers: workers.all,
  };
};

export const mapDispatchToProps = dispatch => ({
  _setApprovalTimesheet: x => dispatch(setApprovalTimesheet(x)),
  _editTimesheet: x => dispatch(editTimesheet(x)),
  _setTimesheetEntries: x => dispatch(setTimesheetEntries(x)),
  _setTimesheetNotes: x => dispatch(setTimesheetNotes(x)),
  _setTimesheetStatus: x => dispatch(setTimesheetStatus(x)),
  _setUnpaidTimesheetEntries: x => dispatch(setUnpaidTimesheetEntries(x)),
  _setApprovalsWorkerShifts: x => dispatch(setApprovalsWorkerShifts(x)),
  _setLoading: x => dispatch(setLoading(x)),
});

export default ApprovalsForm;
