import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  Checkbox,
} from '@material-ui/core';
import {
  Autocomplete,
} from '@material-ui/lab';
import {
  getShifts,
  createShift,
  getAllSites,
} from '../../API';
import {
  setShifts,
  setSites,
} from '../../redux/actions/AllActions';


export function CreateShiftForm(props) {

  useEffect(() => {
    getAllSites().then(props._setSites)
  }, []);
  const [selectedSite , setSelectedSite] = useState({ SiteId: 0, Name: '' });
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [name, setName] = useState('');
  const [multiSite, setMultiSite] = useState(false);

  const canSave = !!(selectedSite.SiteId && startTime && endTime && (name || props.noName));
  
  const onSaveShiftClick = () => {
    // props.noname means that this is the schedule modal saving a shift
    if(props.noName) {
      canSave && props.saveShift(selectedSite.SiteId, startTime, endTime, null, null, null, null, multiSite).then(() => {
        setSelectedSite({ SiteId: 0, Name: '' });
        setStartTime('');
        setEndTime('');
        setName('');
        setMultiSite(false);
      }).then(getShifts).then(props._setShifts);
    } else {
      canSave && props.saveShift(selectedSite.SiteId, startTime, endTime, name, multiSite).then(() => {
        setSelectedSite({ SiteId: 0, Name: '' });
        setStartTime('');
        setEndTime('');
        setName('');
        setMultiSite(false);
      }).then(getShifts).then(props._setShifts);
    }

    
  }

  if(!props.sites.length) {
    return <div/>
  }
  return (
    <Grid>
      <Grid style={styles.fieldPadding}>
        <Autocomplete 
          style={{ maxLength: '320px' }}
          id="user-into-worker-group"
          options={props.sites.filter(a => a.Active)}
          getOptionSelected={(option, value) => option.SiteId === value.SiteId}
          onChange={(event, newValue) => {
            setSelectedSite(newValue || { SiteId: 0, Name: '' });
          }}
          value={selectedSite}
          getOptionLabel={(opt) => opt.Name}
          renderInput={(params) => <TextField { ...params } label="Select Site" variant="outlined" />}
        />
      </Grid>
      { !props.noName && <Grid style={styles.fieldPadding}>
        <TextField 
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          type="text"
          placeholder="Name"
        />
        <InputLabel shrink>Name</InputLabel>
      </Grid> }
      <Grid style={styles.fieldPadding}>
        <TextField 
          value={startTime} 
          onChange={(e) => setStartTime(e.currentTarget.value)} 
          type="time" 
        />
        <InputLabel shrink>start time</InputLabel>
      </Grid>
      <Grid style={styles.fieldPadding}>
        <TextField 
          value={endTime} 
          onChange={(e) => setEndTime(e.currentTarget.value)} 
          type="time" 
        />
        <InputLabel shrink>end time</InputLabel>
      </Grid>
      <Grid style={styles.fieldPadding}>
        <Checkbox
          title='Multi Site'
          value={multiSite}
          checked={multiSite}
          onChange={(e) => {
            setMultiSite(e.target.checked);
          }}
        />
        <InputLabel shrink>Multi Site</InputLabel>
      </Grid>
      <Grid style={styles.fieldPadding}>
        <Button disabled={!canSave} onClick={onSaveShiftClick}>Save</Button>
      </Grid>
    </Grid>
  );

}


const styles = {
  fieldPadding: { padding: '4px 0px 2px 0px' },
}


export const mapStateToProps = (state) => ({
  sites: state.sites.all,
});

export const mapDispatchToProps = dispatch => ({
  _setSites: x => dispatch(setSites(x)),
  _setShifts: x => dispatch(setShifts(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateShiftForm));
  