import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Grid, Drawer, CssBaseline, 
} from '@material-ui/core';
import {
  isMobile,
} from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DevicesReportMap from './DevicesReportMap';
import { getDevicesReport, getApprovalsData, getRawData, getAllZones } from '../../API';
import {
   WorkerActions, setSites, setTimesheetEntries,
  setApprovalTimesheet, setTimesheets,
} from '../../redux/actions/AllActions';
import Timesheet from '../../Models/Timesheet';
import ReportingDevicesForm from './ReportingDevicesForm';
import { styles } from '../Common/GlobalStyles';
import { utcToNZT } from '../../Utils';



const START_DAY = '00:00';
const END_DAY = '23:59';


class DevicesReport extends Component {
  state = {
    selectedWorkerId: null,
    startDate: moment(),
    reportData: {},
    open: true,//!isMobile,
    isTimeFiltering: false,
    startTime: START_DAY,
    endTime: END_DAY,
    rawdats: [],
    zones: [],
  }

  toggleDrawer = (e, open = false) => {
    this.setState(
      prevState => ({ open: open || !prevState.open }),
    );
  }

  handleStartTimeChange = ({ currentTarget }) => {
    this.setState({ startTime: currentTarget.value });
  }

  handleEndTimeChange = ({ currentTarget }) => {
    this.setState({ endTime: currentTarget.value });
  }

  handleToggleTimeFilter = (val) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ isTimeFiltering: !this.state.isTimeFiltering });
  }

  handleDeviceSelectChange = ({ target }) => {
    this.setState({ selectedWorkerId: target.value });
    this.handleSearch(this.state.startDate, target.value);
  }

  handleDateChange = ({ target }) => {
    this.setState({ startDate: target.value });
    this.handleSearch(target.value, this.state.selectedWorkerId);
  }

  componentDidMount() {
    this.getData();
  }

  handleSearch = async (startDate, selectedWorkerId) => {
    const mStartDate = moment(startDate);
    if (!mStartDate.isValid()) {
      return;
    }
    const res = await getDevicesReport(moment(startDate).format('YYYY-MM-DD'), selectedWorkerId);
    if(this.props.me.IsSuperAdministrator){
      const rawdats = await  getRawData(selectedWorkerId, 
        moment(startDate).format('YYYY-MM-DD'),
        moment(startDate).add(1, 'days').format('YYYY-MM-DD'));
      this.setState({ reportData: res, rawdats });
    } else {
      this.setState({ reportData: res });
    }
  }

  getData = async () => {
    const { sites, workers } = await getApprovalsData();
    const sDate = this.state.startDate.format('YYYY-MM-DD');
    const {
      setAllSites, setAllWorkers, setAllTimesheets,
      setAllTimesheetEntries, setCurrentTimesheet,
    } = this.props;

    setAllSites(sites);
    setAllWorkers(workers);

    const res = await getDevicesReport(sDate, workers[0].WorkerId);
    const zones = await getAllZones();
    this.setState({ reportData: res, zones });
    
    if (workers.length > 1) {
      this.setState({
        selectedWorkerId: workers[0].WorkerId,
        startDate: sDate,
      });
    }
  }


  renderDrawerContent = () => {
    const {
      workers,
    } = this.props;
    const {
      open, startDate, selectedWorkerId, startTime, endTime,
      isTimeFiltering,
    } = this.state;
    const openDrawer = () => this.toggleDrawer(null, true);
    return (open
      && (
      <ReportingDevicesForm
        openDrawer={openDrawer}
        workers={workers.filter(w => !w.IsArchived)}
        handleDateChange={this.handleDateChange}
        handleDeviceSelectChange={this.handleDeviceSelectChange}
        startDate={startDate}
        selectedWorkerId={selectedWorkerId}
        handleEndTimeChange={this.handleEndTimeChange}
        handleStartTimeChange={this.handleStartTimeChange}
        handleToggleTimeFilter={this.handleToggleTimeFilter}
        startTime={startTime}
        endTime={endTime}
        isTimeFiltering={isTimeFiltering}
      />
      )
    );
  }

  getReportData = () => {
    const {
      isTimeFiltering, reportData, startTime, endTime,
    } = this.state;
    const { locationTimestamps } = reportData;
    if (!isTimeFiltering || !(locationTimestamps && locationTimestamps.length)) {
      return reportData.locationTimestamps;
    }
    const startParts = startTime.split(':');
    const endParts = endTime.split(':');
    const startTimeFilter = moment(locationTimestamps[0].LocationDateTime);
    startTimeFilter.minutes(parseInt(startParts[1], 0));
    startTimeFilter.hours(parseInt(startParts[0], 0));
    const endTimeFilter = moment(locationTimestamps[0].LocationDateTime);
    endTimeFilter.minutes(parseInt(endParts[1], 0));
    endTimeFilter.hours(parseInt(endParts[0], 0));

    const filteredLocations = locationTimestamps.filter((l) => {
      const time = moment(l.LocationDateTime);
      return time.isAfter(startTimeFilter) && time.isBefore(endTimeFilter);
    });
    return filteredLocations;
  }

  filterRawDats = (rawdats) => {
    const {
      isTimeFiltering, startTime, endTime
    } = this.state;
    if(!isTimeFiltering) {
      return rawdats;
    }
    return rawdats.filter(r => {
      const dateutc = moment.utc(r.split(';')[3], 'YYMMDDHHmmss');
      const datenz = utcToNZT(dateutc);
      const startParts = startTime.split(':');
      const endParts = endTime.split(':');
      const startTimeFilter = moment(datenz);
      startTimeFilter.minutes(parseInt(startParts[1], 0));
      startTimeFilter.hours(parseInt(startParts[0], 0));
      const endTimeFilter = moment(datenz);
      endTimeFilter.minutes(parseInt(endParts[1], 0));
      endTimeFilter.hours(parseInt(endParts[0], 0));
      return datenz.isAfter(startTimeFilter) && datenz.isBefore(endTimeFilter);
    });
  }

  render() {
    const {
      sites, classes,
    } = this.props;
    const {
      open,
      rawdats
    } = this.state;
    const locationTimestamps = this.getReportData();
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
          classes={{
            paper: classNames(classes.drawer, open ? classes.drawerOpen : classes.drawerClose),
          }}
          open={open}
        >
          <div className={classes.toolbar} onClick={this.toggleDrawer}>
            <IconButton>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          { this.renderDrawerContent() }
        </Drawer>

        <main className={classes.content} style={{ padding: '0px' }}>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ padding: '0px' }}>
              <DevicesReportMap 
                rawdats={this.filterRawDats(rawdats)} 
                sites={sites.filter(s => !!s.Active)} 
                locationTimestamps={locationTimestamps || []} 
                zones={this.state.zones}
              />
            </Grid>
          </Grid>
        </main>
      </div>


    );
  }
}

const mapStateToProps = (state) => {
  const {
    workers, sites, timesheets, timesheetEntries, me
  } = state;
  let timesheet = null;
  if (timesheets.approvalCurrentTimesheetId) {
    timesheet = new Timesheet(timesheets.approvalCurrentTimesheetId);
    timesheet.setTimesheetEntryList(timesheetEntries.all.filter(
      r => r.TimesheetId === timesheet.TimesheetId));
  }

  return { workers: workers.all, sites: sites.all, timesheet, me: me.me };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAllWorkers: ws => dispatch(WorkerActions.setWorkers(ws)),
  setAllSites: ws => dispatch(setSites(ws)),
  setAllTimesheetEntries: ws => dispatch(setTimesheetEntries(ws)),
  setCurrentTimesheet: ws => dispatch(setApprovalTimesheet(ws)),
  setAllTimesheets: ws => dispatch(setTimesheets(ws)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(DevicesReport)));
