import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import MapGL, {
  GeoJSONLayer,
  Popup,
  Feature,
  Marker,
} from 'react-mapbox-gl';
import {
  MAPBOX_TOKEN,
  SAT_MAP_URI,
} from '../../Constants/Constants';
import * as turf from '@turf/turf';


const Map = MapGL({
  accessToken: MAPBOX_TOKEN,
  preserveDrawingBuffer: true,
});


export default class ZoneReportMap extends Component {
  state = {
    zoom: 7,
    pitch: 45,
    center: [174.822493, -36.855736],
  }

  componentDidMount() {
  }

  handleStyleLoad = map => {
    if(map) {
      map.resize();
    }
  }

  getMapProps = () => {
    const center = this.props.center || this.state.center;
    return {
      center,
      style: SAT_MAP_URI,
      onMove: this.handleMove,
      className: 'dashboard-map dashboard-responsive',
      onStyleLoad: this.handleStyleLoad,
      onClick: this.handleMapClick,
    };
  }

  render() {
    const {
      colorMap,
      sites,
      zones,
    } = this.props;
    const mapProps = this.getMapProps();
    const zoneLayers = zones.map(z => {
      return (
        <GeoJSONLayer
            key={"polys-as2x" + z.ZoneId}
            data={turf.featureCollection([z.GeoJSON])}
            linePaint={{
              'line-color': z.color,
              'line-width': 2,
            }}
            fillExtrusionPaint={{
              'fill-extrusion-color': z.color,
              'fill-extrusion-height': 0.4,
              'fill-extrusion-opacity': 0.5,
            }}
            type="fill-extrusion"
            extruded
        />
      )
    });
    return (
      <Map
        {...mapProps }
        fitBounds={undefined}
        onRender={(map) => this.props.setMapRef(map)}
        preserveDrawingBuffer={true}
      >
        {
          [(
            <GeoJSONLayer
              key="polys-asx"
              data={turf.featureCollection(sites.map(s => s.GeoJSON))}
              linePaint={{
                'line-color': '#a69924',
                'line-width': 4,
              }}
              fillExtrusionPaint={{
                'fill-extrusion-color': '#f5e342',
                'fill-extrusion-height': 0.2,
                'fill-extrusion-opacity': 0.2,
              }}
              type="fill-extrusion"
              extruded
            />
          ),
          ...zoneLayers,
          ]
        }
      </Map>
    );
  }
}

