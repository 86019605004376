import React, { Component } from 'react';
import {
  FormControl,
  Button,
  Paper,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Common/FormStyles';
import { updateCompany } from '../../API';
import CompaniesList from './CompanyList';

class ManageCompanies extends Component {
  state = {
    companies: [],
  }

  archiveCompany(company) {
    var question = company.IsArchived ? 'unarchive' : 'archive';
    var answer = window.prompt(`are you sure you want to ${question} ${company.Name}? type yes to confirm.`);
    if(answer === 'yes') {
      return updateCompany({ ...company, IsArchived: !company.IsArchived }).then(() => window.alert('done'));
    }
    return Promise.resolve();
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper style={{ padding: '70px', margin: '70px', minWidth: '500px' }}>
        <Grid container>
          <Grid item xs={12}>
            <CompaniesList onClick={ this.archiveCompany } />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ManageCompanies);
