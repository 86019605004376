import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import * as turf from '@turf/turf';
import moment from 'moment-timezone';
import {
  getWorkers,
  getCloseContacts,
  getAllSites,
  getAllZones,
  getWorkerLocationsByDateRange,
} from '../../API';
import {
  WorkerActions,
  setSites,
  setZones,
  setLocations,
  setSnackbar,  
  setLoading,
} from '../../redux/actions/AllActions';
import ContactTracingMap from './ContactTracingMap';
import ContactTracingResultList, { getZoneNamesFromContact } from './ContactTracingResultList';
import { randomColor, parseUrlQueryParameters, caseStatusColor } from '../../Utils';
import  { 
  clusteriseContactTracingResults,
  locationTimestampsToPoints,
} from '../../GeoFunctions';
import { CovidCaseActions } from '../../redux/actions/CovidCaseActions';
import RefreshIcon from '@material-ui/icons/Refresh';
import { WorkerCovidActions } from '../../redux/actions/WorkersCovidActions';
import WarningIcon from '@material-ui/icons/Warning';
import { ContactTracingActions } from '../../redux/actions/ContactTracingActions';
import { ContactTracingForm } from './ContactTracingForm';
import { Worker } from '../../api/index';
import { RootReducerType } from '../../redux/store/rootReducer';
import ContactReportPrintButton from './ContactReportPrintButton';


export const styles = (theme) => ({
  buttonTypography: {
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontSize: '12px',
  },
  warningIcon: {
    transformY: '20px',
    height: '15px',
    transform: 'translateY(2px)',
  },
});

interface ContactTracingPageState {
  colorMap: any;
  mapColorStops: Array<any>;
  loaded: boolean;
  mapRef: any;
  hasResult: boolean;
};

interface StateProps {
  workers: Array<any>;
  customSettings: any;
  currentUser: any;
  selectedWorker: Worker;
  covidCaseStatus: string;
  firstSymptomDate: string;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  startTime: string;
  endTime: string;
  distanceBuffer: number;
  timeBuffer: number;
};

interface DispatchProps {
  setAllWorkers(ws: any): void;
  _setAllSites(ws: any): void;
  _setSnackbar(ws: any): void;
  _setLoading(ws: any): void;
  _setSelectedWorker(ws: any, b: any): void;
  _setAllZones(ws: any): void;
  loadWorkerCovidCase(ws: any): void;
  loadCreateCovidCaseForm(ws: any): void;
  _setContactGeojson(ws: any): void;
  _setWorkerTrack(ws: any): void;
};

interface OwnProps {
  classes: any;
  history: any;
}

type ContactTracingPageProps = StateProps & DispatchProps & OwnProps;

export class ContactTracingPageComponent extends Component<ContactTracingPageProps> {
  state:ContactTracingPageState = {
    loaded: false,
    hasResult: false,
    colorMap: {},
    mapColorStops: [],
    mapRef: null,
  }

  componentDidMount() {
    this.loadData();
  }

  setMapRef = (ref) => {
    this.setState({ mapRef: ref });
  }

  handleFormChange = (changes: ContactTracingPageState) => {
    this.setState({ formState: changes });
  }

  componentWillReceiveProps(newProps, newState) {
    const {
      loadWorkerCovidCase,
      loadCreateCovidCaseForm,
    } = this.props;
    const sWorker = this.props.selectedWorker || newProps.selectedWorker;
    if(sWorker && !this.state.loaded) {
      loadWorkerCovidCase(newProps.selectedWorker.covidCasesUri);
      loadCreateCovidCaseForm(newProps.selectedWorker.covidCaseCreateFormUri);
      this.setState({ loaded: true });
    }
    const toCompare = ['fromDate', 'toDate', 'startTime', 'endTime', 'distanceBuffer', 'timeBuffer'];
    toCompare.forEach(k => {
      if(JSON.stringify(newProps[k]) !== JSON.stringify(this.props[k])) {
        this.setState({ hasResult: false });
      }
    })
  }

  loadData = async () => {
    const {
      setAllWorkers, _setLoading, _setAllZones, _setAllSites, selectedWorker, history,
      _setSelectedWorker,
    } = this.props;
    
    _setLoading(true);
    const [ workers, sites, zones ] = await Promise.all([
      getWorkers(),
      getAllSites(),
      getAllZones(),
    ]);
    const colorMap = {};
    const mapColorStops = [];
    workers.forEach((w) => {
      colorMap[`${w.WorkerId}`] = randomColor();
      mapColorStops.push([w.WorkerId, colorMap[`${w.WorkerId}`]]);
    });
    this.setState({ colorMap, mapColorStops });
    setAllWorkers(workers);
    _setAllZones(zones);
    _setAllSites(sites);
    
    if(selectedWorker) {
      await this.getCloseContactData(selectedWorker.workerId);
    } else {
      const workerId = parseUrlQueryParameters(history,'workerId');
      const name = parseUrlQueryParameters(history,'name');
      const uri = parseUrlQueryParameters(history,'uri');
      _setSelectedWorker(workerId, uri);
    }
    _setLoading(false);
    this.setState({ loaded: true });
   
  }

  getCloseContactData = async (workerId) => {
    this.props._setLoading(true);
    const { _setContactGeojson } = this.props;
    const {
      fromDate,
      toDate,
      distanceBuffer,
      timeBuffer,
      startTime,
      endTime,
      workers,
    } = this.props;

    const startDateTime = moment.tz(`${fromDate.format('YYYY-MM-DD')} ${startTime}`, "Pacific/Auckland");
    const endDateTime = moment.tz(`${toDate.format('YYYY-MM-DD')} ${endTime}`, "Pacific/Auckland");
    await this.getCloseContactTrack(workerId);
    const contactData = await getCloseContacts(workerId,
      startDateTime.utc().format('YYYY-MM-DD'),
      endDateTime.utc().format('YYYY-MM-DD'),
      timeBuffer,
      distanceBuffer,
      startDateTime.utc().format('HH:mm'),
      endDateTime.utc().format('HH:mm')
    );
    
    if (contactData && contactData.length) {
      const geojson = clusteriseContactTracingResults(contactData);
      
      _setContactGeojson(geojson);
      this.setState({ hasResult: true });
    } else {
      this.handleNoContactResult();
    }

    this.props._setLoading(false);
  }

  handleNoContactResult = async () => {
    this.setState({ contactGeojson: null, contactLog: null, hasResult: true });
    this.props._setSnackbar({
      message: `No contact detected`,
      openTime: new Date().getTime(),
    });
  }

  clearResult = () => {
    this.setState({ contactGeojson: null, contactLog: null, workerTrack: null, hasResult: false });
  }

  formCallback = ({ fromDate, toDate, fromTime, toTime, distancBuffer, timeBuffer }) => {
    this.setState({ fromDate, toDate, fromTime, toTime, distancBuffer, timeBuffer });
  }

  getCloseContactTrack = async (workerId) => {
    
    const {
      fromDate,
      toDate,
      startTime,
      endTime,
    } = this.props;

    const startDateTime = moment.tz(`${fromDate.format('YYYY-MM-DD')} ${startTime}`, "Pacific/Auckland");
    const endDateTime = moment.tz(`${toDate.format('YYYY-MM-DD')} ${endTime}`, "Pacific/Auckland");

    const locs = await getWorkerLocationsByDateRange({ 
      WorkerId: workerId,
      StartDateTimeUTC: startDateTime.utc().format('YYYY-MM-DD HH:mm'),
      EndDateTimeUTC: endDateTime.utc().format('YYYY-MM-DD HH:mm'),
    });

    if (locs.length) {
      this.props._setWorkerTrack(locationTimestampsToPoints(locs));
    }
  }

  render() {
    const { 
      classes,
      covidCaseStatus,
      selectedWorker,
      firstSymptomDate,
    } = this.props;

    const {
      mapColorStops,
      colorMap,
    } = this.state;
    
    const covidStatusStyle = caseStatusColor(covidCaseStatus);

    return (
      <Grid container className="dashboard-container">
        <Grid item className="left" xs={12} md={8} lg={9}>
          <Grid item xs={12}>
            <ContactTracingMap colorStops={ mapColorStops } setMapRef={this.setMapRef} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={3} className="right" style={
          { backgroundColor: '#fff', maxWidth: '325px', paddingLeft: '20px' }}>
          <div style={{ height: '50px', padding: '15px 20px 0px 20px' }}>
            <Typography variant="h5" noWrap style={{ margin: '10px', color: '#6e6e6e' }}>
              { selectedWorker && selectedWorker.name }
            </Typography>
          </div>

          <div  style={{ height: '35px', margin: '0px', marginLeft: '18px' }}>
            { covidCaseStatus && <div className={classes.buttonTypography} style={covidStatusStyle}>
              <WarningIcon className={classes.warningIcon} /> {`Covid-19 Status ${covidCaseStatus}`}
            </div> }
          </div>
          <ContactTracingForm  />
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '6px', paddingRight: '6px', height: '15px', zIndex: 9 }}> 
            <ContactReportPrintButton
              covidCaseStatus={covidCaseStatus}
              firstSymptomDate={firstSymptomDate}
              mapRef={this.state.mapRef}
            />
            <IconButton
              aria-label="Search"
              disabled={!!this.state.hasResult}
              color={"primary"}
              onClick={() => this.getCloseContactData(selectedWorker && selectedWorker.workerId) }
            >
              <RefreshIcon />
            </IconButton>                         
          </div>
          <div style={{maxHeight: 'calc(100vh - 350px)', overflow: 'auto'}}>
            <ContactTracingResultList
              colorMap={colorMap}
            />
          </div>   
        </Grid>    
      </Grid>
    );
  }
}

export const mapStateToProps = (state: RootReducerType):StateProps => {
  const { workers, me, workersCovid, covidCase, contactTracing } = state;
  const caseStatus = covidCase.covidCase && covidCase.covidCase.uri && covidCase.covidStatusList.find(
    s => s.key === covidCase.covidCase.caseStatus);

  const {
    fromDate,
    toDate,
    startTime,
    endTime,
    distanceBuffer,
    timeBuffer,
  } = contactTracing;

  return {
    workers: workers.all,
    customSettings: me.company.customSettings,
    currentUser: me.me,
    selectedWorker: workersCovid.selectedWorker,
    covidCaseStatus: caseStatus ? caseStatus.description : 'Not a Case',
    firstSymptomDate: covidCase.covidCase ? covidCase.covidCase.caseDateTime : '',
    fromDate,
    toDate,
    startTime,
    endTime,
    distanceBuffer,
    timeBuffer,
  };
};

const mapDispatchToProps = (dispatch):DispatchProps => ({
  setAllWorkers: ws => dispatch(WorkerActions.setWorkers(ws)),
  _setAllSites: ws => dispatch(setSites(ws)),
  _setSnackbar: ws => dispatch(setSnackbar(ws)),
  _setLoading: ws => dispatch(setLoading(ws)),
  _setSelectedWorker: (workerId, uri) => {
    // [Rimu 09/09/2020] moving from the old endpoints to the RestApi - so part way through this as below
    dispatch(WorkerActions.setSelectedDashboardWorker(workerId));
    dispatch(WorkerCovidActions.setSelectedWorker(uri));
  },
  _setAllZones: ws => dispatch(setZones(ws)),
  loadWorkerCovidCase: uri => dispatch(CovidCaseActions.findWorkersCovidCase(uri)),
  loadCreateCovidCaseForm: uri => dispatch(CovidCaseActions.getUpdateCovidCaseForm(uri)),
  _setContactGeojson: ws => dispatch(ContactTracingActions.setContactGeojson(ws)),
  _setWorkerTrack: ws => dispatch(ContactTracingActions.setWorkerTrack(ws)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(ContactTracingPageComponent)));
