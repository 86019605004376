import { handle, Handlers } from 'redux-pack';
import { PayloadAction } from '../actions/types'
import { WORKER_ActionTypes } from '../actions/WorkersCovidActions';
import { FormModelBase, RestModelBase} from '../../RestClient/RestApiMicroFormat';
import { CovidCase, Worker } from '../../api/index';


export type CovidCaseApiModel = CovidCase & RestModelBase

export interface WorkersState {  
  workersList: Array<Worker>;
  selectedWorker: Worker | undefined;
}

const initialState = {
  workersList: [],
  selectedWorker: undefined,
};

export default function WorkerReducerV2(state: WorkersState, action: PayloadAction<any>): WorkersState {
  const newState = makeNewState(state, action);
  return newState;
}

function makeNewState(state: WorkersState = initialState, action: PayloadAction<any>): WorkersState {
  
  const commonOnStartHandler = (s: WorkersState, a: PayloadAction<any>) => ({
    ...s, 
    isLoading: true, 
    debugLastCommand: a.type+"start",
  });

  const commonErrorHandler = (s: WorkersState,a: PayloadAction<Error>) => ({
     ...s, 
     error: a.payload.message, 
     debugLastCommand:a.type+"+error" 
    });
  

  switch (action.type) {
    
    case WORKER_ActionTypes.SET_SELECTED_WORKER: {
      const handlerActions : Handlers<WorkersState, Worker, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s,action),
        failure: commonErrorHandler,
        success: (s,a) => ({
          ...s, 
          selectedWorker: a.payload, 
          isLoading:false, 
          debugLastCommand:a.type+"+success"
        }),
      };

      return handle(state, action, handlerActions);
    }

    case WORKER_ActionTypes.SET_WORKER_LIST:{
      const handlerActions : Handlers<WorkersState, Array<Worker>, Error, any, any> = {
        start: (s)  => commonOnStartHandler(s, action),
        failure: commonErrorHandler,
        success: (s, a) => ({
          ...s, 
          workersList:a.payload, 
          isLoading:false, 
          debugLastCommand:a.type+"+success"
        }),
        
      };
      return handle(state, action, handlerActions);
    }

    default:
      return state;
  }
}