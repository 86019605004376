import BaseModelList from './BaseModelList';
import WorkerList from './WorkerList';
import Timesheet from './Timesheet';

export default class TimesheetList extends BaseModelList {
  constructor(data) {
    super(data, 'TimesheetId', Timesheet);
  }

  setWorkerList = (objects) => {
    this.workerList = new WorkerList(objects);
  }

  getSelectOptions = (includeName) => this.objects.map((t) => {
    const w = this.workerList.findById(t.WorkerId);
    return {
      text: `${includeName ? w.Name + ' ' : ''} ${t.getDateDescription()}`,
      TimesheetId: t.TimesheetId,
    };
  });
}
