import React, { useState, useEffect } from 'react';
import MapGL, {
  Layer,
  Feature,
} from 'react-mapbox-gl';
import {
  MAPBOX_TOKEN,
  SAT_MAP_URI,
  
} from '../../../Constants/Constants';
import 'mapbox-gl/dist/mapbox-gl.css';


const Map = MapGL({
  accessToken: MAPBOX_TOKEN,
});


export default function(props) {

  const [zoom, setZoom] = useState(5);
  const [center, setCenter] = useState([171, -44]);

  useEffect(() => {
    props.lnglat.lng && setCenter([props.lnglat.lng, props.lnglat.lat]);
  }, [props.lnglat.lng, props.lnglat.lat])

  const onZoomEnd = (map) => {
    setZoom(map.getZoom());
  }

  const onMoveEnd = (map) => {
    const lnglat = map.getCenter();
    setCenter([lnglat.lng, lnglat.lat]);
  }

  const onLoaded = (map) => {
    map.resize();
    map.on('idle', function(){
      map.resize();
    });
  }

  return <Map
    style={'mapbox://styles/mapbox/satellite-streets-v11'}
    containerStyle={{
      height: '100%',
      width: '100%',
      minHeight: '85vh',
      minWidth: '100vw',
    }}
    onStyleLoad={ onLoaded }
    onClick={props.handleMapClick}
    center={ center }
    zoom={[zoom]}
    onZoomEnd={onZoomEnd}
    onMoveEnd={onMoveEnd}
  >
    <Layer type="circle" 
      id="circles" 
      paint={{
        "circle-radius": 8,
        "circle-color": "#03fcec",
        'circle-opacity': 0.2
      }}
    >
      <Feature coordinates={[props.lnglat.lng, props.lnglat.lat]}  />
    </Layer>
    <Layer type="circle" 
      id="circlesbigger" 
      paint={{
        "circle-radius": 12,
        "circle-color": "#03fcec",
        'circle-opacity': 0.3
      }}
    >
      <Feature coordinates={[props.lnglat.lng, props.lnglat.lat]}  />
    </Layer>
    <Layer type="symbol" 
      id="marker" 
      layout={{ "icon-image": "viewpoint-15" }}
      paint={{
        "icon-halo-width": 2
      }}
    >
      <Feature coordinates={[props.lnglat.lng, props.lnglat.lat]}  />
    </Layer>
  </Map>
}