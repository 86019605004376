import React, { Component } from 'react';
import {
  Paper,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  Typography,
  Fab,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Common/FormStyles';
import { getLabourAgencies, postAddLabourAgency, postUpdateLabourAgency, getAgencyConsultants, postAgencyConsultant, deleteAgencyConsultant } from '../../API';


export function AgencyConsulant(props) {
  const { Name, CellPhone, Email, onClick, ShouldReceiveAllContact } = props;
  return <ListItem 
      button 
    >
      <ListItemText
          primary={Name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'block' }}
                component="p"
                variant="body2"
              >
                { Email }
              </Typography>
              <Typography
                sx={{ display: 'block' }}
                component="p"
                variant="body2"
              >
                { CellPhone }
              </Typography>
              {ShouldReceiveAllContact &&
                <Typography
                  sx={{ display: 'block', color: 'red' }}
                  component="p"
                  variant="body2"
                >
                  ALL CONTACT
                </Typography>
              }
            </React.Fragment>
          }
      />
      <div style={{ float: 'right' }}>
          <Fab size="small" color="secondary" onClick={onClick}>
            <DeleteIcon />
          </Fab>
        </div>
    </ListItem>
}



class LabourAgencies extends Component {
  state = {
    agencies: [],
    newAgencyName: '',
    newConsultant: { Name: '', Email: '', CellPhone: '', AgencyConsultantId: null },
    selectedAgency: null,
    agencyConsultants: [],
  }

  componentDidMount() {
    this.getData();
  }

  onDeleteConsultantClick = async (agcid) => {
    if(window.confirm("are you sure you want to delete the consultant?")) {
      await deleteAgencyConsultant(agcid);
      this.getData();
    }
  }

  getData = async () => {
    const data = await Promise.all([
      getLabourAgencies(),
    ]);
    let agencyConsultants = [];
    if(this.state.selectedAgency) {
      agencyConsultants = await getAgencyConsultants(this.state.selectedAgency.LabourAgencyId);
    }
    this.setState({
      agencies: data[0],
      newAgencyName: '',
      newConsultant: {},
      agencyConsultants,
    });
  }

  onNewAgencyNameChange = (e) => {
    this.setState({ 
      newAgencyName: e.target.value,
    });
  }

  saveNewAgency = () => {
    postAddLabourAgency(this.state.newAgencyName).then(this.getData);
  }

  saveNewConsultant = () => {
    postAgencyConsultant(this.state.newConsultant).then(this.getData);
  }

  onConsultantInputChange = (e) => {
    let { name, value } = e.target;
    if(name == 'ShouldReceiveAllContact') {
      value = !!e.target.checked;
    }
    const newConsultant = { 
      ...this.state.newConsultant,
      [name]: value,
      LabourAgencyId: this.state.selectedAgency.LabourAgencyId,
      AgencyConsultantId: null,
    };
    this.setState({ newConsultant });
  }

  onLabourAgencySelect = async (labourAgency) => {
    this.setState({ selectedAgency: labourAgency });
    var consultants = await getAgencyConsultants(labourAgency.LabourAgencyId);
    this.setState({ agencyConsultants: consultants });
  }

  renderLabourAgency = (labourAgency) => {
    return (
      <ListItem 
        button 
        onClick={() => this.onLabourAgencySelect(labourAgency)}
        key={`laba${labourAgency.LabourAgencyId}`}
      >
        <ListItemText>
          { labourAgency.Name }
        </ListItemText>
      </ListItem>
    );
  }

  render() {
    const {
      selectedAgency,
      agencyConsultants,
      newConsultant,
      newAgencyName,
    } = this.state;
    const canSaveConsultant = !!(newConsultant.Name && newConsultant.Email && newConsultant.CellPhone);
    const canSaveNewAgencty = !!newAgencyName;

    return (
      <div style={{ padding: '15px', minHeight: '70vh', overflow: 'auto' }}>
        <Grid container>
          <Grid item xs={6} md={3}>
            <Grid item>
              Add a new Agency
            </Grid>
            <Grid item style={{ paddingTop: '6px'}}>
              <FormControl fullWidth>
                <TextField onChange={this.onNewAgencyNameChange} placeholder="agency name" value={newAgencyName} />
              </FormControl>
            </Grid>
            <Grid item style={{ paddingTop: '6px'}}>
              <Button onClick={this.saveNewAgency} color="primary" variant="outlined" disabled={!canSaveNewAgencty}>
                Save
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: '12px'}}>
              Add a new Consultant
            </Grid>
            <Grid item style={{ paddingTop: '6px'}}>
              <FormControl fullWidth>
                <TextField name="Name" onChange={this.onConsultantInputChange} placeholder="name" disabled={!selectedAgency} />
              </FormControl>
            </Grid>
            <Grid item style={{ paddingTop: '6px'}}>
              <FormControl fullWidth>
                <TextField name="Email" onChange={this.onConsultantInputChange} placeholder="email" disabled={!selectedAgency} />
              </FormControl>
            </Grid>
            <Grid item style={{paddingTop: '6px'}}>
              <FormControl fullWidth>
                <TextField name="CellPhone" onChange={this.onConsultantInputChange} placeholder="cellphone +6423333333" disabled={!selectedAgency} />
              </FormControl>
            </Grid>
            <Grid item style={{paddingTop: '6px'}}>
              <FormControl fullWidth>
                <FormHelperText>Check to make this consultant recieve all SMS</FormHelperText>
                <input type="checkbox" onChange={this.onConsultantInputChange} name="ShouldReceiveAllContact" />
              </FormControl>
            </Grid>
            <Grid item style={{ paddingTop: '6px'}}>
              <Button onClick={this.saveNewConsultant} color="primary" variant="outlined" disabled={!canSaveConsultant}>
                Save
              </Button>
            </Grid>                                
          </Grid>
          <Grid item xs={6} md={4}>
            <List
              component="nav"
              subheader={<ListSubheader component="div">All Agencies (click to select)</ListSubheader>}
            >
              { this.state.agencies.map(this.renderLabourAgency) }
            </List>
          </Grid>
          { selectedAgency && 
            <Grid item xs={6} md={5}>
              <List
                  component="nav"
                  subheader={<ListSubheader component="div">Consultants for { selectedAgency.Name }</ListSubheader>}
                >
                  { agencyConsultants.map((ac) => (<AgencyConsulant { ...ac } onClick={() => this.onDeleteConsultantClick(ac.AgencyConsultantId)} />)) }
              </List>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(LabourAgencies);
