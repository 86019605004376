import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/AccountCircle';
import * as turf from '@turf/turf';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../Approvals/ApprovalsForm';


export function getZoneNamesFromContact(contactGeojson, zones, contactLogEntry) {
  const points = contactGeojson.features.filter(
    f => f.properties.WorkerId === contactLogEntry.workerId).map(
      p => p.geometry.coordinates);

  const zoneNames = zones.filter(
    z => !!turf.pointsWithinPolygon(
      turf.points(points), turf.buffer(z.GeoJSON, 2, { units: 'meters' })).features.length).map(z => z.Name);
  return zoneNames;
}


export function ContactTracingResultListComponent({ contactLog, colorMap, zones, contactGeojson }) {
  const items = contactLog.map((cl, i) => {
    const color = colorMap[cl.workerId];
    const zoneNames = getZoneNamesFromContact(contactGeojson, zones, cl);
    const zonesText = zoneNames.length ? `Contact within ${zoneNames.join(',')}` : '';
    return (
      <ListItem key={i}>
        <ListItemIcon>
          <PersonIcon style={{ color }} />
        </ListItemIcon>
        <ListItemText primary={cl.contactWith} secondary={zonesText} />
      </ListItem>
    );
  });

  return (
    <List
      style={{ maxHeight: '100%' }}
      component="div"
    >
      <ListItem key="keyskeys">
        <ListItemText primary="Contact Detected" />
      </ListItem>
      {items}
      <ListItem key="keys" style={{ opacity: 1 }}>
        <ListItemText primary="" />
      </ListItem>
      <ListItem key="keys0" style={{ opacity: 1 }}>
        <ListItemText primary="" />
      </ListItem>
      <ListItem key="keys1" style={{ opacity: 1 }}>
        <ListItemText primary="" />
      </ListItem>
    </List>
  );
}

export const mapStateToProps = (state) => {
  const { contactTracing, zones, workers } = state;
  const { contactGeojson } = contactTracing;
  const contactLog = makeContactLog(contactGeojson);

  return {
    contactLog,
    zones: zones.all,
    workers: workers.all,
    contactGeojson,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactTracingResultListComponent);

export function makeContactLog({ features }) {

  const workerIds = [...new Set(features.map(f => f.properties.WorkerId))];

  const log = workerIds.map(id => features.find(f => f.properties.WorkerId === id)).map(
    (g) => ({
      name: g.properties.Name,
      time: g.properties.LocationDateTime,
      contactWith: g.properties.ContactName,
      workerId: g.properties.WorkerId
    }));

  log.sort((a, b) => {
    const aa = moment(a.time);
    const bb = moment(b.time);
    if (aa.isSame(bb)) {
      return 0;
    }
    return aa.isBefore(bb) ? -1 : 1;
  });

  return log;
}


