/* eslint-disable react/no-unused-state */
import { connect } from 'react-redux';
import React from 'react';
import * as turf from '@turf/turf';
import MapGL, {
  GeoJSONLayer,
  Popup,
} from 'react-mapbox-gl';
import { WorkerActions } from '../../redux/actions/AllActions';
import {
  MAPBOX_TOKEN,
  SAT_MAP_URI,
} from '../../Constants/Constants';
import { uniqInArray } from '../../Utils';
import moment from 'moment';
import DashboardMapBase from './DashboardMapBase';
import { randomColor } from '../../Utils';

const Map = MapGL({
  accessToken: MAPBOX_TOKEN,
});




class WorkerLocationMap extends DashboardMapBase {
  state = {
    zoom: 11,
    pitch: 0,
    fitBounds: [
      [180.4833, -34.05265942137597],
      [163.65234, -47.57652],
    ],
    bearing: undefined,
  }

  componentDidMount() {
    if (this.props.siteSelected) {
      this.setState({ fitBounds: this.getBounds(this.props.siteSelected.GeoJSON) });
      this.setState({ bearing: this.getBearing() });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setFitboundsFromNextProps(nextProps);
  }


  getWorkerFeature = (selectedWorkerId) => {
    const { workerLocations } = this.props;
    return workerLocations.features.find(wl => wl.properties.WorkerId === selectedWorkerId);
  }

  getMapURI = () => SAT_MAP_URI

  getMapProps = () => {
    const { pitch, fitBounds } = this.state;
    return {
      pitch: [pitch],
      fitBounds,
      // fitBoundsOptions: { padding: 0, offset: [0, -60] },
      style: this.getMapURI(),
      onMove: this.handleMove,
      className: 'dashboard-map dashboard-responsive',
      onStyleLoad: this.handleStyleLoad,
    };
  }

  handleCircleHover = (e) => {
    const { features, lngLat, type } = e;

    if (type === 'mouseleave') {
      return this.setState({ popup: null });
    }

    /* eslint-disable */
    const names = uniqInArray(features.map(
      ({ properties }) => {
        const worker = this.props.workers.find(w => w.WorkerId === properties.WorkerId);
        return `${worker.Name} ${moment(properties.LocationDateTime).format('HH:mm')}`;
      }));
    const toShow = names.map((n) => {
      return (
        <div key={n}>
          {n}
        </div>
      );
    });
    /* eslint-enable */

    const popup = (
      <Popup
        key={`popup${Math.random()}`}
        coordinates={[lngLat.lng, lngLat.lat]}
        offset={{
          'bottom-left': [12, -38], bottom: [0, -38], 'bottom-right': [-12, -38],
        }}
      >
        { toShow }
      </Popup>
    );
    this.setState({ popup });
  }

  render() {
    const {
      selectedWorkerId, workerLocations, zones,
    } = this.props;
    const selectedWorker = workerLocations && workerLocations.features.find(wl => wl.properties.WorkerId === selectedWorkerId);
    const workerFeat = selectedWorker && turf.featureCollection([selectedWorker]);
    const zonesColl = turf.featureCollection(zones.map(z => z.GeoJSON));
    const extraLayer3 = workerLocations && (
    <GeoJSONLayer
      key="points-asx-00122"
      id="points-layer-221333"
      data={workerFeat || workerLocations}
      circlePaint={{
        'circle-opacity': 0.8,
        'circle-radius': 8,
        'circle-color': '#fc4103',
      }}
      circleOnMouseEnter={this.handleCircleHover}
      circleOnMouseLeave={this.handleCircleHover}
    />
    );
    
    for(var i=0; i<zones.length; i++){
      zones[i].color = randomColor();
    }

    const extraLayer2 = zones.map(z => {
      return (
        <GeoJSONLayer
            key={"polys-as2x" + z.ZoneId}
            data={turf.featureCollection([z.GeoJSON])}
            linePaint={{
              'line-color': z.color,
              'line-width': 2,
            }}
            fillExtrusionPaint={{
              'fill-extrusion-color': z.color,
              'fill-extrusion-height': 0.4,
              'fill-extrusion-opacity': 0.5,
            }}
            type="fill-extrusion"
            extruded
        />
      )
    });

    const mapProps = this.getMapProps();
    return (
      <Map
        {...mapProps}
      >
        {
          [(
            <GeoJSONLayer
              key="polys-asx"
              data={this.getSiteFeatureCollection()}
              linePaint={{
                'line-color': '#a69924',
                'line-width': 8,
              }}
              fillExtrusionPaint={{
                'fill-extrusion-color': '#f5e342',
                'fill-extrusion-height': 19,
                'fill-extrusion-opacity': 0.35,
              }}
              type="fill-extrusion"
              extruded
            />
          ),
          extraLayer3,
          extraLayer2,
          this.state.popup,
          ]
        }
      </Map>
    );
  }
}

const mapStateToProps = state => ({
  selectedWorkerId: state.ui.selectedDashboardWorkerId,
});

const mapDispatchToProps = (dispatch) => ({
  selectWorker: x => dispatch(WorkerActions.setSelectedDashboardWorker(x)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkerLocationMap);
