import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getRawData } from '../../API';
import momenttz from 'moment-timezone';

export function makeBatteryChargeFromVoltage(rawbatt) {
  const voltage = parseInt(rawbatt) / 1000; 
  if (voltage > 4.15) {
    return 100;
  } else if (voltage > 3.8) {
    return (55 +  (voltage - 3.8) * 128.571);
  } else if(voltage > 3.65) {
    return 5 + (voltage - 3.65) * 333.333;
  } else if (voltage > 3.5) {
    return (voltage - 3.5) * 33.333;
  }
  return 0;
}

const eventNames = {
  0: 'Reserved1',
  1: 'Reserved2',
  2: 'AutoReport',
  3: 'Button',
  4: 'Lowbatt',
  5: 'BatteryPowerOFF',
  6: 'ChargeStart',
  7: 'ChargeFull',
  8: 'PowerOnPowerSavingMode',
  9: 'PowerOffPowerSavingMode',
}

function DeviceLog(props) {
  const { log } = props;
  if(log.includes('R') || log.includes('timestamp')) {
    return <div key={log}>{log}</div>
  };
  const parts = log.split(';');

  const [indx, mode, imei, timestamp,
    gps, wifi, cell, eventid, data_combo,
    battery, signal, sequence ] = parts;
  const [satnum, lat, lon ] = gps.split(',');
  const time = momenttz.utc(`20${timestamp}`, 'YYYYMMDDHHmmSS').local().format('MMM-DD HH:mm');
  const batpct = makeBatteryChargeFromVoltage(battery);
  return <div key={log}>
    <div>
      <span style={{ color: '#999', fontSize: '9px'}}>{ log }</span>
    </div>
    <div>
      {time} mode:{mode}, {batpct.toFixed(0)}%, sats: {satnum}, sig: {signal}, pos:[ {lat},{lon} ], evt: {eventNames[eventid]}
    </div>
  </div>
}


export default function({ workerId, startDate, endDate }) {

    const [data, setData] = useState([]);

    useEffect(() => {
      const a = moment(startDate).format('yyyy-MM-DD HH:MM:SS');
      const b = moment(endDate).format('yyyy-MM-DD HH:MM:SS');
      getRawData(workerId, a, b).then(logs => setData(logs));
    }, [workerId]);

    return <div>
      {
         data.map((t, i) => <DeviceLog key={i+t} log={t} />)
      }

    </div>

}