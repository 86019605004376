import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import {
  getWorkerShifts,
  postWorkerShift,
  copyWorkerShifts,
} from '../../API';
import {
  setWorkerShifts,
  setScheduleNextWeek,
  setSchedulePreviousWeek,
  setScheduleGroupView,
  setLoading,
} from '../../redux/actions/AllActions';
import { formatUtcDateAsNZTime, isNowDuringOrBeforePeriod } from '../../Utils';



export function ScheduleWeekControl(props) {
  const { start, end, showGroups, week } = props;
  useEffect(() => {
    props._setLoading(true);
    getWorkerShifts(start, end).then((d) => {
      props._setWorkerShifts(d);
      props._setLoading(false);
    });
  }, [start]);

  const copyWeekClick = () => {
    if(!window.confirm('copy week will ignore single worker shifts and only copy group shifts')) {
      return;
    }
    props._setLoading(true);
    copyWorkerShifts(start).then(() => getWorkerShifts(start, end)).then(
      (s) => {
        props._setWorkerShifts(s);
        props._setLoading(false);
      });
  }

  return (
    <Grid container style={{padding: '20px', overflowY: 'auto' }} justify="space-between">
      <Grid item xs={12}>
        { formatUtcDateAsNZTime(week[0], 'dddd DD MMMM')}
        { " - "}
        { formatUtcDateAsNZTime(week[6], 'dddd DD MMMM')}
      </Grid>
      <Grid item xs={6}>
        <Button onClick={() => props._setScheduleGroupView(!showGroups)}>
          { showGroups ? 'Show Workers' : 'Show Groups' }
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Grid container justify="flex-end" direction="row">
          {props.showCopyWeek && <Button onClick={copyWeekClick}>
            Copy Week
          </Button>}
          <Button onClick={props._setSchedulePreviousWeek}>
            Previous Time
          </Button>
          <Button onClick={props._setScheduleNextWeek}>
            Next Time
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

}

const cellStyle = { 
  flexDirection: 'row',
  display: 'flex',
  flexGrow: 1,
  minHeight: '50px',
  margin: '1px',
  border: '1px black solid',
};

export const mapStateToProps = (state) => {
  const {
    workerShifts,
  } = state;
  const startDate = workerShifts.uiState.week[0];
  const endDate = workerShifts.uiState.week[6];

  return {
    week: workerShifts.uiState.week,
    start: startDate.format('YYYY-MM-DD HH:mm'),
    end: endDate.format('YYYY-MM-DD HH:mm'),
    showGroups: workerShifts.uiState.showGroups,
    showCopyWeek: isNowDuringOrBeforePeriod(startDate, endDate),
  };
};

export const mapDispatchToProps = dispatch => ({
  _setWorkerShifts: (x) => dispatch(setWorkerShifts(x)),
  _setScheduleNextWeek: (x) => dispatch(setScheduleNextWeek(x)),
  _setSchedulePreviousWeek: (x) => dispatch(setSchedulePreviousWeek(x)),
  _setScheduleGroupView: (x) => dispatch(setScheduleGroupView(x)),
  _setLoading: (x) => dispatch(setLoading(x)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleWeekControl));
  