import React, { Dispatch, PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Theme,
  List,
  ListItem,
} from "@material-ui/core";
import { Worker, CovidCase, CovidCaseHistory } from '../../api/index';
import { RootReducerType } from "../../redux/store/rootReducer";
import MUIDataTable from 'mui-datatables';
import { CovidCaseActions } from '../../redux/actions/CovidCaseActions';
import { CovidCaseState } from "../../redux/reducers/CovidCaseReducer";
import { isoDateStringToDayFormat, IsoStringIsBeforeIsoString } from '../../Utils';
import { FormFieldType, FormOption, FormModelBase } from "../../RestClient/RestApiMicroFormat";
import { PayloadAction } from "../../redux/actions/types";
import HistoryItem from './HistoryItem';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: 20,
    overflow: 'auto',
  },
}));


interface StateProps {
  historyList?: CovidCaseHistory;
  historyListUri?: string;
}

interface DispatchProps {
  loadHistoryList(uri: string) : void;
}

type Props = StateProps & DispatchProps;

export function CovidCaseHistoryList(props: PropsWithChildren<Props>): JSX.Element {
  const classes = useStyles();
  const [historyLoaded, setHistoryLoaded] = useState(false);
  // componentDidMount
  useEffect(() => {
    
    if(!historyLoaded && props.historyListUri) {
      props.loadHistoryList(props.historyListUri!);
      setHistoryLoaded(true);
    }

  });

  if(!props.historyList) {
    return <div />;
  }

  return (

    <List className={classes.root} id="covid-cases-page">
      { props.historyList!.changes.map(
        (c, i) => <HistoryItem { ...c } prefix="COVID-19" key={i} />) }
    </List>
  );
}


export const mapStateToProps = (state: RootReducerType, ownProps: any): StateProps => {
  
  const covidCaseState:CovidCaseState = state.covidCase;
  return {
    //using this Uri top get the options
    historyListUri: covidCaseState.covidCase && covidCaseState.covidCase.historyUri! ,
    historyList: covidCaseState.covidCaseHistory,
  };
}

export const mapDispatchToProps = (dispatch:<T>(v:any) => Promise<PayloadAction<T>>, ownProps: any): DispatchProps => {
  return {
    loadHistoryList: (uri) => dispatch(CovidCaseActions.getcovidCaseHistory(uri)), 
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CovidCaseHistoryList);

