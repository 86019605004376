import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { RestClientActions } from './redux/actions/ConfigurationActions';
import store from './redux/store/rootStore';
import { getEnvVariables } from './Constants/EnvironmentVariables';
import RouterProvider from './RouterProvider';
import { IS_DEV_ENV } from './Constants/Constants';
import './App.css';


const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2196f3', // light blue
      type: 'light',
    },
    secondary: {
      main: '#ffa733', // orange
      type: 'light',
    },
    tertiary: {
      main: '#ffea00', // yellow
      type: 'light',
    },
    warning: {
      main: '#ffae42',
    },
  },
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none!important',
        height: 'calc(100vh - 191px)'
      },
    },
  },
});




class App extends Component {
  state = {
    ready: false,
  }
  componentDidMount() {
    // Initialise
    getEnvVariables().then(() => {
      store.dispatch( RestClientActions.getEnvironment() );
      store.dispatch( RestClientActions.getRestClient() );
      this.setState({ ready: true });
    })
    
  }

  render() {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <div style={{ maxWidth: '100vw' }}>
            { this.state.ready && <RouterProvider /> }
          </div>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
