import { createSelector } from 'reselect';
import Timesheet from '../../Models/Timesheet';
import {
  getapprovalCurrentTimesheet,
  getSites,
  getCompany,
  getWorkers,
  getApprovalsTimesheetEntries,
  getUnpaidTimesheetEntries,
} from './common';

const getters = [
  getapprovalCurrentTimesheet,
  getSites,
  getCompany,
  getWorkers,
  getApprovalsTimesheetEntries,
  getUnpaidTimesheetEntries,
];

const getActiveApprovalTimesheet = createSelector(getters,
  (timesheet, sites, company, workers, tsEntries, unpaidTimesheetEntries) => {
    if (!timesheet) {
      return null;
    }
    const ts = new Timesheet(timesheet);
    ts.setCompany(company);
    ts.setWorker(workers.find(w => w.WorkerId === ts.WorkerId));
    ts.setTimesheetEntryList(tsEntries);
    ts.timesheetEntryList.setSiteList(sites);
    ts.setUnpaidEntries(unpaidTimesheetEntries);
    return ts;
  });

export default getActiveApprovalTimesheet;
