/* eslint-disable */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Container from './Container';
import Approvals from './Components/Approvals/Approvals';
import DevicesReport from './Components/ReportingDevices/DevicesReport';
import ReportingTimesheets from './Components/ReportingTimesheet/ReportingTimesheets';
import Dashboard from './Components/Dashboard/Dashboard';
import MyTimesheet from './Components/MyTimesheet/MyTimesheet';
import ManageUsers from './Components/SetUpManageUsers/ManageUsers';
import SettingsForm from './Components/Settings/SettingsForm';
import SetupSite from './Components/Sites/SetupSite';
import SetupZone from './Components/Zones/SetupZone';
import SuperAdminPage from './Components/SuperAdmin/SuperAdminPage';
import DashboardContact from './Components/ContactTracing/ContactTracingPage';
import {CovidCaseForm} from './Components/CovidCase/CovidCaseForm';
import CovidCasesPage from './Components/CovidCase/CovidCasesPage';
import WorkerGroupsPage from './Components/WorkerGroups/WorkerGroupsPage';
import ShiftsPage from './Components/Shifts/ShiftsPage';
import WorkerShiftsPage from './Components/WorkerShifts/WorkerShiftsPage';
import { getEnvVariables } from './Constants/EnvironmentVariables';
import Logout from './Components/Auth/Logout';
import MobileDashboardPage from './Components/MobileDashboard/MobileDashboardPage';
import IncidentReportPage from './Components/IncidentReport/IncidentReportPage';
import ZoneReportPage from './Components/ZoneReport/ZoneReportPage';
import WorkerTotalsReportPage from './Components/TotalsReport/WorkerTotalsReportPage';

import Login from './Components/Auth/Login';
import { isIOS } from 'react-device-detect';


class RouterProvider extends Component {
  state = {
    new: 9,
    apiRoot: null,
  }

  componentDidMount() {
    if(window.location.pathname === 'logout') {
      return;
    }
    getEnvVariables();
    /* evil monkey patch to fix the render blocking
       caused by router + redux */
    const forcerender = () => this.setState(
      { new: new Date().getTime() });
    const pushState = history.pushState;
    history.pushState = function () {
      pushState.apply(history, arguments);
      forcerender();
    };
    const replaceState = history.replaceState;
    history.replaceState = function () {
      replaceState.apply(history, arguments);
      forcerender();
    };
        
  }

  shouldComponentUpdate() {
    return true;
  }

  render() {
  // eslint-disable-next-line no-undef
    const locationpathname = window.location.pathname + this.state.new;

    return (
      <Router locationpathname={locationpathname}>
        <Route exact path="/logout" render={p => <Logout locationpathname={locationpathname} />} />
        <Container locationpathname={locationpathname}>
          <Route path="/devicereport" render={p => <DevicesReport {...p} locationpathname={locationpathname} />} />
          <Route path="/approvals" render={p => <Approvals {...p} locationpathname={locationpathname} />} />
          <Route path="/timesheets" render={p => <ReportingTimesheets {...p} locationpathname={locationpathname} />} />
          <Route exact path="/dashboard" render={p => <Dashboard {...p} />} locationpathname={locationpathname} />
          <Route exact path="/" render={p => <Dashboard {...p} />} locationpathname={locationpathname} /> 
          <Route exact path="/contact" render={p => <DashboardContact {...p} />} locationpathname={locationpathname} />
          <Route exact path="/users" render={p => <ManageUsers {...p} />} locationpathname={locationpathname} />
          <Route exact path="/mytimesheet" render={p => <MyTimesheet {...p} locationpathname={locationpathname} />} />
          <Route exact path="/locations" render={p => <DevicesReport {...p} />} locationpathname={locationpathname} />
          <Route exact path="/login" render={myprops => <Login {...myprops} locationpathname={locationpathname} />} />
          <Route exact path="/sites" render={p => <SetupSite {...p} locationpathname={locationpathname} />} />
          <Route exact path="/zones" render={p => <SetupZone {...p} locationpathname={locationpathname} />} />
          <Route exact path="/ts/:access_token" render={p => <MyTimesheet {...p} locationpathname={locationpathname} />} />
          <Route exact path="/company" render={p => <SettingsForm {...p} locationpathname={locationpathname} />} />
          <Route exact path="/admin-portal" render={this.renderHangfireForm} />
          <Route exact path="/superadmin" render={p => <SuperAdminPage {...p} locationpathname={locationpathname} />} />
          <Route exact path="/createcovidcase" render={p => <CovidCaseForm {...p} locationpathname={locationpathname} />} />
          <Route exact path="/covidcases" render={p => <CovidCasesPage {...p} locationpathname={locationpathname} />} />
          <Route exact path="/workergroups" render={p => <WorkerGroupsPage { ...p} locationpathname={locationpathname} /> } />
          <Route exact path="/shifts" render={p => <ShiftsPage { ...p} locationpathname={locationpathname} /> } />
          <Route exact path="/schedule" render={p => <WorkerShiftsPage { ...p} locationpathname={locationpathname} /> } />
          <Route exact path="/m/:access_token" render={p => <MobileDashboardPage { ...p} locationpathname={locationpathname} /> } />
          <Route exact path="/incident" render={p => <IncidentReportPage { ...p} locationpathname={locationpathname} /> } />
          <Route exact path="/productivity" render={p => <ZoneReportPage { ...p} locationpathname={locationpathname} /> } />
          <Route exact path="/supervisorTotals" render={p => <WorkerTotalsReportPage { ...p} locationpathname={locationpathname} /> } />
          { /*isIOS && <Route exact path="/" render={p => <MobileDashboardPage { ...p} locationpathname={locationpathname} /> } /> */}
        </Container>
      </Router>
    );
  }
}

export default RouterProvider;
